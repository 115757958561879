import React from "react";
import InviteStructureTitle from "./InvteStructureTitle";
import InviteCounts from "./InviteCounts";
import InviteStructureTable from "./InviteStructureTable";
import PageWrapper from "../../../Routes/PageWrapper";

function InviteStructure () {
    return(
        <>
        <PageWrapper>
            <div className="wrapperPartners">
            <InviteStructureTitle/>
            <InviteStructureTable/>
            </div>
           
            </PageWrapper>
        </>
    )
}

export default InviteStructure