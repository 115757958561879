// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billsSecondWrapper {
  max-width: 87.1440897325%;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/Style/Bills/BillsWrapper.scss"],"names":[],"mappings":"AACA;EACI,yBAAA;EACA,cAAA;AAAJ","sourcesContent":["\n.billsSecondWrapper{\n    max-width: 1010px / 1159px * 100%;\n    margin: 0 auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
