import React from "react";
import MoneyTransactionTitle from "./MoneyTransactionTitle";
import MoneyTransaction from "./MoneyTransaction";
import { Outlet } from "react-router-dom";
import HorizontalLinearAlternativeLabelStepper from './StepBar'

function MoneyTransactionWrapper () {

    return(
        <div className="profileWrapper" style={{padding:'10px', paddingTop:'40px', height:'100vh', overflow:'hidden'}}>
            <MoneyTransactionTitle/>
            <div className="moneyTransactionWrapper">
                <MoneyTransaction/>
                {/* <HorizontalLinearAlternativeLabelStepper/>
                <Outlet/> */}
            </div>
        </div>

    )
}

export default MoneyTransactionWrapper