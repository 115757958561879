import React, { useEffect, useState, useRef } from "react";
import { Divider, Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import "../../../Style/cabinet/Cabinet.scss";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../../images/trash.svg";
import { ReactComponent as Edit } from "../../../images/edit.svg";
import ImageCropperModal from "../../../Modal/PhotoEditorModal";
import PhotoDeleteModal from "../../../Modal/PhotoDeleteModal";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import ShareIcon from "@mui/icons-material/Share";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
function Cabinet({ isSmallScreen, isOpen, onClose }) {
  const data = useSelector((state) => state.reducer.data);
  const [cabData, setCabData] = useState([]);
  const [inviter, setInviter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setModalDelete] = useState(false);
  const { t, i18n } = useTranslation();
  const textFieldRefs = useRef([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [photoCab, setPhotoCab] = useState(new Map());
  const [photoInviter, setPhotoInviter] = useState(new Map());
  const [photoId, setPhotoId] = useState();
  const [open, setOpen] = React.useState(false);
  const [valueEmail, setValueEmail] = useState('')
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const getUser = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/consultant`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setCabData(response.data.personal);
          setInviter(response.data.inviter);
          getPhotoId(response.data.personal.urlId);
          getPhotoInviter(response.data.inviter.urlId);
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const textFields = [{ id: 1, value: `${cabData.referralLink}` }];

  // Получить фото Personal
  const getPhotoId = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });

      setPhotoCab(URL.createObjectURL(response.data));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // Получить фото Inviter

  const getPhotoInviter = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });

      setPhotoInviter(URL.createObjectURL(response.data));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // Модальное окно Редактирования

  const hendleOpenModalPhotoEditor = () => {
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const handleSave = async (cropper) => {
    const apiUrl = ENDPOINTS.USER + `/avatar`;
    cropper
      .getCroppedCanvas({
        width: 96,
        height: 96,
        fillColor: "#fff",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      })
      .toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("image", blob, "image.png");

        try {
          const response = await axios.put(apiUrl, formData, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
          });
        } catch (error) {
          console.error("Upload error:", error);
        } finally {
          getUser();
        }
      }, "image/png");
  };

  // Модальное окно Удаления

  const hendleOpenModalDelete = () => {
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setModalDelete(false);
  };

  const handleDelete = async () => {
    getUser();
  };

  const styleDivider = {
    width: "90%",
    margin: "0 auto",
  };

  // Копия ссылок

  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setSnackbarMessage(`Copied: ${value}`);
      setOpenSnackbar(true);
    });
  };

  const handleShare = () => {
    setOpen(true);
  };

  const handleCloseShare = () => {
    setOpen(false);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleChangeEmail = (event) => {
    const newValue = event.target.value
    setValueEmail(newValue)
  }

  const handleSendEmail = async () => {
    const payload = {
      email: valueEmail
    }
    const apiUrl = urlFromTemplate(ENDPOINTS.MAIL) + `/invite`
    try {
      const response = await axios.post(apiUrl,payload,{
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
    } catch (error) {
      console.error(error)
    } finally {
      setValueEmail('')

    }


  }

  return (
    <>
      {!isSmallScreen && (
        <Drawer
          anchor="right"
          open={isOpen}
          onClose={onClose}
          PaperProps={{
            sx: {
              width: "250px",
            },
          }}
        >
          <div className="wrapper">
            <div style={{ position: "sticky", top: "0px" }}>
              <div className="myAccountWrapper">
                <div className="myAccount">
                  <div className="myAcountImageWrapper">
                    <div
                      className="myAccountImage"
                      style={{ backgroundImage: `url(${photoCab})` }}
                    >
                      <div className="buttons">
                        <button
                          className="hover-button"
                          onClick={hendleOpenModalDelete}
                        >
                          <Trash style={{ width: "20px", height: "20px" }} />
                        </button>
                        <button
                          className="hover-button"
                          onClick={hendleOpenModalPhotoEditor}
                        >
                          <Edit style={{ width: "25px", height: "25px" }} />
                        </button>
                      </div>
                    </div>
                    <div className="levelAccountWrapper">
                      <div className="levelAccountText">
                        {cabData.statusName}
                      </div>
                    </div>
                  </div>
                  <div className="myAccountTextWrapper">
                    <div
                      className="myAccountImageLogin"
                      style={{ backgroundImage: `url(${photoCab})` }}
                    ></div>
                    <div className="myAccountLoginText">{cabData.login}</div>
                  </div>
                </div>
                <div>
                  {textFields.map((field, index) => (
                    <div
                      key={field.id}
                      style={{
                        maxWidth: "270px",
                        margin: "0 auto",
                        marginBottom: "10px",
                      }}
                    >
                      <TextField
                        autoComplete="off"
                        inputRef={(el) => (textFieldRefs.current[index] = el)}
                        value={field.value}
                        InputProps={{
                          style: {
                            color: "gray",
                            borderRadius: "10px",
                            width: "90%",
                            margin: "0 auto",
                          },
                          readOnly: true,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Tooltip title="Copy">
                                <IconButton
                                  onClick={() => handleCopy(field.value)}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Share">
                                <IconButton onClick={handleShare}>
                                  <ShareIcon />
                                </IconButton>
                              </Tooltip>
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        fullWidth
                      />
                    </div>
                  ))}

                  <Snackbar
                    open={openSnackbar}
                    autoHideDuration={3000}
                    onClose={handleCloseSnackbar}
                  >
                    <MuiAlert
                      elevation={6}
                      variant="filled"
                      onClose={handleCloseSnackbar}
                      severity="success"
                    >
                      {snackbarMessage}
                    </MuiAlert>
                  </Snackbar>
                </div>
                <Divider style={styleDivider} />
                <div className="myAccountPortfolioWrapper">
                  <div className="myAccountPortfolioContainer">
                    <div className="myAccountPortfolio">
                      <div className="imgPortfolioWrapper"></div>
                      <div className="portfolioDescription">
                        {cabData && cabData.cumulativeGroupVolume &&
                            <div className="portfolioDescriptionMoneyPartners">
                              <b>€{cabData.cumulativeGroupVolume.eurAmount} </b>
                              <b>${cabData.cumulativeGroupVolume.usdAmount} </b>
                              <b>₮{cabData.cumulativeGroupVolume.usdtAmount} </b>
                            </div>
                        }
                        <div className="portfolioDescriptionText">
                          {t("CabinetPartnerCabinetBillGroup")}
                        </div>
                      </div>
                    </div>
                    <div className="myAccountPortfolio">
                      <div className="imgPortfolioWrapper"></div>
                      <div className="portfolioDescription">
                        {cabData && cabData.groupActivePortfolio &&
                            <div className="portfolioDescriptionMoneyPartners">
                              <b>${cabData.groupActivePortfolio.usdAmount} </b>
                              <b>€{cabData.groupActivePortfolio.eurAmount} </b>
                              <b>₮{cabData.groupActivePortfolio.usdtAmount} </b>
                            </div>
                        }
                        <div className="portfolioDescriptionText">
                          {t("CabinetPartnerCabinetBillGroupeActive")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Divider style={styleDivider} />
              </div>
              <div className="consultantLableWrapper">
                <div className="consultantLable">
                  {t("MyCabinetConsultant")}
                </div>
              </div>
              <div className="consultantWrapper">
                <div className="consultantContainer">
                  <div className="consultant">
                    <div className="consultantImageWrapper">
                      <div
                        className="consultantImage"
                        style={{ backgroundImage: `url(${photoInviter})` }}
                      />
                      {inviter.statusName === "Без статуса" ? null : (
                        <div className="consultantWrapperText">
                          <div className="levelconsultantText">
                            {inviter.statusName}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="consultantNameWrapper">
                      <div className="consultantNameContainer">
                        <div className="consultantName">Вера Тарасовна</div>
                        <div className="consultantLoginWrapper">
                          <div
                            className="consultantLoginImage"
                            style={{ backgroundImage: `url(${photoInviter})` }}
                          ></div>
                          <div className="consultantLoginText">
                            {inviter.login}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="socialNetworkWrapper">
                  <div className="socialNetworkImgWrapper">
                    <div className="socialNetworkImgTg"></div>
                    <div className="socialNetworkText">nick_surname</div>
                  </div>
                  <div className="socialNetworkImgWrapper">
                    <div className="socialNetworkImgEmail"></div>
                    <div className="socialNetworkText">mail@mail.com</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      )}

      <ImageCropperModal
        open={openModal}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <PhotoDeleteModal
        open={openModalDelete}
        handleClose={handleCloseModalDelete}
        handleRerender={handleDelete}
      />
      <Modal
        open={open}
        onClose={handleCloseShare}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('ModalWindowEmailTitle')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{display:'flex', justifyContent:'center'}}>
            <TextField value={valueEmail} onChange={(e) => handleChangeEmail(e)} placeholder={t('ModalWindowEmail')}></TextField>
            <Button variant="contained" color="success" style={{marginLeft:'10px'}} onClick={handleSendEmail}>
              {t('ModalWindowEmailButton')}
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default Cabinet;
