import React, { useEffect, useState } from "react";
import RegistrationHeader from "./RegistationHeader";
import RegistrationBody from "./RegistartionBody";
import RegistrationFooter from "./RegistartionFooter";
import '../../Style/registration/Registration.scss'


function Registration() {

    const [language, setLanguage] = useState('')

    useEffect(() => {
      const language = localStorage.getItem('site_settings')
      if(language) {
        let myObject = JSON.parse(language);
        setLanguage(myObject['language'])
      }
 
    },[])


    return (
        <div className="registrationWrapper">
            <RegistrationHeader language={language}/>
            <RegistrationBody language={language}/>
            {/* <RegistrationFooter/> */}
        </div>
    )
}

export default Registration