// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperTableBills {
  width: 92.5%;
  padding: 25px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

@media screen and (max-width: 770px) {
  .wrapperTableBills {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 660px) {
  .wrapperTableBills {
    padding: 0px;
    padding-top: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Bills/BillsBody.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,cAAA;AACJ;;AAEA;EACI;IACI,sBAAA;IACA,uBAAA;IACA,mBAAA;EACN;AACF;AAEA;EACI;IACI,YAAA;IACA,iBAAA;EAAN;AACF","sourcesContent":[".wrapperTableBills{\n    width: 92.5%;\n    padding: 25px;\n    display: flex;\n    justify-content: center;\n    margin: 0 auto;\n}\n\n@media screen and (max-width: 770px) {\n    .wrapperTableBills{\n        flex-direction: column  ;\n        justify-content: center;\n        align-items: center;\n    }\n}\n\n@media screen and (max-width: 660px) {\n    .wrapperTableBills{\n        padding: 0px;\n        padding-top: 25px;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
