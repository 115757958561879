import React, {useEffect, useState} from 'react';
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import "../Style/Modal/SiteSettingsModal.scss"
import urlFromTemplate from "../configs/url";
import {ENDPOINTS} from "../configs/endpoints";
import axios from "axios";
import {useDispatch} from "react-redux";
import { setLanguageState } from '../store/reducerLanguage';
import { useTranslation } from 'react-i18next';

const SiteSettingsModal = ({isOpen, onClose}) => {

    const [chosenCountry, setChosenCountry] = useState('');
    const [chosenLanguage, setChosenLanguage] = useState('');
    const [chosenCurrency, setChosenCurrency] = useState('');
    const [result, setResult] = useState({});
    const [errorLabel, setErrorLabel] = useState('');
    const [timezoneArr, settimezoneArr] = useState([])
    const { t, i18n } = useTranslation();
    const [timezoneObj, setTimezoneObj] = useState([])
    useEffect(() => {
        if(result && result.timezone !== undefined && result.language !== undefined && result.currency !== undefined) {
            setChosenCountry(result.timezone);
            setChosenLanguage(result.language);
            setChosenCurrency(result.currency);
        }
    }, [result]);

   


    useEffect(() => {
        if(isOpen) {
            handleSiteSettings()
            timezone()
        }

    }, [isOpen]);

    const handleSiteSettings = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setResult(response.data)
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
            const language = localStorage.getItem('site_settings')
           if (language){
            let myObject = JSON.parse(language);
            i18n.changeLanguage(myObject['language'])
           }
    },[])



    const handleSubmit = async (e) => {
        i18n.changeLanguage(chosenLanguage)
        e.preventDefault();
        const currentSiteSettings = {
            timezone: chosenCountry,
            language: chosenLanguage,
            currency: chosenCurrency
        };
        const currentSiteSettingsJSON = JSON.stringify(currentSiteSettings);
        localStorage.setItem('site_settings', currentSiteSettingsJSON);
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS);
        try {
            await axios.put(apiUrl, currentSiteSettings, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }).then((response) => {
                handleCloseModal(e);
            });
        } catch (error) {
            console.error(error);
            setErrorLabel(error.message);
        } finally{
            window.location.reload()
        }
    };

    const timezone = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS) + `/timezones`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            settimezoneArr(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if(timezoneArr.timezones) {
        //     const timezoneObj = timezoneArr.timezones.reduce((acc, timezone) => {
        //         acc[timezone] = timezone;
        //         return acc;
        //       }, {});
        //   console.log(timezoneObj);
        //       setTimezoneObj(timezoneObj)
        const transformedTimezoneArr = timezoneArr.timezones.map(timezone => ({
            value: timezone,
            label: timezone
          }));
          setTimezoneObj(transformedTimezoneArr)
          console.log(transformedTimezoneArr)
        } else {
        console.log(timezoneArr)

        }
 
          
    },[timezoneArr])

    const handleCloseModal = (e) => {
        e.preventDefault();
        setErrorLabel('');
        setResult({});
        onClose();
    }

    const countryList = [
        {value: 'Europe/Minsk', label: 'Africa',},
        // 'africa',
        // 'albania'
    ];

    const languageList = [
        {value: 'Русский', label: 'Русский'},
        {value: 'English', label: 'English'},
        {value: 'Turkish', label: 'Turkish'},
    ]

    const currencyList = [
        {value: 'USD', label: 'USD'},
        {value: 'Bitcoin', label: 'Bitcoin'}
    ]

    return (
        <div className={`modal ${isOpen ? 'open' : ''}`} >
            {result && result.timezone !== undefined && result.language !== undefined && result.currency !== undefined &&
            <div className="modal-content">
                <label className="modalTitle"> {t('SiteSettingsModalTitle')} </label>
                <Box sx={{
                    maxWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }} value='1'>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLableCountry')}
                        value={chosenCountry} // Use the state value as the value prop
                        onChange={(event) => setChosenCountry(event.target.value)}
                    >
                        {timezoneObj.length > 0 && timezoneObj.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLabelLanguage')}
                        value={chosenLanguage} // Use the state value as the value prop
                        onChange={(event) => setChosenLanguage(event.target.value)}
                    >
                        {languageList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('SiteSettingsModalLabelCurrancy')}
                        value={chosenCurrency} // Use the state value as the value prop
                        onChange={(event) => setChosenCurrency(event.target.value)}
                    >
                        {currencyList.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {errorLabel && errorLabel !== '' &&
                        <p style={{ color: 'red', textAlign: 'center', fontSize: '17px'}}>{errorLabel}</p>
                    }
                    <div className="siteSettingsButtonContainer">
                        <button className="whiteButton buttonBoldText siteSettingsButton" style={{marginRight: '10px'}}
                                onClick={handleCloseModal}>
                            {t('SiteSettingsModalButtonClose')}
                        </button>
                        <button className="yellowButton buttonBoldText siteSettingsButton "
                            id='siteSettingsButtonSave'
                            onClick={handleSubmit}>
                            {t('SiteSettingsModalButtonSave')}
                        </button>
                    </div>
                </Box>
            </div>}
        </div>
    );
};

export default SiteSettingsModal;