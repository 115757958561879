import React, {useEffect, useState} from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Checkbox from '@mui/material/Checkbox';
import MenuItem from '@mui/material/MenuItem';
import '../../../Style/Profile/BasicData.scss'
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import dayjs from "dayjs";
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';

function Location() {

    const [checkboxEnabled, setCheckboxEnabled] = useState(false);
    const [errorLabel, setErrorLabel] = useState('');
    const [result, setResult] = useState({
        country: '',
        region: '',
        city: '',
        address: '',
        index: ''
    });
    const { t, i18n } = useTranslation();


    const handleUpdateUserLocation = async (e) => {
        e.preventDefault();
        const apiUrl = urlFromTemplate(ENDPOINTS.USER_LOCATION);
        try {
            await axios.put(apiUrl, result, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            }).then((response) => {
                setErrorLabel('');
            });
        } catch (error) {
            console.error(error);
            setErrorLabel(error.message);
        }
    };

    const handleFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        setResult((prevState) => ({
            country: field === 'country' ? newValue : prevState.country,
            region: field === 'region' ? newValue : prevState.region,
            city: field === 'city' ? newValue : prevState.city,
            address: field === 'address' ? newValue : prevState.address,
            index: field === 'index' ? newValue : prevState.index,
        }));
    };

    const handleCheckboxChange = () => {
        setCheckboxEnabled(!checkboxEnabled);
    };

    useEffect(() => {
        retrieveUserLocation();
    }, []);


    const retrieveUserLocation = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER_LOCATION)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setResult(response.data)

        } catch (error) {
            console.error(error);
        }
    }

    // TODO: API for fetching countries and their regions (values = labels)

    const country = [
        {
            value: 'Turkey',
            label: 'Turkey',
        },
        {
            value: 'Belarus',
            label: 'Belarus',
        },
        {
            value: 'Russia',
            label: 'Russia',
        },
    ];

    const city = [
        {
            value: 'Istanbul',
            label: 'Istanbul'
        },
        {
            value: 'Minsk',
            label: 'Minsk'
        },
        {
            value: 'Moscow',
            label: 'Moscow'
        },
    ]

    const region = [
        {
            value: 'Istanbul',
            label: 'Istanbul'
        },
        {
            value: 'Minsk',
            label: 'Minsk'
        },
        {
            value: 'Moscow',
            label: 'Moscow'
        },
    ]

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <PageWrapper>
            <div className="wrapperBasicData">
                <div className="nameWrapper" style={{ marginBottom: "10px" }}>
                     <span style={{marginLeft:'0px'}}  className="nameText" id="nameTextLocation"> {t('ProfileLocationTitle')}</span>
                        </div>
                <Box sx={{
                    maxWidth: '400px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }} value='1'>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('ProfileLocationCountry')}
                        value={result.country}
                        onChange={handleFieldChange('country')}
                    >
                        {country.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('ProfileLocationRegion')}

                        value={result.region}
                        onChange={handleFieldChange('region')}
                    >
                        {region.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{marginTop: '10px', width: '100%'}}
                        id="outlined-select-currency"
                        select
                        label={t('ProfileLocationCity')}

                        value={result.city}
                        onChange={handleFieldChange('city')}
                    >
                        {city.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                        <TextField sx={{marginTop: '10px', width: '60%'}} type="text" id="outlined-basic"
                        label={t('ProfileLocationAddress')}
                                   
                                   variant="outlined" value={result.address}
                                   onChange={handleFieldChange('address')}/>
                        <TextField sx={{marginTop: '10px', width: '35%'}} type="text" id="outlined-basic"
                        label={t('ProfileLocationIndex')}
                                   
                                   variant="outlined" value={result.index}
                                   onChange={handleFieldChange('index')}/>
                    </Box>
                    <div className="wrapperCheckboxProfile">
                        <Checkbox checked={checkboxEnabled} onChange={handleCheckboxChange}/>
                        <div className="checkboxProfileText">
                            <span>
                            {t('ProfileBasicDataAtantion')}
                            </span>
                        </div>
                    </div>
                </Box>
                {errorLabel && errorLabel !== '' &&
                    <p style={{color: 'red', fontSize: '17px'}}>{errorLabel}</p>
                }
                <button className="saveButtonProfile" disabled={!checkboxEnabled}
                        style={checkboxEnabled ? {} : {cursor: 'not-allowed', backgroundColor: 'lightgray'}}
                        onClick={handleUpdateUserLocation}>
                                            {t('ProfileButtonSave')}

                </button>
            </div>
            </PageWrapper>
        </LocalizationProvider>
    )
}

export default Location