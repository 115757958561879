import { baseConfig } from './baseConfig.js';

const apiUrl = baseConfig.apiBaseUrl;

export const ENDPOINTS = {
  AUTH: 'auth',
  PROJECT: 'project',
  ACCOUNT_LOG: 'account-log',
  USER: 'user',
  ACCOUNT: 'account',
  SITE_SETTINGS: 'site-settings',
  USER_ADDITIONAL: 'user-additional',
  USER_CONTACTS: 'user-contacts',
  USER_LOCATION: 'user-location',
  STORAGE: 'storage',
  CONSULTANT_REQUIREMENT: 'consultant-requirement',
  ACTIVE_STATUS: 'user-active',
  CONSULTANT_FORM: 'consultant-form',
  TRAINING:'training',
  TRAINING_EVENT:'training-event',
  ACCOUNT_PROJECT_LOG:'account-project-log',
  VERIFICATION:'verification',
  MAIL:'mail',
  CRYPTOCLOUD: 'cryptocloud',
  AGREEMENT: 'agreement',
  INVOICE: 'invoice',
  WALLET: 'wallet',
  WALLET_LOG: 'wallet-log'
};

function addApiBaseUrl(item) {
    Object.keys(item).forEach((key) => {
      if (typeof item[key] === 'object') {
        addApiBaseUrl(item[key], apiUrl);
      } else {
        item[key] = apiUrl + item[key];
      }
    });
  }
  
  

addApiBaseUrl(ENDPOINTS);