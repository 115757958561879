import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modalRegData: false
};

const modalRegSlice = createSlice({
    name: 'modalReg',
    initialState,
    reducers: {
        setModalReg: (state, action) => {
            state.modalRegData = action.payload;
        }
    }
});

export const { setModalReg } = modalRegSlice.actions;
export default modalRegSlice.reducer;
