import React from "react";
import "../Style/landing/Policy.scss";

function TermsEn() {
  return (
    <>
      <div className="policyWrapper">
        <div className="headerPolicyWrapper">
          <div className="policyText">CONDITIONS OF USE</div>
        </div>
        <div className="policyBodyWrapper">
          <div className="policyBodyText">
            <h3>
              <b>1. Introduction</b>
            </h3>
            <span>
              <p>Welcome to Diamond!</p>
              <p>
                These Terms of Service (“Terms”, “Terms of Service”) govern your
                use of our website located at
                https://test.diamond.balinasoft.com/ (collectively or
                individually, the “Service”) operated by Diamond.
              </p>
              <p>
                Our Privacy Policy also governs your use of our Service and
                explains how we collect, protect and disclose information
                collected from your use of our web pages.
              </p>
              <p>
                Your agreement with us includes these Terms and our Privacy
                Policy (“Agreements”). You acknowledge that you have read and
                understood the Agreements and agree to be bound by them.
              </p>
              <p>
                If you do not agree to the Agreements (or cannot comply with
                them), you may not use the Service, but please let us know by
                emailing info@diamond.com so that we can try to find a solution.
                These Terms apply to all visitors, users and others who wish to
                access or use the Service.
              </p>
            </span>
            <h3>
              <b>2. Communications</b>
            </h3>
            <span>
              <p>
                By using our Service, you agree to sign up to receive
                newsletters, marketing or promotional materials and other
                information we may send. However, you may opt out of receiving
                any or all of these communications from us by clicking the
                “Unsubscribe” link or by writing to info@diamond.com.
              </p>
            </span>
            <h3>
              <b>3. Purchases</b>
            </h3>
            <span>
              <p>
                If you wish to purchase any product or service available through
                the Service (“Purchase”), you may be asked to provide certain
                information related to your Purchase, including, but not limited
                to, your credit or debit card number, your card expiration date,
                your billing address, and shipping information.
              </p>
              <p>
                You represent and warrant that (i) you have the legal right to
                use any card(s) or other payment method(s) in connection with
                any Purchase; and (ii) the information you provide to us is
                true, correct and complete.
              </p>
              <p>
                We may use third parties to facilitate payment and Purchases. By
                submitting your information, you authorize us to provide
                information to these third parties in accordance with our
                Privacy Policy.
              </p>
              <p>
                We reserve the right to refuse or cancel your order at any time
                for reasons including, but not limited to: availability of a
                product or service, errors in the description or price of a
                product or service, an error in your order, or other reasons.
              </p>
              <p>
                We reserve the right to refuse or cancel your order if fraud or
                an unauthorized or illegal transaction is suspected.
              </p>
            </span>
            <h3>
              <b>4. Contests, Sweepstakes and Promotions</b>
            </h3>
            <span>
              <p>
                Any contests, sweepstakes or other promotions and marketing tips
                (“Promotions”) available through the Service may be governed by
                rules separate from these Terms of Service. If you participate
                in any Promotion, please review the applicable rules as well as
                our Privacy Policy. If the rules of a Promotion conflict with
                these Terms of Service, the rules of the Promotion that appear
                in your personal profile will apply.
              </p>
            </span>
            <h3>
              <b>5. Refunds</b>
            </h3>
            <span>
              <p>
                We issue refunds for services within 30 days after you notify
                us, but not earlier than 90 days after the original purchase of
                the service.
              </p>
            </span>
            <h3>
              <b>6. Content</b>
            </h3>
            <span>
              <p>
                Our Service allows you to link to, store, share and otherwise
                make available certain information, text, graphics, calculations
                and other materials (“Content”). You are responsible for the
                confidentiality of Content that you receive on the Service
                through your personal account.
              </p>
              <p>
                We are responsible for the Content we post, including its
                legality, reliability and appropriateness.
              </p>
              <p>
                We reserve the right to terminate the account of anyone found to
                have committed copyright infringement.
              </p>
              <p>
                You are responsible for any Content you submit, post or display
                on or through the Service, and you are responsible for
                protecting those rights. We are not responsible for Content
                posted by you or third parties on or through the Service.
                However, by posting Content through the Service, you grant us
                the right and license to use, modify, publicly perform, publicly
                display, reproduce and distribute such Content on and through
                the Service. You agree that this license includes the right for
                us to provide access to your Content to other users of the
                Service who may also use your Content in accordance with these
                Terms.
              </p>
              <p>
                Diamond has the right, but not the obligation, to monitor and
                edit all Content submitted by users.
              </p>
              <p>
                In addition, Content found on or through this Service is the
                property of, or used with permission by, Diamond. You may not
                distribute, modify, transmit, reuse, upload, repost, copy or use
                said Content, in whole or in part, for commercial purposes or
                for personal gain, without prior written authorization from us.
              </p>
            </span>
            <h3>
              <b>7. Prohibited Use</b>
            </h3>
            <span>
              <p>
                You may only use the Service for lawful purposes and in
                accordance with the Terms. You agree not to use the Service:
              </p>
              <p>
                7.1.1. In any manner that violates any applicable national or
                international laws or regulations.
              </p>
              <p>
                7.1.2. for the purpose of exploiting, harming or attempting to
                exploit or harm minors in any way by exposing them to
                inappropriate content or otherwise.
              </p>
              <p>
                7.1.3 To transmit or cause to be sent any advertising or
                promotional material, including any “junk mail,” “chain
                letters,” “spam,” or any other similar invitations.
              </p>
              <p>
                7.1.4 Impersonate or attempt to impersonate the Company, an
                employee of the Company, another user, or any other person or
                organization.
              </p>
              <p>
                7.1.5 Violate the rights of others in any way, or in any
                unlawful, threatening, fraudulent or harmful manner, or in
                connection with any unlawful, illegal, fraudulent or harmful
                purpose or action.
              </p>
              <p>
                7.1.6 Engage in any other conduct that restricts or inhibits
                anyone from using or enjoying the Service, or that we believe
                may harm or offend the Company or users of the Service or expose
                them to liability.
              </p>
              <p>In addition, you agree not to:</p>
              <p>
                7.2.1 Use the Service in any manner that could disable,
                overburden, damage or impair the Service or interfere with any
                other party's use of the Service, including their ability to
                engage in real-time activity through the Service.
              </p>
              <p>
                7.2.2 Use robots, spiders or other automated devices, processes
                or means to access the Service for any purpose, including
                monitoring or copying any material on the Service.
              </p>
              <p>
                7.2.3 Use any manual process to monitor or copy any material on
                the Service or for any other unauthorized purpose without our
                prior written consent.
              </p>
              <p>
                7.2.4 Use any device, software or procedure that interferes with
                the normal operation of the Service.
              </p>
              <p>
                7.2.5. Introduce any viruses, Trojan horses, worms, logic bombs
                or other material that is malicious or technologically harmful.
              </p>
              <p>
                7.2.6. Attempt to gain unauthorized access to, interfere with,
                damage or disrupt any part of the Service, the server on which
                the Service is stored, or any server, computer or database
                connected to the Service.
              </p>
              <p>
                7.2.7 Attack the Service through a denial of service attack or a
                distributed denial of service attack.
              </p>
              <p>
                7.2.8. Take any action that may damage or falsify the Company's
                rating.
              </p>
              <p>
                7.2.9. Otherwise attempt to interfere with the normal operation
                of the Service.
              </p>
            </span>
            <h3>
              <b>8. Analytics</b>
            </h3>
            <span>
              <p>
                We may use third party service providers to monitor and analyze
                the use of our Service.
              </p>
            </span>
            <h3>
              <b>9. Prohibition of use by minors</b>
            </h3>
            <span>
              <p>
                The Service is intended only for access and use by persons at
                least eighteen (18) years of age. By accessing or using the
                Service, you warrant and represent that you are at least
                eighteen (18) years of age and have full power, authority and
                capacity to enter into this agreement and to comply with all of
                the terms and conditions of the Terms. If you are under eighteen
                (18) years of age, you are prohibited from both accessing and
                using the Service.
              </p>
            </span>
            <h3>
              <b>10. Accounts</b>
            </h3>
            <span>
              <p>
                By creating an account with us, you warrant that you are over 18
                years of age and that the information you provide to us is
                accurate, complete and current at all times. Inaccurate,
                incomplete or outdated information may result in immediate
                termination of your account on the Service.
              </p>
              <p>
                You are responsible for maintaining the confidentiality of your
                account and password, including but not limited to restricting
                access to your computer and/or account. You agree to accept
                responsibility for all acts and behaviors that occur under your
                account and/or password, whether your password is used on our
                Service or a third party service. You must notify us immediately
                of any breach of security or unauthorized use of your account.
              </p>
              <p>
                You may not use as your username the name of another person or
                entity, or a name or trademark that is the subject of the rights
                of another person or entity other than you, without proper
                authorization. You may not use as a username any name that is
                offensive, vulgar or obscene.
              </p>
              <p>
                We reserve the right to refuse service, terminate accounts,
                remove or edit content, or cancel orders at our sole discretion
                in this case.
              </p>
            </span>
            <h3>
              <b>11. Intellectual Property</b>
            </h3>
            <span>
              <p>
                The Service and its original content (excluding User Submitted
                Content), features and functionality are and will remain the
                exclusive property of Diamond and its licensors. The Service is
                protected by copyright, trademark and other country laws. Our
                trademarks may not be used in connection with any product or
                service without the prior written consent of Diamond.
              </p>
            </span>
            <h3>
              <b>12. Copyright Policy</b>
            </h3>
            <span>
              <p>
                We respect the intellectual property rights of others. It is our
                policy to respond to any claim that Content posted on the
                Service infringes the copyright or other intellectual property
                rights (“Infringement”) of any person or entity.
              </p>
              <p>
                If you are a copyright owner or authorized on its behalf and
                believe that a copyrighted work has been copied in a way that
                constitutes copyright infringement, please send your claim by
                email to info@diamond.com with “Copyright Infringement” in the
                subject line and include in your claim a detailed description of
                the alleged infringement as set forth below under “DMCA Notice
                and Procedure for Handling Copyright Infringement Claims.”
              </p>
              <p>
                You may be held liable for damages (including costs and
                attorneys' fees) for misleading or bad faith claims of
                infringement of your copyrights by any Content posted on and/or
                through the Service.
              </p>
            </span>
            <h3>
              <b>
                13. DMCA notice and procedure for handling claims of copyright
                infringement
              </b>
            </h3>
            <span>
              <p>
                You may file a notice under the Digital Millennium Copyright Act
                (DMCA) by providing our copyright agent with the following
                information in writing:
              </p>
              <p>
                13.1. an electronic or physical signature of a person authorized
                to act on behalf of the copyright owner;
              </p>
              <p>
                13.2. a description of the copyrighted work that you claim has
                been infringed, including the URL (i.e., web page address) of
                the location where the copyrighted work or a copy of the
                copyrighted work exists;
              </p>
              <p>
                13.3. identification of the URL or other specific location on
                the Service where the material you claim is infringing is
                located;
              </p>
              <p>13.4. your address, telephone number and e-mail address;</p>
              <p>
                13.5. a statement from you that you have a good faith belief
                that the disputed use is not authorized by the copyright owner,
                its agent, or the law;
              </p>
              <p>
                13.6. a statement from you, made under penalty of perjury, that
                the above information in your notice is accurate and that you
                are the copyright owner or authorized to act on behalf of the
                copyright owner.
              </p>
              <p>You may contact our copyright agent at info@diamond.com.</p>
            </span>
            <h3>
              <b>14. Error Reporting and Feedback</b>
            </h3>
            <span>
              <p>
                You may provide us directly at info@diamond.com or through
                third-party sites and tools with information and feedback
                regarding bugs, suggestions for improvement, ideas, problems,
                complaints and other issues related to our Service (“Feedback”).
                You acknowledge and agree that (i) you do not retain, acquire or
                assert any intellectual property rights or other right, title or
                interest in the Testimonials; (ii) Company may have development
                ideas similar to the Testimonials; (iii) the Testimonials do not
                contain confidential or proprietary information of you or any
                third party; and (iv) Company has no obligation of
                confidentiality with respect to the Testimonials. In the event
                that ownership of the Testimonials cannot be transferred due to
                applicable mandatory law, you grant Company and its affiliates
                the exclusive, transferable, irrevocable, royalty-free,
                sublicensable, unrestricted and perpetual right to use
                (including copying, modifying, creating derivative works,
                publishing, distributing and commercializing) the Testimonials
                in any manner and for any purpose.
              </p>
            </span>
            <h3>
              <b>15. Links to other websites</b>
            </h3>
            <span>
              <p>
                Our Service may contain links to third-party web sites or
                services that are not owned or controlled by diamond.
              </p>
              <p>
                diamond has no control over, and assumes no responsibility for,
                the content, privacy policies, or practices of any third party
                web sites or services. We do not guarantee the offerings of any
                of these organizations/individuals or their websites.
              </p>
              <p>
                You acknowledge and agree that diamond shall not be responsible
                or liable, directly or indirectly, for any damage or loss caused
                or alleged to be caused by or in connection with use of or
                reliance on any such content, goods or services available on or
                through any such third party web sites or services.
              </p>
              <p>
                We strongly encourage you to review the terms of service and
                privacy policies of any third-party websites or services you
                visit.
              </p>
            </span>
            <h3>
              <b>16. Disclaimer of Warranty</b>
            </h3>
            <span>
              <p>
                THESE SERVICES ARE PROVIDED BY THE COMPANY ON AN “AS IS” AND “AS
                AVAILABLE” BASIS. THE COMPANY MAKES NO REPRESENTATIONS OR
                WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION
                OF ITS SERVICES OR THE INFORMATION, CONTENT OR MATERIALS
                INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE
                SERVICES, THEIR CONTENTS, OR ANY SERVICES OR ITEMS OBTAINED FROM
                US IS AT YOUR SOLE RISK.
              </p>
              <p>
                NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY
                MAKES ANY WARRANTY OR REPRESENTATION AS TO THE COMPLETENESS,
                SAFETY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
                SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY
                NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS
                THAT THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS
                OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
                ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                THAT THE SERVICES OR THE SERVER THAT MAKES THEM AVAILABLE ARE
                FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
                SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES
                WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p>
                THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESS
                OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR
                A PARTICULAR PURPOSE.
              </p>
              <p>
                THE FOREGOING SHALL NOT AFFECT ANY WARRANTY THAT CANNOT BE
                EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
              </p>
            </span>
            <h3>
              <b>17. Limitation of Liability</b>
            </h3>
            <span>
              <p>
                EXCEPT WHERE PROHIBITED BY LAW, YOU AGREE TO INDEMNIFY US AND
                OUR OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS AGAINST ANY
                INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL OR CONSEQUENTIAL
                DAMAGES, HOWEVER ARISING (INCLUDING ATTORNEYS' FEES AND ALL
                RELATED COSTS AND EXPENSES IN LITIGATION AND ARBITRATION, AT
                TRIAL OR ON APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR
                ARBITRATION IS COMMENCED), WHETHER IN CONTRACT, NEGLIGENCE OR
                OTHER TORT ACTION. EXCEPT WHERE PROHIBITED BY LAW, IF THE
                COMPANY'S LIABILITY IS DETERMINED, IT WILL BE LIMITED TO THE
                AMOUNT PAID FOR PRODUCTS AND/OR SERVICES AND IN NO EVENT WILL
                CONSEQUENTIAL OR PUNITIVE DAMAGES BE AVAILABLE. SOME STATES DO
                NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE, INCIDENTAL OR
                CONSEQUENTIAL DAMAGES, SO THE PRECEDING LIMITATION OR EXCLUSION
                MAY NOT APPLY TO YOU.
              </p>
            </span>
            <h3>
              <b>18. Termination</b>
            </h3>
            <span>
              <p>
                We may terminate or suspend your account and deny you access to
                the Service immediately, without notice or liability, in our
                sole discretion, for any reason and without limitation,
                including but not limited to a violation of the Terms.
              </p>
              <p>
                If you wish to terminate your account, you may simply stop using
                the Service at the end of your settlement with us or notify us
                by email marked “Delete”.
              </p>
              <p>
                All provisions of the Terms that by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnification and limitations of liability.
              </p>
            </span>
            <h3>
              <b>19. Governing Law</b>
            </h3>
            <span>
              <p>
                These Terms shall be governed by and construed in accordance
                with the laws that apply to the agreement without regard to its
                conflict of law provisions.
              </p>
              <p>
                Our failure to enforce any rights or provisions of these Terms
                will not be deemed a waiver of those rights. If any provision of
                these Terms is held by a court of law to be invalid or
                unenforceable, the remaining provisions of these Terms will
                remain in full force and effect. These Terms constitute the
                entire agreement between us with respect to our Service and
                supersede any prior agreements that may have been made between
                us with respect to the Service.
              </p>
            </span>
            <h3>
              <b>20. Changes to the Service</b>
            </h3>
            <span>
              <p>
                We reserve the right to withdraw or modify our Service, and any
                services or materials we make available through the Service, in
                our sole discretion without notice. We will not be liable if for
                any reason all or part of the Service is unavailable at any time
                or for any period. From time to time, we may restrict access to
                some parts of the Service or the entire Service to users,
                including registered users.
              </p>
            </span>
            <h3>
              <b>21. Amendments to the Terms</b>
            </h3>
            <span>
              <p>
                We may amend the Terms at any time by posting the amended Terms
                on this Site. It is your responsibility to review these Terms
                periodically.
              </p>
              <p>
                Your continued use of the Platform following the posting of
                revised Terms means that you accept and agree to the changes.
                You should check this page frequently to be aware of any changes
                as they are binding on you.
              </p>
              <p>
                By continuing to access or use our Service after any changes
                take effect, you agree to be bound by the revised terms. If you
                do not agree to the new terms, you are no longer authorized to
                use the Service.
              </p>
            </span>
            <h3>
              <b>22. Waiver and Severability</b>
            </h3>
            <span>
              <p>
                No waiver by the Company of any term set forth in the Terms
                shall be deemed to be a further or continuing waiver of such
                term or a waiver of any other term or condition, and any failure
                by the Company to assert a right or provision under the Terms
                will not constitute a waiver of such right or provision.
              </p>
              <p>
                If any provision of the Terms is held by a court or other body
                of competent jurisdiction to be invalid, illegal or
                unenforceable for any reason, such provision will be waived or
                limited to the minimum extent that the remaining provisions of
                the Terms will continue in full force and effect.
              </p>
            </span>
            <h3>
              <b>23. Confirmation</b>
            </h3>
            <span>
              <p>
                BY USING THE SERVICE OR OTHER SERVICES PROVIDED BY US, YOU
                ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF SERVICE AND AGREE
                TO BE BOUND BY THEM.
              </p>
            </span>
            <h3>
              <b>24. Contact Us</b>
            </h3>
            <span>
              <p>
                Please send your feedback, comments, technical support requests
                to info@diamond.com.
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsEn;
