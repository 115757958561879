// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperBillsCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.billsCard {
  background: white;
  width: 40%;
  height: 150px;
  margin-top: 20px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#primaryAccount {
  background: linear-gradient(135deg, #6e85d5, #0a96bc);
  color: white;
}

#bonusAccount {
  background: linear-gradient(135deg, #fb9f5c, #D2691E);
  color: white;
}

#CumulativePrimaryAccount {
  background: linear-gradient(135deg, #16c4cf, #0a96bc);
  color: white;
}

.buttonBillsCard {
  display: flex;
  justify-content: space-between;
}

.buttonBillsCardNone {
  display: flex;
  justify-content: end;
}

@media screen and (max-width: 630px) {
  .wrapperBillsCard {
    flex-direction: column;
  }
  .billsCard {
    height: 200px;
    width: 70%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Bills/BillsCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACC,eAAA;EACA,8BAAA;EACC,mBAAA;AACN;;AAEA;EACI,iBAAA;EACA,UAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACJ;;AAEA;EACI,qDAAA;EACA,YAAA;AACJ;;AAEA;EACI,qDAAA;EACA,YAAA;AACJ;;AAEA;EACI,qDAAA;EACA,YAAA;AACJ;;AAEA;EACI,aAAA;EACA,8BAAA;AACJ;;AAGA;EACI,aAAA;EACA,oBAAA;AAAJ;;AAOA;EACI;IACI,sBAAA;EAJN;EAME;IACI,aAAA;IACA,UAAA;EAJN;AACF","sourcesContent":[".wrapperBillsCard{\n    display:flex;\n    flex-direction:row;\n     flex-wrap: wrap;\n     justify-content:space-between;\n      align-items:center\n}\n\n.billsCard{\n    background:white;\n    width:40%;\n    height: 150px;\n    margin-top: 20px;\n    border-radius: 20px;\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n#primaryAccount{\n    background: linear-gradient(135deg, #6e85d5, #0a96bc);\n    color: white;  \n}\n\n#bonusAccount{\n    background: linear-gradient(135deg, #fb9f5c, #D2691E);\n    color: white;  \n}\n\n#CumulativePrimaryAccount{\n    background: linear-gradient(135deg, #16c4cf, #0a96bc);\n    color: white;  \n}\n\n.buttonBillsCard{\n    display: flex;\n    justify-content: space-between;\n    \n}\n\n.buttonBillsCardNone{\n    display: flex;\n    justify-content: end;\n}\n\n@media screen and (max-width: 900px) {\n\n}\n\n@media screen and (max-width: 630px) {\n    .wrapperBillsCard{\n        flex-direction: column;\n    }\n    .billsCard{\n        height: 200px;\n        width: 70%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
