import React from "react";
import '../../Style/Profile/IndexProfile.scss'
import Navigation from "../Navigation/Navigation";
import { Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import IndexBillsContainer from "./indexBillsContainer";
import PageWrapper from "../../Routes/PageWrapper";

function IndexBills () {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))


    return(
        <PageWrapper>
                <IndexBillsContainer/>
            </PageWrapper>
    
    )
}

export default IndexBills