// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperIncomeTableMain {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  margin: 20px auto 20px;
  padding: 20px;
  box-sizing: border-box;
}

.incomeTableTitle {
  font-size: 20px;
  margin-left: 2px;
  margin-bottom: 20px;
}

.transactionTableTitle {
  font-size: 14px;
  margin-left: 2px;
  margin-bottom: 7px;
}

.boldTextDescription {
  font-size: 16px;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: 10px;
}

@media screen and (max-width: 650px) {
  .wrapperIncomeTableTable {
    background-color: white;
    border-radius: 20px;
    margin: 20px auto;
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/IncomeTable.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AAEA;EACE,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AACF;;AAEA;EACE;IACE,uBAAA;IACA,mBAAA;IAEA,iBAAA;IACA,aAAA;EAAF;AACF","sourcesContent":[".wrapperIncomeTableMain {\n  background-color: white;\n  border-radius: 8px;\n  width: 100%;\n  margin: 20px auto 20px;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n.incomeTableTitle {\n  font-size: 20px;\n  margin-left: 2px;\n  margin-bottom: 20px;\n}\n\n.transactionTableTitle {\n  font-size: 14px;\n  margin-left: 2px;\n  margin-bottom: 7px;\n}\n\n.boldTextDescription {\n  font-size: 16px;\n  font-weight: bold;\n  margin-left: 2px;\n  margin-bottom: 10px;\n}\n\n@media screen and (max-width:'650px') {\n  .wrapperIncomeTableTable {\n    background-color: white;\n    border-radius: 20px;\n    // width: 88%;\n    margin: 20px auto;\n    padding: 20px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
