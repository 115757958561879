import React, {useEffect, useState, useRef} from "react";
import "../../../Style/Project/Project.scss";
import {useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {Map, Placemark, YMaps} from "@pbe/react-yandex-maps";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import {
    GaugeContainer,
    GaugeReferenceArc,
    GaugeValueArc,
    useGaugeState,
} from "@mui/x-charts/Gauge";
import {useTranslation} from "react-i18next";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import FormControl from "@mui/material/FormControl";
import {styled} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

function ProjectDetails() {
    const [primaryImage, setPrimaryImage] = useState(null);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [project, setProject] = useState();
    const [didUserContributed, setDidUserContributed] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    //   const price = project.minPrice.toString();
    const [gaugeWidth, setGaugeWidth] = useState(120);
    const [primaryAccount, setPrimaryAccount] = useState([]);
    const [bonusAccount, setBonusAccount] = useState([]);
    const [cumulativePrimaryAccount, setCumulativePrimaryAccount] = useState([]);
    const {t, i18n} = useTranslation();
    const [inputValue, setInputValue] = useState('');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [inputPassword, setInputPassword] = useState('')
    const [projectActive, setProjectActive] = useState(false)
    const [age, setAge] = React.useState("EUR");
    const [currencyTransaction, setCurrencyTransaction] = useState('EUR')
    const [signaturePad, setSignaturePad] = useState(null);
    const canvasRef = useRef(null);
    const saveButtonRef = useRef(null);
    const clearButtonRef = useRef(null);
    const imageRef = useRef(null);
    const [pdfUrl, setPdfUrl] = useState('../../images/PDF/file.pdf');

    const handleValueInput = (event) => {
        const value = event.target.value;
        setInputValue(value);
    }

    const handleInputPassword = (event) => {
        const value = event.target.value
        setInputPassword(value)
    }

    useEffect(() => {
        if (location.state && location.state.project) {
            setProject(location.state.project);
        }
    }, [location]);

    useEffect(() => {
        if (project != undefined) {
            setDidUserContributed(project.didUserContributed);
            setProjectActive(project.active)
        }
    }, [project]);

    useEffect(() => {
        const retrieveFiles = async () => {
            setPrimaryImage(
                await fetchImageById(project.projectFiles.primaryPhotoId)
            );

            const additionalImagePromises =
                project.projectFiles.additionalPhotoIdList.map(async (id) => {
                    return await fetchImageById(id);
                });
            const additionalImageUrls = await Promise.all(additionalImagePromises);
            setAdditionalImages(additionalImageUrls.filter((url) => url !== null));
        };

        if (project) {
            retrieveFiles();
        }
    }, [project]);

    useEffect(() => {
        handleAddBills();
    }, []);

    function GaugePointer() {
        const {valueMin, valueMax, valueAngle, outerRadius, cx, cy} = useGaugeState();

        if (valueAngle === null) {
            return null;
        }

        const target = {
            x: cx + outerRadius * Math.sin(valueAngle),
            y: cy - outerRadius * Math.cos(valueAngle),
        };
        return (
            <g>
                <circle cx={cx} cy={cy} r={5} fill="#3fab3f"/>
                <path
                    d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
                    stroke="#3fab3f"
                    strokeWidth={3}
                />
            </g>
        );
    }

    const fetchImageById = async (id) => {
        if (!id) return null;

        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
                responseType: "blob",
            });

            return URL.createObjectURL(response.data);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    };

    const handleAddBills = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            response.data.forEach((walletItem) => {
                if (walletItem.accountType === "PRIMARY") {
                    setPrimaryAccount(walletItem);
                } else if (walletItem.accountType === "BONUS") {
                    setBonusAccount(walletItem);
                } else if (walletItem.accountType === "COMMISSION") {
                    setCumulativePrimaryAccount(walletItem);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };


    const [selectedOption, setSelectedOption] = useState("PRIMARY");

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleValidValue = () => {
        if (didUserContributed) {
            if (parseFloat(inputValue) < project.repeatPrice || inputValue === '') {
                setError(true);
            } else {
                setError(false);
                handlePaidProject()
            }
        } else if (!didUserContributed) {
            if (parseFloat(inputValue) < project.minPrice || inputValue === '') {
                setError(true);
            } else {
                setError(false);
                handlePaidProject()
            }
        }
    }

    const handlePaidProject = async () => {
        const payload = {
            "projectId": project.id,
            "currency": currencyTransaction,
            "amount": parseFloat(inputValue),
            // "password": inputPassword
        }
        const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/project`;
        try {
            await axios
                .post(apiUrl, payload, {
                        withCredentials: true,
                        headers: {
                            "Accept": "application/json",
                            "Access-Control-Allow-Credentials": "true",
                        },
                    }
                )
                .then((response) => {
                    handleGetProject(response.data.templateUrlId)
                });
        } catch (error) {
            setErrorMessage(error.response.data.message)
            // setModalOpen(true)
            alert(error.response.data.message)
        } finally {
        }
    }

    const handleGetInvoice = async () => {
        const params = {
            projectId: project.id,
            amount: parseFloat(inputValue),
            currency: currencyTransaction
        }
        const queryString = new URLSearchParams(params).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.INVOICE)

        try {
            await axios.post(apiUrl, params, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
                responseType: 'blob',
            }).then((response) => {
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                navigate('/mainpage')
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleGetProject = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    const handleOpen = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }


    const handleAlert = () => {
        alert(`${t('ProjectPageButtonAlert')}`)
    }

    // Стили
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '20px',
    };

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    // Currency

    const StyledSelect = styled(Select)({
        width: '60px',
        borderRadius: "10px",
        border: 'none',
        // height: "50px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
    });


    const handleChange = (event) => {
        setAge(event.target.value);
        if (event.target.value === "USD") {
            setCurrencyTransaction('USD')
        } else if (event.target.value === 'EUR') {
            setCurrencyTransaction('EUR')
        } else if (event.target.value === 'USDT') {
            setCurrencyTransaction('USDT')
        }
    };

    const StyledMenuItem = styled(MenuItem)({
        borderRadius: "10px",
    });

    const styles = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    return (
        <>
            {!project && <div>Project is empty</div>}
            {project && (
                <div>
                    <div className="projectDescriptionHeaderContainer">
                        <div className="projectNameDescription">{project.title}</div>
                    </div>

                    <div className="projectWrapper">
                        <div className="projectContainer" style={{padding: "0px"}}>
                            <div className="projectPreviewWrapper" style={{padding: "0px"}}>
                                <div className="projectContainer" id="projectDetailsContainer" style={{padding: "0px"}}>
                                    {primaryImage ? (
                                        <div
                                            className="projectImgDetails"
                                            style={{backgroundImage: `url(${primaryImage})`}}
                                        ></div>
                                    ) : (
                                        <div className="projectImg placeholderImage"></div>
                                    )}

                                    <div className="projectInfoWrapper" id="projectDetailsInfoWrapper">
                                        <div className="projectInfoFirst">
                                            {/* <div className="projectInfo">
                        <div className="imgInfo" id="monthMoney"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">20%</div>
                          <div className="infoText">
                            {" "}
                            {t("MainPageProjectProfitPerMonth")}{" "}
                          </div>
                        </div>
                      </div>
                      <div className="projectInfo">
                        <div className="imgInfo" id="minPrice"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">$ {project.minPrice}</div>
                          <div className="infoText" id="infoTextGreen">
                            {t("MainPageProjectMinimumTransactionAmount")}
                          </div>
                        </div>
                      </div> */}
                                            <div className="projectInfo">
                                                <div className="imgInfo" id="calendarProject"></div>
                                                <div className="infoTextWrapper">
                                                    <div
                                                        className="infoText">{t('MainPageProjectDurationOfPlacementInTheProgramStart')}: {project.beginDate}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="projectInfoSecond">
                                            <div className="projectInfo">
                                                <div className="imgInfo" id="calendarProject"></div>
                                                <div className="infoTextWrapper">
                                                    <div
                                                        className="infoText">{t('MainPageProjectDurationOfPlacementInTheProgramEnd')}: {project.endDate}</div>
                                                </div>
                                            </div>
                                            {/* <div className="projectInfo">
                        <div className="imgInfo" id="minRepeatPrice"></div>
                        <div className="infoTextWrapper">
                          <div className="infoMoney">
                            $ {project.repeatPrice}
                          </div>
                          <div className="infoText" id="infoTextGreen">
                            {t("MainPageProjectMinRepeatTransactionAmount")}
                          </div>
                        </div>
                      </div> */}
                                        </div>
                                    </div>
                                    <div className="projectPullWrapper" id="projectDetailsPullWrapper"
                                         style={{alignItems: 'center', flexDirection: 'column'}}>
                                        <div className="projectPull">
                                            <GaugeContainer
                                                width={gaugeWidth}
                                                height={150}
                                                startAngle={-110}
                                                endAngle={110}
                                                // value={(project.income / project.poolSum) * 100}
                                                value={Math.min((project.income / project.poolSum) * 100, 100)}
                                                className="custom-gauge-container"
                                            >
                                                <GaugeReferenceArc className="custom-reference-arc"/>
                                                <GaugeValueArc className="custom-value-arc"/>
                                                <GaugePointer className="custom-pointer"/>
                                            </GaugeContainer>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                maxWidth: "200px",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    width: "100%",
                                                    justifyContent: "space-between",
                                                }}
                                            >
                                                <div style={{fontSize: "13px"}}>
                                                    €{project.income}
                                                </div>
                                                <div style={{fontSize: "13px"}}>
                                                    €{project.poolSum}
                                                </div>
                                            </div>
                                            <div style={{margin: "5px auto"}}>
                                                {" "}
                                                {t("MainPageProjectPoolFilling")}{" "}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {projectActive ? <a href="#projectPay" className="saveButtonProfile  buttonBoldText" style={{
                                background: 'var(--green, #10B981)',
                                maxWidth: '250px',
                                textDecoration: 'none',
                                scrollBehavior: 'smooth'
                            }}>
                                {t("ProjectPageButton")}
                            </a> :
                            //    <button className="saveButtonProfile  buttonBoldText" style={{background:'var(--green, #10B981)'}} onClick={handleAlert}>
                            //    {t("ProjectPageButton")}
                            //  </button>
                            null
                        }

                        <div className="infoMoney projectAdditionalInfo">
                            {project.description}
                        </div>
                        <div>
                            <YMaps>
                                <div className="project-map">
                                    <Map
                                        defaultState={{
                                            center: [project.lat, project.lng],
                                            zoom: 10,
                                        }}
                                        style={{width: "100%", height: "100%", maxWidth: "100%"}}
                                    >
                                        <Placemark geometry={[project.lat, project.lng]}/>
                                    </Map>
                                </div>
                            </YMaps>
                        </div>

                        {additionalImages && additionalImages.length > 0 && (
                            <div className="nameWrapper" style={{marginBottom: "10px"}}>
                                <span className="nameText"> {t("ProjectPageImgTitle")} </span>
                            </div>
                        )}
                        <div className="additionalImageWrapper">
                            {additionalImages.map((imageUrl, index) => (
                                <div
                                    key={index}
                                    className="additionalImage"
                                    style={{backgroundImage: `url(${imageUrl})`}}
                                ></div>
                            ))}
                        </div>
                    </div>

                    <div className="contributionText" id="projectPay">
                        {" "}
                        {t("ProjectPageTitlePurchase")}{" "}
                    </div>

                    {projectActive ? (
                        <div className="projectWrapper" style={{marginBottom: '0px',}}>
                            <FormControl style={{marginBottom: '10px'}}>
                                <StyledSelect
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={age}
                                    onChange={handleChange}
                                    style={{
                                        borderRadius: "10px",
                                        // height: "50px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    <StyledMenuItem value={"EUR"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                                    </StyledMenuItem>
                                    <StyledMenuItem value={"USD"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                                    </StyledMenuItem>
                                    <StyledMenuItem value={"USDT"}>
                                        {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                                    </StyledMenuItem>
                                </StyledSelect>
                            </FormControl>
                            {/* <div className="contributionContainer"> */}
                            <h3> {t("ProjectPageEnterSum")} </h3>

                            {didUserContributed && (
                                <TextField
                                    style={{width: "90%"}}
                                    type="number"
                                    autoComplete="off"
                                    variant="standard"
                                    placeholder={`${t("ProjectPageMinimalCost")}: € ${project.repeatPrice}`}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={inputValue}
                                    onChange={handleValueInput}
                                    error={error}
                                    helperText={error ? `${t('ValueShouldBeAtLeast')} €${project.repeatPrice}` : ''}
                                />
                            )}

                            {!didUserContributed && (
                                <TextField
                                    type="number"
                                    style={{width: "90%"}}
                                    autoComplete="off"
                                    variant="standard"
                                    placeholder={`${t("ProjectPageMinimalCost")}: €${project.minPrice}`}
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    value={inputValue}
                                    onChange={handleValueInput}
                                    error={error}
                                    helperText={error ? `${t('ValueShouldBeAtLeast')} €${project.minPrice}` : ''}
                                />
                            )}
                            {/* </div> */}
                            {/* <div className="contributionContainer"> */}
                            <h3>{t("ProjectPageEnterPassword")}</h3>
                            <TextField
                                value={inputPassword}
                                onChange={handleInputPassword}
                                autoComplete="off"
                                variant="standard"
                                type="password"
                                placeholder="password"
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                            {/* </div> */}
                            <button
                                className="saveButtonProfile buttonBoldText"
                                onClick={handleValidValue}
                            >
                                {t("ProjectPageButtonSecond")}
                            </button>
                        </div>
                    ) : null}
                </div>
            )}
            <Modal
                open={modalOpen}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={styles}>
                    <h2>{errorMessage}</h2>
                    <Box sx={{mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <h1>Draw over image</h1>
                        <div className="wrapper" style={{position: 'relative', width: '400px', height: '100px'}}>
                            <img
                                ref={imageRef}
                                src="https://preview.ibb.co/jnW4Qz/Grumpy_Cat_920x584.jpg"
                                style={{width: "100%", height: '100%'}}
                                alt="background"
                            />
                            <canvas
                                ref={canvasRef}
                                className="signature-pad"
                                style={{
                                    position: 'absolute',
                                    zIndex: '10',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%'
                                }}
                            ></canvas>
                        </div>
                        <div>
                            <button ref={saveButtonRef} id="save">Save</button>
                            <button ref={clearButtonRef} id="clear">Clear</button>
                        </div>
                    </Box>
                </Box>
            </Modal>

        </>
    );
}

export default ProjectDetails;
