import React, {useEffect, useState} from 'react';
import Navigation from "../Navigation/Navigation";
import {Box} from "@mui/material";
import {useMediaQuery, useTheme} from "@mui/material";
import Cabinet from "../Cabinet/Cabinet";
import MainPageContainer from "./MainPageContainer";
import PageWrapper from "../../Routes/PageWrapper";
import {useTranslation} from 'react-i18next';
import urlFromTemplate from '../../configs/url';
import {ENDPOINTS} from '../../configs/endpoints';
import axios from 'axios';

function MainPage() {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const {t, i18n} = useTranslation();
    const [resault, setResult] = useState({})
    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
    }


    useEffect(() => {
        handleChangeLanguage()
    }, [])

    const handleChangeLanguage = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setResult(response.data)
        } catch (error) {
            console.error(error);
        } finally {
            const language = resault.language
            if (language) {
                i18n.changeLanguage(language)
            }
        }
    }

    useEffect(() => {
        const language = resault.language
        if (language) {
            i18n.changeLanguage(language)
        }
    }, [resault])

    return (
        <>
            <PageWrapper>
                <Box style={style}>
                    <Navigation isSmallScreen={isSmallScreen}/>
                    <MainPageContainer theme={theme} isSmallScreen={isSmallScreen}/>
                    <Cabinet isSmallScreen={isSmallScreen}/>
                </Box>
            </PageWrapper>
        </>
    )
}

export default MainPage