import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';

function Password () {
    const { t, i18n } = useTranslation();



    return(
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <PageWrapper>
        <div className="wrapperBasicDataPassword">
        <div className="nameWrapper" style={{ marginBottom: "10px" }}>
                     <span style={{marginLeft:'0px'}}  className="nameText">{t('ProfilePasswordTitle')}</span>
                        </div>
        <Box sx={{ maxWidth: '400px' ,display:'flex', flexDirection:'column', justifyContent:'space-between', alignItems:'center'}} value='1'>
        <TextField  sx={{marginTop: '10px',width: '100%'}} type="password" id="outlined-basic" label={t('ProfilePassword')} variant="outlined" />
        <TextField  sx={{marginTop: '10px',width: '100%'}} type="password" id="outlined-basic" label={t('ProfilePasswordFin')} variant="outlined" />
        </Box>
        </div>
        </PageWrapper>
        </LocalizationProvider>

    )

}

export default Password