import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setModalReg } from "../store/modalRegistration";
import { Checkbox, FormGroup } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import "../Style/ModalWindowRegistration/ModalWindowRegistration.scss";
import { useTranslation } from 'react-i18next';

function ModalWindowRegistration() {
  const data = useSelector((state) => state.modalRegData.modalRegData);
  const dispatch = useDispatch();
  const [checkboxEnabled, setCheckboxEnabled] = useState(false);
  const { t, i18n } = useTranslation();


  const handleCheckboxChange = () => {
    setCheckboxEnabled(!checkboxEnabled);
};

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius:'20px',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    dispatch(setModalReg(false));
  };

  return (
    <Modal
      open={data}
      onClose={handleClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="modalWindowRegistration" sx={{ ...style }}>
        <p id="parent-modal-description">
          {t('ModalWindowRegText')}
        </p>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox   onChange={handleCheckboxChange} />}
            label={t('ModalWindowRegCheckBox')}
          />
        </FormGroup>
        <div className="modalWindowRegButtonsWrapper">
        <button
          className="saveButtonProfile"
          disabled={!checkboxEnabled}
          style={
            checkboxEnabled
              ? {}
              : { cursor: "not-allowed", backgroundColor: "lightgray" }
          }
          onClick={handleClose}
        >
          {t("ModalWindowRegButton")}
        
        </button>
        <button
          className="saveButtonProfile"
          style={ {  backgroundColor: "lightgray" }
          }
          onClick={handleClose}
        >
          {t("ModalWindowRegButtonLater")}
        
        </button>
        </div>
        
      </Box>
    </Modal>
  );
}

export default ModalWindowRegistration;
