import React, { useEffect, useState } from "react";
import '../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function BillsTitle () {
    const { t, i18n } = useTranslation();



    return(
        <div className="nameWrapper">
        <span className="nameText"> 
       {t('BillsTitle')}
        </span>
        </div>
    )
}

export default BillsTitle