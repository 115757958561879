import React, { useEffect, useState } from "react";
import "../../../Style/Partners/BonusPartner.scss";
import { Typography } from "@mui/material";
import { CalendarIcon } from "@mui/x-date-pickers";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Gauge, gaugeClasses } from '@mui/x-charts/Gauge';
import { useTranslation } from 'react-i18next';

function PartnerBonus() {

    const settings = {
        width: 150,
        height: 150,
        value: 60,
      };

      const { t, i18n } = useTranslation();


  return (
    <div className="wrapperBonusPartner">
      <div style={{ width: "95%" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography style={{ display: "flex", flexDirection: "column" }}>
            {t('CabinetPartner')}
            <span style={{ color: "gray", fontSize: "12px" }}>
              01.05.2024 - 24.05.2024
            </span>
          </Typography>
          <div>
            <CalendarIcon />
          </div>
        </div>
        <div style={{display:'flex'}}>
        <div style={{ display: "flex", flexDirection: "column", width:"40%", marginTop:'20px'}}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
                <FiberManualRecordIcon style={{width:'10px', height:'10px', color:'blue', marginRight:'5px'}}/>
                Invite bonus

            </div>
            <div>$0.00</div>
          </div>
          {/* <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
            <FiberManualRecordIcon style={{width:'10px', height:'10px', color:'purple', marginRight:'5px'}}/>
            Liader bonus

            </div>
            <div>$ 0.00</div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
            <FiberManualRecordIcon style={{width:'10px', height:'10px', color:'orange',  marginRight:'5px'}}/>
            Equal bonus
            </div>
            <div>$ 0.00</div>
          </div> */}
          {/* <div
            style={{
              width: "100%",
              // height: "70px",
              background: "#f3f3f3",
              marginTop: "10px",
              borderRadius: "10px",
            }}
          >
            <p style={{ marginLeft: "20px", fontSize: "14px" }}>Общая сумма</p>
            <div style={{marginBottom:'15px' ,marginLeft: "20px", fontSize: "15px" }}>$ 0.00</div>
          </div> */}
        </div>
        <div style={{width:'60%', display:'flex', justifyContent:'center'}}>
        <Gauge
      {...settings}
      cornerRadius="50%"

      sx={(theme) => ({
        [`& .${gaugeClasses.valueText}`]: {
          fontSize: 40,
        },
        [`& .${gaugeClasses.valueArc}`]: {
          fill: '#52b202',
        },
        [`& .${gaugeClasses.referenceArc}`]: {
          fill: theme.palette.text.disabled,
        },
      })}
    />
        </div>
        </div>
   
      </div>
      <div style={{width:'100%', display:'flex', justifyContent:'center', marginTop:'30px', height:'50px', alignItems:'center'
      // , borderTop:'1px solid black' 
      }}>
            {/* <div>
                <Link color={'#4f4ff1'} style={{cursor:'pointer'}}>
                Смотреть подробнее
                </Link>
            </div> */}
        </div>
    </div>
  );
}

export default PartnerBonus;
