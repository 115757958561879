import React from "react";
import BasicData from "./ProfileSections/BasicData";


function ProfileSectionsContainer () {

    return(
        <>
        <BasicData/>
        </>
    )
}

export default ProfileSectionsContainer