import React from "react";
import "../Style/landing/Policy.scss";

function Policy() {
  return (
    <>
      <div className="policyWrapper">
        <div className="headerPolicyWrapper">
          <div className="policyText">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</div>
        </div>
        <div className="policyBodyWrapper">
          <div className="policyBodyText">
            <p>
              <b>
                Настоящая Политика Конфиденциальности действует в отношении всех
                данных и информации, которую Вы как Пользователь оставляете в
                ходе использования и взаимодействия с сайтом
                https://test.diamond.balinasoft.com/ (далее по тексту - Сайт) и
                регулирует порядок обработки (то есть сбора, хранения,
                использования, систематизации и т.п.) информации, полученной о
                Вас как о Пользователе Сайта.
              </b>
            </p>
            <p>
              <b>
                Данная Политика не применяется в случаях, если Пользователь
                переходит по интернет-ссылкам, которые могут быть доступны на
                Сайте.
              </b>
            </p>
            <p>
              <b>
                Любое взаимодействие с Сайтом (например: просмотр, регистрация,
                пользование сервисами, принятие оферт) означает, что Вы выразили
                безоговорочное согласие с настоящим документом и с указанными в
                нем условиями.
              </b>
            </p>
            <p>
              <b>
                В случае несогласия с настоящими условиями Вы должны
                воздержаться от взаимодействия с Сайтом, пользования сервисами,
                а также прекратить процесс регистрации на Сайте.
              </b>
            </p>
            <p>
              <b>
                По всем вопросам, касающимся условий обработки информации и
                Ваших данных, необходимо обращаться в службу поддержки
                посредством специального сервиса на Сайте или по электронной
                почте support@diamond.com.
              </b>
            </p>
            <h3>
              <b>I. Общие положения</b>
            </h3>
            <span>
                <p>
              1.1. Настоящая политика обработки персональных данных составлена в
              соответствии с требованиями Закона о персональных данных и
              определяет порядок обработки персональных данных и меры по
              обеспечению безопасности персональных данных, предпринимаемые
              Diamond (далее – Оператор).
              </p>
              <p>
              1.2. Оператор ставит своей важнейшей целью и условием
              осуществления своей деятельности соблюдение прав и свобод человека
              и гражданина при обработке его персональных данных, в том числе
              защиты прав на неприкосновенность частной жизни, личную и семейную
              тайну.
              </p>
              <p>
              1.3. Настоящая политика конфиденциальности в отношении обработки
              персональных данных (далее – Политика) применяется ко всей
              информации, которую Оператор может получить о посетителях и
              Пользователях сайта https://test.diamond.balinasoft.com/
              </p>
            </span>
            <h3>
              <b>II. Основные понятия, используемые в Политике</b>
            </h3>
            <span>
            <p>
              2.1. Автоматизированная обработка данных – обработка персональных
              данных и иной информации с помощью средств вычислительной техники.
              </p>
              <p>
              2.2. Блокирование данных – временное прекращение обработки
              информации и персональных данных (за исключением случаев, если
              обработка необходима для уточнения персональных данных).
              </p>
              <p>
              2.3. Сайт – совокупность графических и информационных материалов,
              а также программ для ЭВМ и баз данных, обеспечивающих их
              доступность в сети интернет по адресу
              https://test.diamond.balinasoft.com/
              </p>
              <p>
              2.4. Информационная система данных — совокупность содержащихся в
              базах данных персональных данных и информации, и обеспечивающая их
              обработку информационных технологий и технических средств.
              </p>
              <p>
              2.5. Обезличивание персональных данных — действия, в результате
              которых невозможно определить без использования дополнительной
              информации принадлежность персональных данных конкретному
              Пользователю или иному субъекту персональных данных.
              </p>
              <p>
              2.6. Обработка персональных данных – любое действие (операция) или
              совокупность действий (операций), совершаемых с использованием
              средств автоматизации или без использования таких средств, с
              персональными данными, включая сбор, запись, систематизацию,
              накопление, хранение, уточнение (обновление, изменение),
              извлечение, использование, передачу (распространение,
              предоставление, доступ), обезличивание, блокирование, удаление,
              уничтожение персональных данных.
              </p>
              <p>
              2.7. Оператор – Diamond
              </p>
              <p>
              2.8. Пользователь – любой посетитель Сайта.
              </p>
              <p>
              2.9. Персональные данные – любая информация, относящаяся прямо или
              косвенно к определенному Пользователю Сайта и позволяющая его
              идентифицировать.
              </p>
              <p>
              2.10. Персональные данные, разрешенные субъектом персональных
              данных для распространения - персональные данные, доступ
              неограниченного круга лиц к которым предоставлен субъектом
              персональных данных путем дачи согласия на обработку персональных
              данных, разрешенных субъектом персональных данных для
              распространения в порядке, предусмотренном Законом о персональных
              данных (далее - персональные данные, разрешенные для
              распространения).
              </p>
              <p>
              2.11. Предоставление персональных данных – действия, направленные
              на раскрытие персональных данных определенному лицу или
              определенному кругу лиц.
              </p>
              <p>
              2.12. Распространение персональных данных – любые действия,
              направленные на раскрытие персональных данных неопределенному
              кругу лиц (передача персональных данных) или на ознакомление с
              персональными данными неограниченного круга лиц, в том числе
              обнародование персональных данных в средствах массовой информации,
              размещение в информационно-телекоммуникационных сетях или
              предоставление доступа к персональным данным каким-либо иным
              способом.
              </p>
              <p>
              2.13. Трансграничная передача персональных данных – передача
              персональных данных на территорию иностранного государства органу
              власти иностранного государства, иностранному физическому лицу или
              иностранному юридическому лицу.
              </p>
              <p>
              2.14. Уничтожение персональных данных – любые действия, в
              результате которых персональные данные уничтожаются безвозвратно с
              невозможностью дальнейшего восстановления содержания персональных
              данных в информационной системе персональных данных и (или)
              уничтожаются материальные носители персональных данных.
              </p>
            </span>
            <h3>
              <b>III. Основные права и обязанности Оператора</b>
            </h3>
            <span>
              <p>3.1. Оператор имеет право:</p>
              <br /> – получать от Пользователя достоверные информацию и/или
              документы, содержащие персональные данные и иную информацию;
              <br />– в случае отзыва Пользователем согласия на обработку
              персональных данных Оператор вправе продолжить обработку
              персональных данных без согласия Пользователя при наличии
              оснований, указанных в Законе о персональных данных;
              <br />– самостоятельно определять состав и перечень мер,
              необходимых и достаточных для обеспечения выполнения обязанностей,
              предусмотренных Законом о персональных данных и принятыми в
              соответствии с ним нормативными правовыми актами, если иное не
              предусмотрено Законом о персональных данных или другими
              федеральными законами.
              <p>3.2. Оператор обязан:</p>
              – предоставлять Пользователю по его просьбе информацию, касающуюся
              обработки его персональных данных и иной информации о нем;
              <br />– организовывать обработку информации и персональных данных
              в порядке, установленном действующим международным
              законодательством;
              <br />– отвечать на обращения и запросы Пользователя в
              соответствии с требованиями Закона о персональных данных;
              <br />– сообщать в уполномоченный орган по защите прав субъектов
              персональных данных по запросу этого органа необходимую информацию
              в течение 30 дней с даты получения такого запроса;
              <br />– публиковать или иным образом обеспечивать неограниченный
              доступ к настоящей Политике в отношении обработки персональных
              данных;
              <br />– принимать правовые, организационные и технические меры для
              защиты персональных данных и иной информации от неправомерного или
              случайного доступа к ним, уничтожения, изменения, блокирования,
              копирования, предоставления, распространения персональных данных,
              а также от иных неправомерных действий в отношении персональных
              данных;
              <br />– прекратить передачу (распространение, предоставление,
              доступ) персональных данных, прекратить обработку и уничтожить
              персональные данные в порядке и случаях, предусмотренных Законом о
              персональных данных;
              <br />– исполнять иные обязанности, предусмотренные Законом о
              персональных данных.
            </span>
            <h3>
              <b>IV. Основные права и обязанности Пользователя</b>
            </h3>
            <span>
              <p>4.1. Пользователь имеет право:</p>
              – получать информацию, касающуюся обработки его персональных
              данных, за исключением случаев, предусмотренных федеральными
              законами. Сведения предоставляются субъекту персональных данных
              Оператором в доступной форме, и в них не должны содержаться
              персональные данные, относящиеся к другим субъектам персональных
              данных, за исключением случаев, когда имеются законные основания
              для раскрытия таких персональных данных. Такую информацию каждый
              Пользователь может получить, обратившись в службу поддержки
              посредством специального сервиса на Сайте или по электронной почте
              support@diamond.com;
              <br />– требовать от оператора уточнения его персональных данных,
              их блокирования или уничтожения в случае, если персональные данные
              являются неполными, устаревшими, неточными, незаконно полученными
              или не являются необходимыми для заявленной цели обработки, а
              также принимать предусмотренные законом меры по защите своих прав.
              Все требования должны направляться по электронной почте
              support@diamond.com;
              <br />– выдвигать условие предварительного согласия при обработке
              персональных данных в целях продвижения на рынке товаров, работ и
              услуг;
              <br />– на отзыв согласия на обработку персональных данных. Отзыв
              согласия должен направляться по электронной почте
              support@diamond.com в свободной форме;
              <br />– обжаловать в уполномоченный орган по защите прав субъектов
              персональных данных или в судебном порядке неправомерные действия
              или бездействие Оператора при обработке его персональных данных;
              <br />– на осуществление иных прав, предусмотренных международным
              законодательством.
              <p>4.2. Пользователь обязан:</p>
              <br />– предоставлять Оператору достоверные данные о себе;
              <br />– сообщать Оператору об уточнении (обновлении, изменении)
              своих персональных данных.
              <p>
                4.3. Пользователи, передавшие Оператору недостоверные сведения о
                себе, либо сведения о другом субъекте персональных данных без
                согласия последнего, несут ответственность в соответствии с
                международным законодательством, а также в соответствии с
                законодательством страны своего резидентства.
              </p>
            </span>
            <h3>
              <b>V. Информация о Пользователе, которая может обрабатываться</b>
            </h3>
            <span>
              <p>
                5.1. Оператор может обрабатывать следующие данные о
                Пользователе:
              </p>
              – Фамилия, имя, отчество.
              <br />– Адрес электронной почты, телефон, имя пользователя в
              различных мессенджерах.
              <br />– Год, месяц, дата и место рождения.
              <br />– Фотографии.
              <br />– Сведения о гражданстве, виде на жительство и т.п.
              <br />– Реквизиты документа, удостоверяющего личность.
              <br />– Иная информация о Пользователе, которую он предоставил на
              Сайте или посредством электронной почты support@diamond.com
              <br />– Данные, которые автоматически передаются в процессе
              использования Сайта с помощью установленного на устройстве
              Пользователя программного обеспечения, в том числе такими данными
              могут быть данные об использовании сервисов, данные о просмотре
              сообщений, дата и время доступа к сервисам, и иная подобная
              информация.
              <br />– Данные и информация, которые передаются Пользователем при
              обращении в службу поддержки.
              <br />– Данные и информация, которые передаются Пользователем по
              электронной почте в адрес Оператора.
              <p>
                5.2. Также на сайте происходит сбор и обработка обезличенных
                данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов
                интернет-статистики.
              </p>
              <p>
                5.3. Обработка специальных категорий персональных данных,
                касающихся расовой, национальной принадлежности, политических
                взглядов, религиозных или философских убеждений, интимной жизни,
                Оператором не осуществляется.
              </p>
              <p>
                5.4. Обработка персональных данных, разрешенных для
                распространения, из числа специальных категорий персональных
                данных, указанных в ст. 9,10 Закона о персональных данных,
                допускается, если соблюдаются запреты и условия, предусмотренные
                ст. 9,10 Закона о персональных данных.
              </p>
              <p>
                5.5. Оператор оставляет за собой право проверять достоверность
                информации, предоставляемой Пользователем, а также оценивать его
                дееспособность. При этом Оператор исходит из того, что
                Пользователь предоставляет достоверную и достаточную информацию
                о себе и поддерживает эту информацию в актуальном состоянии. В
                случае выявления фактов предоставления Пользователем
                недостоверной информации Оператор имеет право заблокировать
                Пользователя и ограничить его доступ к Сайту.
              </p>
            </span>
            <h3>
              <b>VI. Правила и принципы обработки персональных данных</b>
            </h3>
            <span>
              <p>
                6.1. Оператором собирается и хранится только та информация,
                которая необходима для посещения Пользователем Сайта,
                пользования его сервисами и получения услуг.
              </p>
              <p>
                6.2. Информация Пользователя обрабатывается в следующих целях:
              </p>
              (а) Идентификация Пользователя в процессе использования им Сайта/
              получения услуг.
              <br />
              (b) Предоставление Пользователю персонифицированных сервисов и
              оказание услуг, а также заключение соглашений и договоров.
              <br />
              (с) Связь с Пользователем, в том числе направление уведомлений,
              запросов и информации, касающихся использования Сайта и получения
              услуг.
              <br />
              (d) Улучшение качества оказываемых услуг, получение обратной связи
              от Пользователей, увеличение удобства пользования Сайтом,
              разработка новых продуктов и услуг.
              <br />
              (e) Проведение статистических и иных исследований.
              <br />
              (f) Направление рекламных предложений, в том числе предложений о
              получении услуг.
              <br />
              (g) Заключения соглашений с Оператором или иными юридическими
              лицами, информация об услугах (товарах) которых размещена на
              Сайте.
              <p>
                6.3. Пользователь соглашается, что предоставленная им информация
                будет использована в целях персонализации контента на Сайте.
              </p>
              <p>
                6.4. Обработка персональных данных и информации осуществляется
                на законной и справедливой основе.
              </p>
              <p>
                6.5. Обработка персональных данных ограничивается достижением
                конкретных, заранее определенных и законных целей. Не
                допускается обработка персональных данных, несовместимая с
                целями сбора персональных данных.
              </p>
              <p>
                6.6. Не допускается объединение баз данных, содержащих
                персональные данные, обработка которых осуществляется в целях,
                несовместимых между собой.
              </p>
              <p>
                6.7. Обработке подлежат только персональные данные, которые
                отвечают целям их обработки. Не допускается избыточность
                обрабатываемых персональных данных по отношению к заявленным
                целям их обработки.
              </p>
              <p>
                6.8. Хранение персональных данных осуществляется в форме,
                позволяющей определить субъекта персональных данных, не дольше,
                чем этого требуют цели обработки персональных данных, если срок
                хранения персональных данных не установлен федеральным законом,
                договором, стороной которого, бенефициаром или поручителем по
                которому является субъект персональных данных. Обрабатываемые
                персональные данные уничтожаются либо обезличиваются по
                достижении целей обработки или в случае утраты необходимости в
                достижении этих целей, если иное не предусмотрено федеральным
                законом.
              </p>
              <p>
                6.9. Оператор имеет право направлять Пользователю уведомления о
                новых продуктах и услугах, специальных предложениях и различных
                событиях. Пользователь всегда может отказаться от получения
                информационных сообщений, направив Оператору письмо на адрес
                электронной почты support@diamond.com c пометкой «Отказ от
                уведомлений о новых продуктах и услугах и специальных
                предложениях»
              </p>
            </span>
            <h3>
              <b>VII. Условия использования информации Пользователя.</b>
            </h3>
            <span>
              <p>
                <b>Согласие на обработку данных и их передачу третьим лицам</b>
              </p>
              <p>
                7.1. Пользователь соглашается, что часть предоставленной им
                информации будет доступна другим Пользователям Сайта, а именно:
                ник или имя, электронная почта и иные средства связи. В случае
                необходимости скрыть какие-либо данные от других Пользователей,
                Пользователю необходимо воспользоваться соответствующим
                функционалом Сайта или направить письмо по адресу электронной
                почты: support@diamond.com.
              </p>
              <p>
                7.2. Данные Пользователя, указанные им на Сайте, переданные им
                через службу поддержки или по электронной почте обрабатываются
                Оператором. В случаях, когда Пользователь находится не в ОАЭ,
                Пользователь соглашается на трансграничную передачу своих данных
                в адрес Оператора.
              </p>
              <p>
                7.3. Оператор вправе передать информацию Пользователя третьим
                лицам в следующих случаях:
              </p>
              (а) когда Пользователь выразил согласие на такие действия или
              <br />
              (b) когда передача необходима для исполнения определенного
              соглашения или договора, в том числе оферты с Пользователем или
              <br />
              (с) по запросу государственных органов, судебных органов и
              должностных лиц или
              <br />
              (d) в иных случаях, предусмотренных международным
              законодательством.
              <p>
                Данные Пользователя, который заключил какое-либо соглашение,
                используя Сайт (информацию на Сайте), также передаются сторонами
                заключенного соглашения.
              </p>
              <p>
                В случае необходимости отзыва согласия или изменения данных
                Пользователю необходимо направить соответствующее заявление по
                адресу электронной почты support@diamond.com.
              </p>
              <p>
                7.4. В случае необходимости в целях заключения соглашений и
                оказания услуг, по запросу Оператора Пользователь обязуется
                предоставить свои персональные данные. Персональными данными
                являются любые сведения, относящиеся прямо или косвенно к
                Пользователю, в том числе, но не исключая: фамилия, имя,
                отчество, дата и место рождения, паспортные данные, статус. При
                непредставлении по запросу Оператора персональных данных, доступ
                Пользователя к услугам и Сайту может быть ограничен.
              </p>
              <p>
                7.5. В случае передачи своих персональных данных Пользователь
                выражает свое безоговорочное согласие на их обработку и
                хранение.
              </p>
              <p>
                7.6. Пользователь несет ответственность за любую информацию,
                опубликованную им на Сайте, в сервисах и чатах, посвященных
                проекту Diamond, и соглашается, что эта информация будет
                доступна другим Пользователям. Пользователь соглашается, что
                любая информация, размещенная им на Сайте в сервисах и чатах,
                посвященных проекту Diamond, может быть прочитана, собрана или
                использована другими Пользователями и сторонними лицами (в
                случае, если она находится в открытом доступе).
              </p>
              <p>
                7.7. Пользователь самостоятельно принимает решение о
                предоставлении его персональных данных и дает согласие свободно,
                своей волей и в своем интересе.
              </p>
            </span>
            <h3>
              <b>VIII. Изменение и удаление данных о Пользователе.</b>
            </h3>
            <span>
              <p>
                <b>Отзыв согласия на обработку персональных данных</b>
              </p>
              <p>
                8.1. Пользователь может в любой момент изменить (обновить,
                дополнить) предоставленную им информацию или ее часть, направив
                письмо на электронный адрес: support@diamond.com..
              </p>
              <p>
                8.2. Пользователь может в любой момент удалить предоставленную
                им персональную информацию, направив письмо на электронный
                адрес: support@diamond.com.
              </p>
              <p>
                8.3. Права, предусмотренные пунктами 8.1. и 8.2. настоящей
                Политики могут быть ограничены в соответствии с требованиями
                законодательства. В частности, такие ограничения могут
                предусматривать обязанность Оператора сохранить измененную или
                удаленную Пользователем информацию на срок, установленный
                законодательством, и передать такую информацию в соответствии с
                законодательно установленной процедурой государственному органу.
              </p>
              <p>
                8.4. Пользователь может в любой момент отозвать согласие на
                обработку своих персональных и иных данных, направив письмо на
                электронный адрес: support@diamond.com. В ряде случаев отзыв
                согласия может повлечь невозможность получения услуг (как в
                целом, так и в части).
              </p>
            </span>
            <h3>
              <b>
                IX. Срок хранения информации и персональных данных Пользователя
              </b>
            </h3>
            <span>
              <p>
                9.1. Предоставленная Пользователем информация хранится в течение
                6 месяцев с момента ее предоставления, за исключением случаев,
                указанных в п. 9.2. настоящей Политики.
              </p>
              <p>
                9.2. В случае принятия Пользователем оферт, заключения
                соглашений, предоставленная Пользователем информация хранится в
                течение всего периода действия оферты/соглашения, а также в
                течение 3 (трех) лет после прекращения действия
                оферты/соглашения.
              </p>
              <p>
                9.3. В случае необходимости сокращения срока хранения
                предоставленной Пользователем информации Пользователь может
                направить письмо с описанием ситуации на электронный адрес:
                support@diamond.com.
              </p>
            </span>
            <h3>
              <b>X. Использование файлов Cookie и счетчиков</b>
            </h3>
            <span>
              <p>
                10.1. На Сайте могут использоваться файлы Cookie для
                персонализации возможностей Сайта и сервисов, для предоставления
                Пользователю персонализированных сервисов, для таргетирования
                рекламы, в статистических и исследовательских целях, а также для
                улучшения работы Сайта и оказания услуг.
              </p>
              <p>
                10.2. Пользователь осознает, что оборудование и программное
                обеспечение, используемые им для посещения сайтов в сети
                интернет, могут обладать функцией запрещения операций с файлами
                Cookie (для любых сайтов или для определенных сайтов), а также
                удаления ранее полученных файлов Сookie.
              </p>
              <p>
                10.3. Оператор может установить, что пользование Сайтом и
                сервисами возможно лишь при условии, что прием и получение
                файлов Cookie разрешены Пользователем. В таких случаях,
                пользуясь Сайтом Пользователь соглашается на размещение в своем
                браузере файлов Cookie.
              </p>
              <p>
                10.4. Счетчики, размещенные на Сайте и в сервисах, могут
                использоваться для анализа файлов Cookie Пользователя, для сбора
                и обработки статистической информации об использовании Сервисов,
                а также для обеспечения работоспособности сервисов в целом или
                их отдельных функций в частности. Технические параметры работы
                счетчиков определяются Оператором и могут изменяться без
                предварительного уведомления Пользователя.
              </p>
            </span>
            <h3>
              <b>XI. Правовые основания обработки персональных данных</b>
            </h3>
            <span>
              <p>
                11.1. Правовыми основаниями обработки персональных данных
                Оператором являются:
              </p>
              – DATA PROTECTION LAW DIFC LAW NO. 5 OF 2020;
              <br />– международное законодательство в сфере защиты персональных
              данных;
              <br />– согласия Пользователей на обработку их персональных
              данных, на обработку персональных данных, разрешенных для
              распространения.
              <p>
                11.2. Оператор обрабатывает персональные данные Пользователя
                только в случае их заполнения и/или отправки Пользователем
                самостоятельно через специальные формы, расположенные на Сайте
                или направленные Оператору посредством электронной почты.
              </p>
              <p>
                11.3. Заполняя соответствующие формы и/или отправляя свои
                персональные данные Оператору, Пользователь выражает свое
                согласие с данной Политикой.
              </p>
            </span>
            <h3>
              <b>XII. Конфиденциальность персональных данных</b>
            </h3>
            <span>
              <p>
                12.1. Оператор и иные лица, получившие доступ к персональным
                данным, обязаны не раскрывать третьим лицам и не распространять
                персональные данные без согласия субъекта персональных данных,
                если иное не предусмотрено применимым законодательством.
              </p>
            </span>
            <h3>
              <b>
                XIII. Ответственность за хранение данных, необходимых для
                пользования
              </b>
            </h3>
            <span>
              <p>
                <b>Сайтом и получения услуг</b>
              </p>
              <p>
                13.1. Пользователь обязан обеспечивать сохранность всех данных
                (логин, пароль, ID, логин и пароль от электронной почты)
                Пользователя, необходимых для пользования Сайтом и получения
                услуг.
              </p>
              <p>
                13.2. При наличии информации или подозрений о неправомерном
                получении третьими лицами данных Пользователя, Пользователь
                обязан незамедлительно предпринять все зависящие от него меры и
                уведомить Оператора по электронной почте support@diamond.com.
              </p>
              <p>
                13.3. Все действия, совершенные Пользователям на Сайте, в
                сервисах под именем (логином) Пользователя считаются
                совершенными Пользователем.
              </p>
            </span>
            <h3>
              <b>XIV. Меры, применяемые для защиты информации Пользователя</b>
            </h3>
            <span>
              <p>
                14.1. Оператором принимаются необходимые и достаточные
                организационные и технические меры для защиты информации
                Пользователя от неправомерного или случайного доступа,
                уничтожения, изменения, блокирования, копирования,
                распространения, а также от иных неправомерных действий с ней
                третьих лиц.
              </p>
              <p>
                14.2. В случаях, если произойдет какая-либо утечка данных
                Пользователей, Оператор обязуется сообщить об этом Пользователям
                в течение 72 часов с момента обнаружения такой утечки.
              </p>
            </span>
            <h3>
              <b>XV. Изменение Политики конфиденциальности</b>
            </h3>
            <span>
              <p>
                15.1. В настоящую Политику конфиденциальности могут быть внесены
                изменения. При внесении изменений в актуальной редакции
                указывается дата последнего обновления. Новая редакция Политики
                вступает в силу с момента ее размещения, если иное не
                предусмотрено новой редакцией Политики.
              </p>
              <p>
                15.2. В случае несогласия с изменениями в Политике, Пользователь
                обязан прекратить пользование Сайтом. Продолжение пользования
                Сайтом, его сервисами означают согласие с изменениями,
                внесенными в настоящую Политику.
              </p>
            </span>
            <h3>
              <b>XVI. Заключительные положения</b>
            </h3>
            <span>
              <p>
                16.1. Пользователь может получить любые разъяснения по
                интересующим вопросам, касающимся обработки его данных,
                обратившись к Оператору с помощью электронной почты
                support@diamond.com.
              </p>
              <p>
                16.2. В данном документе будут отражены любые изменения политики
                обработки персональных данных Оператором. Политика действует
                бессрочно до замены ее новой версией.
              </p>
              <p>
                16.3. Актуальная версия Политики в свободном доступе расположена
                в сети Интернет по адресу: https://test.diamond.balinasoft.com/
              </p>
              <p>16.4. Настоящая редакция Политики действует с 16.05.2024 г.</p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Policy;
