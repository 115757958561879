// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/motivation/level.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../images/motivation/levelBlock.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../images/motivation/Arrow.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperLevelFirst {
  width: 84px;
  height: 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imgLevelFirst {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 66px;
  height: 66px;
  background-size: 66px 66px;
}

.imgLevelSecond {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  width: 66px;
  height: 66px;
  background-size: 66px 66px;
}

.wrapperLefelArrow {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  width: 26px;
  height: 17px;
  background-size: 26px 17px;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/Style/Partners/Motivation.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,yDAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;AACJ;;AAGA;EACI,yDAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;AAAJ;;AAIA;EACI,yDAAA;EACA,WAAA;EACA,YAAA;EACA,0BAAA;EACA,4BAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,kBAAA;AADJ","sourcesContent":[".wrapperLevelFirst{\n    width: 84px;\n    height: 84px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.imgLevelFirst{\n    background-image: url(../../images/motivation/level.png);\n    width: 66px;\n    height: 66px;\n    background-size: 66px 66px;\n\n}\n\n.imgLevelSecond{\n    background-image: url(../../images/motivation/levelBlock.png);\n    width: 66px;\n    height: 66px;\n    background-size: 66px 66px;\n\n}\n\n.wrapperLefelArrow{\n    background-image: url(../../images/motivation/Arrow.png);\n    width: 26px;\n    height: 17px;\n    background-size: 26px 17px;\n    background-repeat: no-repeat;\n    display: flex;\n    align-items: center;\n    margin: 0 auto;\n    text-align: center;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
