// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nameWrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  margin: 30px auto 0px;
}

.nameText {
  font-size: 30px;
}

#wrapperPartnership {
  min-height: 636px;
}`, "",{"version":3,"sources":["webpack://./src/Style/NameCabinet.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,qBAAA;AACJ;;AAEA;EAEI,eAAA;AAAJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":[".nameWrapper{\n    width: 100%;\n    height: 50px;\n    display: flex;\n    align-items: center;\n    margin: 30px auto 0px;\n}\n\n.nameText{\n    // margin-left: 100px / 1500px * 100%;\n    font-size: 30px;\n}\n\n#wrapperPartnership{\n    min-height: 636px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
