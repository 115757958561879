import React, {useEffect, useState} from "react";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import {ReactComponent as Withdraw} from '../../images/Verification/checkTrue.svg'
import {ReactComponent as Decline} from '../../images/Agreement/decline.svg'
import {ReactComponent as Pending} from '../../images/Verification/pending.svg'
import {ReactComponent as Send} from '../../images/Agreement/send.svg'
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

function VerificationPage() {

    const [content, setContent] = useState([])
    const [pageSettings, setPageSettings] = useState({})
    const [row, setRow] = useState([])
    const [photoCab, setPhotoCab] = useState({});
    const [selectedImage, setSelectedImage] = useState(null);
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState(0)
    const pageSize = 10;
    const [open, setOpen] = useState(false);

    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        retrieveWithdrawConfirmations()
    }, [page])

    const retrieveWithdrawConfirmations = async () => {
        const param = {
            page: page,
            size: 10,
        }

        const queryString = new URLSearchParams(param).toString();
        const apiUrl = urlFromTemplate(ENDPOINTS.VERIFICATION) + `/logs` + `?${queryString}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setContent(response.data.content)
            setPagination(response.data.totalElements);
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        content.forEach((row) => {
            getPhotoId(row.templateUrlId, row.verificationId);
        });
    }, [content]);

    const handleVerifyClick = () => {
        navigate('/mainpage/profile/verification');
    }

    //Форматирование даты

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const getPhotoId = async (urlId, verificationId) => {
        if (!urlId) return null;

        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${urlId}`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
                responseType: "blob",
            });

            setPhotoCab((prevState) => ({
                ...prevState,
                [verificationId]: URL.createObjectURL(response.data),
            }));
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${urlId}`, error);
            return null;
        }
    };

    const handleClickOpen = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedImage(null);
    };

    const getStatusMessage = (status) => {
        switch (status) {
            case 'PENDING_SIGNING':
                return <Send style={{width: '25px', height: '25px'}}/>;
            case 'PENDING_CONFIRMATION':
                return <Pending style={{width: "25px", height: '25px'}}/>;
            case 'CONFIRMED':
                return <Withdraw style={{width: "25px", height: '25px'}}/>;
            case 'DECLINED':
                return <Decline style={{width: "25px", height: '25px'}}/>;
            default:
                return 'Unknown status.';
        }
    };

    // Открытие PDF

    const handleOpenAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    return (
        <div className="mainPageBody important-margin-top">
            <div style={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                <span style={{fontSize: "30px"}}>{t("verification")}</span>
                <button className="yellowButton" onClick={handleVerifyClick}>
                    {t("VerifyButton")}
                </button>
            </div>
            <div className="wrapperIncomeTableTransaction">
            <TableContainer component={Paper} style={{boxShadow: "none"}}>
                    <Table
                        aria-label="simple table">
                        <TableHead
                            style={{background: "#F7F9FC", border: "1px solid white"}}
                        >
                            <TableRow style={{border: "none"}}>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>ID</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementTime")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("MainPageTransactionsDoc")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                    <b>{t("status")}</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {content.length > 0 && content.map((row) => (
                                <TableRow
                                    key={row.is}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.verificationId}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {formatDate(row.dateTime)}
                                    </TableCell>
                                    <TableCell align="right">
                                        <div
                                            className="myAccountImageLogin"
                                            style={{
                                                backgroundImage: `url(${photoCab[row.verificationId]})`,
                                                borderRadius: 0,
                                                cursor: "pointer",
                                                width: '50px',
                                                height: '50px',
                                                backgroundSize: '100% 100%'
                                            }}
                                            onClick={() => handleClickOpen(photoCab[row.verificationId])}
                                        ></div>
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Button disabled>
                                            {getStatusMessage(row.status)}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={false}
                        component="div"
                        count={pagination}
                        rowsPerPage={pageSize}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </TableContainer>
            </div>
            <Dialog open={open} onClose={handleClose} maxWidth="lg">
                <DialogContent>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Preview"
                            style={{width: "100%", height: "auto"}}
                        />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default VerificationPage;