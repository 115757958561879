import React from "react";
import GraphicalStructureTitle from "./GraphicalStructureTitle";
import InviteCounts from "./InviteCounts";
import GraphicalRepresentation from "./GraphicalRepresentation";
import PageWrapper from "../../../Routes/PageWrapper";

function GraphicalStructure() {
    return (
        <>
            <PageWrapper>
                <div className="wrapperPartners">
                    <GraphicalStructureTitle/>
                </div>
                <GraphicalRepresentation/>
            </PageWrapper>
        </>
    )
}

export default GraphicalStructure