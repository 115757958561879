import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import '../../Style/MainPageContainer.scss';
import PdfRender from "./pdf"; // Убедитесь, что путь правильный

function MainPageContainer({ theme, isSmallScreen }) {
  const style = {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    background: '#eef1fa'
  };

  // Пример использования абсолютного пути или URL
  const pdfUrl = require('../../images/PDF/file.pdf');

  return (
    <div style={style} className="mainPageContainer">
      <Header theme={theme} isSmallScreen={isSmallScreen} />
      <Outlet />
      {/* <PdfRender file={pdfUrl} /> */}
      <Footer />
    </div>
  );
}

export default MainPageContainer;
