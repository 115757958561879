import React, {useState, useCallback, useRef, useEffect} from "react";
import {AgGridReact} from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import urlFromTemplate from '../../../configs/url.js';
import {ENDPOINTS} from '../../../configs/endpoints.js';
import axios from 'axios';
import demo from '../../../images/Black.png'
import PhotoCellRenderer from "./PhotoCellRenderer.jsx";
import '../../../Style/InviteStructure/InviteStructureTable.scss'
import {ReactComponent as Search} from '../../../images/incomeTable/searchImage.svg'
import {ReactComponent as Close} from '../../../images/incomeTable/closeImage.svg'
import {height, width} from "@mui/system";
import {TextField, styled} from "@mui/material";
import InviteCounts from "./InviteCounts";

const getPhotoId = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
        const response = await axios.get(apiUrl, {
            withCredentials: true,
            headers: {
                'Accept': 'application/json',
                'Access-Control-Allow-Credentials': 'true'
            },
            responseType: 'blob'
        });

        return URL.createObjectURL(response.data);
    } catch (error) {
        console.error(`Failed to fetch image/document with id ${id}`, error);
        return null;
    }
};

const InviteStructureTable = () => {

    const [counts, setCounts] = useState([]);
    const [structure, setStructure] = useState([]);

    const [isInputVisible, setIsInputVisible] = useState(false);

    const handleButtonClick = () => {
        setIsInputVisible(true);
    };


    const graphicalStructure = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/user-structure`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            });

            const {counts, structure} = response.data;
            await Promise.all([]);

            setCounts(counts);
            setStructure(await Promise.all(structure.map(async (item) => {
                if (item.urlId) {
                    const imageUrl = await getPhotoId(item.urlId);
                    return {...item, imageUrl};
                }
                return {...item, imageUrl: null};
            })));

        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        graphicalStructure();
    }, []);

    const columnDefs = [
        {
            field: "login",
            filter: "agTextColumnFilter",
            filterParams: {
                filterOptions: ["contains", "notContains", "startsWith", "endsWith"],
                suppressAndOrCondition: true
            }
        },
        {field: "parent", headerName: "Parent ID"},
        {field: "ngo", headerName: "НГО"},
        {
            headerName: "Photo",
            field: "imageUrl",
            cellRendererFramework: PhotoCellRenderer
        }
    ];

    const autoGroupColumnDef = {
        minWidth: 200
    };

    const defaultColDef = {
        flex: 1,
        sortable: true,
        filter: true
    };

    const getDataPath = ({id, parent}) => {
        const result = [id];
        let row = structure.find((row) => row.id === parent);
        while (row) {
            result.unshift(row.id);
            row = structure.find((r) => r.id === row.parent);
        }
        return result;
    };

    const gridRef = useRef();

    const handleFilter = useCallback(() => {
        gridRef.current?.api.setQuickFilter(
            document.getElementById("filter-text-box")?.value
        );
    }, []);

    const StyledTextField = styled(TextField)({
        '& input::placeholder': {
            padding: '0px',
            marginBottom: '0px'
        },
        '& .MuiInputBase-input': {
            // padding: '0px',
            marginBottom: '0px'
        }
    });

    return (
        <>
            <InviteCounts counts={counts}/>
            <div className="wrapperIncomeTableTable">
                <div className="ag-theme-alpine ag-theme-quartz"
                     style={{height: "500px", width: "100%", marginBottom: '50px'}}>

                    <div className={`search-container ${isInputVisible ? 'active' : ''}`}>
                        {!isInputVisible && (
                            <button className="search-button" onClick={handleButtonClick}>
                                <Search style={{width: '20px', height: '20px'}}/>
                            </button>
                        )}
                        <StyledTextField
                            type="text"
                            id="filter-text-box"
                            placeholder="Filter list"
                            disabled={!isInputVisible}
                            onInput={handleFilter}
                            style={{width: '80%', marginBottom: '10px',}}
                            className={`search-input ${isInputVisible ? 'visible' : ''}`}
                            InputProps={{
                                startAdornment: (
                                    <Search style={{width: '20px', height: '20px'}}/>
                                )
                            }}
                        />


                    </div>

                    {structure.length > 0 ? (
                        <AgGridReact
                            suppressBrowserResizeObserver={true}
                            rowData={structure}
                            treeData={true}
                            animateRows={true}
                            autoGroupColumnDef={autoGroupColumnDef}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            getDataPath={getDataPath}
                            ref={gridRef}
                            rowHeight={53}
                        />
                    ) : (
                        <p>Loading...</p>
                    )}
                </div>
            </div>
        </>
    );
};

export default InviteStructureTable;
