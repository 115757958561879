import React from "react";
import {Box} from "@mui/material";
import {useMediaQuery, useTheme} from "@mui/material";
import Cabinet from "../Cabinet/Cabinet";
import ProjectPageContainer from "./ProjectPageContainer";
import Navigation from "../Navigation/Navigation";
import PageWrapper from "../../Routes/PageWrapper";

function MainPage() {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row'
    }

    return (
        <>
        <PageWrapper>
            {/* <Box style={style}> */}
                <ProjectPageContainer theme={theme} isSmallScreen={isSmallScreen}/>
            {/* </Box> */}
            </PageWrapper>
        </>
    )
}

export default MainPage