// import { createSlice } from "@reduxjs/toolkit";


// const language = localStorage.getItem('site_settings')
// let myObj = JSON.parse(language)
// let lang = myObj['language']

// const initialState = {
//     dataLanguage: lang
// };

// const languageStateSlice = createSlice({
//     name: 'languageData',
//     initialState,
//     reducers: {
//         setLanguageState: (state, action) => {
//             state.dataLanguage = action.payload;
//         }
//     }
// });

// export const { setLanguageState } = languageStateSlice.actions;
// export default languageStateSlice.reducer;