import { TextField } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";


function FourthTab ({handleClose}) {
    const { t, i18n } = useTranslation();

    return (
        <div>
        <h2> {t('CabinetPartnerModalWindowFourthCongratulations')}</h2>
        <p id="parent-modal-description">
        {t('CabinetPartnerModalWindowFourthInvite')}
        </p>
        <TextField
        className='secondTabFormControl'
        label={t('CabinetPartnerModalWindowFourthPersonal')}
        name="email"
        variant="outlined"
      />
        <button className="saveButtonProfile" onClick={handleClose} >
          {t('CabinetPartnerModalWindowFourthClose')}
        </button>
      </div>
    )
}

export default FourthTab