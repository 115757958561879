import React, { useEffect, useState } from 'react';
import { TextField, Checkbox, FormControlLabel, Button, FormGroup, Typography } from '@mui/material';
import "../../Style/ModalWindowRegistration/ModalWindowRegistration.scss";
import { useTranslation } from "react-i18next";
import urlFromTemplate from '../../configs/url';
import { ENDPOINTS } from '../../configs/endpoints';
import axios from 'axios';

const SecondTab = ({handleNextTabThird, formStatus}) => {
  const { t, i18n } = useTranslation();
  const [formUser, setFormUser] = useState({})
  const [formDataUserChange, setFormDataUserChange] = useState({})
   // Получение формы 
   const handleGetForm = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_FORM)
    try{
      const response = await axios(apiUrl, {
        withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
      })
      console.log(response.data)
      setFormUser(response.data)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    handleGetForm()
  },[])


  const [formData, setFormData] = useState({
    fullName: formUser.name,
    birthDate: formUser.dateOfBirth,
    residence: formUser.placeOfResidence,
    phoneNumber: formUser.phoneNumber,
    maritalStatus: formUser.familyStatus,
    education: formUser.education,
    currentJob: formUser.workPlace,
    hobbies: formUser.hobby,
    msWord: formUser.word,
    powerPoint: formUser.powerPoint,
    excel: formUser.excel,
    zoom: formUser.zoom,
    email: formUser.email,
    instagram: formUser.instagram,
    telegram: formUser.telegram,
    whatsapp: formUser.whatsapp,
    facebook: formUser.facebook,
    vkontakte: formUser.vk,
  });

  useEffect(() => {
    setFormData({
      fullName: formUser.name,
      birthDate: formUser.dateOfBirth,
      residence: formUser.placeOfResidence,
      phoneNumber: formUser.phoneNumber,
      maritalStatus: formUser.familyStatus,
      education: formUser.education,
      currentJob: formUser.workPlace,
      hobbies: formUser.hobby,
      pcUsage: true,
      msWord: formUser.word,
      powerPoint: formUser.powerPoint,
      excel: formUser.excel,
      zoom: formUser.zoom,
      email: formUser.email,
      instagram: formUser.instagram,
      telegram: formUser.telegram,
      whatsapp: formUser.whatsapp,
      facebook: formUser.facebook,
      vkontakte: formUser.vk,
    })
  },[formUser])

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    handleSubmitForm()
    handleNextTabThird();
    
  };

  // Отправка формы 
  const handleSubmitForm = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_FORM)
    const formDataUser = {
      name: formData.fullName,
      dateOfBirth: formData.birthDate,
      placeOfResidence: formData.residence,
      phoneNumber: formData.phoneNumber,
      familyStatus: formData.maritalStatus,
      education: formData.education,
      workPlace: formData.currentJob,
      hobby: formData.hobbies,
      word: formData.msWord,
      powerPoint: formData.powerPoint,
      excel: formData.excel,
      zoom: formData.zoom,
      email: formData.email,
      instagram: formData.instagram,
      telegram: formData.telegram,
      whatsapp: formData.whatsapp,
      facebook: formData.facebook,
      vk: formData.vkontakte,
    }
    try {
      const response = await axios.post(apiUrl, formDataUser,{
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  // useEffect(() => {
  //   console.log(formStatus)
  // },[])

 

  return (
    <div className='secondTabWrapper'>
      <form className='secondTabForm' onSubmit={handleSubmit}>
        <Typography variant="h4"> {t('CabinetPartnerModalWindowSecondTabTitle')} </Typography>
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabName')}
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabBirthday')}
          name="birthDate"
          value={formData.birthDate}
          onChange={handleChange}
          variant="outlined"
          type="date"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabLocation')}
          name="residence"
          value={formData.residence}
          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabPhone')}
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabFamily')}
          name="maritalStatus"
          value={formData.maritalStatus}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabEducation')}
          name="education"
          value={formData.education}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabWork')}
          name="currentJob"
          value={formData.currentJob}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabHobby')}
          name="hobbies"
          value={formData.hobbies}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <FormGroup>
          <Typography variant="h6"> {t('CabinetPartnerModalWindowSecondTabComputerTitle')} </Typography>
          <FormControlLabel
            control={<Checkbox checked={formData.msWord} onChange={handleChange} name="msWord" />}
            label="Microsoft Word / Pages"
          />
          <FormControlLabel
            control={<Checkbox checked={formData.powerPoint} onChange={handleChange} name="powerPoint" />}
            label="Power Point / Keynote"
          />
          <FormControlLabel
            control={<Checkbox checked={formData.excel} onChange={handleChange} name="excel" />}
            label="Excel / Numbers"
          />
          <FormControlLabel
            control={<Checkbox checked={formData.zoom} onChange={handleChange} name="zoom" />}
            label="Zoom"
          />
        </FormGroup>
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabEmail')}
          name="email"
          value={formData.email}
          onChange={handleChange}
          variant="outlined"
          required
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="Instagram"
          name="instagram"
          value={formData.instagram}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="Telegram"
          name="telegram"
          value={formData.telegram}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="WhatsApp"
          name="whatsapp"
          value={formData.whatsapp}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label="FaceBook"
          name="facebook"
          value={formData.facebook}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          className='secondTabFormControl'
          label={t('CabinetPartnerModalWindowSecondTabVk')}
          name="vkontakte"
          value={formData.vkontakte}
          onChange={handleChange}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button type="submit" variant="contained" color="primary">
          {t('CabinetPartnerModalWindowSecondTabSubmit')}
        </Button>
      </form>
    </div>
  );
};

export default SecondTab;
