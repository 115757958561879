// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    English: {
      translation: {
    // Общие
    'MainPageBillsMoreInfo': 'More details',
    'ProfileButtonSave':'Save',
    'BillsCardAllOperation': 'Account transactions',
    'BillsModalWindowButton':'Close',
    'BillsRefillButtonExit': 'Back',
    'ConsultantDashboard':`Consultant Dashboard`,
    'ClientDashboard':`Client Dashboard`,
    'ValueShouldBeAtLeast':'Value should be at least',
    'status': 'Status',

       // Модальное окно после регистрации
       'ModalWindowRegText': 'To start working with the platform, become a member. To become a member, agree to the terms and conditions and the charter',
       'ModalWindowRegCheckBox': 'I have read and agree to all the terms of participation',
       'ModalWindowRegButton': 'I want to become a member',
       'ModalWindowRegButtonLater': 'Later',


    // Навигация
        "lorem": "Home",
        "profile": "Profile",
        "bills": 'Bills',
        "referrals":'Referrals',
        "events": 'Events',
        "agreement":'Agreements',
        'consultant':'Consultant',
        'project':'Projects',
        'withdraw':'Withdraw',
        "verification":'Verification',
        'invoice': 'Invoices',
        "exit": 'Exit',
    // Header
        'buttonConnectWallet': 'Manage Wallets',
        'buttonTelegramHeader': 'Lorem ipsum',
        'buttonCountOfUser':'Registered users',
        // Модальное окно подключения кошелька
        "buttonAddWalletTitle": "Connect Wallet",
    "buttonAddWalletAttention": "ATTENTION!",
    "buttonAddWalletDescription": "SPECIFY ONLY NON-CUSTODIAL WALLET (BEP-20). You can create a non-custodial wallet on popular services like MetaMask or TrustWallet.",
    "buttonAddWalletDescriptionSecond": "We also recommend reading the instructions for creating and using a wallet on the official websites.",
    "buttonMetaMask": "MetaMask",
    "buttonTrustWallet": "TrustWallet",
    "buttonAddWalletAttentionSecond": "PROHIBITED",
    "buttonAddWalletDescriptionThird": "Do not specify wallets from Binance, Bybit, or other crypto exchanges.",
    "buttonAddWalletInputLabel": "Enter wallet address (BEP-20)",
    "buttonAddWalletAttentionThird": "Once connected, you will not be able to replace or disconnect the wallet.",
    "buttonAddWalletClose": "Cancel",
    "buttonAddWalletConnect": "Connect",
    // Кабинет
        'MyCabinetPersonalActivePortfolio': 'Personal active portfolio'   ,
        'MyCabinetTotalIncomeReceived': 'Total income received',
        'MyCabinetConsultant': 'Your consultant',
         // Модальное окно удаление
         'MyCabinetModalWindowDeleteTitle': 'Delete photo' ,
         'MyCabinetModalWindowDeleteYes': 'Yes' ,
         'MyCabinetModalWindowDeleteNo': 'No' ,
         // Модальное окно Добавления
         'MyCabinetModalWindowAddPhotoTitle':'Add a photo',
         'MyCabinetModalWindowAddPhotoSelectFile':'Choose File',
         'MyCabinetModalWindowAddPhotoFileNotSelected':'File not selected',
         'MyCabinetModalWindowAddPhotoExit':'Exit',
         'MyCabinetModalWindowAddPhotoSave':'Save',
    // Главная страница кабинет клиента
        "MainPageBillsMainBalanceUSD": 'Primary account',
        'MainPageBillsMainBalanceUSDAwaitingWithdrawal': 'Pending Withdrawal:',
        'MainPageBillsMainBalanceUSDFrozen': 'Frozen:',
        'MainPageBillsMainBalanceUSDInOrders': 'In orders:',
        'MainPageBillsCumulativePrincipalBalanceUSD': 'Cumulative principal balance',
        'MainPageIncomeTableTitle': 'Latest project incomes',
        'MainPageIncomeTableFirstColumn':'Income time',
        'MainPageIncomeTableSecondColumn':'Income amount',
        'MainPageIncomeTableThirdColumn':'Contribution date',
        'MainPageIncomeTableFourthColumn':'Contribution amount',
        'MainPageIncomeTableFourthColumnType':'Income type',
        'MainPageIncomeTableFourthColumnTypeDescInterest':'Monthly',
        'MainPageIncomeTableFourthColumnTypeDescFinal':'Final',
        'MainPageProjectTitle': 'Projects',
        'MainPageProjectProfitPerMonth': 'Profit per month',
        'MainPageProjectDurationOfPlacementInTheProgram' : 'Duration of placement in the program',
        'MainPageProjectDurationOfPlacementInTheProgramStart' : 'Start',
        'MainPageProjectDurationOfPlacementInTheProgramEnd' : 'End',
        'MainPageProjectMinimumTransactionAmount' : 'Minimum transaction amount',
        'MainPageProjectMinRepeatTransactionAmount': 'Min. repeat transaction amount',
        'MainPageProjectPoolFilling': 'Total pool',
        'MainPageTransactionsYourTransactions': 'Your transactions',
        'MainPageTransactionsActive': 'Active',
        'MainPageTransactionsFrozen': 'Frozen',
        'MainPageTransactionsFirstColumn': 'Project',
        'MainPageTransactionsDoc': 'File',
        'TemplateFile': 'Template',
        'MainPageTransactionsDocUpload': 'Confirmation',
        'MainPageTransactionsSecondColumn': 'Sum',
        'MainPageTransactionsThirdColumn': 'Income',
        'MainPageTransactionsFourthColumn': 'Expiration date',
        'MainPageDropDownAll':'Active',
        'MainPageDropDownOpened':'Opened',
        'MainPageDropDownPaid':'Paid',
        'MainPageDropDownCollected':'Collected',
        'MainPageTransactionTableEnd':'Completed',


    //Страница проекта
        'ProjectPageButton': 'Contribute into project',
        'ProjectPageButtonAlert':'You cannot invest in an inactive project',
        'ProjectPageImgTitle': 'Project images',
        'ProjectPageTitlePurchase': 'Purchase share in program',
        'ProjectPageMinimalCost': 'Minimum purchase amount',
        'ProjectPageEnterSum': 'Enter amount',
        'ProjectPageRadioMainBalance': 'Primary account',
        'ProjectPageRadioBonusBalance': 'Bonus account',
        'ProjectPageRadioPrimaryAccumulativeBalance': 'Cumulative primary account',
        'ProjectPageEnterPassword': 'Enter password',
        'ProjectPageButtonSecond': 'Sign the contract',
        'ProjectPageModalWindowButton':'To the replenishment page',

    // Footer
        'FooterLegalInformation':'Legal information',
        'FooterPrivacyPolicy':'Privacy Policy',
        'FooterTermsOfUse':'Terms of use',
        'FooterSupport':'Support',
        'FooterSocial':'Social network',
        'FooterSocialTelegramNews':'News channel for Clients',
        'FooterSocialTelegramNav':'Navigation channel',
        'FooterSocialYouTube':'Youtube channel',
        'FooterSocialInstagram':'Instagram channel',
    // Профиль
        'ProfileTitle':'Profile',
        'ProfileNavBasicData': 'Basic data',
        'ProfileNavContacts': 'Contacts',
        'ProfileNavPassword': 'Passwords',
        'ProfileNavLocation': 'Location',
        'ProfileNavNotification': 'Notifications and Security',
        'ProfileNavVerification': 'Verification',
        'ProfileNavTelegram': 'Telegram-bot',
                  //Профиль основные данные
                      'ProfileBasicDataTitle':'Basic data',
                      'ProfileBasicDataLogin':'Enter login',
                      'ProfileBasicDataSurname':'Enter last name',
                      'ProfileBasicDataName':'Enter your name',
                      'ProfileBasicDataLastname':'Enter middle name',
                      'ProfileBasicDataDateOfBirth':'Date of Birth',
                      'ProfileBasicDataSex':'Gender  ',
                      'ProfileBasicDataSexMale':'Male',
                      'ProfileBasicDataSaxFemale':'Female',
                      'ProfileBasicDataSexOther':'Other',
                      'ProfileBasicDataVerification':'Type of verification',
                              //Типы верификации
                              'ProfileBasicDataVerificationRepeatedVerificationByAdmin': 'Repeated verification by admin',
                              'ProfileBasicDataVerificationByPhone': 'Verification by phone',
                              'ProfileBasicDataVerificationByDocument': 'Documentary verification',
                              'ProfileBasicDataVerificationAutomatic': 'Automatic verification',
                              'ProfileBasicDataVerificationAtPlace': 'Verification at place of residence',
                              'ProfileBasicDataVerificationByEmail': 'Verification by email',
                      'ProfileBasicDataTypeOfDocument':'Document type',
                       // Типы Документов
                              'ProfileBasicDataTypeOfDocumentPassport':'Passport',
                              'ProfileBasicDataTypeOfDocumentResident':'Resident card',
                              'ProfileBasicDataTypeOfDocumentDrivers':`Driver's license`,
                              'ProfileBasicDataTypeOfDocumentIdentification':'Identification',
                      'ProfileBasicDataNumberOfDocument':'Document Number',
                      'ProfileBasicDataDateDocument':'Document issue date',
                      'ProfileBasicDataIssuingAuthority':'Issuing authority',
                      'ProfileBasicDataAtantion':'I confirm that the entered data matches the data in my document.',
                  // Профиль контакты
                      'ProfileContactsTitle': 'Contacts',
                      'ProfileContactsPhone': 'Phone',
                      'ProfileContactsVk': 'VK',
                      'ProfileContactsOk': 'OK',
                  //Профиль пароли
                      'ProfilePasswordTitle': 'Passwords',
                      'ProfilePassword': 'Password',
                      'ProfilePasswordFin': 'Financial password',
                  //Профиль местоположение
                      'ProfileLocationTitle': 'Location',
                      'ProfileLocationCountry': 'Сountry',
                      'ProfileLocationRegion': 'Region/State',
                      'ProfileLocationCity': 'City',
                      'ProfileLocationAddress': 'Address',
                      'ProfileLocationIndex': 'Index',
                  //Профиль Уведомления
                      'ProfileNotificationTitle': 'Notifications and Security',
                      'ProfileNotificationVerification': 'Two-Step Verification',
                      'ProfileNotificationAttantion': `To increase the level of protection for your account, enable two-factor authentication. Select one of the two-step verification methods provided`,
                      'ProfileNotificationButton': 'To plug',
                      'ProfileNotificationAttantionSelect': 'Select events for which to enable two-factor authentication',
                      'ProfileNotificationAuth': 'Authorization',
                      'ProfileNotificationConclusion': 'Withdrawal/transfer of funds',
                  // Профиль верификация
                      'ProfileVerificationEmailTitle': 'Email verification',
                      'ProfileVerificationEmailText': 'Enter Email',
                      'ProfileVerificationPersonality': 'Identity verification',
                      'ProfileVerificationPersonalityAttantion': 'The following documents can be accepted as supporting documents for identity verification:',
                      'ProfileVerificationPersonalityAttantionFirst':'- scanning a copy of the pages of your passport;',
                      'ProfileVerificationPersonalityAttantionSecond':'- a copy of the front and back of your ID card;',
                      'ProfileVerificationPersonalityAttantionThird':'- a copy of the page of the foreign passport;',
                      'ProfileVerificationPersonalityAttantionFourth':`- a copy of the front and back of your driver's license.`,
                      'ProfileVerificationPlace': 'Verification of place of residence',
                      'ProfileVerificationPlaceAttantion': ` To go through the stage of verifying your place of residence through the Sum Sub service, make sure that you have gone through the stages of verifying your personal data in the previous block. `,
                      'ProfileVerificationButton': 'Click to upload photo',
                      'ProfileVerificationButtonSend': 'Send',
                      'ProfileVerificationButtonDelete': 'Delete photo',
                      'ProfileVerificationFastVerify': 'Quick verification by administrator',
                      'ProfileVerificationSlowVerify': 'Paid verification',
                      'VerifyButton': 'Verify',
                      'VerificationRequired': 'Verification required for this operation',
                  // Профиль  Telegram-bot
                      'ProfileTelegramAttantion':'To connect a bot:',
                      'ProfileTelegramAttantionFirst':'Click the "Link account" button',
                      'ProfileTelegramAttantionSecond':'Allow your browser to open the Telegram app',
                      'ProfileTelegramAttantionThird':`In the Telegram chat that opens, click "Start". After successful connection you 
                      You will receive a welcome message, and your linked Telegram account will be displayed in your personal account.`,
                      'ProfileTelegramButton':'Link account',
            //Счета
            'BillsTitle': 'Your accounts',
                //Счета карточки
                    'BillsCardMainBalance': 'Primary account',
                    'BillsCardBonusBalance': 'Bonus account',
                    'BillsCardAuxiliaryBalance': 'Auxiliary account',
                    'BillsCardCumulativeBalance': 'Commission account',
                    'BillsCardCumulativeBonusBalance': 'Cumulative bonus account',
                    'BillsCardStatistic': 'Accounts statistics',
                    'BillsCardStatisticAllSum': 'Total on accounts:',
                    'BillsCardStatisticTotalReceipts': 'Total receipts:',
                    'BillsCardStatisticTotalWriteOffAmount': 'Total write-offs:',
                    'BillsCardStatisticTotalFrozen': 'Total frozen:',


              //Счета Модальные окна
                  //Бонусный баланс
                  'BillsModalWindowBonusBalanceTitle': 'Bonus account',
                  'BillsModalWindowBonusBalanceText': 'Funds are credited to the Bonus account as bonuses from the referral system for new payments of investment programs',
                  //Накопительный баланс
                  'BillsModalWindowCumulativeBalanceTitle': 'Cumulative primary account',
                  'BillsModalWindowCumulativeBalanceTextFirst': 'In the "Cumulative primary account" of the client, you can accumulate funds credited as "Profit" and use them to purchase a share in the "Diamond" investment program',
                  'BillsModalWindowCumulativeBalanceTextSecond': 'Withdrawal of funds from the "Cumulative primary account" will become available as our company successfully sells apartments. In the near future, we will update your Personal Account by adding a new widget to track the progress of apartment sales',
                  //Накопительный бонусный баланс
                  'BillsModalWindowCumulativeBonusBalanceTitle': 'Cumulative bonus account',
                  'BillsModalWindowCumulativeBonusBalanceTextFirst': 'In the partner\'s "Cumulative bonus account", you can accumulate funds credited as bonuses from the referral system (excluding bonuses for new payments) and use them to purchase a share in the "Diamond" investment program',
                  'BillsModalWindowCumulativeBonusBalanceTextSecond': 'Withdrawal of funds from the "Cumulative bonus account" will become available as our company successfully sells apartments. In the near future, we will update your Personal Account by adding a new widget to track the progress of apartment sales',
                      //Модальное окно вывода средств с комиссионного аккаунта
                      'ComissionModalWindowTitle':'Transfer to subaccount',
                      'CommissionModalWindowWithdrawalButton':'Transfer',
                      'ComissionModalWindowTitleSecond':'Transfer to subaccount',
                      'CommissionModalWindowWithdrawalButtonSecond':'Withdraw',
                      'CommissionModalWindowWithdrawalCurrencyUsd':'$',
                      'CommissionModalWindowWithdrawalCurrencyEuro':'€',
                      'CommissionModalWindowWithdrawalCurrencyUsdt':'₮',
                // Счета Таблица
                    'BillsTable': 'Recent transactions',
                    'BillsTableButton': 'Transaction history',
                 // Счета исторя операций
                    'BillsHistoryTitle': 'List of financial transactions',
                    'BillsHistoryButtonFirst': 'Transferred',
                    'BillsHistoryButtonSecond': 'Pending transfer',
                    'BillsHistorySearchTitle': 'Search financial transactions',
                    'BillsHistorySearchLable': 'Search account by any parameter',
                    'BillsHistoryTotalCount': 'Total transactions:',
                    'BillsHistoryTotalCountInUSD':'In',
                    'BillsHistoryRowsOnPage': 'Records per page:',

                  //Счета Поиск по истории операций
                    'BillsHistorySearchAdvancedTitle': 'Advanced document search',
                    'BillsHistorySearchAdvancedId': 'Document ID',
                    'BillsHistorySearchAdvancedDate': 'Creation date',
                    'BillsHistorySearchAdvancedType': 'Document type',
                    'BillsHistorySearchAdvancedCurrency': 'Currency',
                    'BillsHistorySearchAdvancedFrom': 'From',
                    'BillsHistorySearchAdvancedBefore': 'To',
                    'BillsHistorySearchAdvancedTypeBill': 'Account type',
                    'BillsHistorySearchAdvancedSend': 'Sender',
                    'BillsHistorySearchAdvancedRecipient': 'Recipient',
                    'BillsHistorySearchAdvancedReset': 'Reset',
                    'BillsHistorySearchAdvancedApply': 'Apply',
                    'BillsHistorySearchAdvancedAnd': 'And',
                    'BillsHistorySearchAdvancedOr': 'Or',
                    'BillsHistorySearchAdvanced': '',

                 //Счета отдельная страница пополнение счета
                 'BillsRefillTitle': 'Refill',
                 'BillsRefillAttantionImportantInfoTitle': 'Important information!',
                 'BillsRefillAttantionImportantInfoText': `Dear Partner! Check the network carefully before sending
                 funds. When sending funds from another network, return the funds
                 IMPOSSIBLE! `,
                 'BillsRefillChooseNetwork': 'Select network',
                 'BillsRefillAttantionTextFirst': ` Send the amount you need to the wallet address below
                 cryptocurrencies including 1% commission. Those. if you want to receive
                 1000 USDT, then you need to send 1010 USDT. Send to
                 this address is USDT only on your chosen network. For check
                 receipt of cryptocurrency after sending, click the "Update" button
                 balance" on the account being replenished. `,
                 'BillsRefillAttantionTextTitle': 'Minimum payment amount - 10 USDT',
                 'BillsRefillInputLable': 'Account number',
                 'BillsRefillQrLable': 'Or scan the QR code',
                 'BillsRefillButtonNext': `I made a payment`,
                //Счета Страница пополнения счета
                'BillsRefillSuccessCongratulations': 'Congratulations!',
                'BillsRefilDesc':'Your request to top up your account has been accepted',
                'BillsRefilDescSecond':'Your payment has been accepted for processing. Please wait for funds to be credited in your personal account.',
                'BillsRefilButton':'Return to accounts',
                //Счета Страница Вывод средств
                'BillsWithdrawalTitle': 'Withdrawal of funds ',
                'BillsWithdrawalSum': 'Withdrawal amount',
                'BillsWithdrawalNetwork': 'Network',
                'BillsWithdrawalNumber': 'Account number',
                'BillsWithdrawalTotalCount':'Total amount',
                'BillsWithdrawalCommission': 'including commission',
                'BillsWithdrawalAttantionPassword': 'To confirm, please enter your financial password.',
                'BillsWithdrawalPassword': 'Financial password',
                'BillsWithdrawalButton': 'Withdrawal of funds',
                //Счета Страница Перевод Средств
                'BillsMoneyTransactionTitle':'Money transaction',
                //Первый этап
                'BillsMoneyTransactionFirstStep':'Recipient selection',
                'BillsMoneyTransactionChooseRecipient':' Recipient selection',
                'BillsMoneyTransactionMyBills':'Transfer between my accounts',
                //Второй этап
                'BillsMoneyTransactionSecondStep':'Transfer to account',
                'BillsMoneyTransactionSecondStepChooseBill': 'Select the account to which the transfer will be made',
                'BillsMoneyTransactionSecondStepBonusBalance': 'bonus account',
                'BillsMoneyTransactionSecondStepBalance': 'Current balance',
                //Третий этап
                'BillsMoneyTransactionThirdStep':'Top up amount',
                'BillsMoneyTransactionThirdStepFinPasswordDesc':'To confirm, please enter your financial password',
                'BillsMoneyTransactionThirdStepFinPassword':'Financial password',

                'BillsMoneyTransactionButtonNext':'Further',
                'BillsMoneyTransactionButtonBack':'Back',
                'BillsMoneyTransactionSuccess':'Congratulations! The transfer was successful',
                'BillsMoneyTransactionButtonChange':'Transfer funds',
                'BillsMoneyTransactionButtonToBills':'To accounts',
                 // Счета таблица 2
                 'BillsTableSecondID':'ID',
                 'BillsTableSecondSender':'Transaction type',
                 'BillsTableSecondRecipient':'Recipient',
                 'BillsTableSecondPostingТame':'Account type',
                 'BillsTableSecondTransactionAmount':'Transaction amount',
                 'BillsTableSecondPostingDate':'Posting date',
            // Договоры
                  // Консультант
                  'AgreementsConsultantTitle': 'Consultant agreements',
                  // Проекты
                  'AgreementsProjectTitle': 'Project contribution agreements',
                  // Выводы
                  'AgreementsWithdrawTitle': 'Certificates of funds receipt',
                  // Кошельки
                  'SenderWallet': 'Sender Wallet',
                  'ReceiverWallet': 'Receiver Wallet',
                  'Wallets': 'Wallets',
                  'WalletHistory': 'Wallet history',
                  'CreateWallet': 'Create wallet',
                  'WalletNumber': 'Wallet Number',
                  'ChangeWallet': 'Change',
                  'DeleteWallet': 'Delete',
                  'UpdateWalletTitle': 'Update wallet',
                  'DeleteWalletTitle': 'Delete wallet',
                  'updateReason':'Select update reason:',
                  'deleteReason':'Select delete reason:',
                  'requestUpdate':'Request update',
                  'requestDelete':'Request delete',
                  'SelectCurrency': 'Select currency',
                  'NoWalletsToAdd': 'You have already created wallets for each available currency',
                  'OldWalletNumber': 'Old wallet number',
                  'NewWalletNumber': 'New wallet number',
                  'Currency': 'Currency',
                  'Reason': 'Reason',
                  'Type': 'Type',
                  'NoWalletsInfo': 'You need to add a wallet before creating invoice',
                  'NavigateToWalletPage': 'To Wallets',
                  // Инвойсы
                  'InvoiceTitle': 'Invoices',
                  'CreateInvoice': 'Create invoice',
                  'InvoiceModalTitle':'Create new invoice',
                  'ConsultantFee': 'Consultant fee',
                  'ProjectContributions': 'Project contributions',
                  'ProjectContributionsDisabled': 'Project contributions (at least 1 confirmed agreement in this currency is required)',
                  'InvoiceInclude': 'Include in invoice',
                  'TotalCost': 'Total Cost',
                  'TransactionCommission': 'Transaction commission',
                  'ActivationCommission': 'Activation commission',
                  'InvoiceCreatedSuccessfully': 'Invoice was successfully created',
                  'ErrorOccurred': 'Error occurred',
                  'InvoiceActionTitle': 'Select an action',
                  'InvoiceSubmit': 'By clicking SUBMIT button confirmation files will be sent to administrator for verification',
                  'InvoiceDelete': 'By clicking DELETE button invoice will be deleted (agreements can be used again)',
                  'ResendConfirmation': 'Resend Confirmation',
          //Кабинет партнера
                      // Кабинет Партнера Навигация
                      'CabinetPartnerNavStructureMain': 'Structure',
                      'CabinetPartnerNavStructureTable': 'Table Structure',
                      'CabinetPartnerNavStructureGraph': 'Graphic Structure',
            // Кабинет Патрнера Кабинет
                'CabinetPartnerCabinetBillGroup': 'Cumulative Group Volume (CGV)',
                'CabinetPartnerCabinetBillPrivate': 'Personal Volume (PV)',
                'CabinetPartnerCabinetBillGroupeActive': 'Group active portfolio',
                'CabinetPartnerCabinetBillPrivateApartament': 'Personal volume from apartment sales (PSV)',
                   //Кабинет партнера баланс
                        'CabinetPartnerBalanceWaiting': 'Pending withdrawal',
                    //Кабинет партнера бонусы
                        'CabinetPartner': 'Received bonuses',
                    //Кабинет партнера Сделки
                        'CabinetPartnerStatic': 'Transaction statistics in structure',
                        'CabinetPartnerStaticNone': 'No transactions for the specified period',
                    // Кабинет партнера мотивация
                        'CabinetPartnerMotivationTitle': 'Personal motivation',
                        'CabinetPartnerMotivationAttantion': 'To reach the next level, all conditions must be met',
                        'CabinetPartnerMotivationTarget': 'Goal',
                        'CabinetPartnerMotivationMore': 'More',
                   // Кабинет партнера лидеры
                        'CabinetPartnerMotivationLiderTitle': 'Top leaders by number of personally invited',
                        'CabinetPartnerMotivationLiderDesc': 'For all time',
                        'CabinetPartnerMotivationLiderAttention': 'Your partners have no personally invited yet',
                    //Кабинет партнера Структура личных приглашений
                        'CabinetPartnerStructureTitle': 'Personal table structure',
                   //Кабинет партнера кол-во партнеров
                        'CabinetPartnerStructureBarNew': 'New partners',
                        'CabinetPartnerStructureBarPersonal': 'Personally invited',
                        'CabinetPartnerStructureBarAll': 'Total partners',
                        'CabinetPartnerStructureBarActive': 'Active in 1st line',
                        'CabinetPartnerStructureBarActiveSecond': 'Active in structure',
                    // Кабинет партнера Структура Таблица
                    'CabinetPartnersStructureTableLogin': 'Login',
                    'CabinetPartnersStructureTableFloor': 'Floor',
                    'CabinetPartnersStructureTablePartners': 'Partners',
                    'CabinetPartnersStructureTableNGO': 'NGO',
              //Кабинет партнера ЛИНЕЙНАЯ Структура
                'CabinetPartnerLinearStructureTitle':'Linear structure',
                 //Кабинет партнера модальное окно
                    //First Tab
                    'CabinetPartnerModalWindowFirstTabTitle':'Dear Consultant!',
                    'CabinetPartnerModalWindowFirstTabDescFirst':`We welcome you and wish you personal happiness and professional success! We wish you success both in our company and in the business you are engaged in.`,
                    'CabinetPartnerModalWindowFirstTabDescSecond':`We offer you to carefully fill out a personal questionnaire. On one hand, we will see your knowledge, and on the other hand, the questionnaire will help us create a personalized training program for you!`,
                    'CabinetPartnerModalWindowFirstTabButton':`Fill out the questionnaire`,
                    //Second Tab
                    'CabinetPartnerModalWindowSecondTabTitle':'Questionnaire',
                    'CabinetPartnerModalWindowSecondTabName':'Full Name (Latin alphabet)',
                    'CabinetPartnerModalWindowSecondTabBirthday':'Date of Birth',
                    'CabinetPartnerModalWindowSecondTabLocation':'Place of Residence',
                    'CabinetPartnerModalWindowSecondTabPhone':'Phone Number',
                    'CabinetPartnerModalWindowSecondTabFamily':'Marital Status',
                    'CabinetPartnerModalWindowSecondTabEducation':'Education',
                    'CabinetPartnerModalWindowSecondTabWork':'Current Job',
                    'CabinetPartnerModalWindowSecondTabHobby':'Hobbies',
                    'CabinetPartnerModalWindowSecondTabComputerTitle':'Computer Usage:',
                    'CabinetPartnerModalWindowSecondTabEmail':'Personal Email',
                    'CabinetPartnerModalWindowSecondTabVk':'VK',
                    'CabinetPartnerModalWindowSecondTabSubmit':'Submit',
                    //Third Tab
                    'CabinetPartnerModalWindowThirdTabRegistrationFee':'Consultant Registration Fee Paid',
                    'CabinetPartnerModalWindowThirdTabRegistrationFeeButton':'Pay',
                    'CabinetPartnerModalWindowThirdTabSign':'Consultant Agreement Signed',
                    'CabinetPartnerModalWindowThirdTabSignButton':'Sign',
                    'CabinetPartnerModalWindowThirdTabSalesConversations':'Knowledge of "Sales Conversations"',
                    'CabinetPartnerModalWindowThirdTabFunctionality':'Knowledge of Personal Account Functionality and Administration',
                    'CabinetPartnerModalWindowThirdTabAgreementClient':'Knowledge of Client Agreement',
                    'CabinetPartnerModalWindowThirdTabAgreementConsultant':'Knowledge of Consultant Agreement',
                    'CabinetPartnerModalWindowThirdTabDirector':'Training Center Director',
                    'CabinetPartnerModalWindowThirdTabManager':'Training Center Manager',
                    'CabinetPartnerModalWindowThirdTabNext':'Next',
                    //Fourth Tab
                    'CabinetPartnerModalWindowFourthCongratulations':'Congratulations on receiving the Consultant number',
                    'CabinetPartnerModalWindowFourthInvite':'We invite you to the Basic Seminar',
                    'CabinetPartnerModalWindowFourthPersonal':'Personal Email',
                    'CabinetPartnerModalWindowFourthClose':'Finish',
            //Настройки сайта
                    'SiteSettingsModalTitle': 'Site Settings',
                    'SiteSettingsModalLableCountry': 'Timezone',
                    'SiteSettingsModalLabelLanguage': 'Language',
                    'SiteSettingsModalLabelCurrancy': 'Currency',
                    'SiteSettingsModalButtonClose': 'Cancel',
                    'SiteSettingsModalButtonSave': 'Save',
            // Модальное окно почты
            'ModalWindowEmailTitle':'Send by mail',
            'ModalWindowEmail':'Enter your email',
            'ModalWindowEmailButton':'Send',
      //Agreement
      'AgreementId': 'Agreement ID',
      'AgreementTime':'Last update time',
      'AgreementUserName':'Login',
      'AgreementType':'Contract type',
      'AgreementProjectName': 'Project name',
      'AgreementAmount': 'Amount',
      'AgreementCurrency': 'Currency',
      'CreateAgreement': 'Create agreement',
      'SendAnotherConfirmation': 'Send another confirmation',
      'DeletingSignConfirm': 'Are you sure you want to delete the signed document?',
      'DeleteAgreementConfirm': 'Are you sure you want to delete agreement?',
      'DeleteButton': 'Delete',
      'SubmitButton': 'Submit'
      }
    },
    Русский: {
      translation: {
    // Общие
        'MainPageBillsMoreInfo': 'Подробнее',
        'ProfileButtonSave':'Сохранить',
        'BillsCardAllOperation': 'Все операции',
        'BillsModalWindowButton':'Закрыть',
        'BillsRefillButtonExit': 'Назад',
        'ConsultantDashboard':'Кабинет Консультанта',
        'ClientDashboard':'Кабинет Клиента',
        'ValueShouldBeAtLeast':'Значение должно быть не менее',
        'status': 'Статус',
    // Модальное окно после регистрации
        'ModalWindowRegText':'Для начала работы с платформой станьте участником. Для того, чтобы стать участником согласитесь с правилами и условиями и уставом',
        'ModalWindowRegCheckBox': 'Я ознакомился и согласен со всеми условиями участия',
        'ModalWindowRegButton':'Хочу стать участником',
        'ModalWindowRegButtonLater': 'Позже',

    // Навигация
        "lorem": "Домой",
        "profile": "Профиль",
        "bills": 'Счета',
        "referrals":'Рефералы',
        "events":'События',
        "agreement":'Договоры',
        'consultant':'Консультант',
        'project':'Проекты',
        'withdraw':'Выводы',
        "verification":'Верификация',
        'invoice': 'Инвойсы',
        "exit": 'Выход',
    // Header
        'buttonConnectWallet': 'Управление Кошельками',
        'buttonTelegramHeader': 'Lorem ipsum',
        'buttonCountOfUser':'Зарегистрированных пользователей',
        // Модальное окно подключения кошелька
            'buttonAddWalletTitle':'Подключение кошелька',
            'buttonAddWalletAttention':'ВНИМАНИЕ!',
            'buttonAddWalletDescription':`УКАЗЫВАЙТЕ ТОЛЬКО НЕКАСТОДИАЛЬНЫЙ КОШЕЛЕК (BEP-20) `,
            'buttonAddWalletDescriptionFourth':'Вы можете создать некастодиальный кошелек на популярных сервисах таких как MetaMask или TrustWallet.',
            'buttonAddWalletDescriptionSecond':'Также рекомендуем ознакомиться с инструкцией создания и исмользования  кошелька на официальных веб-сайтах',
            'buttonMetaMask':'',
            'buttonTrustWallet':'',
            'buttonAddWalletAttentionSecond':'ЗАПРЕЩАЕТСЯ',
            'buttonAddWalletDescriptionThird':'Указывать кошельки Binance, Bybit или других крипто бирж.',
            'buttonAddWalletInputLabel':'Укажите адрес кошелька (BEP-20)',
            'buttonAddWalletAttentionThird':'Подключив кошелек, вы не сможете его заменить или отключить',
            'buttonAddWalletClose':'Отмена',
            'buttonAddWalletConnect':'Подключить',

    // Кабинет
        'MyCabinetPersonalActivePortfolio': 'Личный активный портфель',
        'MyCabinetTotalIncomeReceived': 'Общий полученный доход',
        'MyCabinetConsultant': 'Ваш консультант' ,
        // Модальное окно удаление
        'MyCabinetModalWindowDeleteTitle': 'Удалить фотографию' ,
        'MyCabinetModalWindowDeleteYes': 'Да' ,
        'MyCabinetModalWindowDeleteNo': 'Нет' ,
        // Модальное окно Добавления
        'MyCabinetModalWindowAddPhotoTitle':'Добавить фотографию',
        'MyCabinetModalWindowAddPhotoSelectFile':'Выберите файл',
        'MyCabinetModalWindowAddPhotoFileNotSelected':'Файл не выбран',
        'MyCabinetModalWindowAddPhotoExit':'Выход',
        'MyCabinetModalWindowAddPhotoSave':'Сохранить',
    // Главная страница кабинет клиента
        "MainPageBillsMainBalanceUSD": 'Основной счёт',
        'MainPageBillsMainBalanceUSDAwaitingWithdrawal': 'Ожидают вывода:',
        'MainPageBillsMainBalanceUSDFrozen': 'Заморожены:',
        'MainPageBillsMainBalanceUSDInOrders': 'В ордерах:',
        'MainPageBillsCumulativePrincipalBalanceUSD': 'Накопительный основной счёт',
        'MainPageIncomeTableTitle': 'Последние начисления дохода',
        'MainPageIncomeTableFirstColumn':'Время начисления',
        'MainPageIncomeTableSecondColumn':'Сумма дохода',
        'MainPageIncomeTableThirdColumn':'Дата сделки',
        'MainPageIncomeTableFourthColumn':'Сумма сделки',
         'MainPageIncomeTableFourthColumnType':'Тип начисления',
        'MainPageIncomeTableFourthColumnTypeDescInterest':'Ежемесячный',
        'MainPageIncomeTableFourthColumnTypeDescFinal':'Окончательный',
        'MainPageProjectTitle': 'Проекты',
        'MainPageProjectProfitPerMonth': 'Доходность в месяц',
        'MainPageProjectDurationOfPlacementInTheProgramStart' : 'Начало',
        'MainPageProjectDurationOfPlacementInTheProgramEnd' : 'Конец',
        'MainPageProjectMinimumTransactionAmount' : 'Минимальная сумма сделки',
        'MainPageProjectMinRepeatTransactionAmount': 'Мин. сумма повторной сделки',
        'MainPageProjectPoolFilling': 'Наполнение пула',
        'MainPageTransactionsYourTransactions': 'Ваши сделки',
        'MainPageTransactionsActive': 'Активные',
        'MainPageTransactionsFrozen': 'Замороженные',
        'MainPageTransactionsFirstColumn': 'Проект',
        'MainPageTransactionsDoc': 'Файл',
        'TemplateFile': 'Шаблон',
        'MainPageTransactionsDocUpload': 'Подтверждение',
        'MainPageTransactionsSecondColumn': 'Сумма',
        'MainPageTransactionsThirdColumn': 'Доход',
        'MainPageTransactionsFourthColumn': 'Дата окончания',
        'MainPageDropDownAll':'Активные',
        'MainPageDropDownOpened':'Открытые',
        'MainPageDropDownPaid':'Выплаченные',
        'MainPageDropDownCollected':'Собраные',
        'MainPageTransactionTableEnd':'Завершен',
    //Страница проекта
        'ProjectPageButton': 'Вложить долю в проект',
        'ProjectPageButtonAlert':'Вы не можете вкладываться в неактивный проект',
        'ProjectPageImgTitle': 'Изображения проекта',
        'ProjectPageTitlePurchase': 'Покупка доли в программе',
        'ProjectPageMinimalCost':'Минимальная сумма покупки',
        'ProjectPageEnterSum': 'Введите сумму',
        'ProjectPageRadioMainBalance': 'Основной счёт',
        'ProjectPageRadioBonusBalance': "Бонусный счёт",
        'ProjectPageRadioPrimaryAccumulativeBalance': 'Комиссионный счёт',
        'ProjectPageEnterPassword': 'Введите пароль',
        'ProjectPageButtonSecond': 'Подписать договор',
        'ProjectPageModalWindowButton':'На страницу пополнения',
    // Footer
        'FooterLegalInformation':'Юридическая информация',
        'FooterPrivacyPolicy':'Политика конфиденциальности',
        'FooterTermsOfUse':'Пользовательское соглашение',
        'FooterSupport':'Тех.поддержка',
        'FooterSocial':'Соц.сети',
        'FooterSocialTelegramNews':' Новостной канал для Клиентов',
        'FooterSocialTelegramNav':'Навигационный канал',
        'FooterSocialYouTube':'Канал Youtube',
        'FooterSocialInstagram':'Канал Insagram',
    // Профиль
        'ProfileTitle':'Профиль',
        'ProfileNavBasicData': 'Основные данные',
        'ProfileNavContacts': 'Контакты',
        'ProfileNavPassword': 'Пароли',
        'ProfileNavLocation': 'Местоположение',
        'ProfileNavNotification': 'Уведомления и безопасность',
        'ProfileNavVerification': 'Верификация',
        'ProfileNavTelegram': 'Telegram-bot',
              //Профиль основные данные
                  'ProfileBasicDataTitle':'Основные данные',
                  'ProfileBasicDataLogin':'Введите логин',
                  'ProfileBasicDataSurname':'Введите фамилию',
                  'ProfileBasicDataName':'Введите имя',
                  'ProfileBasicDataLastname':'Введите отчество',
                  'ProfileBasicDataDateOfBirth':'Дата рождения',
                  'ProfileBasicDataSex':'Пол:',
                  'ProfileBasicDataSexMale':'Мужской',
                  'ProfileBasicDataSaxFemale':'Женский',
                  'ProfileBasicDataSexOther':'Другой',
                  'ProfileBasicDataVerification':'Вид верификации',
                      //Типы верификации
                        'ProfileBasicDataVerificationRepeatedVerificationByAdmin': 'Повторная верификация админом',
                        'ProfileBasicDataVerificationByPhone': 'Верификация по телефону',
                        'ProfileBasicDataVerificationByDocument': 'Документальная верификация',
                        'ProfileBasicDataVerificationAutomatic': 'Автоматическая верификация',
                        'ProfileBasicDataVerificationAtPlace': 'Верификация по месту жительства',
                        'ProfileBasicDataVerificationByEmail': 'Верификация по электронной почте',
                  'ProfileBasicDataTypeOfDocument':'Тип документа',
                      // Типы Документов
                        'ProfileBasicDataTypeOfDocumentPassport':'Паспорт',
                        'ProfileBasicDataTypeOfDocumentResident':'Вид на жительство',
                        'ProfileBasicDataTypeOfDocumentDrivers':'Водительское удостоверение',
                        'ProfileBasicDataTypeOfDocumentIdentification':'Удостоверение личности',
                  'ProfileBasicDataNumberOfDocument':'Номер документа',
                  'ProfileBasicDataDateDocument':'Дата выдачи документа',
                  'ProfileBasicDataIssuingAuthority':'Орган выдавший документ',
                  'ProfileBasicDataAtantion':'Я подтверждаю, что введённые данные соответствуют данным моего документа.',
              // Профиль контакты
                   'ProfileContactsTitle': 'Контакты',
                   'ProfileContactsPhone': 'Телефон',
                   'ProfileContactsVk': 'Вконтакте',
                   'ProfileContactsOk': 'Одноклассники',
              //Профиль пароли
                   'ProfilePasswordTitle': 'Пароли',
                   'ProfilePassword': 'Пароль',
                   'ProfilePasswordFin': 'Финансовый пароль',
              //Профиль местоположение
                   'ProfileLocationTitle': 'Местоположение',
                   'ProfileLocationCountry': 'Страна',
                   'ProfileLocationRegion': 'Область/Штат',
                   'ProfileLocationCity': 'Город',
                   'ProfileLocationAddress': 'Адрес',
                   'ProfileLocationIndex': 'Индекс',
              //Профиль Уведомления
                   'ProfileNotificationTitle': 'Уведомления и безопасность',
                   'ProfileNotificationVerification': 'Двухэтапная аутентификация',
                   'ProfileNotificationAttantion': `Чтобы увеличить уровень защиты вашего аккаунта, подключите двухфакторную аутентификацию. Выберите один из предоставленных способов двухэтапной аутентификации`,
                   'ProfileNotificationButton': 'Подключить',
                   'ProfileNotificationAttantionSelect': 'Выберите события, для которых включить двухфактоорную аутентификацию',
                   'ProfileNotificationAuth': 'Авторизация',
                   'ProfileNotificationConclusion': 'Вывод/перевод средств',
              // Профиль верификация
                   'ProfileVerificationEmailTitle': 'Верификация e-mail',
                   'ProfileVerificationEmailText': 'Введите Email',
                   'ProfileVerificationPersonality': 'Верификация личности',
                   'ProfileVerificationPersonalityAttantion': 'В качестве подтверждающих документов для верификации личности могут быть приняты следующие документы ',
                   'ProfileVerificationPersonalityAttantionFirst':'- сканирование копиистраниц Вашего паспорта;',
                   'ProfileVerificationPersonalityAttantionSecond':'- копия лицевой и обратной стороны Вашей ID-карты;',
                   'ProfileVerificationPersonalityAttantionThird':'- копия страницы заграничного паспорта;',
                   'ProfileVerificationPersonalityAttantionFourth':'- копия лицевой и обратной стороны Вашего водительского удостоверения.',
                   'ProfileVerificationPlace': ' Верификация места проживания',
                   'ProfileVerificationPlaceAttantion': ` Для прохождения этапа верификации места проживания через сервис Sum Sub убедитесь, что Вы прошли этапы верификации личных данных в предыдущем блоке. `,
                   'ProfileVerificationButton': ' Нажмите чтобы загрузить фотографию',
                   'ProfileVerificationButtonSend': 'Отправить',
                   'ProfileVerificationButtonDelete': 'Удалить фотографию',
                   'ProfileVerificationFastVerify': 'Быстрая верификация администратором',
                   'ProfileVerificationSlowVerify': ' Платная верификация',
                   'VerifyButton': 'Пройти верификацию',
                   'VerificationRequired': 'Для этого действия трубется пройти верификацию',
              // Профиль  Telegram-bot
                   'ProfileTelegramAttantion':'Чтобы подключить бот:',
                   'ProfileTelegramAttantionFirst':'Нажмите кнопку "Привязать аккаунт"',
                   'ProfileTelegramAttantionSecond':'Разрешите браузеру открыть приложение Telegram',
                   'ProfileTelegramAttantionThird':`В открывшемся Telegram-чате нажмите "Start". После успешного подключения Вы 
                   получите приветственное сообщение, а в личном кабинете отобразится привязанный аккаунт в Telegram.`,
                   'ProfileTelegramButton':' Привязать аккаунт',
    //Счета
        'BillsTitle':'Ваши счета',
            //Счета карточки
                'BillsCardMainBalance': 'Основной счёт',
                'BillsCardBonusBalance': 'Бонусный счёт',
                'BillsCardAuxiliaryBalance': 'Вспомогательный счёт',
                'BillsCardCumulativeBalance': 'Комиссионный счёт',
                'BillsCardCumulativeBonusBalance': 'Накопительный бонусный счёт',
                'BillsCardStatistic': 'Статистика по счетам',
                'BillsCardStatisticAllSum': 'Всего на счетах:',
                'BillsCardStatisticTotalReceipts': 'Общая сумма поступлений:',
                'BillsCardStatisticTotalWriteOffAmount': 'Общая сумма списаний:',
                'BillsCardStatisticTotalFrozen': 'Всего заморожено:',

              //Счета Модальные окна
                  //Бонусный баланс
              'BillsModalWindowBonusBalanceTitle':'Бонусный счёт',
              'BillsModalWindowBonusBalanceText':'На Бонусный счет поступают средства в виде бонусов по реферальной системе за новые оплаты инвестиционных программ',
              //Накопительный баланс
              'BillsModalWindowCumulativeBalanceTitle':'Накопительный основной счёт',
              'BillsModalWindowCumulativeBalanceTextFirst':`На "Комиссионном счёте" клиента Вы можете накапливать средства, начисляемые в виде "Profit", и использовать их для покупки доли в инвестиционной программе "Diamond"`,
              'BillsModalWindowCumulativeBalanceTextSecond':`Вывод средств с "Комиссионного счета" станет доступным по мере успешной продажи квартир нашей компанией. В ближайшее время мы обновим Ваш Личный Кабинет, добавив новый виджет для отслеживания прогресса продаж квартир`,
              //Накопительный бонусный баланс
              'BillsModalWindowCumulativeBonusBalanceTitle':'Коммисионный счёт',
              'BillsModalWindowCumulativeBonusBalanceTextFirst':'На "Комиссионном счёте" партнера Вы можете накапливать средства, начисляемые в виде бонусов по реферальной системе (исключая бонусы за новые оплаты), и использовать их для покупки доли в инвестиционной программе "Diamond"',
              'BillsModalWindowCumulativeBonusBalanceTextSecond':'Вывод средств с "Накопительного бонусного счёта" станет доступным по мере успешной продажи квартир нашей компанией. В ближайшее время мы обновим Ваш Личный Кабинет, добавив новый виджет для отслеживания прогресса продаж квартир',
               //Модальное окно вывода средств с комиссионного аккаунта
                    'ComissionModalWindowTitle':'Перевод на вспомогательный счет',
                    'CommissionModalWindowWithdrawalButton':'Перевести',
                    'ComissionModalWindowTitleSecond':'Вывод средств',
                    'CommissionModalWindowWithdrawalButtonSecond':'Вывести',
                    'CommissionModalWindowWithdrawalCurrencyUsd':'$',
                    'CommissionModalWindowWithdrawalCurrencyEuro':'€',
                    'CommissionModalWindowWithdrawalCurrencyUsdt':'₮',

            // Счета Таблица
                'BillsTable':'Последние проводки',
                'BillsTableButton':'История операций',
            // Счета исторя операций
                'BillsHistoryTitle':'Список финансовых операций',
                'BillsHistoryButtonFirst':'Проведены',
                'BillsHistoryButtonSecond':'Ожидают проводки',
                'BillsHistorySearchTitle':'Поиск финансовой операции',
                'BillsHistorySearchLable':'Поиск счета по любому параметру',
                'BillsHistoryTotalCount':'Всего проводок:',
                'BillsHistoryTotalCountInUSD':'В',
                'BillsHistoryRowsOnPage':'Записей на странице:',
                //Счета Поиск по истории операций
                'BillsHistorySearchAdvancedTitle':'Расширенный поиск документа',
                'BillsHistorySearchAdvancedId':'ID Документа',
                'BillsHistorySearchAdvancedDate':'Дата создания',
                'BillsHistorySearchAdvancedType':'Тип документа',
                'BillsHistorySearchAdvancedCurrency':'Валюта',
                'BillsHistorySearchAdvancedFrom':'От',
                'BillsHistorySearchAdvancedBefore':'До',
                'BillsHistorySearchAdvancedTypeBill':'Тип счета',
                'BillsHistorySearchAdvancedSend':'Отправитель',
                'BillsHistorySearchAdvancedRecipient':'Получатель',
                'BillsHistorySearchAdvancedReset':'Сбросить',
                'BillsHistorySearchAdvancedApply':'Применить',
                'BillsHistorySearchAdvancedAnd':'И',
                'BillsHistorySearchAdvancedOr':'Или',
                'BillsHistorySearchAdvanced':'',

               //Счета отдельная страница пополнения счета
                  'BillsRefillTitle': 'Пополнение счета',
                  'BillsRefillAttantionImportantInfoTitle': 'Важная информация!',
                  'BillsRefillAttantionImportantInfoText': ` Уважаемый Партнёр! Внимательно проверяйте сеть перед отправкой
                  средств. При отправке средств из другой сети средства вернуть
                  НЕВОЗМОЖНО!`,
                  'BillsRefillChooseNetwork': 'Выберите сеть',
                  'BillsRefillAttantionTextFirst': `  Отправьте на указанный ниже адрес кошелька нужную Вам сумму
                  криптовалюты с учетом 1% комиссии. Т.е. если Вы хотите получить
                  1000 USDT, то необходимо отправить 1010 USDT. Отправляйте на
                  этот адрес только USDT в выбранной Вами сети. Для проверки
                  поступления криптовалюты после отправки нажмите кнопку "Обновить
                  баланс" на пополняемом счете.`,
                  'BillsRefillAttantionTextTitle': 'Минимальная сумма платежа - 10 USDT',
                  'BillsRefillInputLable': 'Номер счета',
                  'BillsRefillQrLable': 'Или отсканируйте QR код',
                  'BillsRefillButtonNext': ` Я провел(а) оплату`,
                  //Счета Страница пополнения счета
                    'BillsRefillSuccessCongratulations': 'Поздравляем!',
                    'BillsRefilDesc':'Ваш запрос  на пополнение счета принят',
                    'BillsRefilDescSecond':'Ваш платеж принят в обработку. Пожалуйста, ожидайте зачисления средств в личном кабинете.',
                    'BillsRefilButton':'  Вернуться к счетам',
                  //Счета Страница Вывод средств
                    'BillsWithdrawalTitle': ' Вывод средств',
                    'BillsWithdrawalSum': 'Сумма вывода',
                    'BillsWithdrawalNetwork': 'Сеть',
                    'BillsWithdrawalNumber': 'Номер счета',
                    'BillsWithdrawalTotalCount':'Общая сумма',
                    'BillsWithdrawalCommission': 'с учетом комиссии',
                    'BillsWithdrawalAttantionPassword': 'Для подтверждения укажите Ваш финансовый пароль',
                    'BillsWithdrawalPassword': 'Финансовый пароль',
                    'BillsWithdrawalButton': 'Вывод средств',
                    //Счета Страница Перевод Средств
                    'BillsMoneyTransactionTitle':'Перевод средств',
                    //Первый этап
                    'BillsMoneyTransactionFirstStep':'Выбор получателя',
                    'BillsMoneyTransactionChooseRecipient':' Выбор получателя',
                    'BillsMoneyTransactionMyBills':'Перевести между моими счетами',
                    //Второй этап
                    'BillsMoneyTransactionSecondStep':'Перевод на счет',
                    'BillsMoneyTransactionSecondStepChooseBill': 'Выберите счет, на который будет перевод',
                    'BillsMoneyTransactionSecondStepBonusBalance': 'Бонусный счёт USD',
                    'BillsMoneyTransactionSecondStepBalance': 'Текущий счёт',
                    //Третий этап
                    'BillsMoneyTransactionThirdStep':'Сумма пополнения',
                    'BillsMoneyTransactionThirdStepFinPasswordDesc':'Для подтверждения укажите Ваш финансовый пароль',
                    'BillsMoneyTransactionThirdStepFinPassword':'Финансовый пароль',

                    'BillsMoneyTransactionButtonNext':'Далее',
                    'BillsMoneyTransactionButtonBack':'Назад',
                    'BillsMoneyTransactionSuccess':'Поздравляем! Перевод прошел успешно',
                    'BillsMoneyTransactionButtonChange':'Перевести средства',
                    'BillsMoneyTransactionButtonToBills':'К счетам',
                // Счета таблица 2 
                    'BillsTableSecondID':'ID',
                    'BillsTableSecondSender':'Тип транзакции',
                    'BillsTableSecondRecipient':'Счет',
                    'BillsTableSecondPostingТame':'Название проводки',
                    'BillsTableSecondTransactionAmount':'Сумма проводки',
                    'BillsTableSecondPostingDate':'Дата проводки',
                  // Договоры
                  // Консультант
                  'AgreementsConsultantTitle': 'Договоры консультанта',
                  // Проекты
                  'AgreementsProjectTitle': 'Договоры о вложении в проект',
                  // Выводы
                  'AgreementsWithdrawTitle': 'Акты о получении денежных средств',
                  // Кошельки
                  'SenderWallet': 'Кошелек отправителя',
                  'ReceiverWallet': 'Кошелек получателя',
                  'Wallets': 'Кошельки',
                  'WalletHistory': 'История изменения',
                  'CreateWallet': 'Создать кошелек',
                  'WalletNumber': 'Номер кошелька',
                  'ChangeWallet': 'Изменить',
                  'DeleteWallet': 'Удалить',
                  'UpdateWalletTitle': 'Изменить номер кошелька',
                  'DeleteWalletTitle': 'Удалить кошелек',
                  'updateReason':'Укажите причину изменения:',
                  'deleteReason':'Укажите причину удаления:',
                  'requestUpdate':'Запросить изменение',
                  'requestDelete':'Запросить удаление',
                  'SelectCurrency': 'Выберите валюту',
                  'NoWalletsToAdd': 'Вы уже создали кошельки для каждой доступной валюты',
                  'OldWalletNumber': 'Старый номер кошелька',
                  'NewWalletNumber': 'Новый номер кошелька',
                  'Currency': 'Валюта',
                  'Reason': 'Причина',
                  'Type': 'Тип',
                  'NoWalletsInfo': 'Прежде, чем создавать инвойс на оплату, необходимо добавить кошелёк',
                  'NavigateToWalletPage': 'К Кошелькам',
                  // Инвойсы
                  'InvoiceTitle': 'Инвойсы',
                  'CreateInvoice': 'Создать инвойс',
                  'InvoiceModalTitle':'Создайте новый инвойс',
                  'ConsultantFee': 'Сбор консультанта',
                  'ProjectContributions': 'Вложения в проект',
                  'ProjectContributionsDisabled': 'Вложения в проект (необходим хотя бы 1 подтвержденный договор в этой валюте)',
                  'InvoiceInclude': 'Включить в инвойс',
                  'TotalCost': 'Общая стоимость',
                  'TransactionCommission': 'Транзакционная комиссия',
                  'ActivationCommission': 'Активационная комиссия',
                  'InvoiceCreatedSuccessfully': 'Инвойс был успешно создан',
                  'ErrorOccurred': 'Произошла ошибка',
                  'InvoiceActionTitle': 'Выберите действие',
                  'InvoiceSubmit': 'Нажимая кнопку ОТПРАВИТЬ файлы подтверждения будут отправлены администратору на проверку',
                  'InvoiceDelete': 'Нажимая кнопку УДАЛИТЬ инвойс будет удален (договоры могут быть использованы снова)',
                  'ResendConfirmation': 'Отправить подтверждение повторно',
      //Кабинет партнера
            // Кабинет Партнера Навигация
                'CabinetPartnerNavStructureMain': 'Структура',
                'CabinetPartnerNavStructureTable': 'Табличная Приглашений',
                'CabinetPartnerNavStructureGraph': 'Графическая Структура',
            // Кабинет Патрнера Кабинет
                'CabinetPartnerCabinetBillGroup': 'Накопительный груповой объем (CGV)',
                'CabinetPartnerCabinetBillPrivate': 'Личный объем (PV)',
                'CabinetPartnerCabinetBillGroupeActive': 'Групповой активный портфель',
                'CabinetPartnerCabinetBillPrivateApartament': 'Личный объем от продажи квартир (PSV)',
            //Кабинет партнера баланс
            'CabinetPartnerBalanceWaiting':'Ожидают вывода',
              //Кабинет партнера бонусы
                'CabinetPartner':'Полученные бонусы',
              //Кабинет партнера Сделки
                'CabinetPartnerStatic':'Статистика сделок в структуре',
                'CabinetPartnerStaticNone':'Сделок за указанный период нет',
              // Кабинет партнера мотивация
                'CabinetPartnerMotivationTitle':'Личная мотивация',
                'CabinetPartnerMotivationAttantion':'Для достижения слудующего этажа необходимо выполнить все условия',
                'CabinetPartnerMotivationTarget':'Цель',
                'CabinetPartnerMotivationMore':'Еще',
              // Кабинет партнера лидеры
                'CabinetPartnerMotivationLiderTitle':'Топ лидеров по кол-ву лично приглашенных',
                'CabinetPartnerMotivationLiderDesc':'За все время',
                'CabinetPartnerMotivationLiderAttention': 'У ваших партнеров еще нет лично приглашенных',
        //Кабинет партнера Структура личных приглашений
                'CabinetPartnerStructureTitle':'Структура личных приглашений',
                //Кабинет партнера Структура кол-во партнеров
                'CabinetPartnerStructureBarNew':'Новых партнёров',
                'CabinetPartnerStructureBarPersonal':'Лично приглашённых',
                'CabinetPartnerStructureBarAll':'Всего партнеров',
                'CabinetPartnerStructureBarActive':'Активных в 1-й линии',
                'CabinetPartnerStructureBarActiveSecond':'Активных в структуре',
                // Кабинет партнера Структура Таблица
                'CabinetPartnersStructureTableLogin': 'Логин',
                'CabinetPartnersStructureTableFloor': 'Этаж',
                'CabinetPartnersStructureTablePartners': 'Партнёры',
                'CabinetPartnersStructureTableNGO': 'НГО',
          //Кабинет партнера ЛИНЕЙНАЯ Структура
                'CabinetPartnerLinearStructureTitle':'Линейная структура',
           //Кабинет партнера модальное окно
                //First Tab
                'CabinetPartnerModalWindowFirstTabTitle':'Уважаемый Консультант!',
                'CabinetPartnerModalWindowFirstTabDescFirst':`Приветствуем Вас и желаем человеческого счастья и
                профессионального успеха! А успеха Вам желаем как в нашей
                компании, так и в бизнесе, которым вы занимаетесь.`,
                'CabinetPartnerModalWindowFirstTabDescSecond':`  Предлагаем Вам тщательно заполнить персональную анкету. С одной
                стороны мы увидим ваши знания, а с другой стороны анкета поможет
                нам составить для Вас персональную программу обучения!`,
                'CabinetPartnerModalWindowFirstTabButton':`Заполнить анкету`,
                //Second Tab
                'CabinetPartnerModalWindowSecondTabTitle':'Анкета',
                'CabinetPartnerModalWindowSecondTabName':'Ф.И. (Латиницей)',
                'CabinetPartnerModalWindowSecondTabBirthday':'Дата рождения',
                'CabinetPartnerModalWindowSecondTabLocation':'Место проживания',
                'CabinetPartnerModalWindowSecondTabPhone':'Номер телефона',
                'CabinetPartnerModalWindowSecondTabFamily':'Семейное положение',
                'CabinetPartnerModalWindowSecondTabEducation':'Образование',
                'CabinetPartnerModalWindowSecondTabWork':'Текущее место работы',
                'CabinetPartnerModalWindowSecondTabHobby':'Хобби',
                'CabinetPartnerModalWindowSecondTabComputerTitle':'Пользование Персональным Компьютером:',
                'CabinetPartnerModalWindowSecondTabEmail':'Персональную электронную почту',
                'CabinetPartnerModalWindowSecondTabVk':'ВКонтакте',
                'CabinetPartnerModalWindowSecondTabSubmit':'Дальше',
                //Third Tab
                'CabinetPartnerModalWindowThirdTabRegistrationFee':'Оплачен активационный сбор консультанта',
                'CabinetPartnerModalWindowThirdTabRegistrationFeeButton':'Оплатить',
                'CabinetPartnerModalWindowThirdTabSign':'Подписан договор консультанта',
                'CabinetPartnerModalWindowThirdTabSignButton':'Подписать',
                'CabinetPartnerModalWindowThirdTabSalesConversations':'Знание «Беседы о продаже»',
                'CabinetPartnerModalWindowThirdTabFunctionality':'Знание функционала личного кабинета и администрирование ',
                'CabinetPartnerModalWindowThirdTabAgreementClient':'Знание договора клиента',
                'CabinetPartnerModalWindowThirdTabAgreementConsultant':'Знание договора консультанта',
                'CabinetPartnerModalWindowThirdTabDirector':'Директор тренингового центра',
                'CabinetPartnerModalWindowThirdTabManager':'Руководитель',
                'CabinetPartnerModalWindowThirdTabNext':'Дальше',
                //Fourth Tab
                'CabinetPartnerModalWindowFourthCongratulations':'Поздравляем с получением номера Консультанта',
                'CabinetPartnerModalWindowFourthInvite':'Приглашаем на Базовый Семинар',
                'CabinetPartnerModalWindowFourthPersonal':'Персональная электронная почта',
                'CabinetPartnerModalWindowFourthClose':'Завершить',
            //Настройки сайта
                'SiteSettingsModalTitle':'Настройки сайта',
                'SiteSettingsModalLableCountry':'Часовой пояс',
                'SiteSettingsModalLabelLanguage':'Язык',
                'SiteSettingsModalLabelCurrancy':'Валюта',
                'SiteSettingsModalButtonClose':'Отмена',
                'SiteSettingsModalButtonSave':'Сохранить',
            // Модальное окно почты
                'ModalWindowEmailTitle':'Отправить по почте',
                'ModalWindowEmail':'Введите почту',
                'ModalWindowEmailButton':'Отправить',

            //Agreement
                'AgreementId': 'ID договора',
                'AgreementTime':'Время последнего обновления',
                'AgreementUserName':'Пользователь',
                'AgreementType':'Тип договора',
                'AgreementProjectName': 'Название проекта',
                'AgreementAmount': 'Сумма договора',
                'AgreementCurrency': 'Валюта',
                'CreateAgreement': 'Создать договор',
                'SendAnotherConfirmation': 'Отправить другое подтверждение',
                'DeletingSignConfirm': 'Действительно ли вы хотите удалить подписанный документ?',
                'DeleteAgreementConfirm': 'Действительно ли вы хотите удалить договор?',
                'DeleteButton': 'Удалить',
                'SubmitButton': 'Отправить'

      }
    }
  },
  lng: "English",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false
  }
});

export default i18n;
