import React, { useEffect, useState } from "react";
import "../Style/landing/landingFooter.scss";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Link } from "react-router-dom";

function LandingFooter({ language }) {


  return (
    <div className="footerWrapper">
      <div className="footer">
        <div className="linksContainer">
          <div className="supportContainer">
            <div className="supportName">
              {language === "Русский" ? "Тех. поддержка" : "Support"}
            </div>
            <div className="supportLinksContainer">
              <div className="linkEmail">
                <EmailIcon style={{ color: "gray" }} />
                <a href="" className="linkMargin">
                  test@test.test
                </a>
              </div>
              <div className="linkTelegram">
                <TelegramIcon style={{ color: "gray" }} />
                <a href="" className="linkMargin">
                  @diamond_support_bot
                </a>
              </div>
              <div className="linkPolicity">
                <Link to="privacy-policy">
                  <a className="linkPolicity" href="">
                    {language === "Русский"
                      ? "Политика конфиденциальности"
                      : "Privacy Policy"}
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <div className="socialMediaContainer">
            <div className="socialName">
              {language === "Русский" ? "Соц.сети" : " Social media"}
            </div>
            <div className="supportLinksContainer">
              <div className="linkTelegram">
                <TelegramIcon style={{ color: "gray" }} />
                <a href="" className="linkMargin">
                  {language === "Русский" ? "Новостной канал" : "News channel"}
                </a>
              </div>
              <div className="linkTelegram">
                <InstagramIcon style={{ color: "gray" }} />
                <a href="" className="linkMargin">
                  {language === "Русский"
                    ? "Канал Instagram"
                    : "Instagram channel"}
                </a>
              </div>
              <div className="linkTelegram">
                <YouTubeIcon style={{ color: "grey" }} />
                <a href="" className="linkMargin">
                  {language === "Русский"
                    ? " Канал YouTube"
                    : "YouTube channel"}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="attantionWrapper">
          <p className="attantionText">
            {language === "Русский"
              ? ` Обращаем Ваше внимание на то, что данный сайт не является средством
                      массовой информации и предназначен исключительно для предоставления
                      пользователям сайта сведений общего характера и справочной
                      информации (не является публичной офертой)`
              : `  Please note that this site is not a tool
                      media and is intended solely to provide
                      users of the site for general and reference information
                      information (not a public offer)`}
          </p>
        </div>
        {/* <div className="logoWrapper"></div> */}
      </div>
    </div>
  );
}

export default LandingFooter;
