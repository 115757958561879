import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationAdaptive from "../partnersNavigationAdaptive";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch } from "react-redux";
import { setData } from "../../../store/reducer";
import "../../../Style/MainHeader.scss";
import TelegramIcon from "@mui/icons-material/Telegram";
import FlagIcon from "@mui/icons-material/Flag";
import PersonIcon from "@mui/icons-material/Person";
import { setDataNavBar } from "../../../store/reducerNavBar";
import SiteSettingsModal from "../../../Modal/SiteSettingsModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import { ReactComponent as Settings } from "../../../images/header/settings.svg";
import { ReactComponent as ArrowForwardIcon } from "../../../images/header/arrow.svg";
import CabinetAdaptive from "../Cabinet/CabinetAdaptive";
import ModalWindowAddWallet from "../../../Modal/ModalWindowAddWallet";
import Cabinet from "../Cabinet/Cabinet";

function Header({ isSmallScreen }) {
  const [cabinetOpen, setCabinetOpen] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isRotated, setIsRotated] = useState(false);
  const dispatch = useDispatch();
  const [siteSettings, setSiteSettings] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCabinetTrue, setIsCabinetTrue] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("В Кабинет Партнера");
  const [countOfUsers, setCountOfUsers] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  let params = window.location.pathname;
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openModalConnectWallet, setOpenModalConnectWallet] = useState(false);
  const { t, i18n } = useTranslation();
  const [cabData, setCabData] = useState([]);
  const [inviter, setInviter] = useState([]);
  const [photoCab, setPhotoCab] = useState(new Map());
  const [drawerOpenCabinet, setDrawerOpenCabinet] = useState(false);

  const getUser = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/consultant`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setCabData(response.data.personal);
          setInviter(response.data.inviter);
          getPhotoId(response.data.personal.urlId);
          // setPhotoId(response.data.personal.urlId)
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  const getPhotoId = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob", // Ensure response is in blob format
      });

      // Convert blob to object URL
      // return URL.createObjectURL(response.data);
      setPhotoCab(URL.createObjectURL(response.data));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  useEffect(() => {
    handleGetCountOfUsers();
    const storedLabel = localStorage.getItem("buttonLabel");
    if (storedLabel) {
      setButtonLabel(storedLabel);
    }
  }, []);

  const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "auto";
  };

  setTimeout(() => {
    setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
  }, 200);

  const handleMenuClick = () => {
    setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
    dispatch(setDataNavBar(isDrawerOpen));
  };

  const handleOpenCabinet = () => {
    setIsRotated(!isRotated);
    setCabinetOpen(!cabinetOpen);
    dispatch(setData(cabinetOpen));
  };
  const handleOpenCabinetAdaptive = () => {
    setDrawerOpen(true);
  };

  const style = {
    background: "white",
    display: "flex",
  };

  const iconStyle = {
    color: "black",
    transition: "transform 0.5s",
    transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
    cursor: "pointer",
  };

  const handleCabinetChange = () => {
    navigate("/mainpage");
  };

  const handleGetCountOfUsers = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/count`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setCountOfUsers(response.data);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const hanldeConnectWallet = () => {
    setOpenModalConnectWallet(!openModalConnectWallet);
  };

  const handleDrawerToggleCabinet = () => {
    setDrawerOpenCabinet(!drawerOpenCabinet);
  };

  return (
    <div
      className="landingHeaderWrapper"
      style={{ width: "100%", position: "sticky", top: "0px", zIndex: "10" }}
    >
      <NavigationAdaptive
        isOpen={isDrawerOpen}
        isClose={isSmallScreen}
        onClose={handleMenuClick}
      />
      <div className="landingHeader">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={style}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {isSmallScreen && (
                <IconButton
                  size="large"
                  edge="start"
                  color="black"
                  aria-label="menu"
                  onClick={handleMenuClick}
                >
                  <MenuIcon />
                </IconButton>
              )}

              {!isSmallScreen && (
                <div className="containerHeaderButton">
                  <div className="boxCountOfUser">
                    <div className="countOfUser">{countOfUsers.count}</div>
                    <div className="tooltip"> {t("buttonCountOfUser")} </div>
                  </div>
                  <div className="wrapperButtonsCabinet">
                    <div className="headerButtonWrapper">
                      <button
                        className="yellowButton"
                        onClick={hanldeConnectWallet}
                      >
                        {t("buttonConnectWallet")}
                      </button>

                      <button
                        className="buttonPartnersOffice"
                        onClick={handleCabinetChange}
                      >
                        {t("ClientDashboard")}
                      </button>
                      <button
                        className="buttonSettingsWrapper"
                        onClick={handleModalOpen}
                      >
                        <Settings style={{ width: "34px", height: "34px" }} />
                      </button>
                    </div>

                    {!isSmallScreen && (
                      <div className="clientWrapperCabinet">
                        <div className="clientNameLevelWrapper">
                          <div className="clientName">{cabData.login}</div>
                        </div>
                        <div className="clientImageArrowWrapper">
                          <div
                            className="clientImage"
                            style={{ backgroundImage: `url(${photoCab})` }}
                          ></div>
                          <div className="arrow">
                            <ArrowForwardIcon
                              style={iconStyle}
                              onClick={handleDrawerToggleCabinet}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {isSmallScreen && (
                <PersonIcon
                  style={{ color: "black", cursor: "pointer" }}
                  onClick={handleOpenCabinetAdaptive}
                />
              )}
            </Toolbar>
          </AppBar>
        </Box>
      </div>
      <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose} />
      <CabinetAdaptive
        isOpen={drawerOpen}
        isClose={isSmallScreen}
        onClose={handleDrawerToggle}
      />
      <ModalWindowAddWallet
        isOpen={openModalConnectWallet}
        onClose={hanldeConnectWallet}
      />
       <Cabinet isOpen={drawerOpenCabinet} onClose={handleDrawerToggleCabinet}/>

    </div>
  );
}

export default Header;
