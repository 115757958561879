import React, {useEffect, useState} from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import '../../Style/registration/RegistartionHeader.scss'
import FlagIcon from '@mui/icons-material/Flag';
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";

function RegistrationHeader({language}) {

    const [result, setResult] = useState({});

    const style = {
        background: 'black',
        // boxShadow:'none'
    }



    const handleSiteSettings = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                }
            })
            setResult(response.data)
        } catch (error) {
            console.error(error);
        }
    }


    return (
        <div className="registrationHeaderWrapper">
            <div className="registartionHeader">
                <Box sx={{flexGrow: 1}}>
                    <AppBar position="static" style={style}>
                        <Toolbar style={{justifyContent: 'flex-end', width: '90%'}}>
                            <div className="ButtonAuthWrapper">
                                <div className="authWrapper">
                                    <a className="auth">
                                    {language === "Русский"
                      ? "Войти"
                      : "Login"}
                                    </a>
                                </div>
                                <div className="registrationButton">
                                    <a className="registrtion">
                                    {language === "Русский"
                      ? "Регистрация"
                      : "Registration"}
                                    </a>
                                </div>
                            </div>
                            <div className="languageWrapperRegistration">
                                <div className="flagLanguage">
                                    <FlagIcon style={{color: 'white'}}/>
                                </div>
                                {(result && result.country !== undefined && result.language !== undefined &&
                                        result.currency !== undefined) &&
                                    <div className="languageName">{result.language}, {result.currency}</div>
                                }
                                {(!result || result.country === undefined || result.language === undefined ||
                                        result.currency === undefined) &&
                                    <div className="languageName">Русский, EUR</div>
                                }
                            </div>
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
        </div>
    )
}

export default RegistrationHeader