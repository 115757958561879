import {Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {ReactComponent as CheckTrue} from "../../images/ModalWindowCabinetConsultant/checkTrue.svg";
import {ReactComponent as CheckFalse} from "../../images/ModalWindowCabinetConsultant/checkFalse.svg";
import {useTranslation} from "react-i18next";
import {useNavigate} from 'react-router-dom';
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import InvoiceModal from "../../Components/Agreements/InvoiceModal";

function ThirdTab({handleNextTabFourth, onClose}) {
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const [feeRestriction, setFeeRestriction] =
        useState({restrictionStatus: 'ALLOWED', restrictionString: 'allowed'});
    const [activateUser, setActivateUser] = useState({});
    const [allTrue, setAllTrue] = useState(false);
    const state = {
        withInvoiceModal: true,
        withCurrency: 'EUR',
        withConsultantChecked: true,
        withProjectChecked: false
    };

    // Запросы
    const getActivateUser = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setActivateUser(response.data);
        } catch (error) {
            console.error(error);
        }
    };

    const getConsultantFeeRestriction = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + '/fee-restriction';
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setFeeRestriction({
                restrictionStatus: response.data.restrictionStatus,
                restrictionString: response.data.restrictionString
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handelClickPay = async () => {
        navigate('./invoice', {state});
        onClose();
    }

    const handelClickSign = async () => {
        navigate('consultant-agreement')
        onClose();
    }

    useEffect(() => {
        getActivateUser();
        getConsultantFeeRestriction();
    }, []);

    useEffect(() => {
        if (activateUser) {
            const allTrue = Object.values(activateUser).every((value) => value);
            setAllTrue(allTrue);
        }
    }, [activateUser]);

    return (
        <div className="thirdTabWrapper">
            <div className="thirdTabStrokeWrapper" id="firstCheck">
                <div style={{display: "flex", alignItems: "center"}}>
                    {activateUser.agreementSigned ? (
                        <CheckTrue style={{width: "26px", height: "26px"}}/>
                    ) : (
                        <CheckFalse style={{width: "26px", height: "26px"}}/>
                    )}
                    <span className="thirdTabSpan">
                        {t("CabinetPartnerModalWindowThirdTabSign")}{" "}
                    </span>
                </div>
                {activateUser.agreementSigned
                    ?
                    <button className="saveButtonProfileThirdTab"
                            disabled style={{cursor: "not-allowed", backgroundColor: "lightgray"}}>
                        {t("CabinetPartnerModalWindowThirdTabSignButton")}
                    </button>
                    :
                    <button className="saveButtonProfileThirdTab"
                            onClick={handelClickSign}>
                        {t("CabinetPartnerModalWindowThirdTabSignButton")}
                    </button>
                }
            </div>
            <div className="thirdTabStrokeWrapper" id="firstCheck">
                <div style={{display: "flex", alignItems: "center"}}>
                    {activateUser.feePaid ? (
                        <CheckTrue style={{width: "26px", height: "26px"}}/>
                    ) : (
                        <CheckFalse style={{width: "26px", height: "26px"}}/>
                    )}
                    <span className="thirdTabSpan">
                        {t("CabinetPartnerModalWindowThirdTabRegistrationFee")}{" "}
                    </span>
                </div>
                {feeRestriction.restrictionStatus === 'ALLOWED' ? (
                    <button className="saveButtonProfileThirdTab"
                            onClick={handelClickPay}>
                        {t("CabinetPartnerModalWindowThirdTabRegistrationFeeButton")}
                    </button>
                ) : (
                    <button className="saveButtonProfileThirdTab"
                            disabled style={{cursor: "not-allowed", backgroundColor: "lightgray"}}>
                        {t("CabinetPartnerModalWindowThirdTabRegistrationFeeButton")}
                    </button>
                )}
            </div>
            <Typography>
                <h3>{t("CabinetPartnerModalWindowThirdTabSalesConversations")}</h3>
            </Typography>
            <div className="thirdTabStrokeWrapper">
                {activateUser.salesConversationsByConsultant ? (
                    <CheckTrue style={{width: "26px", height: "28px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "28px"}}/>
                )}
                <span className="thirdTabSpan">
                  {t("CabinetPartnerModalWindowThirdTabManager")}
                </span>
            </div>
            {" "}
            <div className="thirdTabStrokeWrapper">
                {activateUser.salesConversationsByAdmin ? (
                    <CheckTrue style={{width: "26px", height: "28px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "28px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabDirector")}
        </span>
            </div>
            <Typography>
                <h3>{t("CabinetPartnerModalWindowThirdTabFunctionality")}</h3>
            </Typography>
            <div className="thirdTabStrokeWrapper">
                {activateUser.accountFunctionalityByConsultant ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabManager")}
        </span>
            </div>
            {" "}
            <div className="thirdTabStrokeWrapper">
                {activateUser.accountFunctionalityByAdmin ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabDirector")}
        </span>
            </div>
            <Typography>
                <h3>{t("CabinetPartnerModalWindowThirdTabAgreementClient")}</h3>
            </Typography>
            <div className="thirdTabStrokeWrapper">
                {activateUser.clientAgreementByConsultant ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabManager")}
        </span>
            </div>
            {" "}
            <div className="thirdTabStrokeWrapper">
                {activateUser.clientAgreementByAdmin ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabDirector")}
        </span>
            </div>
            <Typography>
                <h3>{t("CabinetPartnerModalWindowThirdTabAgreementConsultant")}</h3>
            </Typography>
            <div className="thirdTabStrokeWrapper">
                {activateUser.consultantAgreementByConsultant ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabManager")}
        </span>
            </div>
            {" "}
            <div className="thirdTabStrokeWrapper">
                {activateUser.consultantAgreementByAdmin ? (
                    <CheckTrue style={{width: "26px", height: "26px"}}/>
                ) : (
                    <CheckFalse style={{width: "26px", height: "26px"}}/>
                )}
                <span className="thirdTabSpan">
          {t("CabinetPartnerModalWindowThirdTabDirector")}
        </span>
            </div>
            {allTrue ? (
                <button className="saveButtonProfile" onClick={handleNextTabFourth}>
                    {t("CabinetPartnerModalWindowThirdTabNext")}
                </button>
            ) : (
                <button
                    className="saveButtonProfile"
                    onClick={handleNextTabFourth}
                    disabled
                    style={{cursor: "not-allowed", backgroundColor: "lightgray"}}
                >
                    {t("CabinetPartnerModalWindowThirdTabNext")}
                </button>
            )}
        </div>

    );
}

export default ThirdTab;
