// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/logoDiamond_black.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoImage {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 130px;
  height: 90px;
  z-index: 100;
  background-size: 100% 100%;
}

.languageWrapper {
  width: 50px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  margin-left: 50px;
}

.languageText {
  display: flex;
  justify-content: center;
  align-items: center;
}

.language {
  border: none;
  background: none;
  background-color: none;
}

.languageTextRu:hover {
  color: gold;
  cursor: pointer;
}

.languageTextEn:hover {
  color: gold;
  cursor: pointer;
}

@media screen and (max-width: 450px) {
  .languageWrapper {
    margin-left: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/landing/LandingHeader.scss"],"names":[],"mappings":"AAOA;EACA,yDAAA;EACA,YAAA;EACA,YAAA;EACA,YAAA;EACA,0BAAA;AANA;;AASA;EACA,WAAA;EACA,aAAA;EACA,iBAAA;EACA,6BAAA;EACA,mBAAA;EACA,iBAAA;AANA;;AASA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AANJ;;AASA;EACI,YAAA;EACA,gBAAA;EACA,sBAAA;AANJ;;AAaA;EACI,WAAA;EACA,eAAA;AAVJ;;AAiBA;EACI,WAAA;EACA,eAAA;AAdJ;;AAiBA;EACI;IACI,iBAAA;EAdN;AACF","sourcesContent":[".landingHeaderWrapper{\n    \n}\n.landingHeader{\n\n}\n\n.logoImage{\nbackground-image: url(../../images/logoDiamond_black.png);\nwidth: 130px;\nheight: 90px;\nz-index: 100;\nbackground-size: 100% 100%;\n}\n\n.languageWrapper{\nwidth: 50px;\ndisplay: flex;\nflex-wrap: nowrap;\njustify-content: space-around;\nalign-items: center;\nmargin-left: 50px;\n}\n\n.languageText{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.language{\n    border: none;\n    background: none;\n    background-color: none;\n}\n\n.languageTextRu{\n\n}\n\n.languageTextRu:hover{\n    color: gold;\n    cursor: pointer;\n}\n\n.languageTextEn{\n\n}\n\n.languageTextEn:hover{\n    color: gold;\n    cursor: pointer;\n}\n\n@media screen and (max-width: 450px) {\n    .languageWrapper{\n        margin-left: 20px;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
