import React, { useEffect, useState } from "react";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Button from '@mui/material/Button';
import { Link, useNavigate } from "react-router-dom";
import PersonIcon from '@mui/icons-material/Person';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import PlaceIcon from '@mui/icons-material/Place';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import TelegramIcon from '@mui/icons-material/Telegram';
import {ListItemButton, ListItemIcon, ListItemText, useMediaQuery, useTheme} from "@mui/material";
import { useTranslation } from 'react-i18next';


function NavigationProfile () {

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [selectedIndex, setSelectedIndex] = useState(1);
  let params = ''
  const { t, i18n } = useTranslation();


    useEffect(() => {
      params = window.location.pathname;
  
      switch (params) {
        case "/mainpage/profile/profile":
          setSelectedIndex(0);
          break;
        case "/mainpage/profile/contacts":
          setSelectedIndex(1);
          break;
        case "/mainpage/profile/password":
          setSelectedIndex(2);
          break;
        case "/mainpage/profile/location":
          setSelectedIndex(3);
          break;
        case "/mainpage/profile/notification":
          setSelectedIndex(4);
          break;
        case "/mainpage/profile/verification":
          setSelectedIndex(5);
          break;
        case "/mainpage/profile/telegram":
          setSelectedIndex(6);
          break;
      }
    }, []);

    const handleListItemClick = (event, index) => {
      setSelectedIndex(index);
  
    };

    return(
        <Drawer
        style={{
          marginRight:'10px',
          padding: '10px 0px 10px 10px',
          // backgroundColor: "#1C2434",
          // margin:'10px',
        }}
        sx={{
        //   width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            // width: drawerWidth,
            boxSizing: 'border-box',
            position: 'static',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          // backgroundColor: "#1C2434",

          },
        }}
        variant="permanent"
        anchor="left"
      >
         <List>
      <ListItemButton 
       selected={selectedIndex === 0}
      onClick={(event) => handleListItemClick(event, 0)}
      component={Link}
      to="/mainpage/profile/profile"
      sx={{
        borderRadius: '10px',
        marginRight:'10px'    
      }}
      >
        {isSmallScreen ? (
          <ListItemIcon>
          <PersonIcon
            style={{ color: 'black' }}
          />
          </ListItemIcon>
        ) : (
        <ListItemText primary={t('ProfileNavBasicData')}/>
        )}
      </ListItemButton>
      <ListItemButton 
       selected={selectedIndex === 1}
      onClick={(event) => handleListItemClick(event, 1)}
      component={Link}
      to="/mainpage/profile/contacts"
      sx={{
        borderRadius: '10px',
        marginRight:'10px'    
      }}
      >
        {isSmallScreen ? (
          <MailOutlineIcon
            style={{ color: 'black' }}
          />
        ) : (
 
          <ListItemText primary={t('ProfileNavContacts')}/>
        )}
      </ListItemButton>
      <ListItemButton 
       selected={selectedIndex === 2}
      onClick={(event) => handleListItemClick(event, 2)}
            component={Link}
            to="/mainpage/profile/password"
            sx={{
              borderRadius: '10px',
              marginRight:'10px'    
            }}
      >
        {isSmallScreen ? (
          <LockIcon
            style={{ color: 'black' }}
          />
        ) : (
          <ListItemText primary={t('ProfileNavPassword')}/>
        )}
      </ListItemButton>
      <ListItemButton
       selected={selectedIndex === 3}
      onClick={(event) => handleListItemClick(event, 3)}
            component={Link}
            to="/mainpage/profile/location"
            sx={{
              borderRadius: '10px',
              marginRight:'10px'    
            }}
      >
        {isSmallScreen ? (
          <PlaceIcon
            style={{ color: 'black' }}
          />
        ) : (
          <ListItemText primary={t('ProfileNavLocation')}/>
        )}
      </ListItemButton>
      <ListItemButton 
       selected={selectedIndex === 4}
      onClick={(event) => handleListItemClick(event, 4)}
            component={Link}
            to="/mainpage/profile/notification"
            sx={{
              borderRadius: '10px',
              marginRight:'10px'    
            }}
      >
        {isSmallScreen ? (
          <BeenhereIcon
            style={{ color: 'black' }}
          />
        ) : (
            <ListItemText primary={t('ProfileNavNotification')}/>
        )}
      </ListItemButton>
      <ListItemButton
       selected={selectedIndex === 5}
      onClick={(event) => handleListItemClick(event, 5)}
                  component={Link}
                  to="/mainpage/profile/verification"
                  sx={{
                    borderRadius: '10px',
                    marginRight:'10px'    
                  }}
      >
        {isSmallScreen ? (
          <SwitchAccountIcon
            style={{ color: 'black' }}
          />
        ) : (
            <ListItemText primary={t('ProfileNavVerification')}/>
        )}
      </ListItemButton>
      <ListItemButton 
       selected={selectedIndex === 6}
      onClick={(event) => handleListItemClick(event, 6)}
                  component={Link}
                  to="/mainpage/profile/telegram"
                  sx={{
                    borderRadius: '10px',
                    marginRight:'10px'    
                  }}
      >
        {isSmallScreen ? (
          <TelegramIcon
            style={{ color:'black' }}
          />
        ) : (
          <ListItemText primary={t('ProfileNavTelegram')}/>
        )}
      </ListItemButton>
    </List>
      </Drawer>
    )
}

export default NavigationProfile