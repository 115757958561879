import React, { useState, useRef, useEffect } from "react";
import Typography from "@mui/material/Typography";
import "../../Style/Bills/BillsCard.scss";
import Link from "@mui/material/Link";
import CachedIcon from "@mui/icons-material/Cached";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import { blue, green, pink, purple } from "@mui/material/colors";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ReactComponent as AddIcon } from "../../images/Bills/add.svg";
import { ReactComponent as ReplayIconSvg } from "../../images/Bills/reload.svg";
import { ReactComponent as Dollar } from "../../images/Bills/dollar.svg";
import { ReactComponent as Euro } from "../../images/Bills/euro.svg";
import { ReactComponent as USDT } from "../../images/Bills/usdt.svg";
import { ReactComponent as History } from "../../images/Bills/history.svg";
import ReplayIcon from "@mui/icons-material/Replay";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ReactComponent as ArrowForwardIcon } from "../../images/header/arrow.svg";
import {ReactComponent as Withdraw} from '../../images/Verification/checkTrue.svg'
import {ReactComponent as Decline} from '../../images/Verification/checkFalseDecline.svg'
import {ReactComponent as Pending} from '../../images/Verification/pending.svg'
function BillsCard() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [openMoreInfoBonus, setOpenMoreInfoBonus] = useState(false);
  const [openMoreInfoCumulitive, setOpenMoreInfoCumulitive] = useState(false);
  const [openMoreInfoBonusSecond, setOpenMoreInfoBonusSecond] = useState(false);
  const [openMoreInfoProject, setOpenMoreInfoProject] = useState(false);
  const [wallet, setWallet] = useState([]);
  const [open, setOpen] = useState(false);
  const [amountComission, setAmountComission] = useState(0);
  const [passwordComission, setPasswordComission] = useState("");
  const [activeItem, setActiveItem] = useState("");
  const [openSecond, setOpenSecond] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const navigate = useNavigate();
  const [age, setAge] = React.useState("EUR");
  const [currencyTransaction, setCurrencyTransaction] = useState("EUR");
  const textFieldRef = useRef(null);
  const { t, i18n } = useTranslation();
  const [amountConclusion, setAmountConclusion] = useState(0);
  const [passwordConclusion, setPasswordConclusion] = useState("");
  const [withdrawal, setWithdrawal] = useState([]);
  const [openDeclineMessage, setOpenDeclineMessage] = useState(true);
  const [reason, setReason] = useState("");
  const handleCopy = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select();
      document.execCommand("copy");
    }
  };

  useEffect(() => {
    handleAddBills();
    getWithdrawal();
  }, []);

  const handleAddBills = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setWallet(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleOpen = (item) => {
    switch (item) {
      case "bonus":
        setOpenMoreInfoBonus(true);
        break;
      case "cumulitive":
        setOpenMoreInfoCumulitive(true);
        break;
      case "bonusSecond":
        setOpenMoreInfoBonusSecond(true);
        break;
      case "project":
        setOpenMoreInfoProject(true);
        break;
    }
  };
  const handleClose = (item) => {
    switch (item) {
      case "bonus":
        setOpenMoreInfoBonus(false);
        break;
      case "cumulitive":
        setOpenMoreInfoCumulitive(false);
        break;
      case "bonusSecond":
        setOpenMoreInfoBonusSecond(false);
        break;
      case "project":
        setOpenMoreInfoProject(false);
        break;
    }
  };

  const styleIconButtonRep = {
    width: isSmallScreen ? "34px" : "34px",
    height: isSmallScreen ? "34px" : "34px",
    // color: "black",
    // marginRight: "5px",
  };

  const handleReplenishment = () => {
    navigate("/replenishment");
  };

  const handleConclusion = () => {
    navigate("/conclusion");
  };
  const handleMoneyTransaction = () => {
    navigate("/moneyTransaction");
  };

  const handleReloadBillPrimary = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT) + `/PRIMARY`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      const replaceWalletItem = (wallet, response) => {
        const index = wallet.findIndex(
          (item) => item.accountType === response.data.accountType
        );
        if (index !== -1) {
          const updatedWallet = [...wallet];
          updatedWallet[index] = response.data;
          return updatedWallet;
        }
        return wallet;
      };

      const updatedWallet = replaceWalletItem(wallet, response);
      setWallet(updatedWallet);
    } catch (error) {
      console.error(error);
    }
  };
  const handleReloadBillBonus = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT) + `/BONUS`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      const replaceWalletItem = (wallet, response) => {
        const index = wallet.findIndex(
          (item) => item.accountType === response.data.accountType
        );
        if (index !== -1) {
          const updatedWallet = [...wallet];
          updatedWallet[index] = response.data;
          return updatedWallet;
        }
        return wallet;
      };

      const updatedWallet = replaceWalletItem(wallet, response);
      setWallet(updatedWallet);
    } catch (error) {
      console.error(error);
    }
  };

  // Модальное окно перевода с комиссионного

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleModalOpen = () => setOpen(true);
  const handleModalClose = () => setOpen(false);

  const handleModalOpenSecond = () => setOpenSecond(true);
  const handleModalCloseSecond = () => setOpenSecond(false);

  const handleModalOpenHistory = () => setOpenHistory(true);
  const handleModalCloseHistory = () => setOpenHistory(false);

  const handleChangeAmount = (event) => {
    const newValue = event.target.value;
    setAmountComission(newValue);
  };

  const handleChangePassword = (event) => {
    const newValue = event.target.value;
    setPasswordComission(newValue);
  };

  const handleChangeAmountConclusion = (event) => {
    const newValue = event.target.value;
    setAmountConclusion(newValue);
  };

  const handleChangePasswordConclusion = (event) => {
    const newValue = event.target.value;
    setPasswordConclusion(newValue);
  };

  const handleWithdrawalButton = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/transfer`;
    const payload = {
      amount: amountComission,
      password: passwordComission,
      currency: currencyTransaction,
    };
    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      if(response.data === ''){
        setOpen(false)
        setAmountComission('')
        setPasswordComission('')
      }
    } catch (error) {
      alert(error.response.data.message)
    } 
  };

  const handleConclusionButton = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/pending`;
    const payload = {
      amount: amountConclusion,
      password: passwordConclusion,
      currency: currencyTransaction,
    };
    try {
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      if(response.data === ''){
        setOpenSecond(false)
        setAmountConclusion('')
        setPasswordConclusion('')
      }
    } catch (error) {
      alert(error.response.data.message)
    } 
  };

  // Currency

  const StyledSelect = styled(Select)({
    width: "60px",
    borderRadius: "10px",
    // height: "50px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "10px",
    },
  });

  const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value === "USD") {
      setCurrencyTransaction("USD");
    } else if (event.target.value === "EUR") {
      setCurrencyTransaction("EUR");
    } else if (event.target.value === "USDT") {
      setCurrencyTransaction("USDT");
    }
  };

  const StyledMenuItem = styled(MenuItem)({
    borderRadius: "10px",
  });

  // const handelOpenhistory = () =>{
  //   // navigate('./history')
  // }

  // Выводы

  const getWithdrawal = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG) + `/withdrawal`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setWithdrawal(response.data.content);
    } catch (error) {
      console.error(error);
    }
  };

  // форматирование даты

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  // Decline message

  const handleOpenDeclineMessage = (id) => {
    setOpenDeclineMessage(false);
    const newWithdrawal = withdrawal.filter((item) => item.id === id);

    setReason(newWithdrawal[0].reason);
  };

  const handleCloseDeclineMessage = (id) => {
    setOpenDeclineMessage(true);
  };

  const iconStyle = {
    color: "black",
    cursor: "pointer",
    transform: "rotate(180deg)",
  };


  const getStatusMessage = (status) => {
    switch (status) {
      case 'DECLINE':
        return <Decline style={{width: "25px", height:'25px'}}/>;
      case 'PENDING':
        return <Pending style={{width: "25px", height:'25px'}}/>;
      case 'WITHDRAW':
        return <Withdraw style={{width: "25px", height:'25px'}}/>;
      default:
        return 'Unknown status.';
    }
  };

  return (
    <>
      <div className="wrapperBillsCard">
        <div
          className="billsCardMain"
          // style={{background:'#6e85d5', color:'white'}}
        >
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {wallet.map((item) => {
                if (item.accountType === "PRIMARY") {
                  return (
                      <div>
                        <div className="billMoney">
                          <Euro style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.eurAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <Dollar style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "1px"}}>
                          {item.currencyAmount.usdAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <USDT style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.usdtAmount}
                        </span>
                        </div>
                      </div>
                  );
                }
                return null;
              })}
              <div className="buttonBillsCard">
                <div className="buttonsWrapper">
                  <div
                      className="buttonAddWrapper"
                      style={{ cursor: "pointer" }}
                  >
                    <AddIcon
                      style={styleIconButtonRep}
                      className="activeElement"
                      onClick={() => handleReplenishment()}
                    />
                  </div>
                  <div
                    className="buttonAddWrapper"
                    style={{ cursor: "pointer" }}
                  >
                    <RemoveCircleOutlineIcon
                      style={styleIconButtonRep}
                      onClick={() => handleConclusion()}
                    />
                  </div>
                  <div
                    className="buttonReloadWrapper"
                    style={{ cursor: "pointer" }}
                    onClick={handleReloadBillPrimary}
                  >
                    <ReplayIconSvg style={styleIconButtonRep} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                style={{
                  fontFamily: "HelveticaNeueCyr",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "gray",
                }}
                component="p"
                variant="h5"
              >
                {t("BillsCardMainBalance")}
              </Typography>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link style={{ color: "grey", textDecoration: "grey" }}>
                  {t("BillsCardAllOperation")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="billsCardMain">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {wallet.map((item) => {
                if (item.accountType === "BONUS") {
                  return (
                      <div>
                        <div className="billMoney">
                          <Euro style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.eurAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <Dollar style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "1px"}}>
                          {item.currencyAmount.usdAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <USDT style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.usdtAmount}
                        </span>
                        </div>
                      </div>
                  );
                }
                return null;
              })}
              <div className="buttonBillsCard">
                <div className="buttonsWrapper">
                  <div className="buttonAddWrapper">
                    <RemoveCircleOutlineIcon style={styleIconButtonRep} />
                  </div>
                  <div
                    className="buttonReloadWrapper"
                    onClick={handleReloadBillBonus}
                  >
                    <ReplayIconSvg style={styleIconButtonRep} />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  style={{
                    fontFamily: "HelveticaNeueCyr",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    textAlign: "left",
                    color: "gray",
                  }}
                  component="p"
                  variant="h5"
                >
                  {t("BillsCardBonusBalance")}
                </Typography>
                <Link
                  style={{ textDecoration: "grey", color: "grey" }}
                  color="text.secondary"
                  href="#"
                >
                  {t("BillsCardAllOperation")}
                </Link>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <Link
                  onClick={() => handleOpen("bonus")}
                  style={{
                    color: "white",
                    width: "138px",
                    height: "35px",
                    background: "#3C50E0",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                  }}
                  href="#"
                >
                  {t("MainPageBillsMoreInfo")}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="billsCardMain">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {wallet.map((item) => {
                if (item.accountType === "COMMISSION") {
                  return (
                      <div>
                        <div className="billMoney">
                          <Euro style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.eurAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <Dollar style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "1px"}}>
                          {item.currencyAmount.usdAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <USDT style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.usdtAmount}
                        </span>
                        </div>
                      </div>
                  );
                }
              })}
              <div className="buttonAddWrapper" style={{cursor: "pointer"}}>
                <RemoveCircleOutlineIcon
                    style={styleIconButtonRep}
                    className="activeElement"
                    onClick={handleModalOpen}
                />
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                component="p"
                variant="h5"
                style={{
                  fontFamily: "HelveticaNeueCyr",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "gray",
                }}
              >
                {t("BillsCardCumulativeBalance")}
              </Typography>

              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link
                  style={{
                    color: "white",
                    width: "138px",
                    height: "35px",
                    background: "#3C50E0",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  color="text.secondary"
                  //  color={'#4f4ff1'}
                  onClick={() => handleOpen("bonusSecond")}
                >
                  {t("MainPageBillsMoreInfo")}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="billsCardMain">
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {wallet.map((item) => {
                if (item.accountType === "AUXILIARY") {
                  return (
                      <div>
                        <div className="billMoney">
                          <Euro style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.eurAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <Dollar style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "1px"}}>
                          {item.currencyAmount.usdAmount}
                        </span>
                        </div>
                        <div className="billMoney">
                          <USDT style={{width: "25px", height: "25px"}}/>
                          <span style={{marginLeft: "3px"}}>
                          {item.currencyAmount.usdtAmount}
                        </span>
                        </div>
                      </div>
                  );
                }
              })}
              <div className="buttonAddWrapper" style={{cursor: "pointer"}}>
                <RemoveCircleOutlineIcon
                    style={styleIconButtonRep}
                    className="activeElement"
                    onClick={handleModalOpenSecond}
                />
                <History
                  style={styleIconButtonRep}
                  onClick={handleModalOpenHistory}
                />
              </div>
            </div>

            <Typography
              component="p"
              variant="h5"
              style={{
                fontFamily: "HelveticaNeueCyr",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "22px",
                textAlign: "left",
                color: "gray",
              }}
            >
              {t("BillsCardAuxiliaryBalance")}
            </Typography>
          </div>

          <div>
            <div className="descriptionWrapper">
              <div className="descriptionText">
                {t("MainPageBillsMainBalanceUSDAwaitingWithdrawal")}
              </div>

              {/* <div className="descriptionText">{t('MainPageBillsMainBalanceUSDFrozen')}</div> */}
            </div>
          </div>
        </div>

        {/* <div className="billsCardMain">
          <div>
            <Typography component="p" variant="h5">
              {t("BillsCardStatistic")}
            </Typography>
            <div
              style={{
                marginTop: "10px",
                height: "110px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <div className="myAccountPortfolioBill">
                  <div
                    className="imgPortfolioWrapperBill"
                    style={{ alignItems: "flex-start" }}
                  >
                    <PaymentsIcon sx={{ color: blue[500] }} />
                  </div>
                  <div className="">
                    {t("BillsCardStatisticAllSum")}
                    <b style={{ marginLeft: "5px" }}>$</b>
                  </div>
                </div>
                <div className="myAccountPortfolioBill">
                  <div
                    className="imgPortfolioWrapperBill"
                    style={{ alignItems: "flex-start" }}
                  >
                    <PaymentsIcon sx={{ color: green[500] }} />
                  </div>
                  <div className="">
                    {t("BillsCardStatisticTotalReceipts")}
                    <b style={{ marginLeft: "5px" }}>$</b>
                  </div>
                </div>
              </div>

              <div>
                <div className="myAccountPortfolioBill">
                  <div
                    className="imgPortfolioWrapperBill"
                    style={{ alignItems: "flex-start" }}
                  >
                    <PaymentsIcon sx={{ color: pink[500] }} />
                  </div>
                  <div className="">
                    {t("BillsCardStatisticTotalWriteOffAmount")}
                    <b style={{ marginLeft: "5px" }}>$</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div> */}
      </div>
      {openMoreInfoBonus && (
        <Dialog
          open={openMoreInfoBonus}
          onClose={() => handleClose("bonus")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "30px" }}
        >
          <DialogTitle style={{ margin: "0 auto" }} id="alert-dialog-title">
            {t("BillsModalWindowBonusBalanceTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className="attentionWrapperYellow"
                style={{
                  marginBottom: "50px",
                  marginTop: "50px",
                  marginLeft: "15px",
                }}
              >
                <div className="wrapperIconAttention">
                  <WarningIcon
                    style={{
                      color: "#FFA500",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                </div>
                <div className="attentionText">
                  <span>{t("BillsModalWindowBonusBalanceText")}</span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ letterSpacing: "1px" }}
              onClick={() => handleClose("bonus")}
              autoFocus
            >
              {t("BillsModalWindowButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openMoreInfoCumulitive && (
        <Dialog
          open={openMoreInfoCumulitive}
          onClose={() => handleClose("cumulitive")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ margin: "0 auto" }} id="alert-dialog-title">
            {t("BillsModalWindowCumulativeBalanceTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className="attentionWrapperYellow"
                style={{
                  marginBottom: "50px",
                  marginTop: "50px",
                  marginLeft: "15px",
                }}
              >
                <div className="wrapperIconAttention">
                  <WarningIcon
                    style={{
                      color: "#FFA500",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                </div>
                <div className="attentionText">
                  <span>
                    <p>{t("BillsModalWindowCumulativeBalanceTextFirst")}</p>
                    <p>{t("BillsModalWindowCumulativeBalanceTextSecond")}</p>
                  </span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ letterSpacing: "1px" }}
              onClick={() => handleClose("cumulitive")}
              autoFocus
            >
              {t("BillsModalWindowButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openMoreInfoBonusSecond && (
        <Dialog
          open={openMoreInfoBonusSecond}
          onClose={() => handleClose("bonusSecond")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ margin: "0 auto" }} id="alert-dialog-title">
            {t("BillsModalWindowCumulativeBonusBalanceTitle")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div
                className="attentionWrapperYellow"
                style={{
                  marginBottom: "50px",
                  marginTop: "50px",
                  marginLeft: "15px",
                }}
              >
                <div className="wrapperIconAttention">
                  <WarningIcon
                    style={{
                      color: "#FFA500",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                </div>
                <div className="attentionText">
                  <span>
                    <p>
                      {t("BillsModalWindowCumulativeBonusBalanceTextFirst")}
                    </p>
                    <p>
                      {t("BillsModalWindowCumulativeBonusBalanceTextSecond")}
                    </p>
                  </span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ letterSpacing: "1px" }}
              onClick={() => handleClose("bonusSecond")}
              autoFocus
            >
              {t("BillsModalWindowButton")}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {openMoreInfoProject && (
        <Dialog
          open={openMoreInfoProject}
          onClose={() => handleClose("project")}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ margin: "0 auto" }} id="alert-dialog-title">
            {"Stellar кошелек"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <TextField
                inputRef={textFieldRef}
                value={"Номер счета"}
                InputLabelProps={{ style: { margin: "0 auto" } }}
                InputProps={{
                  style: { color: "grey" },
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title="Copy">
                        <IconButton onClick={handleCopy}>
                          <ContentCopyIcon />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                fullWidth
              />
              <div
                className="attentionWrapperBlue"
                style={{
                  marginBottom: "50px",
                  marginTop: "50px",
                  marginLeft: "15px",
                }}
              >
                <div className="wrapperIconAttention">
                  <PriorityHighIcon
                    style={{
                      color: "blue",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  />
                </div>
                <div className="attentionText">
                  <span>
                    По <a href="">ссылка на Stellar - scan</a> Вы можете
                    отследить историю транзакций кошелька.
                  </span>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ letterSpacing: "1px" }}
              onClick={() => handleClose("project")}
              autoFocus
            >
              Закрыть
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <div>
        <Modal
          open={open}
          onClose={handleModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("ComissionModalWindowTitle")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div
                style={{
                  height: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControl style={{}}>
                    <StyledSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      style={{
                        borderRadius: "10px",
                        // height: "50px",
                        backgroundColor: "white",
                      }}
                    >
                      <StyledMenuItem value={"USD"}>
                        {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                      </StyledMenuItem>
                      <StyledMenuItem value={"EUR"}>
                        {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                      </StyledMenuItem>
                      <StyledMenuItem value={"USDT"}>
                        {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                      </StyledMenuItem>
                    </StyledSelect>
                  </FormControl>
                  <TextField
                    value={amountComission}
                    label={t("BillsTableSecondTransactionAmount")}
                    onChange={(e) => handleChangeAmount(e)}
                    style={{ width: "80%" }}
                  ></TextField>
                </div>

                <TextField
                  value={passwordComission}
                  label={t("ProjectPageEnterPassword")}
                  onChange={(e) => handleChangePassword(e)}
                  type="password"
                ></TextField>
              </div>
            </Typography>
            <Button
              style={{marginTop:'10px'}}
              variant="contained"
              color="success"
              onClick={() => handleWithdrawalButton()}
            >
              {t("CommissionModalWindowWithdrawalButton")}
            </Button>
          </Box>
        </Modal>
      </div>
      <div>
        <Modal
          open={openSecond}
          onClose={handleModalCloseSecond}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {t("ComissionModalWindowTitleSecond")}
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <div
                style={{
                  height: "120px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormControl style={{}}>
                    <StyledSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={age}
                      onChange={handleChange}
                      style={{
                        borderRadius: "10px",
                        // height: "50px",
                        backgroundColor: "white",
                      }}
                    >
                      <StyledMenuItem value={"USD"}>
                        {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                      </StyledMenuItem>
                      <StyledMenuItem value={"EUR"}>
                        {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                      </StyledMenuItem>
                      <StyledMenuItem value={"USDT"}>
                        {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                      </StyledMenuItem>
                    </StyledSelect>
                  </FormControl>
                  <TextField
                    value={amountConclusion}
                    label={t("BillsTableSecondTransactionAmount")}
                    onChange={(e) => handleChangeAmountConclusion(e)}
                    style={{ width: "80%" }}
                  ></TextField>
                </div>
                <TextField
                  value={passwordConclusion}
                  label={t("ProjectPageEnterPassword")}
                  onChange={(e) => handleChangePasswordConclusion(e)}
                  type="password"
                ></TextField>
              </div>
            </Typography>
            <Button 
                 style={{marginTop:'10px'}}
                 variant="contained"
                 color="success"
            onClick={() => handleConclusionButton()}>
              {t("CommissionModalWindowWithdrawalButtonSecond")}
            </Button>
          </Box>
        </Modal>
        <Modal
          open={openHistory}
          onClose={handleModalCloseHistory}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} style={{ width: "650px" }}>
            {!openDeclineMessage && (
              <div>
                <ArrowForwardIcon
                  style={iconStyle}
                  onClick={handleCloseDeclineMessage}
                />
              </div>
            )}
            {!openDeclineMessage && (
              <div>
                <h2>Причина отказа:</h2> <br />
                {reason}
              </div>
            )}
            {openDeclineMessage && (
              <TableContainer component={Paper}>
                <Table sx={{ maxWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell align="right">Amount</TableCell>
                      <TableCell align="right">Currency</TableCell>
                      <TableCell align="right">Date</TableCell>
                      <TableCell align="right">Type</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {withdrawal.map((row) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.id}
                        </TableCell>
                        <TableCell align="right">{row.amount}</TableCell>
                        <TableCell align="right">{row.currency}</TableCell>
                        <TableCell align="right">
                          {formatDate(row.dateTime)}
                        </TableCell>
                        <TableCell align="right">
                            <Button
                              onClick={() => handleOpenDeclineMessage(row.id)}
                              // variant="contained"
                            >
                              {getStatusMessage(row.type)}
                            </Button>
                           
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}

export default BillsCard;
