import React from "react";
import '../../../Style/Body.scss'
import {useSelector} from "react-redux";
import ProjectDetails from "./ProjectDetails";

function Body () {
    const dataCabinet = useSelector((state) => state.OpenNavBar.dataNav)


    return(
        <>
        <div className="mainPageBody">
            <ProjectDetails/>
        </div>
        </>
    )
}

export default Body