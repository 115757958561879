import React from "react";
import BillsPartner from "./Bills/billsPartner";
import PartnerBonus from "./Bonus/partnerBonus";
import StatisticPartner from "./Statistics/partnerStatistics";
import PartnerMotivation from "./Motivation/partnerMotivation";
import PartnerTitle from "./partnersTitle";
import PageWrapper from "../../Routes/PageWrapper";


function PartnersOffice () {
    return(
        <div className="wrapperPartners">
        <PageWrapper>
        <PartnerTitle/>
        <BillsPartner/>
        <PartnerBonus/>
        <StatisticPartner/>
        <PartnerMotivation/>
        </PageWrapper>
        </div>
       
    )
}

export default PartnersOffice