import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import {useTranslation} from "react-i18next";
import urlFromTemplate from '../configs/url';
import {ENDPOINTS} from '../configs/endpoints';
import axios from 'axios';
import '../Style/PhotoEditor/PhotoEditor.scss'
import {styled, TextField} from '@mui/material';
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";


function ModalWindowAddWallet({isOpen, onClose}) {
    const {t, i18n} = useTranslation();

    const [inputValue, setInputValue] = React.useState('');
    const [chosenCurrency, setChosenCurrency] = useState('');
    const [currencies, setCurrencies] = React.useState([]);

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };

    const handleCurrencyChange = (event) => {
        setChosenCurrency(event.target.value);
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: '20px',
    };

    const StyledSelect = styled(Select)({
        width: '60px',
        borderRadius: "10px",
        border: 'none',
        // height: "50px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
    });

    const StyledMenuItem = styled(MenuItem)({
        borderRadius: "10px",
    });

    useEffect(() => {
        retrieveCurrenciesWithoutWallets()
    }, []);

    useEffect(() => {
        if (currencies && currencies.length > 0) {
            setChosenCurrency(currencies[0])
        }
    }, [currencies]);

    const retrieveCurrenciesWithoutWallets = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET) + '/currencies/no-wallet'
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setCurrencies(response.data.currencies);
        } catch (error) {
            console.error(error);
        }
    }

    const handleCancel = () => {
        onClose();
        setInputValue('')
    }

    const handleSendWallet = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET);
        const body = {number: inputValue, currency: chosenCurrency}
        try {
            await axios.post(apiUrl, body, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            onClose()
            setInputValue('')
            await retrieveCurrenciesWithoutWallets()
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleCancel}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box className='photoEditor' sx={style}>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <h2 style={{margin: '0 auto'}}>
                        {t('buttonAddWalletTitle')}
                    </h2>
                </div>
                { (!currencies || currencies.length === 0) && <>
                    <div style={{color: 'red', marginBottom: '5px', marginTop: '15px', textAlign: 'center'}}>
                        {t('NoWalletsToAdd')}
                    </div>
                </>}
                {currencies && currencies.length > 0 && <>
                    <div style={{color: 'red', marginBottom: '5px'}}>
                    {t('buttonAddWalletAttention')}
                </div>
                <div>
                    <div>
                        {t('buttonAddWalletDescription')}
                    </div>
                    <div>
                        <br/>{t('buttonAddWalletDescriptionFourth')}
                    </div>
                    <div>
                        <br/>{t('buttonAddWalletDescriptionSecond')}
                    </div>
                </div>
                <div style={{color: "red", marginBottom: '5px'}}>
                    <br/>{t('buttonAddWalletAttentionSecond')}:
                </div>
                <div>
                    {t('buttonAddWalletDescriptionThird')}
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <br/>{t('SelectCurrency')}
                    <FormControl style={{marginTop: '5px'}}>
                        <StyledSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={chosenCurrency}
                            onChange={handleCurrencyChange}
                            style={{
                                borderRadius: "10px",
                                // height: "50px",
                                backgroundColor: "white",
                            }}
                        >
                            {currencies.includes("EUR") &&
                                <StyledMenuItem value={"EUR"}>
                                    {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                                </StyledMenuItem>
                            }
                            {currencies.includes("USD") &&
                                <StyledMenuItem value={"USD"}>
                                    {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                                </StyledMenuItem>
                            }
                            {currencies.includes("USDT") &&
                                <StyledMenuItem value={"USDT"}>
                                    {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                                </StyledMenuItem>
                            }
                        </StyledSelect>
                    </FormControl>
                </div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <br/>{t('buttonAddWalletInputLabel')}
                    <TextField style={{marginTop: '10px'}} value={inputValue}
                               onChange={handleChange}/>
                </div>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'space-evenly'}}>
                    <Button variant="contained" color="secondary" style={{background: 'lightgray'}} onClick={handleCancel}>
                        {t('buttonAddWalletClose')}
                    </Button>
                    <Button variant="contained" style={{background: '#3C50E0'}} onClick={handleSendWallet}>
                        {t('buttonAddWalletConnect')}
                    </Button>
                </Box>
                </>}
            </Box>
        </Modal>
    )
}

export default ModalWindowAddWallet










