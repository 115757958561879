// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileWrapper {
  background-color: "#eef1fa";
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/IndexProfile.scss"],"names":[],"mappings":"AAAA;EAEI,2BAAA;EAEA,WAAA;AADJ","sourcesContent":[".profileWrapper{\n    // background-color: #DCDCDC;\n    background-color: '#eef1fa';\n\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
