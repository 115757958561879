import React from "react";
import "../Style/landing/Policy.scss";

function Terms() {
  return (
    <>
      <div className="policyWrapper">
        <div className="headerPolicyWrapper">
          <div className="policyText">ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</div>
        </div>
        <div className="policyBodyWrapper">
          <div className="policyBodyText">
            <h3>
              <b>1.Введение</b>
            </h3>
            <span>
              <p>Добро пожаловать в компанию «Diamond»!</p>
              <p>
                Настоящие Условия предоставления услуг («Условия», «Условия
                предоставления услуг») регулируют использование вами нашего
                веб-сайта, расположенного по адресу
                https://test.diamond.balinasoft.com/ (вместе или по отдельности
                «Сервис»), управляемого компанией «Diamond».
              </p>
              <p>
                Наша Политика конфиденциальности также регулирует использование
                вами нашего Сервиса и объясняет, как мы собираем, защищаем и
                раскрываем информацию, полученную в результате использования
                вами наших веб-страниц.
              </p>
              <p>
                Ваше соглашение с нами включает в себя настоящие Условия и нашу
                Политику конфиденциальности («Соглашения»). Вы подтверждаете,
                что прочитали и поняли Соглашения, и соглашаетесь с ними.
              </p>
              <p>
                Если вы не согласны с Соглашениями (или не можете их соблюдать),
                вы не можете пользоваться Сервисом, но, пожалуйста, сообщите нам
                об этом по электронной почте info@diamond.com, чтобы мы могли
                попытаться найти решение. Настоящие Условия распространяются на
                всех посетителей, пользователей и других лиц, желающих получить
                доступ или использовать Сервис.
              </p>
            </span>
            <h3>
              <b>2. Коммуникации</b>
            </h3>
            <span>
              <p>
                Используя наш Сервис, вы соглашаетесь подписаться на рассылку
                новостей, маркетинговых или рекламных материалов и другой
                информации, которую мы можем присылать. Однако вы можете
                отказаться от получения любых или всех этих сообщений от нас,
                перейдя по ссылке «Отказаться от подписки» или написав по адресу
                info@diamond.com.
              </p>
            </span>
            <h3>
              <b>3. Покупки</b>
            </h3>
            <span>
              <p>
                Если вы хотите приобрести какой-либо продукт или услугу,
                доступные через Сервис («Покупка»), вас могут попросить
                предоставить определенную информацию, относящуюся к вашей
                Покупке, включая, помимо прочего, номер вашей кредитной или
                дебетовой карты, дату окончания срока действия вашей карты, ваш
                адрес для выставления счетов и информацию о доставке.
              </p>
              <p>
                Вы заявляете и гарантируете, что: (i) у вас есть законное право
                использовать любую карту (карты) или другой способ (способы)
                оплаты в связи с любой покупкой; и (ii) информация, которую вы
                предоставляете нам, является правдивой, правильной и полной.
              </p>
              <p>
                Мы можем прибегать к услугам третьих лиц для облегчения оплаты и
                совершения Покупок. Предоставляя свои данные, вы даете нам право
                предоставлять информацию этим третьим лицам в соответствии с
                нашей Политикой конфиденциальности.
              </p>
              <p>
                Мы оставляем за собой право отказать или отменить ваш заказ в
                любое время по причинам, включая, но не ограничиваясь: наличие
                товара или услуги, ошибки в описании или цене товара или услуги,
                ошибка в вашем заказе или другие причины.
              </p>
              <p>
                Мы оставляем за собой право отказать или отменить ваш заказ,
                если подозревается мошенничество или несанкционированная или
                незаконная сделка.
              </p>
            </span>
            <h3>
              <b>4. Конкурсы, тотализаторы и акции</b>
            </h3>
            <span>
              <p>
                Любые конкурсы, тотализаторы или другие рекламные акции и
                маркетинговые рекомендации (далее - «Акции»), доступные через
                Сервис, могут регулироваться правилами, отдельными от настоящих
                Условий предоставления услуг. Если вы участвуете в какой-либо
                Акции, ознакомьтесь с соответствующими правилами, а также с
                нашей Политикой конфиденциальности. Если правила Акции
                противоречат настоящим Условиям предоставления услуг,
                применяются правила Акции, которые указаны в вашем личном
                профиле.
              </p>
            </span>
            <h3>
              <b>5. Возврат средств</b>
            </h3>
            <span>
              <p>
                Мы осуществляем возврат средств за услуги в течение 30 дней
                после того, как вы уведомили нас об этом, но не ранее чем через
                90 дней после первоначальной покупки услуги.
              </p>
            </span>
            <h3>
              <b>6. Контент</b>
            </h3>
            <span>
              <p>
                Наш сервис позволяет вам размещать ссылки, хранить, делиться и
                иным образом делать доступной определенную информацию, текст,
                графику, расчеты и другие материалы («Контент»). Вы несете
                ответственность за конфиденциальность Контента, который вы
                получаете в Сервисе через свою личную учетную запись.
              </p>
              <p>
                Мы несем ответственность за публикуемые нами материалы, включая
                их законность, надежность и уместность.
              </p>
              <p>
                Мы оставляем за собой право прекратить действие учетной записи
                любого лица, уличенного в нарушении авторских прав.
              </p>
              <p>
                Вы несете ответственность за любой Контент, который вы
                отправляете, размещаете или демонстрируете на Сервисе или через
                него, и вы несете ответственность за защиту этих прав. Мы не
                несем ответственности за Контент, размещенный вами или третьими
                лицами на Сервисе или через него. Однако, размещая Контент с
                помощью Сервиса, вы предоставляете нам право и лицензию на
                использование, изменение, публичное исполнение, публичный показ,
                воспроизведение и распространение такого Контента на Сервисе и
                через него. Вы соглашаетесь, что эта лицензия включает в себя
                право на предоставление нами доступа к вашему Контенту другим
                пользователям Сервиса, которые также могут использовать ваш
                Контент в соответствии с настоящими Условиями.
              </p>
              <p>
                Компания «Diamond» имеет право, но не обязана контролировать и
                редактировать весь Контент, предоставляемый пользователями.
              </p>
              <p>
                Кроме того, Контент, найденный на или через данный Сервис,
                является собственностью компании «Diamond» или используется с ее
                разрешения. Вы не имеете права распространять, изменять,
                передавать, повторно использовать, загружать, репостить,
                копировать или использовать указанный Контент, полностью или
                частично, в коммерческих целях или для личной выгоды, без
                предварительного письменного разрешения с нашей стороны.
              </p>
            </span>
            <h3>
              <b>7. Запрещенное использование</b>
            </h3>
            <span>
              <p>
                Вы можете использовать Сервис только в законных целях и в
                соответствии с Условиями. Вы соглашаетесь не использовать
                Сервис:
              </p>
              <p>
                7.1.1. каким-либо образом, нарушающим любые применимые
                национальные или международные законы или правила.
              </p>
              <p>
                7.1.2. В целях эксплуатации, причинения вреда или попыток
                эксплуатации или причинения вреда несовершеннолетним любым
                способом, подвергая их воздействию ненадлежащего контента или
                иным образом.
              </p>
              <p>
                7.1.3. Передавать или обеспечивать отправку любых рекламных или
                пропагандистских материалов, включая любые «нежелательные
                письма», «письма счастья», «спам» или любые другие подобные
                приглашения.
              </p>
              <p>
                7.1.4. Выдавать себя или пытаться выдать себя за компанию,
                сотрудника компании, другого пользователя или любое другое лицо
                или организацию.
              </p>
              <p>
                7.1.5. Каким-либо образом нарушать права других лиц, либо
                каким-либо незаконным, угрожающим, мошенническим или вредным
                образом, либо в связи с любыми незаконными, незаконными,
                мошенническими или вредными целями или действиями.
              </p>
              <p>
                7.1.6. Заниматься любым другим поведением, которое ограничивает
                или препятствует кому-либо использовать или наслаждаться
                Сервисом, или которое, по нашему мнению, может нанести вред или
                оскорбить Компанию или пользователей Сервиса или подвергнуть их
                ответственности.
              </p>
              <p>Кроме того, вы соглашаетесь не:</p>
              <p>
                7.2.1. Использовать Сервис любым способом, который может вывести
                из строя, перегрузить, повредить или ухудшить работу Сервиса или
                помешать использованию Сервиса любой другой стороной, включая их
                способность участвовать в деятельности в режиме реального
                времени через Сервис.
              </p>
              <p>
                7.2.2. Использовать роботов, пауков или другие автоматические
                устройства, процессы или средства для доступа к Сервису в любых
                целях, включая мониторинг или копирование любых материалов на
                Сервисе.
              </p>
              <p>
                7.2.3. Использовать любой ручной процесс для мониторинга или
                копирования любого материала на Сервисе или для любой другой
                несанкционированной цели без нашего предварительного письменного
                согласия.
              </p>
              <p>
                7.2.4. Использовать любое устройство, программное обеспечение
                или процедуру, которые мешают нормальной работе Сервиса.
              </p>
              <p>
                7.2.5. Внедрять любые вирусы, троянские кони, черви, логические
                бомбы или другие материалы, которые являются вредоносными или
                технологически опасными.
              </p>
              <p>
                7.2.6. Пытаться получить несанкционированный доступ,
                вмешиваться, повреждать или нарушать работу любых частей
                Сервиса, сервера, на котором хранится Сервис, или любого
                сервера, компьютера или базы данных, подключенных к Сервису.
              </p>
              <p>
                7.2.7. Атаковать Сервис посредством атаки типа «отказ в
                обслуживании» или распределенной атаки типа «отказ в
                обслуживании».
              </p>
              <p>
                7.2.8. Предпринимать любые действия, которые могут повредить или
                фальсифицировать рейтинг Компании.
              </p>
              <p>
                7.2.9. Иным образом пытаться помешать нормальной работе Сервиса.
              </p>
            </span>
            <h3>
              <b>8. Аналитика</b>
            </h3>
            <span>
              <p>
                Мы можем использовать сторонних поставщиков услуг для
                мониторинга и анализа использования нашего Сервиса.
              </p>
            </span>
            <h3>
              <b>9. Запрет использования несовершеннолетними</b>
            </h3>
            <span>
              <p>
                Сервис предназначен только для доступа и использования лицами не
                моложе восемнадцати (18) лет. Получая доступ к Сервису или
                используя его, вы гарантируете и заявляете, что вам не менее
                восемнадцати (18) лет и вы обладаете всеми полномочиями, правами
                и способностями для заключения данного соглашения и соблюдения
                всех условий и положений Условий. Если вам менее восемнадцати
                (18) лет, вам запрещается как доступ, так и использование
                Сервиса.
              </p>
            </span>
            <h3>
              <b>10. Аккаунты</b>
            </h3>
            <span>
              <p>
                Создавая у нас учетную запись, вы гарантируете, что вам больше
                18 лет, и что информация, которую вы нам предоставляете,
                является точной, полной и актуальной в любое время. Неточная,
                неполная или устаревшая информация может привести к немедленному
                прекращению действия вашей учетной записи на Сервисе.
              </p>
              <p>
                Вы несете ответственность за сохранение конфиденциальности вашей
                учетной записи и пароля, включая, но не ограничиваясь,
                ограничением доступа к вашему компьютеру и/или учетной записи.
                Вы соглашаетесь принять на себя ответственность за все действия
                и поступки, которые совершаются под вашей учетной записью и/или
                паролем, независимо от того, используется ли ваш пароль в нашем
                Сервисе или в сервисе третьей стороны. Вы должны немедленно
                уведомить нас о любом нарушении безопасности или
                несанкционированном использовании вашей учетной записи.
              </p>
              <p>
                Вы не имеете права использовать в качестве имени пользователя
                имя другого физического или юридического лица или имя или
                торговую марку, которые являются объектом прав другого
                физического или юридического лица, кроме вас, без
                соответствующего разрешения. Вы не можете использовать в
                качестве имени пользователя любое имя, которое является
                оскорбительным, вульгарным или непристойным.
              </p>
              <p>
                Мы оставляем за собой право отказать в обслуживании, прекратить
                действие учетных записей, удалить или отредактировать
                содержимое, или отменить заказы по нашему собственному
                усмотрению в этом случае.
              </p>
            </span>
            <h3>
              <b>11. Интеллектуальная собственность</b>
            </h3>
            <span>
              <p>
                Сервис и его оригинальный контент (за исключением Контента,
                предоставленного пользователями), возможности и функциональность
                являются и будут являться исключительной собственностью компании
                «Diamond» и ее лицензиаров. Сервис защищен авторским правом,
                торговыми марками и другими законами стран. Наши торговые марки
                не могут быть использованы в связи с любым продуктом или услугой
                без предварительного письменного согласия компании «Diamond».
              </p>
            </span>
            <h3>
              <b>12. Политика в области авторского права</b>
            </h3>
            <span>
              <p>
                Мы уважаем права интеллектуальной собственности других лиц. Наша
                политика заключается в том, чтобы ответить на любое заявление о
                том, что Контент, размещенный на Сервисе, нарушает авторские
                права или другие права на интеллектуальную собственность
                («Нарушение») любого физического или юридического лица.
              </p>
              <p>
                Если вы являетесь владельцем авторских прав или уполномочены от
                его имени и считаете, что защищенная авторским правом работа
                была скопирована таким образом, что это является нарушением
                авторских прав, пожалуйста, отправьте свою претензию по
                электронной почте на адрес info@diamond.com, указав в теме
                письма: «Нарушение авторских прав» и включите в свою претензию
                подробное описание предполагаемого нарушения, как указано ниже,
                в разделе «Уведомление DMCA и процедура рассмотрения претензий о
                нарушении авторских прав».
              </p>
              <p>
                Вы можете быть привлечены к ответственности за возмещение ущерба
                (включая расходы и гонорары адвокатов) в случае введения в
                заблуждение или недобросовестных заявлений о нарушении ваших
                авторских прав любым Содержанием, размещенным на Сервисе и/или
                через Сервис.
              </p>
            </span>
            <h3>
              <b>
                13. Уведомление DMCA и процедура рассмотрения претензий о
                нарушении авторских прав
              </b>
            </h3>
            <span>
              <p>
                Вы можете подать уведомление в соответствии с Законом об
                авторском праве в цифровую эпоху (DMCA), предоставив нашему
                агенту по авторским правам следующую информацию в письменном
                виде:
              </p>
              <p>
                13.1. электронную или физическую подпись лица, уполномоченного
                действовать от имени владельца авторских прав;
              </p>
              <p>
                13.2. описание работы, защищенной авторским правом, которая, как
                вы утверждаете, была нарушена, включая URL (т. е. адрес
                веб-страницы) места, где существует работа, защищенная авторским
                правом, или копия работы, защищенной авторским правом;
              </p>
              <p>
                13.3. идентификация URL-адреса или другого конкретного места на
                Сервисе, где находится материал, который, по вашему мнению,
                нарушает авторские права;
              </p>
              <p>13.4. ваш адрес, номер телефона и адрес электронной почты;</p>
              <p>
                13.5. ваше заявление о том, что вы добросовестно полагаете, что
                спорное использование не разрешено владельцем авторских прав,
                его агентом или законом;
              </p>
              <p>
                13.6. заявление от вас, сделанное под страхом наказания за
                лжесвидетельство, о том, что вышеуказанная информация в вашем
                уведомлении является точной и что вы являетесь владельцем
                авторских прав или уполномочены действовать от имени владельца
                авторских прав.
              </p>
              <p>
                Вы можете связаться с нашим агентом по авторским правам по
                электронной почте info@diamond.com.
              </p>
            </span>
            <h3>
              <b>14. Сообщение об ошибках и обратная связь</b>
            </h3>
            <span>
              <p>
                Вы можете предоставлять нам напрямую по адресу info@diamond.com
                или через сторонние сайты и инструменты информацию и отзывы,
                касающиеся ошибок, предложений по улучшению, идей, проблем,
                жалоб и других вопросов, связанных с нашим Сервисом («Отзывы»).
                Вы признаете и соглашаетесь, что: (i) вы не сохраняете, не
                приобретаете и не утверждаете никаких прав интеллектуальной
                собственности или других прав, титулов или интересов в отношении
                Отзывов; (ii) у Компании могут быть идеи разработки, аналогичные
                Отзывам; (iii) Отзывы не содержат конфиденциальной информации
                или информации, являющейся собственностью вас или любой третьей
                стороны; и (iv) Компания не несет никаких обязательств по
                конфиденциальности в отношении Отзывов. В случае если передача
                права собственности на Отзывы невозможна в силу применимого
                обязательного законодательства, вы предоставляете Компании и ее
                аффилированным лицам исключительное, передаваемое, безотзывное,
                бесплатное, сублицензируемое, неограниченное и бессрочное право
                использовать (включая копирование, изменение, создание
                производных работ, публикацию, распространение и
                коммерциализацию) Отзывы любым способом и для любых целей.
              </p>
            </span>
            <h3>
              <b>15. Ссылки на другие веб-сайты</b>
            </h3>
            <span>
              <p>
                Наш Сервис может содержать ссылки на сторонние веб-сайты или
                сервисы, которые не принадлежат и не контролируются diamond.
              </p>
              <p>
                diamond не контролирует и не несет ответственности за
                содержание, политику конфиденциальности или практику
                использования веб-сайтов или служб третьих лиц. Мы не
                гарантируем предложения любых из этих организаций/физических лиц
                или их веб-сайтов.
              </p>
              <p>
                Вы признаете и соглашаетесь, что компания не несет
                ответственности, прямой или косвенной, за любой ущерб или
                убытки, вызванные или предположительно вызванные использованием
                или доверием к любому такому содержанию, товарам или услугам,
                доступным на или через любые такие сторонние веб-сайты или
                услуги.
              </p>
              <p>
                Мы настоятельно рекомендуем вам ознакомиться с условиями
                предоставления услуг и политикой конфиденциальности любых
                сторонних веб-сайтов или служб, которые вы посещаете.
              </p>
            </span>
            <h3>
              <b>16. Отказ от гарантийных обязательств</b>
            </h3>
            <span>
              <p>
                ДАННЫЕ УСЛУГИ ПРЕДОСТАВЛЯЮТСЯ КОМПАНИЕЙ НА УСЛОВИЯХ «КАК ЕСТЬ» И
                «КАК ДОСТУПНО». КОМПАНИЯ НЕ ДЕЛАЕТ НИКАКИХ ЗАЯВЛЕНИЙ ИЛИ
                ГАРАНТИЙ ЛЮБОГО РОДА, ЯВНЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ, В ОТНОШЕНИИ
                РАБОТЫ СВОИХ УСЛУГ, А ТАКЖЕ ИНФОРМАЦИИ, СОДЕРЖИМОГО ИЛИ
                МАТЕРИАЛОВ, ВКЛЮЧЕННЫХ В НИХ. ВЫ ОДНОЗНАЧНО СОГЛАШАЕТЕСЬ С ТЕМ,
                ЧТО ИСПОЛЬЗОВАНИЕ ВАМИ ЭТИХ УСЛУГ, ИХ СОДЕРЖИМОГО, А ТАКЖЕ ЛЮБЫХ
                УСЛУГ ИЛИ ПРЕДМЕТОВ, ПОЛУЧЕННЫХ ОТ НАС, ОСУЩЕСТВЛЯЕТСЯ
                ИСКЛЮЧИТЕЛЬНО НА ВАШ РИСК.
              </p>
              <p>
                НИ КОМПАНИЯ, НИ КАКОЕ-ЛИБО ЛИЦО, СВЯЗАННОЕ С КОМПАНИЕЙ, НЕ ДАЕТ
                НИКАКИХ ГАРАНТИЙ ИЛИ ЗАЯВЛЕНИЙ В ОТНОШЕНИИ ПОЛНОТЫ,
                БЕЗОПАСНОСТИ, НАДЕЖНОСТИ, КАЧЕСТВА, ТОЧНОСТИ ИЛИ ДОСТУПНОСТИ
                УСЛУГ. НЕ ОГРАНИЧИВАЯ ВЫШЕСКАЗАННОЕ, НИ КОМПАНИЯ, НИ КТО-ЛИБО,
                СВЯЗАННЫЙ С КОМПАНИЕЙ, НЕ ЗАЯВЛЯЕТ И НЕ ГАРАНТИРУЕТ, ЧТО УСЛУГИ,
                ИХ СОДЕРЖАНИЕ ИЛИ ЛЮБЫЕ УСЛУГИ ИЛИ ПРЕДМЕТЫ, ПОЛУЧЕННЫЕ С
                ПОМОЩЬЮ УСЛУГ, БУДУТ ТОЧНЫМИ, НАДЕЖНЫМИ, БЕЗОШИБОЧНЫМИ ИЛИ
                БЕСПЕРЕБОЙНЫМИ, ЧТО ДЕФЕКТЫ БУДУТ ИСПРАВЛЕНЫ, ЧТО УСЛУГИ ИЛИ
                СЕРВЕР, ОБЕСПЕЧИВАЮЩИЙ ИХ ДОСТУПНОСТЬ, НЕ СОДЕРЖАТ ВИРУСОВ ИЛИ
                ДРУГИХ ВРЕДНЫХ КОМПОНЕНТОВ, ИЛИ ЧТО УСЛУГИ ИЛИ ЛЮБЫЕ УСЛУГИ ИЛИ
                ПРЕДМЕТЫ, ПОЛУЧЕННЫЕ С ПОМОЩЬЮ УСЛУГ, БУДУТ ИНЫМ ОБРАЗОМ
                СООТВЕТСТВОВАТЬ ВАШИМ ПОТРЕБНОСТЯМ ИЛИ ОЖИДАНИЯМ.
              </p>
              <p>
                КОМПАНИЯ НАСТОЯЩИМ ОТКАЗЫВАЕТСЯ ОТ ВСЕХ ГАРАНТИЙ ЛЮБОГО РОДА,
                ЯВНЫХ ИЛИ ПОДРАЗУМЕВАЕМЫХ, УСТАНОВЛЕННЫХ ЗАКОНОМ ИЛИ ИНЫХ,
                ВКЛЮЧАЯ, В ЧАСТНОСТИ, ГАРАНТИИ ТОВАРНОГО СОСТОЯНИЯ, НЕНАРУШЕНИЯ
                ПРАВ И ПРИГОДНОСТИ ДЛЯ КОНКРЕТНОЙ ЦЕЛИ.
              </p>
              <p>
                ВЫШЕСКАЗАННОЕ НЕ ЗАТРАГИВАЕТ ЛЮБЫЕ ГАРАНТИИ, КОТОРЫЕ НЕ МОГУТ
                БЫТЬ ИСКЛЮЧЕНЫ ИЛИ ОГРАНИЧЕНЫ В СООТВЕТСТВИИ С ДЕЙСТВУЮЩИМ
                ЗАКОНОДАТЕЛЬСТВОМ.
              </p>
            </span>
            <h3>
              <b>17. Ограничение ответственности</b>
            </h3>
            <span>
              <p>
                ЗА ИСКЛЮЧЕНИЕМ СЛУЧАЕВ, ЗАПРЕЩЕННЫХ ЗАКОНОМ, ВЫ ОБЯЗУЕТЕСЬ
                ОГРАДИТЬ НАС И НАШИХ ДОЛЖНОСТНЫХ ЛИЦ, ДИРЕКТОРОВ, СОТРУДНИКОВ И
                АГЕНТОВ ОТ ЛЮБОГО КОСВЕННОГО, ШТРАФНОГО, СПЕЦИАЛЬНОГО,
                СЛУЧАЙНОГО ИЛИ КОСВЕННОГО УЩЕРБА, КАКИМ БЫ ОБРАЗОМ ОН НИ ВОЗНИК
                (ВКЛЮЧАЯ ГОНОРАРЫ АДВОКАТОВ И ВСЕ СВЯЗАННЫЕ С ЭТИМ РАСХОДЫ И
                ИЗДЕРЖКИ В ХОДЕ СУДЕБНОГО РАЗБИРАТЕЛЬСТВА И АРБИТРАЖА, В ХОДЕ
                СУДЕБНОГО РАЗБИРАТЕЛЬСТВА ИЛИ АПЕЛЛЯЦИИ, ЕСЛИ ТАКОВЫЕ ИМЕЮТСЯ,
                НЕЗАВИСИМО ОТ ТОГО, НАЧАТО ЛИ СУДЕБНОЕ РАЗБИРАТЕЛЬСТВО ИЛИ
                АРБИТРАЖ), БУДЬ ТО ДОГОВОРНОЙ ИСК, ХАЛАТНОСТЬ ИЛИ ДРУГОЕ
                ДЕЛИКТНОЕ ДЕЙСТВИЕ, ИЛИ ВОЗНИКАЮЩИЕ ИЗ ИЛИ В СВЯЗИ С НАСТОЯЩИМ
                СОГЛАШЕНИЕМ, ВКЛЮЧАЯ, БЕЗ ОГРАНИЧЕНИЙ, ЛЮБЫЕ ПРЕТЕНЗИИ ПО
                ТРАВМАМ ИЛИ ИМУЩЕСТВЕННОМУ УЩЕРБУ, ВЫТЕКАЮЩИЕ ИЗ НАСТОЯЩЕГО
                СОГЛАШЕНИЯ И ЛЮБОГО НАРУШЕНИЯ ВАМИ ЛЮБЫХ ФЕДЕРАЛЬНЫХ,
                ГОСУДАРСТВЕННЫХ ИЛИ МЕСТНЫХ ЗАКОНОВ, УСТАВОВ, ПРАВИЛ ИЛИ
                ПОЛОЖЕНИЙ, ДАЖЕ ЕСЛИ КОМПАНИЯ БЫЛА ПРЕДВАРИТЕЛЬНО УВЕДОМЛЕНА О
                ВОЗМОЖНОСТИ ТАКОГО УЩЕРБА. ЗА ИСКЛЮЧЕНИЕМ СЛУЧАЕВ, ЗАПРЕЩЕННЫХ
                ЗАКОНОМ, ЕСЛИ БУДЕТ УСТАНОВЛЕНА ОТВЕТСТВЕННОСТЬ КОМПАНИИ, ОНА
                БУДЕТ ОГРАНИЧЕНА СУММОЙ, УПЛАЧЕННОЙ ЗА ПРОДУКТЫ И/ИЛИ УСЛУГИ, И
                НИ ПРИ КАКИХ ОБСТОЯТЕЛЬСТВАХ НЕ БУДЕТ ИМЕТЬ МЕСТО КОСВЕННЫЙ ИЛИ
                ШТРАФНОЙ УЩЕРБ. В НЕКОТОРЫХ ШТАТАХ НЕ ДОПУСКАЕТСЯ ИСКЛЮЧЕНИЕ ИЛИ
                ОГРАНИЧЕНИЕ ШТРАФНЫХ, СЛУЧАЙНЫХ ИЛИ КОСВЕННЫХ УБЫТКОВ, ПОЭТОМУ
                ПРЕДЫДУЩЕЕ ОГРАНИЧЕНИЕ ИЛИ ИСКЛЮЧЕНИЕ МОЖЕТ К ВАМ НЕ ОТНОСИТЬСЯ.
              </p>
            </span>
            <h3>
              <b>18. Прекращение</b>
            </h3>
            <span>
              <p>
                Мы можем прекратить или приостановить действие вашей учетной
                записи и запретить доступ к Сервису немедленно, без
                предварительного уведомления или ответственности, по нашему
                единоличному усмотрению, по любой причине и без ограничений,
                включая, но не ограничиваясь нарушением Условий.
              </p>
              <p>
                Если вы хотите прекратить действие вашей учетной записи, вы
                можете просто прекратить использование Сервиса по окончании
                расчетов с нами или уведомить нас по электронной почте с
                пометкой «Удалить».
              </p>
              <p>
                Все положения Условий, которые по своей природе должны сохранять
                силу после прекращения действия, сохраняют силу после
                прекращения действия, включая, без ограничений, положения о
                праве собственности, отказы от гарантий, возмещение убытков и
                ограничения ответственности.
              </p>
            </span>
            <h3>
              <b>19. Регулирующее законодательство</b>
            </h3>
            <span>
              <p>
                Настоящие Условия регулируются и толкуются в соответствии с
                законодательством, которое применяется к соглашению без учета
                его коллизионных норм.
              </p>
              <p>
                Неприменение нами каких-либо прав или положений настоящих
                Условий не будет считаться отказом от этих прав. Если какое-либо
                положение настоящих Условий будет признано судом
                недействительным или не имеющим исковой силы, остальные
                положения настоящих Условий останутся в силе. Настоящие Условия
                представляют собой полное соглашение между нами в отношении
                нашего Сервиса и заменяют собой любые предыдущие соглашения,
                которые могли быть заключены между нами в отношении Сервиса.
              </p>
            </span>
            <h3>
              <b>20. Изменения в Сервисе</b>
            </h3>
            <span>
              <p>
                Мы оставляем за собой право отзывать или изменять наш Сервис, а
                также любые услуги или материалы, которые мы предоставляем через
                Сервис, по нашему собственному усмотрению без уведомления. Мы не
                несем ответственности, если по какой-либо причине весь Сервис
                или его часть будет недоступна в любое время или в течение
                любого периода. Время от времени мы можем ограничивать доступ к
                некоторым частям Сервиса или ко всему Сервису для пользователей,
                включая зарегистрированных пользователей.
              </p>
            </span>
            <h3>
              <b>21. Поправки к Условиям</b>
            </h3>
            <span>
              <p>
                Мы можем вносить изменения в Условия в любое время, публикуя
                измененные условия на этом сайте. Вы обязаны периодически
                просматривать эти Условия.
              </p>
              <p>
                Ваше дальнейшее использование Платформы после публикации
                пересмотренных Условий означает, что вы принимаете и
                соглашаетесь с изменениями. Вы должны часто проверять эту
                страницу, чтобы быть в курсе любых изменений, поскольку они
                являются обязательными для вас.
              </p>
              <p>
                Продолжая получать доступ или использовать наш Сервис после
                вступления в силу любых изменений, вы соглашаетесь с
                пересмотренными условиями. Если вы не согласны с новыми
                условиями, вы больше не имеете права пользоваться Сервисом.
              </p>
            </span>
            <h3>
              <b>22. Отказ от прав и делимость</b>
            </h3>
            <span>
              <p>
                Никакой отказ Компании от какого-либо условия, изложенного в
                Условиях, не должен рассматриваться как дальнейший или
                продолжающийся отказ от такого условия или отказ от любого
                другого условия или условия, и любой отказ Компании от
                отстаивания права или положения, предусмотренного Условиями, не
                будет означать отказ от такого права или положения.
              </p>
              <p>
                Если какое-либо положение Условий будет признано судом или иным
                органом компетентной юрисдикции недействительным, незаконным или
                не имеющим исковой силы по какой-либо причине, такое положение
                будет отменено или ограничено в минимальном объеме, при котором
                остальные положения Условий будут продолжать действовать в
                полной мере.
              </p>
            </span>
            <h3>
              <b>23. Подтверждение</b>
            </h3>
            <span>
              <p>
                ИСПОЛЬЗУЯ СЕРВИС ИЛИ ДРУГИЕ УСЛУГИ, ПРЕДОСТАВЛЯЕМЫЕ НАМИ, ВЫ
                ПОДТВЕРЖДАЕТЕ, ЧТО ОЗНАКОМИЛИСЬ С НАСТОЯЩИМИ УСЛОВИЯМИ
                ПРЕДОСТАВЛЕНИЯ УСЛУГ И СОГЛАСНЫ ИХ СОБЛЮДАТЬ.
              </p>
            </span>
            <h3>
              <b>24. Связаться с нами</b>
            </h3>
            <span>
              <p>
                Пожалуйста, присылайте свои отзывы, комментарии, запросы на
                техническую поддержку по электронной почте: info@diamond.com.
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default Terms;
