import React, { useEffect, useState } from "react";
import "../../../Style/IncomeTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/system";
import { TablePagination, Typography } from "@mui/material";

function IncomeTable() {
  const [tablePrimari, setTablePrimari] = useState([]);
  const [tablePrimariAccumulative, setTablePrimariAccumulative] = useState([]);
  const [tableBonus, setTableBonus] = useState([]);
  const [tableBonusAccumulative, setTableBonusAccumulative] = useState([]);
  const [table, setTable] = useState([]);
  const { t, i18n } = useTranslation();
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(0);
  const pageSize = 5;

  useEffect(() => {
    handleTable();
  }, [page]);

  const handleTable = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG) + `/income`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        params: {
          size: 5,
          page: page,
        },
      });
      setTable(response.data.content);
      setPagination(response.data.totalElements);

    } catch (error) {
      console.error(error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const StyledTableHead = styled(TableHead)({
    background: "#F7F9FC",
    border: "none",
    "& th:first-of-type": {
      borderTopLeftRadius: "10px",
    },
    "& th:last-of-type": {
      borderTopRightRadius: "10px",
    },
  });

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  return (
    <div className="wrapperIncomeTableMain">
      <div className="incomeTableTitle">{t("MainPageIncomeTableTitle")}</div>
      <TableContainer
        style={{ boxShadow: "none" }}
        component={Paper}
        className="tableContainer"
      >
        <Table
        //  sx={{ minWidth: "650px" }} 
         aria-label="simple table">
          <TableHead
            style={{ background: "#F7F9FC", border: "1px solid white" }}
          >
            <TableRow style={{ border: "none" }}>
            <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }}
                align="left"
              >
                <b>{t("BillsHistorySearchAdvancedCurrency")}</b>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }}
              >
                <b>{t("MainPageIncomeTableFirstColumn")}</b>
              </TableCell>
     
              <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }}
                align="left"
              >
                <b>{t("MainPageIncomeTableSecondColumn")}</b>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }}
                align="left"
              >
                <b>{t("MainPageIncomeTableThirdColumn")}</b>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                  height: "30px",
                }}
                align="left"
              >
                <b>{t("MainPageIncomeTableFourthColumn")}</b>
              </TableCell>
              <TableCell
                style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                  height: "30px",
                }}
                align="left"
              >
                <b>{t("MainPageIncomeTableFourthColumnType")}</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table.length > 0 && table.map((row) => (
              <TableRow
                key={row.is}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                         <TableCell
                  style={{
                    paddingLeft: "16px",
                    color: "#10B981",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    whiteSpace:'nowrap',
                  }}
                  align="left"
                >
                  {row.currency}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    whiteSpace:'nowrap',

                  }}
                  component="th"
                  scope="row"
                >
                  {formatDate(row.incomeDateTime)}
                </TableCell>
       
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    color: "#10B981",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    whiteSpace:'nowrap',
                  }}
                  align="left"
                >
                  {row.incomeAmount}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    whiteSpace:'nowrap',
                  }}
                  align="left"
                >
                  {formatDate(row.contributionDateTime)}
                </TableCell>
    
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    color: "#259AE6",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    whiteSpace:'nowrap',

                  }}
                  align="left"
                >
                  {row.contributionAmount}
                </TableCell>
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    color: "#259AE6",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                  align="left"
                >
                  {row.transactionType}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={pagination}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </div>
    
  );
}

export default IncomeTable;
