import React, { useEffect, useState } from "react";
import TelegramIcon from '@mui/icons-material/Telegram';
import '../../../Style/Profile/Telegram.scss'
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';


function Telegram () {

    const { t, i18n } = useTranslation();



    return(
        <PageWrapper>
        <div className="wrapperTelegram" style={{padding:'20px'}}>
            <div className="wrapperIcon" style={{display:"flex"}}>
            <TelegramIcon style={{display: 'flex', alignItems:'center'}}/>
            <div className="iconText" style={{marginLeft:'5px', display:"flex", alignItems:'center'}}>
                Telegram-bot
            </div>
            </div>
            <div style={{}}>
            <p>
                {t('ProfileTelegramAttantion')}
            </p>
            <div style={{marginBottom:'10px'}}>1. {t('ProfileTelegramAttantionFirst')};</div>
            <div style={{marginBottom:'10px'}}>2. {t('ProfileTelegramAttantionSecond')};</div>
            <div style={{marginBottom:'10px'}}>3. {t('ProfileTelegramAttantionThird')}</div>
            </div>
            <div className="saveButtonTelegram" >
                {t('ProfileTelegramButton')}
        </div>
        </div>
        </PageWrapper>
    )
}

export default Telegram