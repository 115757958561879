import React, { useEffect, useState } from "react";
import '../../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';
import Link from "@mui/material/Link";

function ConclusionTitle () {
    const { t, i18n } = useTranslation();


    return(
        <div className="nameWrapper" style={{margin: '0', display:'flex', justifyContent:'space-between', alignItems:'flex-end'}}>
        <span className="nameText"> 
          {t('BillsWithdrawalTitle')}
        </span>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link color="text.secondary" href="./mainpage/bills">
            {t('BillsRefillButtonExit')}
          </Link>
        </div>
        </div>
    )
}

export default ConclusionTitle