import React from "react";
import Header from "../MainPage/Header/Header";
import IndexBody from "./ProfileBody";
import '../../Style/Profile/IndexProfile.scss'
import ProfileTitle from "./ProfileTitle";
import Footer from "../MainPage/Footer/Footer";


function IndexProfileContainer ({theme,isSmallScreen}) {

    return(
        <div className="profileWrapper">
        <ProfileTitle/>
        <IndexBody/>
        </div>


    )

}


export default IndexProfileContainer