import React from "react";
import Typography from '@mui/material/Typography';
import '../../Style/Bills/BillsStatic.scss'
import PaymentsIcon from '@mui/icons-material/Payments';
import { blue, green, pink, purple } from "@mui/material/colors";


function BillsStatic () {
    return (
        // <div className="wrapperStaticBill">
        <div className="billsCardTable" >
      <Typography component="p" variant="h6">
        Статистика по счетам
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
      <div className="myAccountPortfolioBill">
                    <div className="imgPortfolioWrapperBill" style={{alignItems: 'flex-start'}}>
                    <PaymentsIcon sx={{ color: blue[500] }}/>
                    </div>
                    <div className="portfolioDescriptionBill">
                    Всего на счетах
                    <b>€</b>
                    </div>
                </div>
                <div className="myAccountPortfolioBill">
                    <div className="imgPortfolioWrapperBill" style={{alignItems: 'flex-start'}}>
                    <PaymentsIcon sx={{ color: green[500] }}/>
                    </div>
                    <div className="portfolioDescriptionBill">
                    Общая сумма поступления
                    <b>€</b>
                    </div>
                </div>
                <div className="myAccountPortfolioBill">
                    <div className="imgPortfolioWrapperBill" style={{alignItems: 'flex-start'}}>
                    <PaymentsIcon sx={{ color: pink[500] }}/>
                    </div>
                    <div className="portfolioDescriptionBill">
                    Общая сумма списаний
                    <b>€</b>
                    </div>
                </div>
                <div className="myAccountPortfolioBill">
                    <div className="imgPortfolioWrapperBill" style={{alignItems: 'flex-start'}}>
                    <PaymentsIcon sx={{ color: purple[500] }}/>
                    </div>
                    <div className="portfolioDescriptionBill">
                    Всего заморожено
                    <b>€0.00</b>
                    </div>
                </div>
      </Typography>

      </div>
        // </div>

    )
}

export default BillsStatic