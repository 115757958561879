import React, {useEffect, useState} from "react";
import "../../../Style/TransactionTable.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import Cookies from "js-cookie";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/system";
import {TablePagination, Typography} from "@mui/material";
import {ReactComponent as Agreement} from "../../../images/incomeTable/Agreement.svg"

function Transactions() {
    const cookie = Cookies.get("Access_Cookie");
    const [tablePrimari, setTablePrimari] = useState([]);
    const [tablePrimariAccumulative, setTablePrimariAccumulative] = useState([]);
    const [tableBonus, setTableBonus] = useState([]);
    const [tableBonusAccumulative, setTableBonusAccumulative] = useState([]);
    const [table, setTable] = useState([]);
    const {t, i18n} = useTranslation();
    const [transactions, setTransactions] = useState([])
    const [pagination, setPagination] = useState();
    const [page, setPage] = useState(0);
    const pageSize = 5;
    const [agreementFile, setAgreementFile] = useState()

    useEffect(() => {
        handleTable();
    }, []);

    const handleTable = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },

            });

            setTable(response.data);

            response.data.forEach((item) => {
                if (item.accountType === "PRIMARY") {
                    setTablePrimari(item);
                } else if (item.accountType === "PRIMARY_ACCUMULATIVE") {
                    setTablePrimariAccumulative(item);
                } else if (item.accountType === "BONUS") {
                    setTableBonus(item);
                } else if (item.accountType === "BONUS_ACCUMULATIVE") {
                    setTableBonusAccumulative(item);
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    function createData(name, amount, income, date) {
        return {name, amount, income, date};
    }

    const StyledTableHead = styled(TableHead)({
        background: "#f6f6f8",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        "& th:first-of-type": {
            borderTopLeftRadius: "10px",
        },
        "& th:last-of-type": {
            borderTopRightRadius: "10px",
        },
    });

    useEffect(() => {
        handleGetTransactions()
    }, [page])

    const handleGetTransactions = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_PROJECT_LOG) + `/contributions`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
                params: {
                    size: 5,
                    page: page,
                },
            })
            setTransactions(response.data.content)
            setPagination(response.data.totalElements);
        } catch (error) {
            console.error(error)
        }
    }

    //Форматирование даты

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const handleChangePage = (event, value) => {
        setPage(value);
    };

    const handleGetAgreement = async (id) => {
        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            const file = new Blob([response.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            // console.log(file)
            window.open(fileURL);
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    return (
        <div className="wrapperIncomeTableTransaction">
            <div className="incomeTableTitleTransaction">
                {t("MainPageTransactionsYourTransactions")}: {/*USD{transactions.usdContributions} /
                EUR {transactions.eurContributions} / USDT {transactions.usdtContributions}*/}
            </div>
            <TableContainer component={Paper} style={{boxShadow: "none"}}>
                <Table
                    aria-label="simple table">
                    <TableHead
                        style={{background: "#F7F9FC", border: "1px solid white"}}
                    >
                        <TableRow style={{border: "none"}}>
                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                            >
                                <b>{t("MainPageIncomeTableThirdColumn")}</b>
                            </TableCell>
                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                            >
                                <b>{t("MainPageTransactionsFirstColumn")}</b>
                            </TableCell>
                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                            >
                                <b>{t("MainPageTransactionsDoc")}</b>
                            </TableCell>
                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                                align="left"
                            >
                                <b>{t("BillsHistorySearchAdvancedCurrency")}</b>
                            </TableCell>
                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                                align="left"
                            >
                                <b>{t("MainPageTransactionsSecondColumn")}</b>
                            </TableCell>

                            <TableCell
                                style={{
                                    border: "none",
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "#64748B",
                                }}
                                align="left"
                            >
                                <b>{t("MainPageTransactionsFourthColumn")} </b>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transactions.length > 0 && transactions.map((row) => (
                            <TableRow
                                key={row.is}
                                sx={{"&:last-child td, &:last-child th": {border: 0}}}
                            >
                                <TableCell
                                    style={{
                                        paddingLeft: "16px",
                                        padding: "25px",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    component="th"
                                    scope="row"
                                >
                                    {formatDate(row.contributionDateTime)}
                                </TableCell>
                                <TableCell
                                    style={{
                                        paddingLeft: "16px",
                                        padding: "25px",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    component="th"
                                    scope="row"
                                >
                                    {row.projectName}
                                </TableCell>
                                <TableCell
                                    style={{
                                        paddingLeft: "16px",
                                        padding: "25px",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    component="th"
                                    scope="row"
                                >
                                    <Agreement
                                        style={{
                                            width: '25px',
                                            height: '25px'
                                        }}
                                        onClick={() => handleGetAgreement(row.templateUrlId)}
                                    />


                                </TableCell>
                                <TableCell
                                    style={{
                                        color: "#10B981",
                                        paddingLeft: "16px",
                                        padding: "25px",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    align="left"
                                >
                                    {row.currency}
                                </TableCell>
                                <TableCell
                                    style={{
                                        color: "#10B981",

                                        paddingLeft: "16px",
                                        padding: "25px",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        lineHeight: "24px",
                                        textAlign: "left",
                                    }}
                                    align="left"
                                >
                                    {row.amount}
                                </TableCell>
                                {
                                    row.isActive ?
                                        <TableCell
                                            style={{
                                                color: "#259AE6",

                                                paddingLeft: "16px",
                                                padding: "25px",
                                                fontFamily: "Helvetica Neue",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "24px",
                                                textAlign: "left",
                                            }}
                                            align="left"
                                        >
                                            {row.projectExpirationDate}
                                        </TableCell>
                                        :
                                        <TableCell
                                            style={{
                                                color: "red",

                                                paddingLeft: "16px",
                                                padding: "25px",
                                                fontFamily: "Helvetica Neue",
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "24px",
                                                textAlign: "left",
                                            }}
                                            align="left"
                                        >
                                            {t('MainPageTransactionTableEnd')}
                                        </TableCell>
                                }

                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={false}
                    component="div"
                    count={pagination}
                    rowsPerPage={pageSize}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </TableContainer>
        </div>
    );
}

export default Transactions;
