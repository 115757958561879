import React from "react";
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";

function LandingBody ({language}) {
    const style = {
        width: '100%',
        height: '70vh',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
    const styleButton = {
        background: 'none',
        letterSpacing: '2px',
        fontSize: '17px'
    }
    return(
        <div style={style}>
            <Link to="/registration" style={styleButton}>
                <Button style={styleButton} variant="contained">
                {language === "Русский"
                      ? "Зарегистрироваться"
                      : "Registration"}
                    </Button>
            </Link>
        </div>
    )
}

export default LandingBody