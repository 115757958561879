import React, { useEffect, useState } from "react";

import '../../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
function ProjectName () {
  const { t, i18n } = useTranslation();

  const [age, setAge] = React.useState('');


  const handleChange = (event) => {
    setAge(event.target.value);
  };
    return(
        <div className="nameWrapper" style={{marginBottom:'15px', display:'flex', justifyContent:'space-between'}}>
        <span className="nameText"> 
           {t('MainPageProjectTitle')}
        </span>
           <FormControl style={{width:'200px', marginRight:'70px', marginLeft:'10px'}} >
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={age}
          onChange={handleChange}
        >
          <MenuItem value={''}> {t('MainPageDropDownAll')} </MenuItem>
          <MenuItem value={'opend'}> {t('MainPageDropDownOpened')} </MenuItem>
          <MenuItem value={'paid'}> {t('MainPageDropDownPaid')} </MenuItem>
          <MenuItem value={'collected'}> {t('MainPageDropDownCollected')} </MenuItem>
        </Select>
      </FormControl>
        </div>
    )
}

export default ProjectName