// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperIncomeTableTransaction {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  margin: 20px auto 20px;
  padding: 20px;
  box-sizing: border-box;
}

.incomeTableTitleTransaction {
  font-size: 20px;
  margin-left: 2px;
  font-family: HelveticaNeueCyr;
  font-size: 20px;
  font-weight: 550;
  line-height: 26px;
  text-align: left;
}

.incomeTableTitleTransactionSecond {
  font-size: 16px;
  margin-left: 2px;
  margin-bottom: 20px;
  font-family: HelveticaNeueCyr;
  font-weight: 550;
  line-height: 26px;
  text-align: left;
  color: gray;
}`, "",{"version":3,"sources":["webpack://./src/Style/TransactionTable.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;EACA,WAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,6BAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;AACF","sourcesContent":[".wrapperIncomeTableTransaction {\n  background-color: white;\n  border-radius: 8px;\n  width: 100%;\n  margin: 20px auto 20px;\n  padding: 20px;\n  box-sizing: border-box;\n}\n\n.incomeTableTitleTransaction {\n  font-size: 20px;\n  margin-left: 2px;\n  font-family: HelveticaNeueCyr;\n  font-size: 20px;\n  font-weight: 550;\n  line-height: 26px;\n  text-align: left;\n}\n\n.incomeTableTitleTransactionSecond {\n  font-size: 16px;\n  margin-left: 2px;\n  margin-bottom: 20px;\n  font-family: HelveticaNeueCyr;\n  font-weight: 550;\n  line-height: 26px;\n  text-align: left;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
