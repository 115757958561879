import React from "react";
import Header from "./Header/HeaderPartner";
import Footer from "../MainPage/Footer/Footer";
import { Outlet } from "react-router-dom";
import PartnerTitle from "./partnersTitle";
import '../../Style/Partners/WrapperPartners.scss'
import '../../Style/MainPageContainer.scss'
const style = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  // background: "#DCDCDC",
  background: '#eef1fa'

};

function PartnersCabinetContainer({ isSmallScreen, theme }) {
  return (
    <div style={style} className="mainPageContainer">
      <Header isSmallScreen={isSmallScreen} theme={theme} />
        <Outlet />

      <Footer />
    </div>
  );
}

export default PartnersCabinetContainer;
