import React, { useEffect, useState } from "react";
import ConclusionTitle from "./ConclusionTitle";
import WarningIcon from "@mui/icons-material/Warning";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import "../../../Style/Profile/BasicData.scss";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useTranslation } from 'react-i18next';

function Conclusion() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const handleExit = () => {
    navigate("/mainpage/bills");
  };



  const verification = [
    {
      value: "ver1",
      label: "TRC 20",
    },
    {
      value: "ver2",
      label: "BEP 20",
    },
  ];

  return (
    <div className="replenishmentWrapperConclusion">
      <ConclusionTitle />
      <div className="replenishmentContainer">
     
        <div
          className="attentionWrapperYellow"
          style={{
            marginBottom: "50px",
            marginTop: "50px",
            marginLeft: "15px",
          }}
        >
          <div className="wrapperIconAttention">
            <WarningIcon
              style={{
                color: "#FFA500",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
          </div>
          <div className="attentionText">
            <span>
              <b> {t('BillsRefillAttantionImportantInfoTitle')} </b>

              <p>
               {t('BillsRefillAttantionImportantInfoText')}
              </p>
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextField
            autoComplete="off"
            sx={{ marginTop: "10px", width: "50%" }}
            id="outlined-basic"
            label={t('BillsWithdrawalSum')}
            variant="outlined"
            InputLabelProps={{ style: {  fontSize: "14px" } }}
          />
          <TextField
            autoComplete="off"
            sx={{ marginTop: "10px", width: "50%" }}
            id="outlined-select-currency"
            select
            label={t('BillsWithdrawalNetwork')}
            defaultValue="ver1"
            InputProps={{ style: { fontSize: "14px" } }}
          >
            {verification.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            autoComplete="off"
            sx={{ marginTop: "10px", width: "50%" }}
            id="outlined-basic"
            label={t('BillsWithdrawalNumber')}
            variant="outlined"
            InputLabelProps={{ style: { fontSize: "14px" } }}
          />
          <div
            style={{
              width: "50%",
              // height: "70px",
              background: "#f3f3f3",
              marginTop: "8px",
              borderRadius: "10px",
            }}
          >
            <p style={{ marginLeft: "20px", fontSize: "14px" }}> {t('BillsWithdrawalTotalCount')} </p>
            <div style={{marginBottom:'15px' ,marginLeft: "20px", fontSize: "15px" }}>€ 0.00</div>
          </div>
          <div style={{ width: "50%" }}>
            <p style={{ marginLeft: "20px", fontSize: "12px" }}>
              {t('BillsWithdrawalCommission')}
            </p>
          </div>

          <div style={{ width: "50%" }}>
            <p> {t('BillsWithdrawalAttantionPassword')} </p>
            <TextField
              autoComplete="off"
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label= {t('BillsWithdrawalPassword')}
              variant="outlined"
              InputProps={{ style: { margin: "0px" } }}
              InputLabelProps={{ style: {  fontSize: "14px" } }}
            />
          </div>
        </div>
        <div
          className="buttonPay"
          style={{maxWidth:'200px' ,margin: "20px auto" }}
          onClick={() => handleExit()}
        >
          {t('BillsWithdrawalButton')}
        </div>
      </div>
    </div>
  );
}

export default Conclusion;
