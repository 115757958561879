import React from "react";
import "../Style/landing/Policy.scss";

function PolicyEN() {
  return (
    <>
      <div className="policyWrapper">
        <div className="headerPolicyWrapper">
          <div className="policyText">PRIVACY POLICY</div>
        </div>
        <div className="policyBodyWrapper">
          <div className="policyBodyText">
            <p>
              <b>
                This Privacy Policy applies to all data and information that you
                as a User leave in the course of using and interacting with the
                site https://test.diamond.balinasoft.com/ (hereinafter referred
                to as the Site) and regulates the order of processing (i.e.
                collection, storage, use, systematization, etc.) of information
                obtained about you as a User of the Site.
              </b>
            </p>
            <p>
              <b>
                This Policy does not apply in cases where the User follows
                Internet links that may be available on the Site.
              </b>
            </p>
            <p>
              <b>
                Any interaction with the Site (for example: browsing,
                registration, use of services, acceptance of offers) means that
                you have unconditionally agreed to this document and the terms
                and conditions specified herein.
              </b>
            </p>
            <p>
              <b>
                In case of disagreement with these terms and conditions, you
                must refrain from interacting with the Site, using the services,
                as well as stop the process of registration on the Site.
              </b>
            </p>
            <p>
              <b>
                If you have any questions regarding the terms and conditions of
                information processing and your data, please contact the support
                service via a special service on the Site or by e-mail at
                support@diamond.com.
              </b>
            </p>
            <h3>
              <b>I. General provisions</b>
            </h3>
            <span>
              <p>
                1.1 This policy of personal data processing is made in
                accordance with the requirements of the Law on personal data and
                determines the order of personal data processing and measures to
                ensure the security of personal data, undertaken by Diamond
                (hereinafter - the Operator).
              </p>
              <p>
                1.2 The Operator sets as its most important goal and condition
                for the implementation of its activities the observance of human
                and civil rights and freedoms in the processing of personal
                data, including the protection of the right to privacy, personal
                and family secrecy.
              </p>
              <p>
                1.3 This privacy policy regarding the processing of personal
                data (hereinafter - the Policy) applies to all information that
                the Operator may obtain about visitors and Users of the website
                https://test.diamond.balinasoft.com
              </p>
            </span>
            <h3>
              <b>II. Basic concepts used in the Policy</b>
            </h3>
            <span>
              <p>
                2.1 Automated data processing - processing of personal data and
                other information by means of computer equipment.
              </p>
              <p>
                2.2 Data blocking - temporary cessation of information and
                personal data processing (except for cases when processing is
                necessary to clarify personal data).
              </p>
              <p>
                2.3 Website - a set of graphical and informational materials, as
                well as computer programs and databases, ensuring their
                availability on the Internet at
                https://test.diamond.balinasoft.com/ .
              </p>
              <p>
                2.4. Data information system - a set of personal data and
                information contained in databases, and information technologies
                and technical means ensuring their processing.
              </p>
              <p>
                2.5. Personal data depersonalization - actions, as a result of
                which it is impossible to determine without using additional
                information whether personal data belong to a particular User or
                other subject of personal data.
              </p>
              <p>
                2.6 Processing of personal data - any action (operation) or set
                of actions (operations), performed with the use of automation or
                without the use of such means, with personal data, including
                collection, recording, systematization, accumulation, storage,
                clarification (update, change), extraction, use, transfer
                (distribution, provision, access), depersonalization, blocking,
                deletion, destruction of personal data.
              </p>
              <p>2.7 Operator - Diamond</p>
              <p>2.8 User - any visitor of the Site.</p>
              <p>
                2.9 Personal Data - any information relating directly or
                indirectly to a certain User of the Site and allowing to
                identify him/her.
              </p>
              <p>
                2.10. Personal data authorized by the subject of personal data
                for dissemination - personal data, access to which is provided
                by the subject of personal data to an unlimited number of
                persons by giving consent to the processing of personal data
                authorized by the subject of personal data for dissemination in
                the manner prescribed by the Law on Personal Data (hereinafter -
                personal data authorized for dissemination).
              </p>
              <p>
                2.11. Provision of personal data - actions aimed at disclosure
                of personal data to a certain person or a certain circle of
                persons.
              </p>
              <p>
                2.12. Dissemination of personal data - any actions aimed at
                disclosure of personal data to an indefinite number of persons
                (transfer of personal data) or familiarization of personal data
                to an unlimited number of persons, including disclosure of
                personal data in mass media, placement in information and
                telecommunication networks or providing access to personal data
                in any other way.
              </p>
              <p>
                2.13. Cross-border transfer of personal data - transfer of
                personal data to the territory of a foreign country to a foreign
                government authority, a foreign individual or a foreign legal
                entity.
              </p>
              <p>
                2.14. Destruction of personal data - any actions, as a result of
                which personal data are irretrievably destroyed with the
                impossibility of further recovery of the content of personal
                data in the information system of personal data and (or)
                material carriers of personal data are destroyed.
              </p>
            </span>
            <h3>
              <b>III. Main rights and obligations of the Operator</b>
            </h3>
            <span>
              <p>3.1 The Operator has the right to:</p>
              <br /> - to receive from the User reliable information and/or
              documents containing personal data and other information;
              <br />- in case the User revokes his/her consent to personal data
              processing, the Operator has the right to continue processing
              personal data without the User's consent if there are grounds
              specified in the Personal Data Law;
              <br />- independently determine the composition and list of
              measures necessary and sufficient to ensure the fulfillment of
              obligations stipulated by the Personal Data Law and regulatory
              legal acts adopted in accordance with it, unless otherwise
              provided by the Personal Data Law or other federal laws.
              <p>3.2 The Operator is obliged to:</p>
              - provide the User at his/her request with information regarding
              the processing of his/her personal data and other information
              about him/her;
              <br />- organize the processing of information and personal data
              in accordance with the procedure established by the current
              international legislation;
              <br />- respond to the User's appeals and requests in accordance
              with the requirements of the Law on Personal Data;
              <br />- to inform the authorized body for the protection of the
              rights of personal data subjects at the request of this body the
              necessary information within 30 days from the date of receipt of
              such a request;
              <br />- to publish or otherwise provide unrestricted access to
              this Policy on personal data processing;
              <br />- take legal, organizational and technical measures to
              protect personal data and other information from unlawful or
              accidental access to them, destruction, modification, blocking,
              copying, provision, dissemination of personal data, as well as
              from other unlawful actions with regard to personal data;
              <br />- cease transfer (dissemination, provision, access) of
              personal data, stop processing and destroy personal data in the
              manner and cases stipulated by the Personal Data Law;
              <br />- fulfill other obligations stipulated by the Personal Data
              Law.
            </span>
            <h3>
              <b>IV. Basic rights and obligations of the User</b>
            </h3>
            <span>
              <p>4.1 The User has the right to:</p>
              - to receive information regarding the processing of his/her
              personal data, except in cases provided for by federal laws.
              Information shall be provided to the subject of personal data by
              the Operator in an accessible form and shall not contain personal
              data relating to other subjects of personal data, except in cases
              where there are legal grounds for disclosure of such personal
              data. Each User may obtain such information by contacting the
              support service via a special service on the Website or by e-mail
              at support@diamond.com;
              <br />- demand from the operator to clarify his/her personal data,
              block or destroy it if the personal data is incomplete, outdated,
              inaccurate, illegally obtained or not necessary for the stated
              purpose of processing, as well as to take measures provided by law
              to protect his/her rights. All requests should be sent by e-mail
              to support@diamond.com;
              <br />- to impose a condition of prior consent when processing
              personal data in order to market goods, works and services;
              <br />- to withdraw consent to the processing of personal data.
              Withdrawal of consent should be sent by e-mail support@diamond.com
              in a free form;
              <br />- to appeal to the authorized body for the protection of the
              rights of personal data subjects or in court against unlawful acts
              or omissions of the Operator in the processing of personal data;
              <br />- to exercise other rights provided for by international
              legislation.
              <p>4.2 The User is obliged to:</p>
              <br />- provide the Operator with reliable data about
              himself/herself;
              <br />- notify the Operator about clarification (update, change)
              of his/her personal data.
              <p>
                4.3 Users who have provided the Operator with unreliable
                information about themselves or information about another
                subject of personal data without the consent of the latter shall
                be liable in accordance with the international legislation, as
                well as in accordance with the legislation of the country of
                their residence.
              </p>
            </span>
            <h3>
              <b>V. Information about the User that may be processed</b>
            </h3>
            <span>
              <p>
                5.1 The Operator may process the following information about the
                User:
              </p>
              - Surname, first name, patronymic.
              <br />- E-mail address, phone number, username in various
              messengers.
              <br />- Year, month, date and place of birth.
              <br />- Photographs.
              <br />- Information about citizenship, residence permit, etc.
              <br />- Details of the identity document.
              <br />- Other information about the User, which he provided on the
              Site or via e-mail support@diamond.com.
              <br />- Data that are automatically transmitted in the process of
              using the Site using the software installed on the User's device,
              including such data may be data on the use of services, data on
              viewing messages, date and time of access to services, and other
              similar information.
              <br />- Data and information transferred by the User when
              contacting the support service.
              <br />- Data and information transmitted by the User by e-mail to
              the Operator.
              <p>
                5.2 The site also collects and processes anonymized data about
                visitors (including cookies) using Internet statistics services.
              </p>
              <p>
                5.3 The Operator does not process special categories of personal
                data concerning race, nationality, political views, religious or
                philosophical beliefs, intimate life.
              </p>
              <p>
                5.4 The processing of personal data, authorized for
                dissemination, from among the special categories of personal
                data specified in Articles 9,10 of the Personal Data Law, is
                allowed if the prohibitions and conditions stipulated in
                Articles 9,10 of the Personal Data Law are complied with.
              </p>
              <p>
                5.5 The Operator reserves the right to verify the accuracy of
                the information provided by the User, as well as to assess
                his/her legal capacity. In this case, the Operator assumes that
                the User provides reliable and sufficient information about
                himself/herself and keeps this information up to date. In case
                of revealing the facts of providing false information by the
                User, the Operator has the right to block the User and limit
                his/her access to the Website.
              </p>
            </span>
            <h3>
              <b>VI. Rules and principles of personal data processing</b>
            </h3>
            <span>
              <p>
                6.1 The Operator collects and stores only the information that
                is necessary for the User to visit the Website, use its services
                and receive services.
              </p>
              <p>
                6.2 The User's information is processed for the following
                purposes:
              </p>
              (a) Identification of the User in the process of using the
              Site/receiving services.
              <br />
              (b) Provision of personalized services and services to the User,
              as well as conclusion of agreements and contracts.
              <br />
              (c) Communication with the User, including sending notifications,
              requests and information related to the use of the Site and
              receipt of services.
              <br />
              (d) Improving the quality of services, receiving feedback from
              Users, increasing the usability of the Site, developing new
              products and services.
              <br />
              (e) Conducting statistical and other research.
              <br />
              (f) Sending promotional offers, including offers to receive
              services.
              <br />
              (g) Conclusion of agreements with the Operator or other legal
              entities, information about services (goods) of which is placed on
              the Site.
              <p>
                6.3 The User agrees that the information provided by him/her
                will be used for the purposes of personalization of content on
                the Site.
              </p>
              <p>
                6.4 The processing of personal data and information is carried
                out on a lawful and fair basis.
              </p>
              <p>
                6.5 The processing of personal data is limited to the
                achievement of specific, predetermined and legitimate purposes.
                Processing of personal data incompatible with the purposes of
                personal data collection is not allowed.
              </p>
              <p>
                6.6 It is not allowed to merge databases containing personal
                data processed for incompatible purposes.
              </p>
              <p>
                6.7 Only personal data that meet the purposes of their
                processing shall be processed. The redundancy of processed
                personal data in relation to the stated purposes of their
                processing is not allowed.
              </p>
              <p>
                6.8 Storage of personal data is carried out in a form that
                allows to identify the subject of personal data, no longer than
                required by the purposes of personal data processing, unless the
                period of storage of personal data is established by federal
                law, contract, party to which, beneficiary or guarantor of which
                is the subject of personal data. Processed personal data shall
                be destroyed or depersonalized upon achievement of the purposes
                of processing or in case of loss of necessity to achieve these
                purposes, unless otherwise provided for by federal law.
              </p>
              <p>
                6.9 The Operator has the right to send the User notifications
                about new products and services, special offers and various
                events. The User can always refuse to receive information
                messages by sending a letter to the Operator to the e-mail
                address support@diamond.com with the note “Refusal of
                notifications about new products and services and special
                offers”.
              </p>
            </span>
            <h3>
              <b>VII. Terms of use of User's information.</b>
            </h3>
            <span>
              <p>
                <b>
                  Consent to data processing and their transfer to third parties
                </b>
              </p>
              <p>
                7.1 The User agrees that part of the information provided by the
                User will be available to other Users of the Site, namely:
                nickname or name, e-mail and other means of communication. In
                case of necessity to hide any data from other Users, the User
                should use the corresponding functionality of the Site or send a
                letter to the e-mail address: support@diamond.com.
              </p>
              <p>
                7.2 The User's data specified by him on the Site, transmitted by
                him through the support service or by e-mail are processed by
                the Operator. In cases where the User is not located in the UAE,
                the User agrees to the cross-border transfer of his/her data to
                the Operator.
              </p>
              <p>
                7.3 The Operator has the right to transfer the User's
                information to third parties in the following cases:
              </p>
              (a) when the User has consented to such actions, or
              <br />
              (b) when the transfer is necessary for the fulfillment of a
              certain agreement or contract, including an offer with the User,
              or
              <br />
              (c) at the request of public authorities, judicial bodies and
              officials, or
              <br />
              (d) in other cases provided for by international law.
              <p>
                The data of the User who has concluded any agreement using the
                Site (information on the Site) is also transferred by the
                parties to the concluded agreement.
              </p>
              <p>
                In case it is necessary to withdraw consent or change the data,
                the User should send a corresponding application to the e-mail
                address support@diamond.com.
              </p>
              <p>
                7.4 In case of necessity for the purposes of conclusion of
                agreements and provision of services, at the request of the
                Operator, the User undertakes to provide his/her personal data.
                Personal data is any information relating directly or indirectly
                to the User, including but not excluding: surname, first name,
                patronymic, date and place of birth, passport data, status. In
                case of failure to provide personal data at the request of the
                Operator, the User's access to the services and the Website may
                be limited.
              </p>
              <p>
                7.5 In case of transferring his/her personal data, the User
                expresses his/her unconditional consent to their processing and
                storage.
              </p>
              <p>
                7.6 The User is responsible for any information published by him
                on the Site, services and chat rooms dedicated to the Diamond
                project, and agrees that this information will be available to
                other Users. The User agrees that any information posted by him
                on the Site in services and chat rooms dedicated to the Diamond
                project can be read, collected or used by other Users and third
                parties (in case it is in the public domain).
              </p>
              <p>
                7.7. The User independently decides whether to provide his
                personal data and gives consent freely, of his own free will and
                in his own interest.
              </p>
            </span>
            <h3>
              <b>VIII. Modification and deletion of User data.</b>
            </h3>
            <span>
              <p>
                <b>Withdrawal of consent to the processing of personal data</b>
              </p>
              <p>
                8.1 The User may at any time change (update, supplement) the
                information provided by him or her or any part thereof by
                sending a letter to the e-mail address: support@diamond.com.
              </p>
              <p>
                8.2 The User may at any time delete the personal information
                provided by him/her by sending a letter to the e-mail address:
                support@diamond.com.
              </p>
              <p>
                8.3 The rights provided for in paragraphs 8.1. and 8.2. of this
                Policy may be restricted in accordance with legal requirements.
                In particular, such restrictions may stipulate the obligation of
                the Operator to keep the information changed or deleted by the
                User for the period established by the legislation and to
                transfer such information in accordance with the legally
                established procedure to the state body.
              </p>
              <p>
                8.4 The User may withdraw consent to the processing of his/her
                personal and other data at any time by sending a letter to the
                e-mail address: support@diamond.com. In some cases, withdrawal
                of consent may result in the impossibility of receiving services
                (either in whole or in part).
              </p>
            </span>
            <h3>
              <b>
                IX. Period of storage of information and personal data of the
                User
              </b>
            </h3>
            <span>
              <p>
                9.1 The information provided by the User is stored for 6 months
                from the moment of its provision, except for the cases specified
                in clause 9.2 of this Policy.
              </p>
              <p>
                9.2 In case the User accepts offers, concludes agreements, the
                information provided by the User is stored during the entire
                period of validity of the offer/agreement, as well as for 3
                (three) years after the termination of the offer/agreement.
              </p>
              <p>
                9.3 If it is necessary to shorten the storage period of the
                information provided by the User, the User may send a letter
                describing the situation to the e-mail address:
                support@diamond.com.
              </p>
            </span>
            <h3>
              <b>X. Use of Cookies and Counters</b>
            </h3>
            <span>
              <p>
                10.1 Cookies may be used on the Site to personalize the features
                of the Site and services, to provide personalized services to
                the User, to target advertising, for statistical and research
                purposes, as well as to improve the operation of the Site and
                the provision of services.
              </p>
              <p>
                10.2 The User realizes that the equipment and software used by
                the User to visit sites on the Internet may have the function of
                prohibiting operations with Cookie files (for any sites or for
                certain sites), as well as the deletion of previously received
                Cookie files.
              </p>
              <p>
                10.3 The Operator may establish that the use of the Site and
                services is possible only if the receipt and receipt of Cookie
                files is authorized by the User. In such cases, by using the
                Site the User agrees to the placement of Cookie files in his
                browser.
              </p>
              <p>
                10.4 The counters placed on the Site and in the Services may be
                used to analyze the User's Cookie files, to collect and process
                statistical information about the use of the Services, as well
                as to ensure the performance of the Services in general or their
                individual functions in particular. The technical parameters of
                the operation of the counters are determined by the Operator and
                may be changed without prior notice to the User.
              </p>
            </span>
            <h3>
              <b>XI. Legal basis of personal data processing</b>
            </h3>
            <span>
              <p>
                11.1 The legal grounds for processing of personal data by the
                Operator are:
              </p>
              – DATA PROTECTION LAW DIFC LAW NO. 5 OF 2020;
              <br />- international legislation in the field of personal data
              protection;
              <br />- Users' consent to the processing of their personal data,
              to the processing of personal data authorized for dissemination.
              <p>
                11.2 The Operator processes the User's personal data only if it
                is filled in and/or sent by the User himself/herself through
                special forms located on the Website or sent to the Operator by
                e-mail.
              </p>
              <p>
                11.3 By filling in the relevant forms and/or sending their
                personal data to the Operator, the User expresses their consent
                to this Policy.
              </p>
            </span>
            <h3>
              <b>XII. Confidentiality of personal data</b>
            </h3>
            <span>
              <p>
                12.1 The Operator and other persons who have access to personal
                data are obliged not to disclose to third parties and not to
                distribute personal data without the consent of the subject of
                personal data, unless otherwise provided by applicable law.
              </p>
            </span>
            <h3>
              <b>
                XIII. Responsibility for the storage of data necessary for the
                use of Website and receiving services
              </b>
            </h3>
            <span>
              <p>
                <b>Сайтом и получения услуг</b>
              </p>
              <p>
                13.1 The User is obliged to ensure the safety of all data
                (login, password, ID, login and password from e-mail) of the
                User necessary for using the Site and receiving services.
              </p>
              <p>
                13.2 If there is any information or suspicion of unauthorized
                obtaining of the User's data by third parties, the User is
                obliged to immediately take all measures depending on him/her
                and notify the Operator by e-mail support@diamond.com.
              </p>
              <p>
                13.3 All actions performed by the User on the Website, in the
                services under the User's name (login) are considered to be
                performed by the User.
              </p>
            </span>
            <h3>
              <b>XIV. Measures taken to protect User's information</b>
            </h3>
            <span>
              <p>
                14.1 The Operator takes necessary and sufficient organizational
                and technical measures to protect the User's information from
                illegal or accidental access, destruction, modification,
                blocking, copying, distribution, as well as from other illegal
                actions of third parties.
              </p>
              <p>
                14.2 In case of any leakage of Users' data, the Operator
                undertakes to inform the Users about it within 72 hours from the
                moment of detection of such leakage.
              </p>
            </span>
            <h3>
              <b>XV. Modification of the Privacy Policy</b>
            </h3>
            <span>
              <p>
                15.1 This Privacy Policy may be amended. When changes are made
                in the current edition, the date of the last update shall be
                indicated. The new edition of the Policy comes into effect from
                the moment of its posting, unless otherwise provided by the new
                edition of the Policy.
              </p>
              <p>
                15.2 In case of disagreement with the changes in the Policy, the
                User is obliged to stop using the Website. Continued use of the
                Site, its services means agreement with the changes made in this
                Policy.
              </p>
            </span>
            <h3>
              <b>XVI. Final Provisions</b>
            </h3>
            <span>
              <p>
                16.1 The User may obtain any clarifications on the issues of
                interest regarding the processing of his/her data by contacting
                the Operator via e-mail support@diamond.com.
              </p>
              <p>
                16.2 This document will reflect any changes to the Operator's
                personal data processing policy. The Policy is valid
                indefinitely until it is replaced by a new version.
              </p>
              <p>
                16.3. The current version of the Policy is freely available on
                the Internet at the following address:
                https://test.diamond.balinasoft.com/.
              </p>
              <p>
                16.4. The present version of the Policy is valid from
                16.05.2024.
              </p>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}

export default PolicyEN;
