// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notificatonWrapper {
  width: 80%;
}

.authWrapperNot {
  display: flex;
}

.authText {
  display: flex;
  align-items: center;
}

.authTollBar {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.tooltext {
  margin-top: 10px;
}

@media screen and (max-width: 570px) {
  .authWrapperNot {
    margin: 20px;
  }
  #nameTextLocation {
    font-size: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/Notification.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAGA;EACI,aAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,gBAAA;AAAJ;;AAGA;EACI;IACE,YAAA;EAAJ;EAGE;IACI,eAAA;EADN;AACF","sourcesContent":[".notificatonWrapper{\n    width: 80%;\n}\n\n.authWrapperNot{\n    display: flex;\n    // justify-content: space-around;\n}\n\n.authText{\n    display: flex;\n    align-items: center;\n}\n\n.authTollBar{\n    margin-top: 20px;\n    display: flex;\n    flex-direction: column;\n}\n    \n.tooltext{\n    margin-top: 10px;\n}\n\n@media screen and (max-width:'570px') {\n    .authWrapperNot{\n      margin: 20px;\n    }\n\n    #nameTextLocation{\n        font-size: 25px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
