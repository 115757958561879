import React from "react";
import Header from "../MainPage/Header/Header";
import Body from "./Body/Body";
import Footer from "../MainPage/Footer/Footer";

function ProjectPageContainer ({theme, isSmallScreen}) {

    const style = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        // background: '#DCDCDC'
        background: '#eef1fa'

    }

    return(
        // <div style={style} className="mainPageContainer">
            <Body/>
        // {/* </div> */}
    )
}

export default ProjectPageContainer