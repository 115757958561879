// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/logo/Union.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logoNavigationImageWrapper {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 180px;
  height: 105px;
  background-size: 100% 100%;
  margin: 30px auto;
  margin-bottom: 76.79px;
}`, "",{"version":3,"sources":["webpack://./src/Style/Navigation/Navigation.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,YAAA;EACA,aAAA;EACA,0BAAA;EACA,iBAAA;EACA,sBAAA;AACJ","sourcesContent":[".logoNavigationImageWrapper{\n    background-image: url(../../images/logo/Union.png);\n    width: 180px;\n    height: 105px;\n    background-size: 100% 100%;\n    margin: 30px auto;\n    margin-bottom: 76.79px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
