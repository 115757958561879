import React, { useEffect, useState } from "react";
import '../../../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function MoneyTransactionTitle () {
  const { t, i18n } = useTranslation();

    return(
        <div className="nameWrapper" style={{margin: '0'}}>
        <span className="nameText"> 
        {t('BillsMoneyTransactionTitle')}

        </span>
        </div>
    )
}

export default MoneyTransactionTitle