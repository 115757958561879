import React, { useState, useRef } from 'react';
import { Modal, Box, Button } from '@mui/material';
import { Cropper } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { useTranslation } from "react-i18next";
import axios from 'axios';
import { ENDPOINTS } from '../configs/endpoints';
import '../Style/PhotoEditor/PhotoEditor.scss'
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const circularCropStyle = `
  .cropper-view-box,
  .cropper-face {
    border-radius: 50%;
  }
`;

function ImageCropperModal  ({ open, handleClose, handleSave })  {
  const [image, setImage] = useState(null);
  const cropperRef = useRef(null);
  const { t, i18n } = useTranslation();

  const onImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };



  const onCrop = () => {

    handleSave(cropperRef.current.cropper)
    handleClose()

  };

  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box className='photoEditor' sx={{ ...style, textAlign: 'center' }}>
      <h2 id="modal-title">{t('MyCabinetModalWindowAddPhotoTitle')}</h2>
      <input type="file" onChange={onImageChange} />
      {image && (
        <div>
          <style>{circularCropStyle}</style>
          <Cropper
            src={image}
            style={{ height: 400, width: '100%' }}
            aspectRatio={1}
            viewMode={1}
            minCropBoxWidth={96}
            minCropBoxHeight={96}
            cropBoxResizable={false}
            dragMode="move"
            guides={false}
            ref={cropperRef}
            cropBoxData={{ width: 96, height: 96 }}
          />
        </div>
      )}
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t('MyCabinetModalWindowAddPhotoExit')}
        </Button>
        <Button variant="contained" color="primary" onClick={onCrop}>
          {t('MyCabinetModalWindowAddPhotoSave')}
        </Button>
      </Box>
    </Box>
  </Modal>
  );
};

export default ImageCropperModal



