import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    dataPartner: {
        data: false
    }
}

const partnerSlice = createSlice({
    name: 'partnerData',
    initialState,
    reducers:{
        setPartner: (state, action) =>{
            state.dataPartner = action.payload
        }
    }
})

export const {setPartner} = partnerSlice.actions
export default partnerSlice.reducer