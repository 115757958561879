// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperBasicData {
  margin-right: 10px;
}

.wrapperBasicDataPassword {
  min-width: 400px;
}

.wrapperCheckboxProfile {
  display: flex;
}

.checkboxProfileText {
  color: grey;
}

.saveButtonProfile {
  min-width: 150px;
  text-align: center;
  cursor: pointer;
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.3334;
  padding: 10px;
  color: rgb(255, 255, 255);
  background: var(--green, #10B981);
  margin-bottom: 20px;
  margin-top: 20px;
  border: none;
  font-weight: bold;
}

@media screen and (max-width: 570px) {
  .wrapperBasicData {
    width: 90%;
  }
  .wrapperBasicDataPassword {
    min-width: 200px;
    max-width: 300px;
    width: 90%;
  }
  .pageWrapper {
    width: 90%;
  }
}
@media screen and (max-width: 400px) {
  .wrapperBasicData {
    width: 80%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/BasicData.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AACA;EACI,gBAAA;AAEJ;;AACA;EACA,aAAA;AAEA;;AAEA;EACA,WAAA;AACA;;AAGA;EACI,gBAAA;EACA,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,aAAA;EAEA,mBAAA;EAEA,uBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,aAAA;EACA,yBAAA;EACA,iCAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AADJ;;AAIA;EACI;IACI,UAAA;EADN;EAGE;IACI,gBAAA;IACA,gBAAA;IACA,UAAA;EADN;EAGE;IACI,UAAA;EADN;AACF;AAIA;EACI;IACI,UAAA;EAFN;AACF","sourcesContent":[".wrapperBasicData{\n    margin-right: 10px;\n}\n.wrapperBasicDataPassword{\n    min-width: 400px;\n}\n\n.wrapperCheckboxProfile{\ndisplay: flex;\n\n}\n\n.checkboxProfileText{\ncolor: grey;\n}\n\n\n.saveButtonProfile{\n    min-width: 150px;\n    text-align: center;\n    cursor: pointer;\n    border-radius: 0.875rem;\n    display: flex;\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: center;\n    justify-content: center;\n    font-size: 1.125rem;\n    line-height: 1.3334;\n    // font-weight: 500;\n    padding: 10px;\n    color: rgb(255, 255, 255);\n    background: var(--green, #10B981);\n    margin-bottom: 20px;\n    margin-top: 20px;\n    border: none;\n    font-weight: bold;\n}\n\n@media screen and (max-width:'570px') {\n    .wrapperBasicData{\n        width: 90%;\n    } \n    .wrapperBasicDataPassword{\n        min-width: 200px;\n        max-width: 300px;\n        width: 90%;\n    }\n    .pageWrapper{\n        width: 90%;\n    }\n}\n\n@media screen and (max-width:'400px') {\n    .wrapperBasicData{\n        width: 80%;\n    } \n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
