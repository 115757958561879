import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import urlFromTemplate from '../../configs/url';
import { ENDPOINTS } from '../../configs/endpoints';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FileUploaderModal = ({ isOpen, onRequestClose, id, reloadPage }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };



  const handleUpload = async () => {
    if (!selectedFile) {
      setUploadStatus('Please select a file first.');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);
    const param = {
        agreementId: id
    }
    const queryString = new URLSearchParams(param).toString();
    const apiUrl = urlFromTemplate(ENDPOINTS.AGREEMENT) + `/sign?${queryString}`
    try {
      await axios.post(apiUrl, formData, {
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Access-Control-Allow-Credentials': 'true'
        },
      });
      setUploadStatus('File uploaded successfully.');
      setSelectedFile(null);
      reloadPage()
      onRequestClose()
    } catch (error) {
      setUploadStatus('File upload failed.');
      console.error(error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onRequestClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Upload File
        </Typography>
        <TextField
          type="file"
          onChange={handleFileChange}
          fullWidth
          margin="normal"
        />
        <Button variant="contained" color="primary" onClick={handleUpload}>
          Upload
        </Button>
        <Button variant="outlined" color="secondary" onClick={onRequestClose} sx={{ ml: 2 }}>
          Close
        </Button>
        <Typography variant="body2" color="textSecondary" mt={2}>
          {uploadStatus}
        </Typography>
      </Box>
    </Modal>
  );
};

export default FileUploaderModal;
