import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useTranslation } from "react-i18next";
import "../../Style/ModalWindowRegistration/ModalWindowRegistration.scss";
import SecondTab from "./SecondTab";
import ThirdTab from "./ThirdTab";
import FourthTab from "./FourthTab";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";

function ModalWindowCabinetConsultant({isOpen, onClose, formStatus}) {
  const [open, setOpen] = React.useState(isOpen);
  const { t, i18n } = useTranslation();
  const [firstTab, setFirstTab] = useState(true);
  const [secondTab, setSecondTab] = useState(false);
  const [thirdTab, setThirdTab] = useState(false)
  const [fourthTab, setFourthTab] = useState(false)
  const handleOpen = () => {
    setOpen(true);
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  const handleNextTab = () => {
    setFirstTab(false);
    setSecondTab(true);
  };

  const handleNextTabThird = () => {
    setSecondTab(false)
    setThirdTab(true)
  }

  const handleNextTabFourth = () => {
    handelActiveuser()
    setThirdTab(false)
    setFourthTab(true)
  } 

  useEffect(() => {
    if(formStatus){
    setFirstTab(false);
    handleNextTabThird()
    }
  },[formStatus])

 

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "20px",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };


  // Установление статуса Активный для консультанта

  const handelActiveuser = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/activate`
    const payload = {}
    try{
      const response = await axios.post(apiUrl, payload, {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Box className="modalWindowRegistration" sx={{ ...style }}>
        {firstTab && (
          <div>
            <h1>{t('CabinetPartnerModalWindowFirstTabTitle')}</h1>
            <p id="parent-modal-description">
            {t('CabinetPartnerModalWindowFirstTabDescFirst')}
            </p>
            <p id="parent-modal-description">
             {t('CabinetPartnerModalWindowFirstTabDescSecond')}
            </p>
            <button className="saveButtonProfile" onClick={handleNextTab}>
              {t('CabinetPartnerModalWindowFirstTabButton')}
            </button>
          </div>
        )}
        {secondTab && (
         <SecondTab handleNextTabThird={handleNextTabThird} formStatus={formStatus}/>
        )}
        {thirdTab && (
          <ThirdTab handleNextTabFourth={handleNextTabFourth} onClose={onClose}/>
        )}
        {fourthTab && (
          <FourthTab handleClose={onClose}/>
        )}
      </Box>
    </Modal>
  );
}

export default ModalWindowCabinetConsultant;
