import React from "react";
import '../../Style/Profile/IndexProfile.scss'
import IndexProfileContainer from "./indexProfilContainer";
import Navigation from "../Navigation/Navigation";
import { Box } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";
import PageWrapper from "../../Routes/PageWrapper";

function IndexProfile () {
    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row'
    }

    return(
        <PageWrapper>
        <Box style={style}>
        {/* <Navigation isSmallScreen={isSmallScreen}/> */}
        <IndexProfileContainer theme={theme} isSmallScreen={isSmallScreen}/>        
        </Box>
        </PageWrapper>

    )

}


export default IndexProfile