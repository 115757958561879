import React, { useEffect, useState } from "react";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../../../Style/Profile/Notification.scss'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import {Checkbox} from "@mui/material";
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';

function Notification() {
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState('google');
    const { t, i18n } = useTranslation();
    const handleChange = (event) => {
        setChecked(event.target.checked);
    };

    const handleChangeRadio = (event) => {
        setValue(event.target.value);
    };

  

    return (
        <PageWrapper>
        <div className="notificatonWrapper">
            <div className="nameWrapper" style={{marginBottom: "10px"}}>
                <span style={{marginLeft: '0px'}} className="nameText" id="nameTextLocation"> {t('ProfileNotificationTitle')}</span>
            </div>
            <div className="authWrapperNot">
                <Switch
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{'aria-label': 'controlled'}}
                />
                <div className="authText">
                {t('ProfileNotificationVerification')}
                </div>
            </div>
            <div className="authDesc">
            {t('ProfileNotificationAttantion')}
            </div>
            {checked &&
                <div className="authTollBar">
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={value}
                            onChange={handleChangeRadio}
                        >
                            <FormControlLabel value="google" control={<Radio/>} label="Google Authenticator"/>
                            <FormControlLabel value="email" control={<Radio/>} label="Email"/>
                        </RadioGroup>
                    </FormControl>
                    <Button style={{maxWidth: '120px'}} variant="outlined">{t('ProfileNotificationButton')}</Button>
                    <div className="tooltext">
                    {t('ProfileNotificationAttantionSelect')}
                    </div>
                    <div className="checkboxTool">
                        <FormControl>
                            <FormControlLabel control={<Checkbox/>} label={t('ProfileNotificationAuth')}/>
                            <FormControlLabel control={<Checkbox/>} label={t('ProfileNotificationConclusion')}/>
                        </FormControl>
                    </div>
                </div>
            }
        </div>
        </PageWrapper>
    )
}

export default Notification