import React from "react";
import ListTitle from "./ListTitle";
import ListBody from "./ListBody";
import PageWrapper from "../../Routes/PageWrapper";


function IndexListOperation () {
    return<>
    <PageWrapper>
        <div className="billsSecondWrapper">
        <ListTitle/>
    <ListBody/>
        </div>
  
    </PageWrapper>
    </>
}

export default IndexListOperation