import React, { useEffect } from "react";
import '../../../Style/Body.scss'
import { useSelector } from "react-redux";
import NameCabinet from "./NameCabinet";
import Bills from "./Bills";
import IncomeTable from "./incomeTable";
import ProjectBody from "./Project";
import Transactions from "./Transactions";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import PageWrapper from "../../../Routes/PageWrapper";
import ModalWindowRegistration from "../../../Modal/ModalWindowRegistration";
function Body () {
    const dataCabinet = useSelector((state) => state.OpenNavBar.dataNav)

    return(
        <>
            <PageWrapper>

        <div className="mainPageBody">
            <NameCabinet/>
            <Bills/>
            <IncomeTable/>
            <ProjectBody/>
            <Transactions/>
            <ModalWindowRegistration/>
        </div>
        </PageWrapper>

        </>
    )
}

export default Body