import React from "react";
import BillsCard from "./BillsCard";
import BillsTable from "./BillsTable";
import BillsStatic from "./BillsStatic";
import '../../Style/Bills/BillsBody.scss'


function IndexBillsBody () {

    return(
        <>
        <BillsCard/>
        {/* <BillsStatic/> */}
        <BillsTable/>
        
        </>
    )

}

export default IndexBillsBody