import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import "../../../Style/Profile/Contacts.scss";
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from "react-i18next";

function Contacts() {
  const [errorLabel, setErrorLabel] = useState("");
  const [result, setResult] = useState({
    email: "",
    phone: "",
    viber: "",
    whatsapp: "",
    facebook: "",
    instagram: "",
    x: "",
    vk: "",
    classmates: "",
  });
  const { t, i18n } = useTranslation();

  const handleFieldChange = (field) => (event) => {
    const newValue = event.target.value;
    setResult((prevState) => ({
      email: field === "email" ? newValue : prevState.email,
      phone: field === "phone" ? newValue : prevState.phone,
      viber: field === "viber" ? newValue : prevState.viber,
      whatsapp: field === "whatsapp" ? newValue : prevState.whatsapp,
      facebook: field === "facebook" ? newValue : prevState.facebook,
      instagram: field === "instagram" ? newValue : prevState.instagram,
      x: field === "x" ? newValue : prevState.x,
      vk: field === "vk" ? newValue : prevState.vk,
      classmates: field === "classmates" ? newValue : prevState.classmates,
    }));
  };

  const handleUpdateUserContacts = async (e) => {
    e.preventDefault();
    const apiUrl = urlFromTemplate(ENDPOINTS.USER_CONTACTS);
    try {
      await axios
        .put(apiUrl, result, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setErrorLabel("");
        });
    } catch (error) {
      console.error(error);
      setErrorLabel(error.message);
    }
  };

  useEffect(() => {
    retrieveUserContacts();
  }, []);

  const retrieveUserContacts = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER_CONTACTS);
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setResult(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <PageWrapper>
        <div className="wrapperBasicData">
          <div className="nameWrapper" style={{ marginBottom: "10px" }}>
            <span style={{ marginLeft: "0px" }} className="nameText">
              {" "}
              {t("ProfileContactsTitle")}
            </span>
          </div>
          <Box
            sx={{
              maxWidth: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            value="1"
          >
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={result.email}
              onChange={handleFieldChange("email")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label={t("ProfileContactsPhone")}
              variant="outlined"
              value={result.phone}
              onChange={handleFieldChange("phone")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="Viber"
              variant="outlined"
              value={result.viber}
              onChange={handleFieldChange("viber")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="Whatsapp"
              variant="outlined"
              value={result.whatsapp}
              onChange={handleFieldChange("whatsapp")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="Facebook"
              variant="outlined"
              value={result.facebook}
              onChange={handleFieldChange("facebook")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="Instagram"
              variant="outlined"
              value={result.instagram}
              onChange={handleFieldChange("instagram")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label="X"
              variant="outlined"
              value={result.x}
              onChange={handleFieldChange("x")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label={t("ProfileContactsVk")}
              variant="outlined"
              value={result.vk}
              onChange={handleFieldChange("vk")}
            />
            <TextField
              sx={{ marginTop: "10px", width: "100%" }}
              id="outlined-basic"
              label={t("ProfileContactsOk")}
              variant="outlined"
              value={result.classmates}
              onChange={handleFieldChange("classmates")}
            />
          </Box>
          {errorLabel && errorLabel !== "" && (
            <p style={{ color: "red", fontSize: "17px" }}>{errorLabel}</p>
          )}
          <button
            className="saveButtonProfile"
            onClick={handleUpdateUserContacts}
          >
            {t("ProfileButtonSave")}
          </button>
        </div>
      </PageWrapper>
    </LocalizationProvider>
  );
}

export default Contacts;
