import React from "react";
import Header from "../MainPage/Header/Header";
import '../../Style/Bills/BillsWrapper.scss'
import Footer from "../MainPage/Footer/Footer";
import IndexBillsBody from "./IndexBillsBody";
import BillsTitle from "./BillsTitle";


function IndexBillsContainer ({theme, isSmallScreen}) {

    return(
        <div className="billsSecondWrapper">
        <BillsTitle/>
        <IndexBillsBody/>
        </div>


    )

}


export default IndexBillsContainer