import './App.css';
import Router from './Routes/IndexRout';

function App() {
    return (
            <Router/>
    );
}

export default App;
