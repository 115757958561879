// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profileBodyWrapper {
  margin: 20px auto;
  width: 95%;
  border-radius: 10px;
  display: flex;
  background-color: white;
  min-height: 80vh;
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/ProfileBody.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,gBAAA;AACJ","sourcesContent":[".profileBodyWrapper{\n    margin: 20px auto;\n    width: 95%;\n    border-radius: 10px;\n    display: flex;\n    background-color: white;\n    min-height: 80vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
