// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveButtonTelegram {
  text-align: center;
  cursor: pointer;
  border-radius: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.125rem;
  line-height: 1.3334;
  font-weight: bold;
  padding: 1rem;
  color: rgb(255, 255, 255);
  background: var(--green, #10B981);
  width: 30%;
  margin-bottom: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 570px) {
  .saveButtonTelegram {
    width: 50%;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/Telegram.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;EACA,uBAAA;EACA,aAAA;EAEA,mBAAA;EAEA,uBAAA;EACA,mBAAA;EACA,mBAAA;EAEA,iBAAA;EACA,aAAA;EACA,yBAAA;EACA,iCAAA;EACA,UAAA;EACA,mBAAA;EACA,gBAAA;AAAJ;;AAGA;EACI;IACI,UAAA;EAAN;AACF","sourcesContent":[".saveButtonTelegram{\n    text-align: center;\n    cursor: pointer;\n    border-radius: 0.875rem;\n    display: flex;\n    -webkit-box-align: center;\n    align-items: center;\n    -webkit-box-pack: center;\n    justify-content: center;\n    font-size: 1.125rem;\n    line-height: 1.3334;\n    // font-weight: 500;\n    font-weight: bold;\n    padding: 1rem;\n    color: rgb(255, 255, 255);\n    background: var(--green, #10B981);\n    width: 30%;\n    margin-bottom: 20px;\n    margin-top: 20px;\n}\n\n@media screen and (max-width:'570px') {\n    .saveButtonTelegram{\n        width: 50%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
