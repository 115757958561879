import React, {useEffect, useState} from 'react';
import './App.css';
import LandingHeader from './landing/landingHeader';
import LandingFooter from './landing/landingFooter';
import LandingBody from './landing/landingBody';
import './Style/landing/IndexLanding.scss'
import PageWrapper from './Routes/PageWrapper';
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "./Style/landing/LandingHeader.scss";
import { Link, json } from "react-router-dom";
import "./Style/Modal/SiteSettingsModal.scss";
function IndexLanding() {

    const [languageColor, setLanguageColor] = useState('')
    const [language, setLanguage] = useState('')

    const style = {
      background: "none",
      boxShadow: "none",
    };
  
    const colorButton = {
      color: "white",
    };
  

      useEffect(() => {
        const siteSettings = {
          language: 'Русский'
        };
    
        localStorage.setItem('site_settings', JSON.stringify(siteSettings));
    },[])
  
  
    useEffect(() => {
      const languageColor = localStorage.getItem('site_settings')
      if(languageColor){
        let myObject = JSON.parse(languageColor);
        if (myObject['language'] === 'Русский'){
          setLanguageColor(true)
        }
        else{
          setLanguageColor(false)
        }
  
        setLanguage(myObject['language'])
      }
  
    },[])

  
  
    const changeLanguageRu = (color) => {
      setLanguageColor(color);
      const language = localStorage.getItem("site_settings");
      if (language) {
        let myObject = JSON.parse(language);
  
        myObject["language"] = "Русский";
        setLanguage(myObject['language'])
        localStorage.setItem("site_settings", JSON.stringify(myObject));
      } else {
        localStorage.setItem("site_settings", JSON.stringify({language: 'Русский'}));
      }
    };
    const changeLanguageEn = (color) => {
      setLanguageColor(color);
      const language = localStorage.getItem("site_settings");
      if (language) {
        let myObject = JSON.parse(language);
  
        myObject["language"] = "English";
        setLanguage(myObject['language'])
        localStorage.setItem("site_settings", JSON.stringify(myObject));
      } else {
      }
    };



    return (
        <PageWrapper>
        <div className="landingImageWrapper">
            <div className="landingOpacityWrapper">
            <div className="landingHeaderWrapper">
      <div className="landingHeader">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={style}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                DIAMOND
              </Typography>
              <Link to="registration">
                <Button style={colorButton}>
                {language === "Русский"
                      ? "Регистрация / Вход"
                      : "Registration / Login"}
                    </Button>
              </Link>
              <div className="languageWrapper">
                <div className="language">
                  <a
                    style={{ color: languageColor ? "gold" : "white" }}
                    onClick={() => changeLanguageRu(true)}
                    className="languageTextRu"
                  >
                    RU
                  </a>
                </div>
                <div className="language">
                  <a
                    style={{ color: languageColor ? "white" : "gold" }}
                    onClick={() => changeLanguageEn(false)}
                    className="languageTextEn"
                  >
                    EN
                  </a>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    </div>
                <LandingBody language={language}/>
                <LandingFooter language={language}/>
            </div>
        </div>
        </PageWrapper>
    );
}

export default IndexLanding;
