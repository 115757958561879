import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import "../../Style/RegistrationBody.scss";
import {useNavigate} from "react-router-dom";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import axios from "axios";
import {ENDPOINTS} from "../../configs/endpoints";
import urlFromTemplate from "../../configs/url";
import Cookies from "js-cookie";
import {useDispatch} from "react-redux";
import {setCabinet} from "../../store/reducerCabinet";
import {setModalReg} from "../../store/modalRegistration";
import {border, borderRadius, padding} from "@mui/system";


function RegistrationBody({language}) {
    const [value, setValue] = React.useState("1");
    const [valueInputLogin, setValueInputLogin] = useState("");
    const [valueInputPassword, setValueInputPassword] = useState("");
    const [forgotPass, setForgotPass] = useState(false);
    const [errorInput, setErrorInput] = useState(false);
    const [errorRef, setErrorRef] = useState(null);
    const [refTrue, setRefTrue] = useState(false);
    const [regLogin, setRegLogin] = useState("");
    const [regEmail, setRegEmail] = useState("");
    const [regEmailPost, setRegEmailPost] = useState("");
    const [regPass, setRegPass] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [emailTrue, setEmailTrue] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const urlParams = new URLSearchParams(window.location.search);
    const ref = urlParams.get("referralLink");
    const cookieLogin = Cookies.get("Access_Cookie");

    useEffect(() => {
        // if(cookieLogin){
        //   navigate('/mainpage')
        // }
        checkRef(ref);
    }, []);

    const checkRef = async (ref) => {
        if (ref) {
            const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/ref/${ref}`;
            try {
                await axios.post(apiUrl).then((response) => {
                    setRegOpen(true);
                    setRefTrue(true);
                    setErrorRef(null);
                    setValue("2");
                });
            } catch (error) {
                setRefTrue(false);
                setErrorRef(error.response.data.message)
            }
        }
    };

    const setSiteSettingsInLocalStorage = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.SITE_SETTINGS);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            localStorage.setItem("site_settings", JSON.stringify(response.data));
        } catch (error) {
            console.error(error);
        }
    };

    const buttonAuth = async () => {
        if (valueInputLogin && valueInputPassword) {
            const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/sign/in`;
            try {
                await axios
                    .post(
                        apiUrl,
                        {
                            login: valueInputLogin,
                            password: valueInputPassword,
                        },
                        {
                            withCredentials: true,
                            headers: {
                                Accept: "application/json",
                                "Access-Control-Allow-Credentials": "true",
                            },
                        }
                    )
                    .then((response) => {
                        dispatch(setCabinet(response.data));
                        setSiteSettingsInLocalStorage();
                        navigate("/mainpage");
                    });
            } catch (error) {
                console.error(error);
                alert(error.response.data.message);
                setErrorInput(true);
            }
        }
    };

    const buttonReg = async () => {
        if (regLogin && regEmail && regPass) {
            const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/sign/up`;
            try {
                await axios
                    .post(
                        apiUrl,
                        {
                            login: regLogin,
                            inviterLogin: ref,
                            email: regEmail,
                            password: regPass,
                        },
                        {
                            withCredentials: true,
                            headers: {
                                Accept: "application/json",
                                "Access-Control-Allow-Credentials": "true",
                            },
                        }
                    )
                    .then((response) => {
                        navigate("/mainpage");
                        dispatch(setModalReg(true));
                    });
            } catch (error) {
                alert(error.response.data.message);
            }
        }
    };

    const handleChangeLogin = (event) => {
        setValueInputLogin(event.target.value);
    };

    const handleChangePassword = (event) => {
        setValueInputPassword(event.target.value);
    };

    const registrationLogin = (event) => {
        setRegLogin(event.target.value);
    };
    const registrationEmail = (event) => {
        setRegEmail(event.target.value);
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (emailRegex.test(event.target.value)) {
            setRegEmailPost();
        }
        setEmailTrue(emailRegex.test(event.target.value));
    };
    const registrationPass = (event) => {
        setRegPass(event.target.value);
    };

    const [regOpen, setRegOpen] = useState(false);

    function getErrorMessage () {
        if (errorRef) {
            return errorRef;
        }
        return language === "Русский"
            ? ` Регистрация возможна только по реферальной ссылке
                                                пригласившего Вас консультанта. За детальной информацией
                                                обратитесь в тех.поддержку: support@support.test
                                                @test_support_bot`
            : `Registration is only possible using a referral link
                                                the consultant who invited you. For detailed information
                                                contact technical support: support@support.test
                                                @test_support_bot`
    }

    const handleOpenReg = () => {
        setRegOpen(true);
    };

    const handleOpenAuth = () => {
        setRegOpen(false);
    };

    const styleTabs = {
        width: "90%",
        maxWidth: "600px",
        // height: "600px",
        margin: "0 auto",
        marginTop: "10%",
        background: "white",
        borderRadius: "24px",
        border: "1px solid black",
        marginBottom: "100px",
    };

    const styleButton = {
        background: "rgb(250, 176, 49)",
        width: "50%",
        margin: "0 auto",
        padding: "10px",
        borderRadius: "0.875rem",
    };

    const handleForgotPass = () => {
        setForgotPass(!forgotPass);
    };
    return (
        <Box style={styleTabs}>
            {!forgotPass && (
                <TabContext value={value}>
                    <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                        <TabList
                            onChange={handleChange}
                            aria-label="lab API tabs example"
                            centered={true}
                        >
                            <Tab
                                onClick={() => handleOpenAuth()}
                                value="1"
                                label={language === "Русский"
                                    ? "Авторизация"
                                    : "Authorization"}
                            />
                            <Tab
                                onClick={() => handleOpenReg()}
                                label={language === "Русский"
                                    ? "Регистрация"
                                    : "Registration"}
                                value="2"
                            />
                        </TabList>
                    </Box>
                    {!regOpen &&
                        <TabPanel
                            id="auth"
                            value="1"
                            style={{
                                padding: "0",
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                margin: "0 auto",
                                height: "500px",
                            }}
                        >
                            <div
                                style={{
                                    fontSize: "2em",
                                    marginBottom: "20px ",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                {language === "Русский" ? "Авторизация" : "Authorization"}
                            </div>
                            <Box
                                sx={{
                                    height: "130px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    marginBottom: "20px",
                                }}
                            >
                                <TextField
                                    autoComplete="off"
                                    error={errorInput || !valueInputLogin}
                                    value={valueInputLogin}
                                    onChange={handleChangeLogin}
                                    sx={{width: "70%"}}
                                    id="outlined-basic"
                                    label={language === "Русский"
                                        ? "Введите логин"
                                        : "Enter login"}
                                    variant="outlined"
                                />
                                <TextField
                                    autoComplete="off"
                                    error={errorInput || !valueInputPassword}
                                    type="password"
                                    value={valueInputPassword}
                                    onChange={handleChangePassword}
                                    sx={{width: "70%"}}
                                    id="outlined-basic"
                                    label={language === "Русский"
                                        ? "Введите пароль"
                                        : "Enter password"}
                                    variant="outlined"
                                />
                            </Box>
                            <Button
                                style={styleButton}
                                onClick={buttonAuth}
                                variant="contained"
                            >
                                {language === "Русский"
                                    ? "Войти"
                                    : "Login"}
                            </Button>
                            <div className="wrapperButtonForgotPassword">
                        <span>
                            {language === "Русский" ? "Забыли пароль?" : "Forgot your password?"}{" "}
                            <a style={{marginLeft: "5px"}} className="forgotPass"
                               onClick={() => handleForgotPass()}>
                                {language === "Русский" ? "Восстановить" : "Restore"}
                            </a>
                        </span>
                            </div>
                        </TabPanel>}
                    {regOpen && (
                        <>
                            {!refTrue ? (
                                <TabPanel
                                    id="reg"
                                    value="2"
                                    style={{
                                        padding: "0",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        margin: "0 auto",
                                        height: "200px",
                                    }}
                                >
                                    <div
                                        className="attentionWrapper"
                                        style={{
                                            marginBottom: "50px",
                                            marginTop: "50px",
                                            marginLeft: "15px",
                                        }}
                                    >
                                        <div className="wrapperIconAttention">
                                            <PriorityHighIcon style={{color: "red"}}/>
                                        </div>
                                        <div className="attentionText">
                                    <span>
                                        <b>
                                        {getErrorMessage()}
                                        </b>
                                    </span>
                                        </div>
                                    </div>
                                </TabPanel>
                            ) : (
                                <TabPanel
                                    id="reg"
                                    value="2"
                                    style={{
                                        padding: "0",
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        margin: "0 auto",
                                        height: "500px",
                                    }}
                                >
                                    <div
                                        style={{
                                            fontSize: "2em",
                                            marginBottom: "20px ",
                                            display: "flex",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {language === "Русский" ? "Регистрация" : "Registration"}
                                    </div>
                                    <Box
                                        sx={{
                                            height: "195px",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            marginBottom: "25px",
                                        }}
                                    >
                                        <TextField
                                            autoComplete="off"
                                            error={!regLogin}
                                            value={regLogin}
                                            onChange={registrationLogin}
                                            sx={{width: "70%", marginBottom: "20px"}}
                                            id="outlined-basic"
                                            label={language === "Русский"
                                                ? "Введите логин"
                                                : "Enter login"}
                                            variant="outlined"
                                        />
                                        <TextField
                                            autoComplete="off"
                                            error={!regEmail}
                                            type="email"
                                            value={regEmail}
                                            onChange={registrationEmail}
                                            sx={{width: "70%", marginBottom: "20px"}}
                                            id="outlined-basic"
                                            label={language === "Русский"
                                                ? "Введите email"
                                                : "Enter email"}
                                            variant="outlined"
                                        />
                                        <TextField
                                            autoComplete="off"
                                            error={!regPass}
                                            type="password"
                                            value={regPass}
                                            onChange={registrationPass}
                                            sx={{width: "70%", marginBottom: "20px"}}
                                            id="outlined-basic"
                                            label={language === "Русский"
                                                ? "Придумайте пароль"
                                                : "Create a password"}
                                            variant="outlined"
                                        />
                                    </Box>
                                    <Button
                                        /*              sx = {{marginTop: '5px !important'}}*/
                                        style={styleButton}
                                        onClick={buttonReg}
                                        variant="contained"
                                    >
                                        {language === "Русский"
                                            ? "Зарегистрироваться"
                                            : "Register"}
                                    </Button>
                                </TabPanel>
                            )}
                        </>
                    )}
                </TabContext>)}
            {forgotPass && (
                <div className="forgotPassWrapper">
                    <div className="forgotPassContainer">
                        <div className="forgonPassDesc">
                            <p>
                                <b>Восстановление пароля</b>
                            </p>
                            <p>
                                Укажите свой логин или email и получите ссылку для
                                восстановления
                            </p>
                        </div>
                        <div className="forgotPassForm">
                            <div className="inputForgotPass">
                                <TextField
                                    autoComplete="off"
                                    error={!valueInputLogin}
                                    value={valueInputLogin}
                                    onChange={handleChangeLogin}
                                    sx={{width: "100%"}}
                                    id="outlined-basic"
                                    label="Ваш логин или email"
                                    variant="outlined"
                                />
                            </div>
                            <div className="buttonForgotPassWrapper">
                                <div
                                    onClick={() => handleForgotPass()}
                                    className="exitButtonPass"
                                >
                                    Отмена
                                </div>
                                <div className="nextButtonPass">Далее</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </Box>
    );
}

export default RegistrationBody;