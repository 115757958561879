import React, {useEffect, useRef, useState} from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import {useSelector} from "react-redux";
import {Link, useNavigate} from "react-router-dom";
import {ReactComponent as Cabinet} from "../../images/Navigation/cabinetClient.svg";
import {ReactComponent as NavigationArrow} from "../../images/Navigation/navigationArrow.svg";
import {ReactComponent as Exit} from "../../images/Navigation/exitNav.svg";
import {ReactComponent as Structure} from "../../images/Navigation/structure.svg";
import {ReactComponent as HandShake} from "../../images/Navigation/handshake.svg"
import {ReactComponent as Events} from "../../images/Navigation/events.svg"
import {ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import {useTranslation} from 'react-i18next';
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import {ReactComponent as Table} from '../../images/NavigationPartner/incomeTableNavigate.svg'
import {ReactComponent as Graphical} from '../../images/NavigationPartner/GraphicalNavigate.svg'

function PartnersNavigation({isSmallScreen}) {
    const data = useSelector((state) => state.OpenNavBar.dataNav);
    const navigate = useNavigate();
    const [activeItem, setActiveItem] = useState(
        localStorage.getItem("activeItem") || "cabinet"
    );
    const [isHovered, setIsHovered] = useState(false);
    const [hoverPosition, setHoverPosition] = useState({top: 0, left: 0});
    const structureButtonRef = useRef(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    let params = "";
    const [open, setOpen] = React.useState(true);
    const {t, i18n} = useTranslation();

    const handleClick = () => {
        setOpen(!open);
    };


    const handleMouseEnter = () => {
        if (structureButtonRef.current) {
            const rect = structureButtonRef.current.getBoundingClientRect();
            setHoverPosition({top: rect.top, left: rect.right});
            setIsHovered(true);
        }
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };


    useEffect(() => {
        params = window.location.pathname;

        switch (params) {
            case "/consultant":
                setSelectedIndex(0);
                break;
            case "/consultant/invite-structure":
                setSelectedIndex(1);
                break;
            case "/consultant/graphical-structure":
                setSelectedIndex(2);
                break;
            case "/consultant/referral":
                setSelectedIndex(3);
                break;
            case "/consultant/events":
                setSelectedIndex(4);
                break;
            default:
                break;
        }
    }, []);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);

    };

    const handleExit = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/logout`
        await axios.post(apiUrl, {
            withCredentials: true,
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Credentials": "true",
            },
        })
    }


    return (
        <>
            {!isSmallScreen && (
                <Drawer
                    open={!isSmallScreen}
                    sx={{
                        width: "231px",
                        backgroundColor: "#1C2434",
                        flexShrink: 0,
                        "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            position: "sticky",
                            top: '0px',
                            display: "flex",
                            // justifyContent: "space-between",
                            flexDirection: "column",
                            backgroundColor: "#1C2434",
                            height: "900px",
                            zIndex: "9",
                        },
                    }}
                    variant="permanent"
                    anchor="left"
                >
                    <div className="logoNavigationImageWrapper">

                    </div>
                    <List>
                        <ListItemButton style={{display: "flex", paddingLeft: "0", color: 'white'}}
                                        selected={selectedIndex === 0}
                                        onClick={(event) => handleListItemClick(event, 0)}
                                        component={Link}
                                        to="/consultant"
                        >
                            <ListItemIcon style={{display: "flex", justifyContent: "center"}}>
                                <Cabinet
                                    style={{
                                        width: "22px",
                                        height: "22px",
                                        fill: 'white',

                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText style={{}} primary={t('ConsultantDashboard')}/>
                            {selectedIndex != 0 && <NavigationArrow/>}
                        </ListItemButton>
                        <ListItemButton
                            style={{display: "flex", paddingLeft: "0", color: 'white'}}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}
                            // selected={selectedIndex === 1}
                            // onClick={(event) => handleListItemClick(event, 1)}
                            onClick={handleClick}

                        >
                            <ListItemIcon style={{display: "flex", justifyContent: "center"}}>
                                <Structure style={{
                                    width: "25px",
                                    height: "25px",
                                    fill: 'white',
                                }}/>
                            </ListItemIcon>
                            <ListItemText primary={t('CabinetPartnerNavStructureMain')}/>
                            {open ? <ExpandLess/> : <ExpandMore/>}
                        </ListItemButton>
                        <Collapse style={{display: 'flex', justifyContent: 'center',}} in={open} timeout="auto"
                                  unmountOnExit>
                            <List style={{display: 'flex', justifyContent: 'center'}} component="div" disablePadding>
                                <ListItemButton style={{display: 'flex', justifyContent: 'center', color: 'white'}}
                                                sx={{pl: 3}}
                                                selected={selectedIndex === 1}
                                                onClick={(event) => handleListItemClick(event, 1)}
                                                component={Link}
                                                to="./invite-structure "
                                >
                                    <ListItemIcon>
                                        <Table
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                stroke: 'white',
                                                fill: 'white'
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}
                                        primary={t('CabinetPartnerNavStructureTable')}/>
                                </ListItemButton>
                            </List>
                            <List component="div" disablePadding>
                                <ListItemButton sx={{pl: 3, color: 'white'}}
                                                selected={selectedIndex === 2}
                                                onClick={(event) => handleListItemClick(event, 2)}
                                                component={Link}
                                                to="./graphical-structure"
                                >
                                    <ListItemIcon>
                                        <Graphical
                                            style={{
                                                width: "20px",
                                                height: "20px",
                                                stroke: 'white',
                                                fill: 'white',
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        style={{display: 'flex', justifyContent: 'center', textAlign: 'center'}}
                                        primary={t('CabinetPartnerNavStructureGraph')}/>
                                </ListItemButton>
                            </List>
                        </Collapse>
                        <ListItemButton
                            style={{display: "flex", paddingLeft: "0", color: 'white'}}
                            selected={selectedIndex === 3}
                            onClick={(event) => handleListItemClick(event, 3)}
                            component={Link}
                            to="referral"
                        >
                            <ListItemIcon
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <HandShake style={{
                                    width: "30px",
                                    height: "30px",
                                }}/>

                            </ListItemIcon>
                            <ListItemText primary={t('referrals')}/>
                            {selectedIndex != 3 && <NavigationArrow/>}
                        </ListItemButton>
                        <ListItemButton
                            style={{display: "flex", paddingLeft: "0", color: 'white'}}
                            selected={selectedIndex === 4}
                            onClick={(event) => handleListItemClick(event, 4)}
                            component={Link}
                            to="events"
                        >
                            <ListItemIcon
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <Events style={{
                                    width: "27px",
                                    height: "27px",
                                }}/>

                            </ListItemIcon>
                            <ListItemText primary={t('events')}/>
                            {selectedIndex != 4 && <NavigationArrow/>}
                        </ListItemButton>
                        <ListItemButton
                            style={{display: "flex", paddingLeft: "0", color: 'white'}}
                            selected={selectedIndex === 5}
                            // onClick={(event) => handleListItemClick(event, 4)}
                            onClick={handleExit}
                            component={Link}
                            to="/registration"
                        >
                            <ListItemIcon
                                style={{display: "flex", justifyContent: "center"}}
                            >
                                <Exit
                                    style={{
                                        width: "30px",
                                        height: "30px",
                                        stroke: 'white',
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText primary={t('exit')}/>
                        </ListItemButton>
                    </List>
                </Drawer>
            )}
        </>
    );
}

export default PartnersNavigation;
