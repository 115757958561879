import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import store from './store/store'
import {BrowserRouter} from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18next/i18next';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
          <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
        </I18nextProvider>
    </Provider>
);

reportWebVitals();
