import React, {useEffect, useState} from "react";
import "../../../Style/Bills.scss";
import CachedIcon from "@mui/icons-material/Cached";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import Typography from "@mui/material/Typography";
// import '../../Style/Bills/BillsCard.scss'
import Link from "@mui/material/Link";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {useMediaQuery, useTheme} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {ReactComponent as AddIcon} from "../../../images/Bills/add.svg";
import {ReactComponent as ReplayIcon} from "../../../images/Bills/reload.svg";
import {ReactComponent as Dollar} from "../../../images/Bills/dollar.svg";
import {ReactComponent as Euro} from "../../../images/Bills/euro.svg";
import {ReactComponent as USDT} from "../../../images/Bills/usdt.svg";

function Bills() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [walletPrimari, setWalletPrimari] = useState([]);
    const [walletBonus, setWalletBonus] = useState([]);
    const [walletAuxiliary, setWalletAuxiliary] = useState([]);
    const [wallet, setWallet] = useState("");
    const {t, i18n} = useTranslation();
    const navigate = useNavigate();

    const styleIconButton = {
        width: isSmallScreen ? "34px" : "34px",
        height: isSmallScreen ? "34px" : "34px",
        // color: "black",
    };
    const styleIconButtonRep = {
        width: isSmallScreen ? "34px" : "34px",
        height: isSmallScreen ? "34px" : "34px",
        // color: "black",
    };

    const handleReplenishment = () => {
        navigate("/replenishment");
    };

    const handleReplayAccount = () => {
        handleAddBills();
    };

    useEffect(() => {
        handleAddBills();
    }, []);

    const handleAddBills = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT) + "/client";
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            console.log(response.data)
            setWallet(response.data);
            response.data.forEach((walletItem) => {
                if (walletItem.accountType === "PRIMARY") {
                    setWalletPrimari(walletItem);
                } else if (walletItem.accountType === "BONUS") {
                    setWalletBonus(walletItem);
                } else if (walletItem.accountType === "AUXILIARY") {
                    setWalletAuxiliary(walletItem)
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <>
            <div className="wrapperBills">
                <div
                    className="billsCardMain"
                >
                    <div>
                        <Typography
                            variant="h6"
                            sx={{flex: 1, display: "flex", justifyContent: "space-between"}}
                        >
                            {walletPrimari && walletPrimari.currencyAmount && <>
                                <div>
                                    <div className="billMoney">
                                        <Euro style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletPrimari.currencyAmount.eurAmount}
                                        </span>
                                    </div>
                                    <div className="billMoney">
                                        <Dollar style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '1px'}}>
                                            {walletPrimari.currencyAmount.usdAmount}
                                        </span>
                                    </div>
                                    <div className="billMoney">
                                        <USDT style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletPrimari.currencyAmount.usdtAmount}
                                        </span>
                                    </div>
                                    <Typography
                                        style={{
                                            fontFamily: "HelveticaNeueCyr",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "22px",
                                            textAlign: "left",
                                            color: "gray",
                                        }}
                                        component="p"
                                        variant="h5"
                                    >
                                        {t("MainPageBillsMainBalanceUSD")}
                                    </Typography>
                                </div>
                            </>}
                            <div className="buttonBillsCard">
                                <div className="buttonsWrapper" style={{alignItems: 'flex-start'}}>
                                    <div
                                        className="buttonAddWrapper"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            className="buttonAddWrapper"
                                            style={{cursor: "pointer"}}
                                        >
                                            <AddIcon
                                                style={styleIconButton}
                                                className="activeElement"
                                                onClick={() => handleReplenishment()}
                                            />
                                        </div>
                                    </div>

                                    <div
                                        className="buttonReloadWrapper"
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            marginRight: "5px",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <div
                                            className="buttonReloadWrapper"
                                            style={{cursor: "pointer"}}
                                        >
                                            <ReplayIcon
                                                style={styleIconButtonRep}
                                                className="activeElement"
                                                onClick={() => handleReplayAccount()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </div>


                </div>
                <div
                    className="billsCardMain"
                >
                    <div>
                        <Typography
                            variant="h6"
                            sx={{flex: 1, display: "flex", justifyContent: "space-between"}}
                        >
                            {walletBonus && walletBonus.currencyAmount && <>
                                <div>
                                    <div className="billMoney">
                                        <Euro style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletBonus.currencyAmount.eurAmount}
                                        </span>
                                    </div>
                                    <div className="billMoney">
                                        <Dollar style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '1px'}}>
                                          {walletBonus.currencyAmount.usdAmount}
                                        </span>
                                    </div>
                                    <div className="billMoney">
                                        <USDT style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletBonus.currencyAmount.usdtAmount}
                                        </span>
                                    </div>
                                    <Typography
                                        style={{
                                            fontFamily: "HelveticaNeueCyr",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "22px",
                                            textAlign: "left",
                                            color: "gray",
                                        }}
                                        component="p"
                                        variant="h5"
                                    >
                                        {t("BillsCardBonusBalance")}
                                    </Typography>
                                </div>
                            </>}
                            <div className="buttonBillsCard">
                                <div className="buttonsWrapper" style={{alignItems: 'flex-start'}}>
                                    <div className="buttonBillsCard">
                                        <div className="buttonsWrapper">
                                        <div className="buttonAddWrapper">
                                                <RemoveCircleOutlineIcon style={styleIconButtonRep}/>
                                            </div>
                                            <div className="buttonReloadWrapper">
                                                <ReplayIcon style={styleIconButtonRep}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </div>


                </div>
                <div
                    className="billsCardMain"
                >
                    <div>
                        <Typography
                            variant="h6"
                            sx={{flex: 1, display: "flex", justifyContent: "space-between"}}
                        >
                            {walletAuxiliary && walletAuxiliary.currencyAmount && <>
                                <div>
                                    <div className="billMoney">
                                        <Euro style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletAuxiliary.currencyAmount.eurAmount}
                                        </span>
                                    </div>
                                    <div className="billMoney">
                                        <Dollar style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '1px'}}>
                                          {walletAuxiliary.currencyAmount.usdAmount}
                                        </span>

                                    </div>
                                    <div className="billMoney">
                                        <USDT style={{width: '25px', height: '25px'}}/>
                                        <span style={{marginLeft: '3px'}}>
                                            {walletAuxiliary.currencyAmount.usdtAmount}
                                        </span>
                                    </div>
                                    <Typography
                                        style={{
                                            fontFamily: "HelveticaNeueCyr",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                            lineHeight: "22px",
                                            textAlign: "left",
                                            color: "gray",
                                        }}
                                        component="p"
                                        variant="h5"
                                    >
                                        {t("BillsCardAuxiliaryBalance")}
                                    </Typography>
                                </div>
                            </>}
                            <div className="buttonBillsCard">
                                <div className="buttonsWrapper" style={{alignItems: 'flex-start'}}>
                                    <div className="buttonBillsCard">
                                        <div className="buttonsWrapper">
                                        <div className="buttonAddWrapper">
                                                <RemoveCircleOutlineIcon style={styleIconButtonRep}/>
                                            </div>
                                            <div className="buttonReloadWrapper">
                                                <ReplayIcon style={styleIconButtonRep}/>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Typography>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Bills;
