import React, { useEffect, useState } from "react";
import '../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';

function ProfileTitle () {
  const { t, i18n } = useTranslation();


  
    return(
        <div className="nameWrapper" style={{width:'95%'}}>
        <span className="nameText"> 

        {t('ProfileTitle')}

    
        </span>
        </div>
    )
}

export default ProfileTitle