import React, { useEffect, useState } from "react";
import ProjectName from "./NameProject";
import "../../../Style/Project/Project.scss";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { Divider, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material";
import { ReactComponent as MoreDetails } from "../../../images/Project/moreDetails.svg";
function ProjectBody() {
  const [gaugeWidth, setGaugeWidth] = useState(120);
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const [images, setImages] = useState(new Map());
  const { t, i18n } = useTranslation();
  const [allProject, setAllProject] = useState(false);
  const [age, setAge] = React.useState("All");
  const [moreParams, setMoreParams] = useState(false);
  const [pagination, setPagination] = useState();
  const [page, setPage] = useState(1);
  const isSmallScreen = useMediaQuery("(max-width:1400px)");
  const isSmallScreenProject = useMediaQuery("(max-width:620px)");
  const isSmallScreenProjectGuage = useMediaQuery("(max-width: 570px)");
  useEffect(() => {
    const fetchAllImages = async () => {
      const imagesMap = new Map();

      for (const project of projects) {
        const urlId = project.projectFiles.primaryPhotoId;
        if (!urlId) {
          imagesMap.set(project.id, null);
        } else {
          imagesMap.set(project.id, await fetchImageById(urlId));
        }
      }
      setImages(imagesMap);
    };

    fetchAllImages();
  }, [projects]);

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      // No value to display
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="#3fab3f" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="#3fab3f"
          strokeWidth={3}
        />
      </g>
    );
  }

  useEffect(() => {
    getProject();
  }, []);

  const handleMoreInfo = (project) => {
    navigate("./project", {
      state: {
        project: project,
      },
    });
  };

  const getProject = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT) + "/active";
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
          params: {
            size: 3,
            page: page - 1,
          },
        })
        .then((response) => {
          setProjects(response.data.content);
          setPagination(response.data.totalPages);
          setAllProject(false);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchImageById = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });

      return URL.createObjectURL(response.data);
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // пагинация
  const handleChangePage = (event, value) => {
    setPage(value);
    window.scrollTo({ top: 1150, behavior: "smooth" });
  };

  useEffect(() => {
    getProject();
  }, [page]);

  // поиск по параметрам

  const handleChange = (event) => {
    setAge(event.target.value);
    if (event.target.value === "All") {
      getProject();
    } else {
      handleParamsProject(event.target.value);
    }
  };

  const handleParamsProject = async (name) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.PROJECT) + `/${name}`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setProjects(response.data.content);
          setMoreParams(true);
          // setAllProject(true)
        });
    } catch (error) {
      console.error(error);
    }
  };

  const StyledSelect = styled(Select)({
    borderRadius: "20px",
    height: "50px",
    backgroundColor: "white",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "20px",
    },
  });

  const StyledMenuItem = styled(MenuItem)({
    borderRadius: "10px", 
  });

  return (
    <>
      <div
        className="nameWrapper"
        style={{
          marginBottom: "15px",
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          margin: "0px auto 20px",
        }}
      >
        <span style={{ fontSize: "30px" }}>{t("MainPageProjectTitle")}</span>
        <FormControl style={{ width: "200px", marginLeft: "10px" }}>
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={age}
            onChange={handleChange}
            style={{
              borderRadius: "20px",
              height: "50px",
              backgroundColor: "white",
            }}
          >
            <StyledMenuItem value={"All"}>
              {t("MainPageDropDownAll")}
            </StyledMenuItem>
            <StyledMenuItem value={"opened"}>
              {t("MainPageDropDownOpened")}
            </StyledMenuItem>
            <StyledMenuItem value={"paid"}>
              {t("MainPageDropDownPaid")}
            </StyledMenuItem>
            <StyledMenuItem value={"collected"}>
              {t("MainPageDropDownCollected")}
            </StyledMenuItem>
          </StyledSelect>
        </FormControl>
      </div>
      {!isSmallScreen && (
        <div className="wrapperProjectMain">
          {projects.map((item, index) => (
            <div className="projectPreviewWrapper">
              <div className="projectContainer">
                {images.has(item.id) && images.get(item.id) ? (
                  <div
                    className="projectImg"
                    style={{ backgroundImage: `url(${images.get(item.id)})` }}
                  ></div>
                ) : (
                  <div className="projectImg placeholderImage"></div>
                )}
                <div className="projectNameInfoPullWrapper">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                    }}
                  >
                    <div className="nameWrapperProject">{item.title}</div>
                    <div
                      className="moreDetails activeElement"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                      onClick={() => handleMoreInfo(item)}
                    >
                      <Link
                        className="moreDetails"
                        style={{ cursor: "pointer" }}
                      >
                        <MoreDetails />
                      </Link>
                    </div>
                  </div>
                  <div className="projectInfoPullWrapper">
                    <div className="projectInfoWrapper">
                      <div className="projectInfoFirst">
                        {/* <div className="projectInfo">
                          <div className="imgInfo" id="monthMoney"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">20%</div>
                            <div className="infoText">
                              {t("MainPageProjectProfitPerMonth")}
                            </div>
                          </div>
                        </div>
                        <div className="projectInfo">
                          <div className="imgInfo" id="minPrice"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">$ {item.minPrice}</div>
                            <div className="infoText" id="infoTextGreen">
                              {t("MainPageProjectMinimumTransactionAmount")}
                            </div>
                          </div>
                        </div> */}
                                <div className="projectInfo">
                          <div className="imgInfo" id="calendarProject"></div>
                          <div className="infoTextWrapper">
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramStart"
                              )}
                              : {item.beginDate}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="projectInfoSecond">
                        <div className="projectInfo">
                          <div className="imgInfo" id="calendarProject"></div>
                          <div className="infoTextWrapper">
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramEnd"
                              )}
                              : {item.endDate}
                            </div>
                          </div>
                        </div>
                        {/* <div className="projectInfo">
                          <div className="imgInfo" id="minRepeatPrice"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">
                              $ {item.repeatPrice}
                            </div>
                            <div className="infoText" id="infoTextGreen">
                              {t("MainPageProjectMinRepeatTransactionAmount")}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className="projectPullWrapper">
                      <div className="projectPull">
                        <GaugeContainer
                          width={gaugeWidth}
                          height={120}
                          startAngle={-110}
                          endAngle={110}
                          // value={(item.income / item.poolSum) * 100}
                          value={Math.min((item.income / item.poolSum) * 100, 100)}
                          className="custom-gauge-container"
                        >
                          <GaugeReferenceArc className="custom-reference-arc" />
                          <GaugeValueArc className="custom-value-arc" />
                          <GaugePointer className="custom-pointer" />
                        </GaugeContainer>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            maxWidth: "200px",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ fontSize: "12px" }}>
                              €{item.income}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                              €{item.poolSum}
                            </div>
                          </div>
                          <div style={{ margin: "5px auto" }}>
                            {t("MainPageProjectPoolFilling")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {index < projects.length - 1 && (
                <Divider style={{ marginTop: "20px" }} />
              )}
            </div>
          ))}
          <Stack spacing={2}>
            <Pagination
              style={{
                marginTop: "40px",
                marginLeft: "31px",
                marginBottom: "43px",
                "& .MuiPaginationItem-root": {
                  color: "#6C757D",
                },
                "& .Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "white",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#6C757D",
                },
              }}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#6C757D",
                },
                "& .Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "white",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#6C757D",
                },
              }}
              count={pagination}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Stack>
        </div>
      )}

      {isSmallScreen && (
        <div className="wrapperProjectMain">
          {projects.map((item, index) => (
            <div className="projectPreviewWrapper">
              <div className="projectContainer">
                {isSmallScreenProject && (
                  <div className="projectLinksWrapper">
                    <div className="nameWrapperProject">{item.title}</div>
                    <div
                      className="moreDetails activeElement"
                      style={{ marginTop: "10px", marginRight: "20px" }}
                      onClick={() => handleMoreInfo(item)}
                    >
                      <Link
                        className="moreDetails"
                        style={{ cursor: "pointer" }}
                      >
                        <MoreDetails />
                      </Link>
                    </div>
                  </div>
                )}
                {images.has(item.id) && images.get(item.id) ? (
                  <div className="wrapperImageGuage">
                    <div
                      className="projectImg"
                      style={{ backgroundImage: `url(${images.get(item.id)})` }}
                    ></div>
                    {!isSmallScreenProjectGuage && (
    <div className="projectPullWrapper">
    <div className="projectPull">
      <GaugeContainer
        width={gaugeWidth}
        height={120}
        startAngle={-110}
        endAngle={110}
        value={Math.min((item.income / item.poolSum) * 100, 100)}
        className="custom-gauge-container"
      >
        <GaugeReferenceArc className="custom-reference-arc" />
        <GaugeValueArc className="custom-value-arc" />
        <GaugePointer className="custom-pointer" />
      </GaugeContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "200px",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "13px" }}>
            €{item.income}
          </div>
          <div style={{ fontSize: "13px" }}>
            €{item.poolSum}
          </div>
        </div>
        <div style={{ margin: "5px auto" }}>
          {t("MainPageProjectPoolFilling")}
        </div>
      </div>
    </div>
  </div>
                    )}
                
                  </div>
                ) : (
                  <div className="wrapperImageGuage">
                    <div className="projectImg placeholderImage"></div>
                  {!isSmallScreenProjectGuage && (
          <div className="projectPullWrapper">
          <div className="projectPull">
            <GaugeContainer
              width={gaugeWidth}
              height={120}
              startAngle={-110}
              endAngle={110}
              // value={(item.income / item.poolSum) * 100}
              value={Math.min((item.income / item.poolSum) * 100, 100)}
              className="custom-gauge-container"
            >
              <GaugeReferenceArc className="custom-reference-arc" />
              <GaugeValueArc className="custom-value-arc" />
              <GaugePointer className="custom-pointer" />
            </GaugeContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                maxWidth: "200px",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ fontSize: "13px" }}>
                  ${item.income}
                </div>
                <div style={{ fontSize: "13px" }}>
                  ${item.poolSum}
                </div>
              </div>
              <div style={{ margin: "5px auto" }}>
                {t("MainPageProjectPoolFilling")}
              </div>
            </div>
          </div>
        </div>
                  )}
          
                  </div>
                )}
                <div className="projectNameInfoPullWrapper">
                  {!isSmallScreenProject && (
                    <div className="projectLinksWrapper">
                      <div className="nameWrapperProject">{item.title}</div>
                      <div
                        className="moreDetails activeElement"
                        style={{ marginTop: "10px", marginRight: "20px" }}
                        onClick={() => handleMoreInfo(item)}
                      >
                        <Link
                          className="moreDetails"
                          style={{ cursor: "pointer" }}
                        >
                          <MoreDetails />
                        </Link>
                      </div>
                    </div>
                  )}

                  <div className="projectInfoPullWrapper">
                    <div className="projectInfoWrapper">
                      <div className="projectInfoFirst">
                        {/* <div className="projectInfo">
                          <div className="imgInfo" id="monthMoney"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">20%</div>
                            <div className="infoText">
                              {t("MainPageProjectProfitPerMonth")}
                            </div>
                          </div>
                        </div>
                        <div className="projectInfo">
                          <div className="imgInfo" id="minPrice"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">$ {item.minPrice}</div>
                            <div className="infoText">
                              {t("MainPageProjectMinimumTransactionAmount")}
                            </div>
                          </div>
                        </div> */}
                           <div className="projectInfo">
                          <div className="imgInfo" id="calendarProject"></div>
                          <div className="infoTextWrapper">
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramStart"
                              )}
                              : {item.beginDate}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="projectInfoSecond">
                        <div className="projectInfo">
                          <div className="imgInfo" id="calendarProject"></div>
                          <div className="infoTextWrapper">
                            <div className="infoText">
                              {t(
                                "MainPageProjectDurationOfPlacementInTheProgramEnd"
                              )}
                              : {item.endDate}
                            </div>
                          </div>
                        </div>
                        {/* <div className="projectInfo">
                          <div className="imgInfo" id="minRepeatPrice"></div>
                          <div className="infoTextWrapper">
                            <div className="infoMoney">
                              $ {item.repeatPrice}
                            </div>
                            <div className="infoText" id="infoTextGreen">
                              {t("MainPageProjectMinRepeatTransactionAmount")}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                    {isSmallScreenProjectGuage && (
     <div className="projectPullWrapper">
     <div className="projectPull">
       <GaugeContainer
         width={gaugeWidth}
         height={120}
         startAngle={-110}
         endAngle={110}
         value={Math.min((item.income / item.poolSum) * 100, 100)}
         className="custom-gauge-container"
       >
         <GaugeReferenceArc className="custom-reference-arc" />
         <GaugeValueArc className="custom-value-arc" />
         <GaugePointer className="custom-pointer" />
       </GaugeContainer>
       <div
         style={{
           display: "flex",
           justifyContent: "space-between",
           width: "100%",
           maxWidth: "200px",
           flexDirection: "column",
         }}
       >
         <div
           style={{
             display: "flex",
             width: "100%",
             justifyContent: "space-between",
           }}
         >
           <div style={{ fontSize: "13px" }}>
             ${item.income}
           </div>
           <div style={{ fontSize: "13px" }}>
             ${item.poolSum}
           </div>
         </div>
         <div style={{ margin: "5px auto" }}>
           {t("MainPageProjectPoolFilling")}
         </div>
       </div>
     </div>
   </div>
                    )}
               
                  </div>
                </div>
              </div>
              {index < projects.length - 1 && (
                <Divider style={{ marginTop: "20px" }} />
              )}
            </div>
          ))}
          <Stack spacing={2}>
            <Pagination
              style={{
                marginTop: "40px",
                marginLeft: "31px",
                marginBottom: "43px",
                "& .MuiPaginationItem-root": {
                  color: "#6C757D",
                },
                "& .Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "white",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#6C757D",
                },
              }}
              sx={{
                "& .MuiPaginationItem-root": {
                  color: "#6C757D",
                },
                "& .Mui-selected": {
                  backgroundColor: "#3f51b5",
                  color: "white",
                },
                "& .MuiPaginationItem-ellipsis": {
                  color: "#6C757D",
                },
              }}
              count={pagination}
              page={page}
              onChange={handleChangePage}
              color="primary"
            />
          </Stack>
        </div>
      )}
    </>
  );
}

export default ProjectBody;
