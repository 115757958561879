import React, { useEffect, useState } from "react";
import ReplenishmentTitle from "./ReplenishmentTitle";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Clock } from "../../../images/clock-svgrepo-com.svg";
import Link from "@mui/material/Link";
import { useTranslation } from 'react-i18next';


function ReplenishmentSuccess () {
  const { t, i18n } = useTranslation();

    const navigate = useNavigate()

    const handleExit = () => {
        navigate('/mainpage/bills')
    }


    return(
        <div className="replenishmentWrapperConclusion">
        <ReplenishmentTitle />
     
        <div className="replenishmentContainer" style={{height:'80vh', display:'flex', alignItems:'center', flexDirection:'column', justifyContent:'center'}}>
            <div style={{width:'100px', height:'100px', marginBottom:'10px'}}>
                <Clock style={{width:'100px', height:'100px', }}/>
            </div>
          <div>
            <div style={{display:"flex", justifyContent:'center'}}>
            <p>
                <b>
                   {t('BillsRefillSuccessCongratulations')}
                </b>
            </p>
            </div>
            <div style={{display:"flex", justifyContent:'center'}}>

            <p>
                <b>
                {t('BillsRefilDesc')}
                </b>
            </p>
            </div>
            <div style={{display:"flex", justifyContent:'center', textAlign:'center'}}>

            <p>
                {t('BillsRefilDescSecond')}
            </p>
            </div>
          </div>
          <div style={{display:"flex", justifyContent:'center'}}>
          <div className="buttonPay" onClick={() => handleExit()}>
                   {t('BillsRefilButton')}
        </div>
        </div>
        </div>
      </div>
    )
}

export default ReplenishmentSuccess