import React from "react";
import NavigationProfile from "./NavigationProfile";
import ProfileTitle from "./ProfileTitle";
import '../../Style/Profile/ProfileBody.scss'
import ProfileSectionsContainer from "./ProfileSectionsContainer";
import RouterProfile from "./RouteProfile";
import { Outlet } from "react-router-dom";

function IndexBody () {
    return(
        <div className="profileBodyWrapper">
        <NavigationProfile/>
        {/* <RouterProfile/> */}
        <Outlet/>
        </div>
        
    )
}

export default IndexBody