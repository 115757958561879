import React, { useEffect, useState } from "react";
import "../../../Style/Partners/BonusPartner.scss";
import {Typography} from "@mui/material";
import {CalendarIcon} from "@mui/x-date-pickers";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import {Gauge, gaugeClasses} from '@mui/x-charts/Gauge';
import Link from "@mui/material/Link";
import BlockIcon from '@mui/icons-material/Block';
import { useTranslation } from 'react-i18next';

function StatisticPartner() {
    const { t, i18n } = useTranslation();

    return (
        <div className="wrapperBonusPartner" style={{padding: '20px'}}>
            <div style={{width: "100%"}}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <Typography style={{display: "flex", flexDirection: "column"}}>
                        {t('CabinetPartnerStatic')}
                        <span style={{color: "gray", fontSize: "12px"}}>
                01.05.2024 - 24.05.2024
              </span>
                    </Typography>
                    <div>
                        <CalendarIcon/>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    padding: '50px'
                }}>
                    <BlockIcon style={{width: '150px', height: '150px'}}/>
                    <div>
                        {t('CabinetPartnerStaticNone')}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default StatisticPartner