import React, { useEffect, useState } from "react";
import '../../../Style/NameCabinet.scss'
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";


function NameCabinet () {
  const { t, i18n } = useTranslation();


    return(
        <div className="nameWrapper">
        <span className="nameText"> 
    
        {t('ClientDashboard')}
      
        </span>
        </div>
    )
}

export default NameCabinet