import React, { useEffect, useState } from "react";
import { Divider, Drawer } from "@mui/material";
import { useSelector } from "react-redux";
import "../../Style/cabinet/Cabinet.scss";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { ReactComponent as Trash } from "../../images/trash.svg";
import { ReactComponent as Edit } from "../../images/edit.svg";
import ImageCropperModal from "../../Modal/PhotoEditorModal";
import PhotoDeleteModal from "../../Modal/PhotoDeleteModal";

function Cabinet({ isSmallScreen, isOpen, onClose }) {
  const data = useSelector((state) => state.reducer.data);
  const [cabData, setCabData] = useState([]);
  const [inviter, setInviter] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openModalDelete, setModalDelete] = useState(false);
  const { t, i18n } = useTranslation();
  const [croppedImage, setCroppedImage] = useState(null);
  const [photoCab, setPhotoCab] = useState(new Map());
  const [photoInviter, setPhotoInviter] = useState(new Map());
  const [photoId, setPhotoId] = useState();
  const getUser = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/client`;
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
        })
        .then((response) => {
          setCabData(response.data.personal);
          setInviter(response.data.inviter);
          getPhotoId(response.data.personal.urlId);
          getPhotoInviter(response.data.inviter.urlId);
          // setPhotoId(response.data.personal.urlId)
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  // Получить фото Personal
  const getPhotoId = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob",
      });

      setPhotoCab(URL.createObjectURL(response.data));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // Получить фото Inviter

  const getPhotoInviter = async (id) => {
    if (!id) return null;

    const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        responseType: "blob", // Ensure response is in blob format
      });

      // Convert blob to object URL
      // return URL.createObjectURL(response.data);
      setPhotoInviter(URL.createObjectURL(response.data));
    } catch (error) {
      console.error(`Failed to fetch image/document with id ${id}`, error);
      return null;
    }
  };

  // Модальное окно Редактирования

  const hendleOpenModalPhotoEditor = () => {
    setOpenModal(true);
  };
  const handleClose = () => setOpenModal(false);
  const handleSave = async (cropper) => {
    // const cropper = cropperRef.current.cropper;
    const apiUrl = ENDPOINTS.USER + `/avatar`;
    cropper
      .getCroppedCanvas({
        width: 96,
        height: 96,
        fillColor: "#fff",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      })
      .toBlob(async (blob) => {
        const formData = new FormData();
        formData.append("image", blob, "image.png");

        try {
          const response = await axios.put(apiUrl, formData, {
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
          });
        } catch (error) {
          console.error("Upload error:", error);
        } finally {
          getUser();
        }
      }, "image/png");
  };

  // Модальное окно Удаления

  const hendleOpenModalDelete = () => {
    setModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setModalDelete(false);
  };

  const handleDelete = async () => {
    getUser();
  };

  const styleDivider = {
    width: "90%",
    margin: "0 auto",
  };

  return (
    <>
      {!isSmallScreen && (
        <Drawer anchor="right" open={isOpen} onClose={onClose}   PaperProps={{
          sx: {
            width: '250px',
          },
        }}>
          <div className="myAccountWrapper">
            <div className="myAccount">
              <div className="myAcountImageWrapper">
                <div
                  className="myAccountImage"
                  style={{ backgroundImage: `url(${photoCab})` }}
                >
                  <div className="buttons">
                    <button
                      className="hover-button"
                      onClick={hendleOpenModalDelete}
                    >
                      <Trash style={{ width: "20px", height: "20px" }} />
                    </button>
                    <button
                      className="hover-button"
                      onClick={hendleOpenModalPhotoEditor}
                    >
                      <Edit style={{ width: "25px", height: "25px" }} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="myAccountTextWrapper">
                <div
                  className="myAccountImageLogin"
                  style={{ backgroundImage: `url(${photoCab})` }}
                ></div>
                <div className="myAccountLoginText">{cabData.login}</div>
              </div>
            </div>
            <Divider style={styleDivider} />
            <div className="myAccountPortfolioWrapper">
              <div className="myAccountPortfolioContainer">
                <div className="myAccountPortfolio">
                  <div className="imgPortfolioWrapper"></div>
                  <div className="portfolioDescription">
                    {cabData && cabData.personalActivePortfolio &&
                        <div className="portfolioDescriptionMoney">
                          <b>€{cabData.personalActivePortfolio.eurAmount} </b>
                          <b>${cabData.personalActivePortfolio.usdAmount} </b>
                          <b>₮{cabData.personalActivePortfolio.usdtAmount}</b>
                        </div>
                    }
                    <div className="portfolioDescriptionText">
                      {t("MyCabinetPersonalActivePortfolio")}
                    </div>
                  </div>
                </div>
                <div className="myAccountPortfolio">
                  <div className="imgPortfolioWrapper"></div>
                  <div className="portfolioDescription">
                    {cabData && cabData.totalIncome &&
                        <div className="portfolioDescriptionMoney">
                          <b>€{cabData.totalIncome.eurAmount} </b>
                          <b>${cabData.totalIncome.usdAmount} </b>
                          <b>₮{cabData.totalIncome.usdtAmount}</b>
                        </div>
                    }
                    <div className="portfolioDescriptionText">
                      {t("MyCabinetTotalIncomeReceived")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Divider style={styleDivider} />
          </div>
          {inviter.login != null && 
                <div className="consultantLableWrapper">
                <div className="consultantLable">{t("MyCabinetConsultant")}</div>
              </div>
          }
    

          {inviter.login != null &&
                <div className="consultantWrapper">
                <div className="consultantContainer">
                  <div className="consultantConteinerSecond" >
                    <div className="consultant" id='consultantId'>
                      <div className="consultantImageWrapper">
                        <div
                          className="consultantImage"
                          style={{ backgroundImage: `url(${photoInviter})` }}
                        />
                        {inviter.statusName === "Без статуса" ? null : (
                          <div className="consultantWrapperText">
                            <div className="levelconsultantText">
                              {inviter.statusName}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="consultantNameWrapper">
                        <div className="consultantNameContainer">
                          <div className="consultantLoginWrapper">
                            <div className="consultantLoginText">
                              {inviter.login}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
                <div className="socialNetworkWrapper">
                  <div className="socialNetworkImgWrapper">
                    <div className="socialNetworkImgTg"></div>
                    <div className="socialNetworkText">nick_surname</div>
                  </div>
                  <div className="socialNetworkImgWrapper">
                    <div className="socialNetworkImgEmail"></div>
                    <div className="socialNetworkText">mail@mail.com</div>
                  </div>
                </div>
              </div>
          }
    
        </Drawer>
      )}

      <ImageCropperModal
        open={openModal}
        handleClose={handleClose}
        handleSave={handleSave}
      />
      <PhotoDeleteModal
        open={openModalDelete}
        handleClose={handleCloseModalDelete}
        handleRerender={handleDelete}
      />
    </>
  );
}

export default Cabinet;
