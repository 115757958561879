// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperStaticBill {
  width: 20%;
}

.billsCardTable {
  background: white;
  width: 20%;
  border-radius: 20px;
  padding: 20px;
}

.myAccountPortfolioBill {
  display: flex;
  flex-direction: row;
}

.imgPortfolioWrapperBill {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.portfolioDescriptionBill {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 770px) {
  .wrapperStaticBill {
    width: 90%;
    margin-bottom: 20px;
  }
  .billsCardTable {
    background: white;
    width: 90%;
    border-radius: 20px;
    padding: 20px;
    margin-bottom: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Bills/BillsStatic.scss"],"names":[],"mappings":"AAAA;EACA,UAAA;AACA;;AAEA;EACI,iBAAA;EACA,UAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;AACJ;;AAKA;EACI;IACI,UAAA;IACA,mBAAA;EAFN;EAKM;IACI,iBAAA;IACA,UAAA;IACA,mBAAA;IACA,aAAA;IACJ,mBAAA;EAHN;AACF","sourcesContent":[".wrapperStaticBill{\nwidth: 20%;\n}\n\n.billsCardTable{\n    background:white;\n    width:20%;\n    border-radius: 20px;\n    padding: 20px;\n}\n\n.myAccountPortfolioBill{\n    display: flex;\n    flex-direction: row;\n\n}\n.imgPortfolioWrapperBill{\n    display: flex;\n    align-items: center;\n    margin-right: 10px;\n\n}\n.portfolioDescriptionBill{\n    display: flex;\n    flex-direction: column;\n\n\n}\n\n\n@media screen and (max-width: 770px) {\n    .wrapperStaticBill{\n        width: 90%;\n        margin-bottom: 20px;\n        }\n\n        .billsCardTable{\n            background:white;\n            width:90%;\n            border-radius: 20px;\n            padding: 20px;\n        margin-bottom: 20px;\n\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
