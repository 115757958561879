import React, { useEffect, useState } from "react";
import "../../../../Style/MoneyTransaction/MoneyTransaction.scss";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

function HorizontalNonLinearStepper() {

  const stepComponents = [<StepOne />, <StepTwo />, <StepThree />];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const steps = [`${t('BillsMoneyTransactionFirstStep')}`, `${t('BillsMoneyTransactionSecondStep')}`, `${t('BillsMoneyTransactionThirdStep')}`];

 

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    navigate("/mainpage/bills");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <div className="stepOneWrapper" style={{ padding: "60px" }}>
              <div className="stepOneText">
                {t('BillsMoneyTransactionSuccess')}
              </div>
            </div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                pt: 2,
              }}
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="buttonStepNext" onClick={handleReset}>
                  {t('BillsMoneyTransactionButtonToBills')}
                </div>
              </div>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box sx={{ mt: 2 }}>{stepComponents[activeStep]}</Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "0 auto",
                pt: 2,
              }}
            >
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
                style={{ textTransform: "none" }}
              >
                {t('BillsMoneyTransactionButtonBack')}
              </Button>

              {activeStep !== steps.length &&
                (completed[activeStep] ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div className="buttonStepNext" onClick={handleNext}>
                      {t('BillsMoneyTransactionButtonNext')}
                    </div>
                  </div>
                ) : (
                  <Button
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontWeight: "500",
                    }}
                    onClick={handleComplete}
                  >
                    <div className="buttonStepNext">
                      {completedSteps() === totalSteps() - 1
                        ? `${t('BillsMoneyTransactionButtonChange')}`
                        : `${t('BillsMoneyTransactionButtonNext')}`}
                    </div>
                  </Button>
                ))}
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}

export default HorizontalNonLinearStepper;
