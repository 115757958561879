import React from 'react';
import '../../../Style/InviteStructure/InviteStructureTable.scss'

const PhotoCellRenderer = ({ value }) => {
  if (value) {
    return <img className='imageTableSturcture' src={value} alt="User" style={{ width: '50px', borderRadius: '50%' }} />;
  } else {
    return <div style={{ width: '50px', height: '50px', borderRadius: '50%', backgroundColor: 'white' }} />;
  }
};

export default PhotoCellRenderer;
