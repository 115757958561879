import React, { useState, useRef, useEffect } from "react";
import '../../../../Style/MoneyTransaction/MoneyTransaction.scss'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PaidIcon from '@mui/icons-material/Paid';
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useTranslation } from 'react-i18next';

function StepTwo () {
    const textFieldRef = useRef(null);
    const { t, i18n } = useTranslation();

 

  const handleCopy = () => {
    if (textFieldRef.current) {
      textFieldRef.current.select();
      document.execCommand("copy");
    }
  };
    const styleActive = {
        border:"1px solid green" ,
        minWidth:'250px',
        minHeight:'160px'
      };

    return (
         <div className="stepOneWrapper">
            <div className="stepOneText">
               {t('BillsMoneyTransactionSecondStepChooseBill')}
            </div>
            <div
            className="networkFirst"
            style={styleActive}
          >
            <div className="iconNetwork">
                <PaidIcon style={{width:'40px', height:'40px'}}/>
            </div>
            <div style={{padding:'10px', textAlign:'center'}} className="textNetwork"> {t('BillsMoneyTransactionSecondStepBonusBalance')} </div>

            <div style={{ maxWidth: "500px", margin: "0 auto" , width:'80%'}}>
          <TextField
            autoComplete="off"
            inputRef={textFieldRef}
            value={t('BillsMoneyTransactionSecondStepBalance')}
            InputProps={{
              style:{color:'gray'},
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
          />
        </div>
          </div>
        </div>
    )
}

export default StepTwo