// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policyWrapper {
  background-color: "#eef1fa";
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headerPolicyWrapper {
  width: 80%;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.policyText {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
}

.policyBodyWrapper {
  width: 80%;
  margin-top: 30px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

@media screen and (max-width: 700px) {
  .policyText {
    font-size: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/landing/Policy.scss"],"names":[],"mappings":"AAAA;EAEA,2BAAA;EAEA,WAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AADA;;AAIA;EACI,UAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AADJ;;AAIA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AADA;;AAKA;EACI,UAAA;EACJ,gBAAA;EACA,uBAAA;EACA,aAAA;EACA,mBAAA;AAFA;;AAOA;EACI;IACI,eAAA;EAJN;AACF","sourcesContent":[".policyWrapper{\n// background-color: #DCDCDC\t;\nbackground-color: '#eef1fa';\n\nwidth: 100%;\ndisplay: flex;\njustify-content: center;\nalign-items: center;\nflex-direction: column;\n}\n\n.headerPolicyWrapper{\n    width: 80%;\n    background-color: white;\n    padding: 30px;\n    border-radius: 10px;\n}\n\n.policyText{\ndisplay: flex;\njustify-content: center;\nalign-items: center;\ntext-align: center;\nfont-size: 30px;\nfont-weight: bold;\n\n}\n\n.policyBodyWrapper{\n    width: 80%;\nmargin-top: 30px;\nbackground-color: white;\npadding: 30px;\nborder-radius: 10px;\n\n}\n\n\n@media screen and (max-width: 700px) {\n    .policyText{\n        font-size: 20px;\n        }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
