import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ClearIcon from "@mui/icons-material/Clear";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import { ReactComponent as Home } from "../../images/Navigation/homeNav.svg";
import { ReactComponent as Cabinet } from "../../images/Navigation/cabinetClient.svg";
import { ReactComponent as Profile } from "../../images/Navigation/profileNav.svg";
import { ReactComponent as Bills } from "../../images/Navigation/BillsNav.svg";
import { ReactComponent as Exit } from "../../images/Navigation/exitNav.svg";
import { ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";
import FlagIcon from "@mui/icons-material/Flag";
import SiteSettingsModal from "../../Modal/SiteSettingsModal";
import { useTranslation } from 'react-i18next';
import {ReactComponent as Table} from '../../images/NavigationPartner/incomeTableNavigate.svg'
import {ReactComponent as Graphical} from '../../images/NavigationPartner/GraphicalNavigate.svg'
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import {ReactComponent as Settings} from '../../images/header/settings.svg'

function NavigationAdaptive({ isOpen, onClose, isClose }) {
  const [activeItem, setActiveItem] = useState("cabinet");
  const [buttonLabel, setButtonLabel] = useState("В Кабинет Партнера");
  const [buttonsTrue, setButtonsTrue] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [siteSettings, setSiteSettings] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  let params = "";
  const { t, i18n } = useTranslation();
  const [countOfUsers, setCountOfUsers] = useState(0)

  setTimeout(() => {
    setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
}, 200);

const handleModalOpen = () => {
    setIsModalOpen(true);
    document.body.style.overflow = 'hidden'
    onClose()
};

const handleModalClose = () => {
    setIsModalOpen(false);
    document.body.style.overflow = 'auto'
};




useEffect(() => {
  handleGetCountOfUsers()
  const storedLabel = localStorage.getItem('buttonLabel');
  if (storedLabel) {
    setButtonLabel(storedLabel);
  }
}, []);




  useEffect(() => {
    params = window.location.pathname;
   
    switch (params) {
      case "/consultant":
        setSelectedIndex(0);
        break;
      case "/consultant/invite-structure":
        setSelectedIndex(1);
        break;
      case "/consultant/graphical-structure":
        setSelectedIndex(2);
        break;
        default:
          break;
    }
  }, []);




  const handleCabinetChange = () => {
    navigate('/mainpage')

};

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    onClose();
  };

  const handleGetCountOfUsers = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/count`;
    try{
      await axios
      .get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      })
      .then((response) => {
        setCountOfUsers(response.data);
      });
    } catch (error) {
      console.error(error)
    }
   
  };

const handleExit = async () => {
  const apiUrl = urlFromTemplate(ENDPOINTS.AUTH) + `/logout`
  await axios.post(apiUrl, {
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Credentials": "true",
    },
  })
}

  return (
    <>
      {isOpen && buttonsTrue && (
        <Drawer
          open={isOpen}
          sx={{
            //   width: drawerWidth,
            backgroundColor: "#1C2434",

            flexShrink: 0,
            "& .MuiDrawer-paper": {
              // width: drawerWidth,
              boxSizing: "border-box",
              position: "static",
              display: "flex",
              flexDirection:'column-reverse',
              backgroundColor: "#1C2434",
              justifyContent:'flex-end'
            },
          }}
          variant="temporary"
          anchor="left"
        >
          <List style={{ width: "100%", backgroundColor: "#1C2434" , color:'white',  }}>
            <ListItemButton
              selected={selectedIndex === 0}
              onClick={(event) => handleListItemClick(event, 0)}
              component={Link}
              to="/consultant"

            >
              <ListItemIcon>
                <Home
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "10px",
                    fill:'white',
                  }}
                />
              </ListItemIcon>
              <ListItemText primary= {t('ConsultantDashboard')}/>
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 1}
              onClick={(event) => handleListItemClick(event, 1)}
              component={Link}
              to="./invite-structure "
            >
              <ListItemIcon>
                <Table
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "10px",
                    stroke:'white',
                    fill:'white'
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('CabinetPartnerNavStructureTable')} />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 2}
              onClick={(event) => handleListItemClick(event, 2)}
              component={Link}
              to="./graphical-structure"
            >
              <ListItemIcon>
                <Graphical
                  style={{
                    width: "25px",
                    height: "25px",
                    stroke:'white',
                    fill:'white',
                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('CabinetPartnerNavStructureGraph')}  />
            </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 3}
              onClick={(event) => handleListItemClick(event, 3)}
              component={Link}
              to="/mainpage/profile/profile"
            >
              <ListItemIcon>
                <Profile
                  style={{
                    width: "25px",
                    height: "25px",
                    fill:'white',
                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('profile')}  />
            </ListItemButton>
            {/* <ListItemButton
              selected={selectedIndex === 4}
              onClick={(event) => handleListItemClick(event, 4)}
              component={Link}
              to="/mainpage/bills"
            >
              <ListItemIcon>
                <Bills
                  style={{
                    width: "22px",
                    height: "22px",
                    fill:'white',
                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('bills')} />
            </ListItemButton> */}
            <ListItemButton
                style={{ display: "flex",  color:'white' }}
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
                component={Link}
                to="./referral"
              >
                <ListItemIcon
                  // style={{ display: "flex", justifyContent: "center" }}
                >
                  <Bills
                    style={{
                      width: "22px",
                      height: "22px",
                      fill:'white',
                    marginRight: "10px",

                    }}
                  />
                </ListItemIcon>
                <ListItemText primary= {t('referrals')}  />
              </ListItemButton>
            <ListItemButton
              selected={selectedIndex === 4}
              // onClick={(event) => handleListItemClick(event, 5)}
              onClick={handleExit}
              component={Link}
              to="/registration"
            >
              <ListItemIcon>
                <Exit
                  style={{
                    width: "25px",
                    height: "25px",
                    stroke:'white',
                    marginRight: "10px",
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={t('exit')} />
            </ListItemButton>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button style={{ minWidth: "150px", maxWidth:'150px' }} className=" yellowButton">
              {t('buttonConnectWallet')}
              </button>
            </ListItem>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ minWidth: "150px", maxWidth:'150px' }}
                className="buttonPartnersOffice"
                onClick={handleCabinetChange}
              >
                  {t('ClientDashboard')}
              </button>
            </ListItem>
            <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <div
                style={{ width: "20%", minWidth: "150px", maxWidth:'150px', color:'white' }}
                className="boxCountOfUser"
              >
<div className="countOfUser">{countOfUsers.count}</div>
<div className="tooltip"> {t('buttonCountOfUser')} </div>

              </div>
            </ListItem>
            {/* <ListItem style={{ display: "flex", justifyContent: "center" }}>
              <button
                style={{ width: "20%", minWidth: "150px", maxWidth:'150px' }}
                className="buttonSocialNetworkWrapper"
              >
                <div className="iconSocialNetwork">
                  <TelegramIcon />
                </div>
                <span>{t('buttonTelegramHeader')}</span>
              </button>
            </ListItem> */}
            <ListItem style={{display:'flex', justifyContent:'center'}}>
        
                <button
                  className="buttonSettingsWrapper"
                  onClick={handleModalOpen}
                  style={{minWidth:'150px', maxWidth:'150px'}}
                >
             <Settings style={{width:'34px', height:'34px'}}/>
                </button>
            </ListItem>
          </List>
          <ClearIcon style={{color:'white', width:'30px', height:'30px', padding:'20px'}} onClick={onClose} />

        </Drawer>
      )}
       <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose} />
    </>
  );
}

export default NavigationAdaptive;
