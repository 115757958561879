import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import "../../Style/ListOperation/ListBody.scss";
import "../../Style/ListOperation/Search.scss";
import { TextField, Box, Pagination, styled } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import SearchIcon from "@mui/icons-material/Search";
import { ReactComponent as ParamsIcon } from "../../images/params.svg";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from 'react-i18next';
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";

function ListBody() {
  const [pagePagination, setPagePagination] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [dateRange, setDateRange] = useState("");
  const { t, i18n } = useTranslation();
  const [contentTable, setContentTable] = useState([])
  const [pagination, setPagination] = useState()
  const [page, setPage] = useState(1)
  const pageSize = 5;


  const handleDateRangeChange = (event) => {
    setDateRange(event.target.value);
  };
  const handleOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (_event, value) => {
    setPagePagination(value);
  };

 

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleTab = () => {
    const firstTab = document.getElementById("listTabButtonfirst");
    firstTab.style.background = "white";
    const secondTab = document.getElementById("listTabButtonSecond");
    secondTab.style.background = "none";
  };

  const handleTabSecond = () => {
    const firstTab = document.getElementById("listTabButtonSecond");
    firstTab.style.background = "white";
    const secondTab = document.getElementById("listTabButtonfirst");
    secondTab.style.background = "none";
  };

  const handleTabDialog = () => {
    const firstTab = document.getElementById("listTabButtonWrapperFieldFirst");
    firstTab.style.background = "white";
    const secondTab = document.getElementById("listTabButtonWrapperFieldSecond");
    secondTab.style.background = "none";
  }

  const handleTabDialogSecond = () =>{
    const firstTab = document.getElementById("listTabButtonWrapperFieldSecond");
    firstTab.style.background = "white";
    const secondTab = document.getElementById("listTabButtonWrapperFieldFirst");
    secondTab.style.background = "none";
  }

  const columns = [
    { id: "name", label: "Name", minWidth: 170 },
    { id: "code", label: "ISO\u00a0Code", minWidth: 100 },
    {
      id: "population",
      label: "Population",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "size",
      label: "Size\u00a0(km\u00b2)",
      minWidth: 170,
      align: "right",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "density",
      label: "Density",
      minWidth: 170,
      align: "right",
      format: (value) => value.toFixed(2),
    },
  ];


  // Таблица 



  const getBillsTable = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG)
    try {
      await axios.get(apiUrl, {
        withCredentials: true,
        headers:{
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
        params:{
          size: 5,
          page: page -1 
        }
      }).then((response) => {
        setContentTable(response.data.content)
        setPagination(response.data.totalElements)
      })
    } catch (error) {
      console.error(error)
    }
  }

  const handleChangePage = (event, value) =>{
    setPage(value)
}

useEffect(() => {
  getBillsTable()
}, [page])



//Форматирование даты

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

  const documentMani = [
    {
      value: "Passport",
      label: `${t('ProfileBasicDataTypeOfDocumentPassport')}`,
    },
    {
      value: "Resident",
      label: `${t('ProfileBasicDataTypeOfDocumentResident')}`,
    },
    {
      value: "Drivers",
      label: `${t('ProfileBasicDataTypeOfDocumentDrivers')}`,
    },
    {
      value: "Identification",
      label: `${t('ProfileBasicDataTypeOfDocumentIdentification')}`,
    },
  ];

  const currency = [
    {
      value: "Euro",
      label: "Euro",
    },
    {
      value: "Dollar",
      label: "Dollar",
    },
  ];

  const billType = [
    {
      value: "TF",
      label: `${t('BillsCardMainBalance')}`,
    },
    {
      value: "TS",
      label: `${t('BillsCardBonusBalance')}`,
    },
    {
      value: "TT",
      label: `${t('BillsCardCumulativeBalance')}`,
    },
 
  ];

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  const StyledTextField = styled(TextField)({
    width: "100%",
    margin: "10px",
    '& input::placeholder': {
      padding: '0px',
      marginBottom:'0px'
    },
    '& .MuiInputBase-input': {
      // padding: '0px',
      marginBottom:'0px'
    }
  });

  const StyledTableHead = styled(TableHead)({
    background: 'black',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    '& th:first-of-type': {
      borderTopLeftRadius: '10px',
    },
    '& th:last-of-type': {
      borderTopRightRadius: '10px',
    }
  });

  return (
    <div className="wrapperListBody">
      {/* <div className="listTabWrapper">
        <div className="listTab">
          <div
            className="listTabButtonWrapper"
            id="listTabButtonfirst"
            onClick={() => handleTab()}
          >
            <div className="listTabButton">{t('BillsHistoryButtonFirst')}</div>
          </div>
          <div
            className="listTabButtonWrapper"
            onClick={() => handleTabSecond()}
            id="listTabButtonSecond"
          >
            <div className="listTabButton"> {t('BillsHistoryButtonSecond')} </div>
          </div>
        </div>
      </div> */}

      <div className="listSearchWrapper">
        <div className="listSearchBlockWrapper">
          <div className="listSearchBlockText"> {t('BillsHistorySearchTitle')} </div>
          <div className="listSearchBlock" >
            <StyledTextField
              autoComplete="off"
              placeholder= {t('BillsHistorySearchLable')}
              variant="outlined"
              size="small"
              style={{ width: "100%", margin: "10px", }}
              // value={searchUserPhone}
              // onChange={handleSearchChange}
              // onKeyDown={handleKeyPress}
              // inputRef={searchInputUser}
              InputProps={{
                style: { height: "40px", borderRadius:'10px' },
                startAdornment: (
                  <SearchIcon
                    style={{ cursor: "pointer" }}
                    // onClick={() => searchUserPhone && searchUsersByPhone(searchUserPhone) }
                  />
                ),
                endAdornment: (
                  <ParamsIcon
                    style={{ cursor: "pointer", width: "25px", height: "25px" }}
                    onClick={() => {
                      handleOpen();
                    }}
                  />
                ),
              }}
            />
          </div>
        </div>
        <div className="listSearchAllWrapper">
          <div className="listSearchAll" id="listSearchAll">
            <div className="listSearchAllText"> {t('BillsHistoryTotalCount')} </div>
            <div className="listSearchAllNumber">37</div>
          </div>
          <div className="listSearchAll">
            <div className="listSearchAllText" id="listSearchAllText">
              {t('BillsHistoryTotalCountInUSD')} EUR:
            </div>
            <div className="listSearchAllNumber">$</div>
          </div>
        </div>
      </div>

      <div className="listTableWrapper">

          <TableContainer style={{ boxShadow: "none" }} component={Paper} >
            <Table  aria-label="sticky table">
            <TableHead style={{background:'#F7F9FC',}}>
                        <TableRow style={{ border: "none" }}>
                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }}><b>{t('BillsTableSecondID')}</b></TableCell>
                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsTableSecondSender')}</b></TableCell>
                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsTableSecondRecipient')}</b></TableCell>
                            {/* <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsTableSecondPostingТame')}</b></TableCell> */}
                                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsHistorySearchAdvancedCurrency')}</b></TableCell>
                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsTableSecondTransactionAmount')}</b></TableCell>
                            <TableCell  style={{
                  border: "none",
                  fontFamily: "Helvetica Neue",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "22px",
                  textAlign: "left",
                  color: "#64748B",
                }} align="left"><b>{t('BillsTableSecondPostingDate')}</b></TableCell>
                        </TableRow>
              </TableHead>
              <TableBody>
              {contentTable.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell  style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                  component="th"
                  scope="row">
                    {item.id}
                  </TableCell>
                  <TableCell style={{
                    paddingLeft: "16px",
                    
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }} align="left">{item.transactionType}</TableCell>
                  <TableCell style={{
                    paddingLeft: "16px",
                    color: "#10B981",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }} align="left">{item.accountType}</TableCell>
                  <TableCell style={{
                    paddingLeft: "16px",
                    color: "#259AE6",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }} align="left">{item.currency}</TableCell>
          
                  <TableCell style={{
                    paddingLeft: "16px",
                    color: "#259AE6",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }} align="left">{item.amount}</TableCell>
                  <TableCell style={{
                    paddingLeft: "16px",
                    
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}  align="left">
                    {formatDate(item.dateTime)}
                  </TableCell>
                </TableRow>
              ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box display={"flex"} justifyContent={"center"} marginTop={3}>
            <Pagination
              count={3}
              page={page}
              onChange={handleChangePage}
              style={{marginBottom:'10px'}}
            />
          </Box>
      </div>

      {openDialog && (
        <Dialog
          open={openDialog}
          onClose={() => handleCloseDialog()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "30px" }}
          PaperProps={{
            style: {
              width: "90%",
              borderRadius: "30px",
              maxWidth: "none",
              maxWidth:'1800px',
            },
          }}
        >
          <DialogTitle
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="alert-dialog-title"
          >
            {t('BillsHistorySearchAdvancedTitle')}
            <DialogActions>
              <Button onClick={() => handleCloseDialog()} autoFocus>
                <ClearIcon style={{ color: "grey" }} />
              </Button>
            </DialogActions>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="paramsWrapper">
                <div className="firstTextFieldWrapper">
                  <div className="firstBlockTextField">
                    <TextField
                      autoComplete="off"
                      sx={{ marginTop: "10px", width: "49%" }}
                      InputProps={{ style: { borderRadius: "10px" } }}
                      label= {t('BillsHistorySearchAdvancedId')}
                    />
                    <TextField 
                      autoComplete="off"
                      label= {t('BillsHistorySearchAdvancedDate')}
                      placeholder="YYYY-MM-DD"
                      value={dateRange}
                      onChange={handleDateRangeChange}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{ marginTop: "10px", width: "49%" }}
                      InputProps={{ style: { borderRadius: "10px" } }}
                    />
                  </div>
                  <div className="firstSecondBlockTextField">
                    <TextField
                      autoComplete="off"
                      sx={{ marginTop: "10px", width: "50%" }}
                      id="outlined-select-currency"
                      select
                      label={t('BillsHistorySearchAdvancedType')}
                      defaultValue="Passport"
                    >
                      {documentMani.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                </div>
                <div className="secondTextFieldWrapper">
                  <TextField   autoComplete="off"
                    sx={{ marginTop: "10px", width: "50%" }}
                    id="outlined-select-currency"
                    select
                    label= {t('BillsHistorySearchAdvancedCurrency')}
                    defaultValue="Euro"
                  >
                    {currency.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="wrapperSum">
                    <TextField   autoComplete="off"
                      sx={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        width: "48%",
                      }}
                      InputProps={{ style: { borderRadius: "10px" } }}
                      label= {t('BillsHistorySearchAdvancedFrom')}
                      type="number"
                      min='1'
                      onKeyPress={handleKeyPress}
                    />{" "}
                    <div className="minusWrapper">-</div>
                    <TextField   autoComplete="off"
                      sx={{ marginTop: "10px", width: "48%" }}
                      InputProps={{ style: { borderRadius: "10px" } }}
                      type="number"
                      label= {t('BillsHistorySearchAdvancedBefore')}
                      onKeyPress={handleKeyPress}
                      min='1'

                    />
                  </div>
                </div>
                <div className="thirdTextFieldWrapper">
                  <TextField
                    sx={{ marginTop: "10px", width: "50%" }}   autoComplete="off"
                    id="outlined-select-currency"
                    select
                    label= {t('BillsHistorySearchAdvancedTypeBill')}
                    defaultValue="TF"
                  >
                    {billType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>

                <div className="fourTextFieldWrapper">
                  <TextField
                    sx={{ maxWidth: "263px", marginTop: "10px", width: "50%" }}   autoComplete="off"
                    id="outlined-select-currency"
                    select
                    label= {t('BillsHistorySearchAdvancedSend')}
                    defaultValue="TF"
                  >
                    {billType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <div className="textFieldTabWrapper">
                    <div className="listTabField">
                      <div className="listTabButtonWrapperField" id="listTabButtonWrapperFieldFirst" onClick={() => handleTabDialog()}>
                        <div className="listTabButtonField">{t('BillsHistorySearchAdvancedAnd')}</div>
                      </div>
                      <div className="listTabButtonWrapperField" id="listTabButtonWrapperFieldSecond" onClick={() => handleTabDialogSecond()}>
                        <div className="listTabButtonField"> {t('BillsHistorySearchAdvancedOr')} </div>
                      </div>
                    </div>
                  </div>
                  <TextField   autoComplete="off"
                    sx={{ maxWidth: "263px", marginTop: "10px", width: "50%" }}
                    id="outlined-select-currency"
                    select
                    label= {t('BillsHistorySearchAdvancedRecipient')}
                    defaultValue="TF"
                  >
                    {billType.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </DialogContentText>
          </DialogContent>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                background: "#EAEAEA",
                color: "black",
                marginRight: "10px",
              }}
              className="saveButtonProfile"
            >
             {t('BillsHistorySearchAdvancedReset')}
            </div>
            <div style={{ marginLeft: "10px" }} className="saveButtonProfile">
            {t('BillsHistorySearchAdvancedApply')}

            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
}

export default ListBody;
