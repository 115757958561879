import React from 'react';
import ImageUploading from 'react-images-uploading';
import axios from 'axios';
import urlFromTemplate from '../../../configs/url';
import { ENDPOINTS } from '../../../configs/endpoints';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';

function VerificationAddPhotos() {
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const { t, i18n } = useTranslation();

  const onChange = (imageList, addUpdateIndex) => {
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };

  const handleSendImage = async (index) => {
    const apiUrl = urlFromTemplate(ENDPOINTS.VERIFICATION) + `/request`
    const imageFile = images[index].file;
    const formData = new FormData();
    formData.append('image', imageFile);

    try {
      const response = await axios.post(apiUrl, formData, {
        withCredentials: true,
        headers: {
          "Accept": "application/json",
          "Access-Control-Allow-Credentials": "true",
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Image uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setImages([])
    }
  };

  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          isDragging,
          dragProps,
        }) => (
          <div className="upload__image-wrapper">
            <div style={{display:'flex'}}> 
            <Button
              // style={isDragging ? { color: 'red' } : undefined}
              style={{background: '#3C50E0', color:'white'}}
              onClick={onImageUpload}
              {...dragProps}
            >
              {t('ProfileVerificationButton')}
            </Button>
            &nbsp;
            <Button style={{background: '#3C50E0', color:'white'}} onClick={onImageRemoveAll}>{t('ProfileVerificationButtonDelete')}</Button>
            </div>
            {imageList.map((image, index) => (
              <div key={index} className="image-item" style={{marginTop:'20px'}}>
                <img src={image['data_url']} alt="" width="400" />
                <div className="image-item__btn-wrapper" style={{marginTop:'20px'}}>
                  <Button style={{background: '#3C50E0', color:'white'}} onClick={() => handleSendImage(index)}> {t('ProfileVerificationButtonSend')} </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default VerificationAddPhotos;

