import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import "../../../Style/Profile/BasicData.scss";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import dayjs from "dayjs";
import PageWrapper from "../../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';

function BasicData() {
    const [checkboxEnabled, setCheckboxEnabled] = useState(false);
    const [errorLabel, setErrorLabel] = useState("");
    const [result, setResult] = useState({
        login: "",
        surname: "",
        name: "",
        patronymic: "",
        dateOfBirth: null,
        sex: "",
        verificationType: "ADMIN_VERIFICATION",
        documentType: "PASSPORT",
        documentNumber: "",
        documentIssueDate: null,
        documentIssuingAuthority: "",
    });
    const { t, i18n } = useTranslation();



    const handleUpdateAdditionalData = async (e) => {
        e.preventDefault();
        const apiUrl = urlFromTemplate(ENDPOINTS.USER_ADDITIONAL);
        try {
            await axios
                .put(apiUrl, result, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Access-Control-Allow-Credentials": "true",
                    },
                })
                .then((response) => {
                    setErrorLabel("");
                });
        } catch (error) {
            console.error(error);
            setErrorLabel(error.message);
        }
    };

    const handleFieldChange = (field) => (event) => {
        const newValue = event.target.value;
        setResult((prevState) => ({
            login: field === "login" ? newValue : prevState.login,
            surname: field === "surname" ? newValue : prevState.surname,
            name: field === "name" ? newValue : prevState.name,
            patronymic: field === "patronymic" ? newValue : prevState.patronymic,
            dateOfBirth: field === "dateOfBirth" ? newValue : prevState.dateOfBirth,
            sex: field === "sex" ? newValue : prevState.sex,
            verificationType:
                field === "verificationType" ? newValue : prevState.verificationType,
            documentType:
                field === "documentType" ? newValue : prevState.documentType,
            documentNumber:
                field === "documentNumber" ? newValue : prevState.documentNumber,
            documentIssueDate:
                field === "documentIssueDate" ? newValue : prevState.documentIssueDate,
            documentIssuingAuthority:
                field === "documentIssuingAuthority"
                    ? newValue
                    : prevState.documentIssuingAuthority,
        }));
    };

    const handleDateFieldChange = (field, newValue) => {
        const formattedDate = newValue
            ? dayjs(newValue).format("YYYY-MM-DD")
            : null;
  
        setResult((prevState) => ({
            login: prevState.login,
            surname: prevState.surname,
            name: prevState.name,
            patronymic: prevState.patronymic,
            dateOfBirth:
                field === "dateOfBirth" ? formattedDate : prevState.dateOfBirth,
            sex: prevState.sex,
            verificationType: prevState.verificationType,
            documentType: prevState.documentType,
            documentNumber: prevState.documentNumber,
            documentIssueDate:
                field === "documentIssueDate"
                    ? formattedDate
                    : prevState.documentIssueDate,
            documentIssuingAuthority: prevState.documentIssuingAuthority,
        }));
    };

    const handleCheckboxChange = () => {
        setCheckboxEnabled(!checkboxEnabled);
    };

    useEffect(() => {
        retrieveUserAdditional();
    }, []);

    const retrieveUserAdditional = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER_ADDITIONAL);
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setResult(response.data);

        } catch (error) {
            console.error(error);
        }
    };

    const verification = [
        {
            value: "ADMIN_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationRepeatedVerificationByAdmin')}`,
        },
        {
            value: "PHONE_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationByPhone')}`,

        },
        {
            value: "DOCUMENT_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationByDocument')}`,

        },
        {
            value: "AUTOMATIC_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationAutomatic')}`,

        },
        {
            value: "LOCATION_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationAtPlace')}`,

        },
        {
            value: "EMAIL_VERIFICATION",
            label: `${t('ProfileBasicDataVerificationByEmail')}`,

        },
    ];

    const document = [
        {
            value: "PASSPORT",
            label: `${t('ProfileBasicDataTypeOfDocumentPassport')}`,

        },
        {
            value: "RESIDENT_CARD",
            label: `${t('ProfileBasicDataTypeOfDocumentResident')}`,

        },
        {
            value: "DRIVER_LICENSE",
            label: `${t('ProfileBasicDataTypeOfDocumentDrivers')}`,

        },
        {
            value: "IDENTIFICATION",
            label: `${t('ProfileBasicDataTypeOfDocumentIdentification')}`,

        },
    ];

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
            <PageWrapper >
            {result && result.documentIssuingAuthority !== undefined && (
                <div className="wrapperBasicData">
                    <div className="nameWrapper" style={{marginBottom: "10px"}}>
                        <span style={{marginLeft: '0px'}} className="nameText"> {t('ProfileBasicDataTitle')}</span>
                    </div>

                    <Box
                        sx={{
                            maxWidth: "400px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                        value="1"
                    >
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataLogin')}
                            variant="outlined"
                            value={result.login}
                            onChange={handleFieldChange("login")}
                        />
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataSurname')}
                            variant="outlined"
                            value={result.surname}
                            onChange={handleFieldChange("surname")}
                        />
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataName')}
                            variant="outlined"
                            value={result.name}
                            onChange={handleFieldChange("name")}
                        />
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataLastname')}
                            variant="outlined"
                            value={result.patronymic}
                            onChange={handleFieldChange("patronymic")}
                        />
                        <DateTimePicker
                            views={["day", "month", "year"]}
                            sx={{marginTop: "10px", width: "100%"}}
                            label={t('ProfileBasicDataDateOfBirth')}
                            value={result.dateOfBirth ? dayjs(result.dateOfBirth) : null}
                            onChange={(newValue) =>
                                handleDateFieldChange("dateOfBirth", newValue)
                            }
                        />
                        <FormControl
                            sx={{
                                marginTop: "10px",
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "felx-start",
                                justifyContent: "space-around",
                            }}
                        >
                            <FormLabel id="demo-row-radio-buttons-group-label">{t('ProfileBasicDataSex')}</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={result.sex}
                                onChange={handleFieldChange("sex")}
                            >
                                <FormControlLabel
                                    value="MALE"
                                    control={<Radio/>}
                                    label={t('ProfileBasicDataSexMale')}
                                />
                                <FormControlLabel
                                    value="FEMALE"
                                    control={<Radio/>}
                                    label={t('ProfileBasicDataSaxFemale')}
                                />
                                <FormControlLabel
                                    value="OTHER"
                                    control={<Radio/>}
                                    label={t('ProfileBasicDataSexOther')}
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-select-currency"
                            select
                            label={t('ProfileBasicDataVerification')}
                            value={result.verificationType}
                            onChange={handleFieldChange("verificationType")}
                        >
                            {verification.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-select-currency"
                            select
                            label={t('ProfileBasicDataTypeOfDocument')}
                            value={result.documentType}
                            onChange={handleFieldChange("documentType")}
                        >
                            {document.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataNumberOfDocument')}
                            variant="outlined"
                            value={result.documentNumber}
                            onChange={handleFieldChange("documentNumber")}
                        />
                        <DateTimePicker
                            views={["day", "month", "year"]}
                            sx={{marginTop: "10px", width: "100%"}}
                            label={t('ProfileBasicDataDateDocument')}
                            value={
                                result.documentIssueDate
                                    ? dayjs(result.documentIssueDate)
                                    : null
                            }
                            onChange={(newValue) =>
                                handleDateFieldChange("documentIssueDate", newValue)
                            }
                        />
                        <TextField
                            sx={{marginTop: "10px", marginBottom: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t('ProfileBasicDataIssuingAuthority')}
                            variant="outlined"
                            value={result.documentIssuingAuthority}
                            onChange={handleFieldChange("documentIssuingAuthority")}
                        />
                        <div className="wrapperCheckboxProfile">
                            <Checkbox
                                checked={checkboxEnabled}
                                onChange={handleCheckboxChange}
                            />
                            <div className="checkboxProfileText">
                <span>
                {t('ProfileBasicDataAtantion')}
                </span>
                            </div>
                        </div>
                    </Box>
                    {errorLabel && errorLabel !== "" && (
                        <p style={{color: "red", fontSize: "17px"}}>{errorLabel}</p>
                    )}
                    <button
                        className="saveButtonProfile"
                        disabled={!checkboxEnabled}
                        style={
                            checkboxEnabled
                                ? {}
                                : {cursor: "not-allowed", backgroundColor: "lightgray"}
                        }
                        onClick={handleUpdateAdditionalData}
                    >
                        {t('ProfileButtonSave')}
                    </button>
                </div>
            )}
            </PageWrapper>
        </LocalizationProvider>
    );
}

export default BasicData;
