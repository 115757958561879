import { configureStore } from '@reduxjs/toolkit';
import reducer from './reducer.js'; 
import { logger } from './logger.js'; 
import OpenNavBar from './reducerNavBar.js'
import cabinetData from './reducerCabinet.js'
import partnerData from './reducerPartner.js'
import modalRegData from './modalRegistration.js'
// import languageStateSlice from './reducerLanguage.js';
const store = configureStore({
  reducer: {
    reducer: reducer, 
    OpenNavBar: OpenNavBar,
    cabinetData: cabinetData,
    partnerData: partnerData,
    modalRegData: modalRegData,
    // languageData: languageStateSlice


  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(logger),
 
});

export default store;
