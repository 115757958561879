import React, { useState, useRef, useEffect } from "react";
import "../../../Style/Bills/pages/Replenishment.scss";
import ReplenishmentTitle from "./ReplenishmentTitle";
import WarningIcon from "@mui/icons-material/Warning";
import { ReactComponent as TetherIcon } from "../../../images/icons8-tether.svg";
import { ReactComponent as QR } from "../../../images/Untitled.svg";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import { useTranslation } from 'react-i18next';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

function Replenishment() {
  const [networkActiveFirst, setNetworkActiveFirst] = useState(true);
  const [networkActiveSecond, setNetworkActiveSecond] = useState(false);
  const { t, i18n } = useTranslation();
  const textFieldRefs = useRef([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();




  const handleActiveNetwork = () => {
    setNetworkActiveFirst(!networkActiveFirst);
    setNetworkActiveSecond(!networkActiveSecond);
  };
  const handleActiveNetworkSecond = () => {
    setNetworkActiveSecond(!networkActiveSecond);
    setNetworkActiveFirst(!networkActiveFirst);
  };

  const handleSuccessPay = () => {
    navigate("/replenishment-success");
  };


  const handleCopy = (value) => {
    navigator.clipboard.writeText(value).then(() => {
      setSnackbarMessage(`Copied: ${value}`);
      setOpenSnackbar(true);
    });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const textFields = [
    { id: 1, value: 'Номер счета' },
  ];

  const styleActive = {
    border: networkActiveFirst ? "1px solid green" : "1px solid black",
  };
  const styleActiveSecond = {
    border: networkActiveSecond ? "1px solid green" : "1px solid black",
  };

  const styleQr = {
    width: isSmallScreen ? "250px" : "350px",
    height: isSmallScreen ? "250px" : "350px",
  };

  return (
    <div className="replenishmentWrapper">
      <ReplenishmentTitle />
      <div className="replenishmentContainer">
 
        <div
          className="attentionWrapperYellow"
          style={{
            marginBottom: "50px",
            marginTop: "50px",
            marginLeft: "15px",
          }}
        >
          <div className="wrapperIconAttention">
            <WarningIcon
              style={{
                color: "#FFA500",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
          </div>
          <div className="attentionText">
            <span>
              <b> {t('BillsRefillAttantionImportantInfoTitle')} </b>

              <p>
               {t('BillsRefillAttantionImportantInfoText')}
              </p>
            </span>
          </div>
        </div>
        <div className="titleWrapper">
          <div style={{fontSize:'1.5em'}}> {t('BillsRefillChooseNetwork')} </div>
        </div>
        <div className="networkChangeWrapper">
          <div
            className="networkFirst"
            style={styleActive}
            id="networkFirst"
            onClick={() => handleActiveNetwork()}
          >
            <div className="iconNetwork">
              <TetherIcon />
            </div>
            <div className="textNetwork">USDT (TRC20)</div>
          </div>
          <div
            className="networkFirst"
            id="networkSecond"
            style={styleActiveSecond}
            onClick={() => handleActiveNetworkSecond()}
          >
            <div className="iconNetwork">
              <TetherIcon />
            </div>
            <div className="textNetwork">USDT (BEP20)</div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            maxWidth: "500px",
            margin: "0 auto",
          }}
        >
          <div
            className="attentionWrapperBlue"
            style={{
              marginBottom: "50px",
              marginTop: "50px",
              marginLeft: "15px",
            }}
          >
            <div className="wrapperIconAttention">
              <PriorityHighIcon
                style={{
                  color: "blue",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
            </div>
            <div className="attentionText">
              <span>
              {t('BillsRefillAttantionTextFirst')}
                <p> {t('BillsRefillAttantionTextTitle')} </p>
              </span>
            </div>
          </div>
        </div>
        <div>
      {textFields.map((field, index) => (
        <div key={field.id} style={{ maxWidth: "500px", margin: "0 auto", marginBottom: '10px' }}>
          <TextField
            autoComplete="off"
            inputRef={(el) => (textFieldRefs.current[index] = el)}
            value={field.value}
            InputProps={{
              style: { color: "gray", borderRadius: '10px' },
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title="Copy">
                    <IconButton onClick={() => handleCopy(field.value)}>
                      <ContentCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
          />
        </div>
      ))}

      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="success">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
    </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p> {t('BillsRefillQrLable')} </p>
        </div>
        <div className="QrWrapper">
          <QR style={styleQr} />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="buttonPay" onClick={() => handleSuccessPay()}>
            {t('BillsRefillButtonNext')}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Replenishment;
