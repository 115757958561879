import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../Style/NameCabinet.scss";
import "../../../Style/Events/Events.scss"
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import axios from "axios";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Events () {
    const { t, i18n } = useTranslation();
    const [trainingEvents, setTrainingEvents] = useState([])
    const [training, setTraining] = useState([])



    useEffect(() => {
        handleGetTraningEvent()
    },[])

    const handleGetTraningEvent = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING_EVENT)
        try {
            const response = await axios.get(apiUrl, {
            withCredentials: true,
            headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
            },
        })
        setTrainingEvents(response.data)
        } catch (error) {
            console.error(error)
        } finally {
            handelGetTraining()
        }
    } 

    const handelGetTraining = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.TRAINING)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
            headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
            }, 
            })
            setTraining(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        console.log(trainingEvents)
        console.log(training)
    },[training])

    const filteredTraining = (rowId) => {
        console.log(rowId)
        return training.filter((item) => item.id === rowId);
      };

      const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
    
        return `${day}.${month}.${year} ${hours}:${minutes}`;
      };

    return (
        <>
        <div className="wrapperEvents">
        <div className="nameWrapper">
          <span className="nameText">{t("events")}</span>
        </div>
        <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Название</TableCell>
            <TableCell align="right">Дата</TableCell>
            <TableCell align="right">Описание</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {trainingEvents.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
          
              <TableCell align="left">{filteredTraining(row.trainingId).map((item) => (
                <React.Fragment>
                {item.ruTraining}
                </React.Fragment>
              ))}
              </TableCell>
                  <TableCell component="th" scope="row" align="right"> 
                  {formatDate(row.dateTime)}
              </TableCell>
              <TableCell align="right">{row.description}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>
        </>
    )
}

export default Events