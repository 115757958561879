import React, {useEffect, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import NavigationAdaptive from "../../Navigation/NavigationAdaptive";
import {useDispatch} from "react-redux";
import {setData} from "../../../store/reducer";
import "../../../Style/MainHeader.scss";
import PersonIcon from "@mui/icons-material/Person";
import {setDataNavBar} from "../../../store/reducerNavBar";
import SiteSettingsModal from "../../../Modal/SiteSettingsModal";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import {ReactComponent as Settings} from "../../../images/header/settings.svg";
import {ReactComponent as ArrowForwardIcon} from "../../../images/header/arrow.svg";
import ModalWindowCabinetConsultant from "../../../Modal/ModalWindowCabinetConsultant/ModalWindowCabinetConsultant";
import Cabinet from "../../Cabinet/Cabinet";
import CabinetAdaptive from "../../Cabinet/CabinetAdaptive";

function Header({isSmallScreen}) {
    const [cabinetOpen, setCabinetOpen] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isRotated, setIsRotated] = useState(false);
    const dispatch = useDispatch();
    const [siteSettings, setSiteSettings] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCabinetTrue, setIsCabinetTrue] = useState(false);
    const [countOfUsers, setCountOfUsers] = useState(0);
    const [buttonLabel, setButtonLabel] = useState("В Кабинет Партнера");
    const [cabinetModal, setCabinetModal] = useState(false)
    const location = useLocation();
    const navigate = useNavigate();
    let params = window.location.pathname;
    const {t, i18n} = useTranslation();
    const [cabData, setCabData] = useState([]);
    const [inviter, setInviter] = useState([]);
    const [photoCab, setPhotoCab] = useState(new Map())
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [drawerOpenCabinet, setDrawerOpenCabinet] = useState(false);
    const [formStatus, setFormStatus] = useState(false)

    const getUser = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/client`;
        try {
            await axios
                .get(apiUrl, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Access-Control-Allow-Credentials": "true",
                    },
                })
                .then((response) => {
                    setCabData(response.data.personal);
                    setInviter(response.data.inviter);
                    getPhotoId(response.data.personal.urlId)
                    // setPhotoId(response.data.personal.urlId)
                });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        getUser();
    }, []);

    const getPhotoId = async (id) => {
        if (!id) return null;

        const apiUrl = urlFromTemplate(ENDPOINTS.STORAGE) + `/${id}`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Credentials': 'true'
                },
                responseType: 'blob'
            });
            setPhotoCab(URL.createObjectURL(response.data))
        } catch (error) {
            console.error(`Failed to fetch image/document with id ${id}`, error);
            return null;
        }
    }

    useEffect(() => {
        handleGetCountOfUsers();
        const storedLabel = localStorage.getItem("buttonLabel");
        if (storedLabel) {
            setButtonLabel(storedLabel);
        }
    }, []);

    const handleModalOpen = () => {
        setIsModalOpen(true);
        document.body.style.overflow = "hidden";
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        document.body.style.overflow = "auto";
    };

    setTimeout(() => {
        setSiteSettings(JSON.parse(localStorage.getItem("site_settings")));
    }, 200);

    const handleMenuClick = () => {
        setIsDrawerOpen((prevIsOpen) => !prevIsOpen);
        dispatch(setDataNavBar(isDrawerOpen));
    };

    const handleOpenCabinet = () => {
        setIsRotated(!isRotated);
        setCabinetOpen(!cabinetOpen);
        dispatch(setData(cabinetOpen));


    };

    const handleOpenCabinetDraw = () => {
        setDrawerOpenCabinet(true)
    };
    const handleOpenCabinetAdaptive = () => {
        setDrawerOpen(true)
    };

    const handleGetCountOfUsers = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/count`;
        try {
            await axios
                .get(apiUrl, {
                    withCredentials: true,
                    headers: {
                        Accept: "application/json",
                        "Access-Control-Allow-Credentials": "true",
                    },
                })
                .then((response) => {
                    setCountOfUsers(response.data);
                });
        } catch (error) {
            console.error(error)
        }

    };

    const style = {
        background: "white",
        display: "flex",
        padding: '0'
    };

    const iconStyle = {
        color: "black",
        transition: "transform 0.5s",
        transform: isRotated ? "rotate(180deg)" : "rotate(0deg)",
        cursor: "pointer",
    };

    const handleCabinetChange = () => {
        // navigate('/consultant')
        // setCabinetModal(true)
        handelCheckUserActive()

    };

    const handleMainPage = () => {
        navigate("/mainpage");
    };

    const handleClose = () => {
        setCabinetModal(false)
    }

    const handleDrawerToggle = () => {
        setDrawerOpen(!drawerOpen);
    };

    const handleDrawerToggleCabinet = () => {
        setDrawerOpenCabinet(!drawerOpenCabinet);
    };

    const openWalletPage = () => {
        navigate('wallet');
    }

    const isVerified = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/verified`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            return response.data.verified;
        } catch (error) {
            console.error(error);
            return false;
        }
    };

    // Проверка на Активированного пользователя

    const handelCheckUserActive = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACTIVE_STATUS) + `/status`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    "Accept": "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            console.log(response.data)
            if (response.data.activeStatus === 'ACTIVE') {
                navigate('/consultant')
            } else {
                const verified = await isVerified();
                if(verified === true) {
                    handleCheckForm()
                } else {
                    alert(t('VerificationRequired'));
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    // Проверка на заполнение формы

    const handleCheckForm = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_FORM) + `/status`
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            console.log(response.data)
            setFormStatus(response.data.formStatus)
            setCabinetModal(true)

        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div
            className="landingHeaderWrapper"
            style={{width: "100%", position: "sticky", top: "0px", zIndex: "10"}}
        >
            <NavigationAdaptive
                isOpen={isDrawerOpen}
                isClose={isSmallScreen}
                onClose={handleMenuClick}
            />
            <div className="landingHeader">
                <Box sx={{flexGrow: 1}}>
                    <AppBar position="static" style={style}>
                        <Toolbar
                            style={{display: "flex", justifyContent: "space-around", padding: '0', width: '100%'}}
                        >
                            {isSmallScreen && (
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="black"
                                    aria-label="menu"
                                    // sx={{mr: 2}}
                                    onClick={handleMenuClick}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            )}
                            {!isSmallScreen && (
                                <div className="containerHeaderButton">
                                    <div className="boxCountOfUser">
                                        <div className="countOfUser">{countOfUsers.count}</div>
                                        <div className="tooltip"> {t('buttonCountOfUser')} </div>
                                    </div>
                                    <div className="wrapperButtonsCabinet">
                                        <div className="headerButtonWrapper">
                                            <button className="yellowButton" onClick={openWalletPage}>
                                                {t("buttonConnectWallet")}
                                            </button>

                                            <button
                                                className="buttonPartnersOffice"
                                                onClick={handleCabinetChange}
                                            >
                                                {t("ConsultantDashboard")}
                                            </button>
                                            <button
                                                className="buttonSettingsWrapper"
                                                onClick={handleModalOpen}
                                            >
                                                <Settings style={{width: "34px", height: "34px"}}/>
                                            </button>
                                        </div>

                                        {!isSmallScreen && (
                                            <div className="clientWrapperCabinet">
                                                <div className="clientNameLevelWrapper">
                                                    <div className="clientName">
                                                        {cabData.login}
                                                    </div>

                                                </div>
                                                <div className="clientImageArrowWrapper">
                                                    <div className="clientImage"
                                                         style={{backgroundImage: `url(${photoCab})`}}>

                                                    </div>
                                                    <div className="arrow">
                                                        <ArrowForwardIcon
                                                            style={iconStyle}
                                                            onClick={handleOpenCabinetDraw}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            )}

                            {isSmallScreen && (
                                <PersonIcon
                                    style={{color: "black", cursor: "pointer"}}
                                    onClick={handleOpenCabinetAdaptive}
                                />
                            )}
                        </Toolbar>
                    </AppBar>
                </Box>
            </div>
            <SiteSettingsModal isOpen={isModalOpen} onClose={handleModalClose}/>
            <ModalWindowCabinetConsultant isOpen={cabinetModal} onClose={handleClose} formStatus={formStatus}/>
            <CabinetAdaptive isOpen={drawerOpen} isClose={isSmallScreen} onClose={handleDrawerToggle}/>
            <Cabinet isOpen={drawerOpenCabinet} onClose={handleDrawerToggleCabinet}/>
        </div>
    );
}

export default Header;
