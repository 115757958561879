import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from "@mui/x-charts/Gauge";
import { useMediaQuery, useTheme } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CalendarIcon } from "@mui/x-date-pickers";
import { ReactComponent as StatisticsManPlus } from "../../../images/statistics/statisticsManPlus.svg";
import { ReactComponent as StatisticsMan } from "../../../images/statistics/statisticsMan.svg";
import { ReactComponent as Statistics } from "../../../images/statistics/statistics.svg";
import { ReactComponent as Statistics2 } from "../../../images/statistics/statistics2.svg";
import { ReactComponent as Structure } from "../../../images/statistics/structure.svg";

import '../../../Style/Partners/Motivation.scss'
import { useTranslation } from 'react-i18next';

function PartnerMotivation() {
  const [gaugeWidth, setGaugeWidth] = useState(120);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const { t, i18n } = useTranslation();

 

  function GaugePointer() {
    const { valueAngle, outerRadius, cx, cy } = useGaugeState();

    if (valueAngle === null) {
      // No value to display
      return null;
    }

    const target = {
      x: cx + outerRadius * Math.sin(valueAngle),
      y: cy - outerRadius * Math.cos(valueAngle),
    };
    return (
      <g>
        <circle cx={cx} cy={cy} r={5} fill="red" />
        <path
          d={`M ${cx} ${cy} L ${target.x} ${target.y}`}
          stroke="red"
          strokeWidth={3}
        />
      </g>
    );
  }
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const rows = [
    createData("01.05.2024 16:50", "Profit накопительный", 6.0),
    createData("01.05.2024 16:50", "Profit", 900.0),
    createData("01.05.2024 16:50", "Возврат средств на вывод", 1600.0),
    createData("01.05.2024 16:50", "Profit", 300.0),
    createData("01.05.2024 16:50", "Коммиссия за вывод", 16.0),
  ];

  return (
    <div className="wrapperMotivationPartner" >
      <div className="motivationCardWrapper">
        <div>
          <Typography> {t('CabinetPartnerMotivationTitle')} </Typography>
        </div>
        <div style={{ display: "flex", alignItems:'center' ,marginTop: "20px", width: "228px" , height:'84px'}}>
          <div className="wrapperLevelFirst">
            <div className="imgLevelFirst">

            </div>
            <div className="levelAccountWrapper">
              <div className="levelAccountText">Уровень 1</div>
            </div>
          </div>
          <div className="wrapperLefelArrow">
                      
          </div>

          <div className="wrapperLevelFirst">
            <div className="imgLevelSecond">

            </div>
            <div className="levelAccountWrapper">
              <div className="levelAccountText">Уровень 2</div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "20px" }}>
          <div>
            <Typography>
              {t('CabinetPartnerMotivationAttantion')}
            </Typography>
          </div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <div className="projectPullWrapper" style={{display:"block"}}>
              <div className="projectPull">
                <GaugeContainer
                  width={gaugeWidth}
                  height={120}
                  startAngle={-110}
                  endAngle={110}
                  value={30}
                >
                  <GaugeReferenceArc />
                  <GaugeValueArc />
                  <GaugePointer />
                </GaugeContainer>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "200px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ fontSize: "13px" }}>0</div>
                  <div style={{ fontSize: "13px" }}>100%</div>
                </div>
                <div
                  style={{
                    margin: "5px auto",
                    fontSize: "12px",
                    color: "grey",
                  }}
                >
                  {t('CabinetPartnerMotivationTarget')} USDv 1 000.00
                </div>
                <div
                  style={{ margin: "auto", fontSize: "12px", color: "grey" }}
                >
                  {t('CabinetPartnerMotivationMore')} USDv 600.00
                </div>
              </div>
            </div>
            <div className="projectPullWrapper" style={{display:"block"}}>
              <div className="projectPull">
                <GaugeContainer
                  width={gaugeWidth}
                  height={120}
                  startAngle={-110}
                  endAngle={110}
                  value={30}
                >
                  <GaugeReferenceArc />
                  <GaugeValueArc />
                  <GaugePointer />
                </GaugeContainer>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  maxWidth: "200px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <div style={{ fontSize: "13px" }}>0</div>
                  <div style={{ fontSize: "13px" }}>100%</div>
                </div>
                <div
                  style={{
                    margin: "5px auto",
                    fontSize: "12px",
                    color: "grey",
                  }}
                >
                     {t('CabinetPartnerMotivationTarget')} USDv 10 000.00
                </div>
                <div
                  style={{ margin: " auto", fontSize: "12px", color: "grey" }}
                >
                  {t('CabinetPartnerMotivationMore')} USDv 6 000.00
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="motivationCardWrapper">
        <div>
          <Typography>Последние регистрации</Typography>
        </div>
        <div
          className="billsTableWrapper"
          style={{ margin: "10px auto", width: "95%" }}
        >
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>ФИО/Логин</TableCell>
                  <TableCell align="right">Дата регистрации</TableCell>
                  <TableCell align="right">ЛО</TableCell>
                  <TableCell align="right">Связаться</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Aliaksandra
                  </TableCell>
                  <TableCell align="right">13.05.2024 14:15</TableCell>
                  <TableCell align="right">USDv 0.00</TableCell>
                  <TableCell align="right">vi.leksa@yandex.by</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div> */}
      {/* <div className="motivationCardWrapper">
        <div>
          <Typography>Статистика по партнерам</Typography>
        </div>
        
        <div
          className="billsTableWrapper"
          style={{ margin: "10px auto", width: "95%" }}
        >
          <TableContainer style={{ boxShadow: "none" }} component={Paper}>
            <Table
              sx={{ borderCollapse: "separate" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="right"> </TableCell>

                  <TableCell style={{ fontSize: "12px" }} align="left">
                    {" "}
                    01.04.2024 - 30.04.2024
                  </TableCell>
                  <TableCell style={{ fontSize: "12px" }} align="left">
                    {" "}
                    01.05.2024 - 31.05.2024
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ height: "260px" }}>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(to right, rgba(0, 255, 0, 0.1 ), transparent)",
                    borderRadius: "10px",
                  }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderRadius: "10px 0 0 10px",
                      width: "30%",
                      color: "Lime",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <StatisticsManPlus
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      Новые
                    </div>
                  </TableCell>
                  <TableCell align="left">0</TableCell>
                  <TableCell align="left">1</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(to right, rgba(255, 165, 0, 0.1 ),transparent)",
                  }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRadius: "10px 0 0 10px", color: "orange" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <StatisticsMan
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      Лично пригл.
                    </div>
                  </TableCell>
                  <TableCell align="left">0</TableCell>
                  <TableCell align="left">1</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(to right, rgba(255, 0, 255, 0.1 ), transparent)",
                  }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRadius: "10px 0 0 10px", color: "Magenta" }}
                    
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                    <Structure    
                    style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                          marginBottom:'0px'
                        }}/>
                    Всего
                    </div>
            
                  </TableCell>
                  <TableCell align="left">0</TableCell>
                  <TableCell align="left">1</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(to right, rgba(220, 20, 60, 0.1 ), transparent)",
                  }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRadius: "10px 0 0 10px", color: "Crimson" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Statistics
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      Л/С/П ветки
                    </div>
                  </TableCell>
                  <TableCell align="left">0</TableCell>
                  <TableCell align="left">1</TableCell>
                </TableRow>
                <TableRow
                  style={{
                    background:
                      "linear-gradient(to right, rgba(0, 191, 255, 0.1 ), transparent)",
                    marginTop: "10px",
                  }}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ borderRadius: "10px 0 0 10px", color: "DeepSkyBlue" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" , position:'relative'}}>
                      <Statistics2
                        style={{
                          width: "20px",
                          height: "17px",
                          marginRight: "5px",
                          position:'absolut'
                        }}
                      />
                      <div style={{marginBottom:'0px'}}>
                      Ветки
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="left">0</TableCell>
                  <TableCell align="left">1</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div> */}
      <div className="motivationCardWrapper">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography> {t('CabinetPartnerMotivationLiderTitle')} </Typography>
            <div style={{ color: "grey" }}> {t('CabinetPartnerMotivationLiderDesc')} </div>
          </div>
          <CalendarIcon />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "300",
            background: "rgb(243, 243, 243)",
            height: "40px",
            marginTop: "30px",
            borderRadius: "10px",
          }}
        >
         {t('CabinetPartnerMotivationLiderAttention')}
        </div>
      </div>
    </div>
  );
}

export default PartnerMotivation;
