import React, {useState, useRef, useEffect} from "react";
import Typography from "@mui/material/Typography";
import "../../../Style/Bills/BillsCard.scss";
import Link from "@mui/material/Link";
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import {useMediaQuery, useTheme} from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import WarningIcon from "@mui/icons-material/Warning";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import {TextField, IconButton, InputAdornment} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import PaymentsIcon from "@mui/icons-material/Payments";
import {blue, green, pink, purple} from "@mui/material/colors";
import urlFromTemplate from "../../../configs/url";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import {useTranslation} from "react-i18next";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import {styled} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import {ReactComponent as Dollar} from "../../../images/Bills/dollar.svg";
import {ReactComponent as Euro} from "../../../images/Bills/euro.svg";
import {ReactComponent as USDT} from "../../../images/Bills/usdt.svg";

function BillsPartner() {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const [openMoreInfoBonus, setOpenMoreInfoBonus] = useState(false);
    const [openMoreInfoCumulitive, setOpenMoreInfoCumulitive] = useState(false);
    const [openMoreInfoBonusSecond, setOpenMoreInfoBonusSecond] = useState(false);
    const [openMoreInfoProject, setOpenMoreInfoProject] = useState(false);
    const [wallet, setWallet] = useState([]);
    const [walletCommission, setWalletCommission] = useState([]);
    const [walletAuxiliary, setWalletAuxiliary] = useState([]);
    const [activeItem, setActiveItem] = useState("");
    const [open, setOpen] = useState(false);
    const [age, setAge] = React.useState("EUR");
    const [currencyTransaction, setCurrencyTransaction] = useState('EUR')
    const [amountComission, setAmountComission] = useState(0)
    const [passwordComission, setPasswordComission] = useState('')
    const [amountConclusion, setAmountConclusion] = useState(0)
    const [passwordConclusion, setPasswordConclusion] = useState('')
    const [openSecond, setOpenSecond] = useState(false)

    const navigate = useNavigate();

    const textFieldRef = useRef(null);
    const {t, i18n} = useTranslation();

    const handleCopy = () => {
        if (textFieldRef.current) {
            textFieldRef.current.select();
            document.execCommand("copy");
        }
    };

    const handleModalOpen = () => setOpen(true);
    const handleModalClose = () => setOpen(false);

    const handleModalOpenSecond = () => setOpenSecond(true);
    const handleModalCloseSecond = () => setOpenSecond(false);

    const handleChangeAmount = (event) => {
        const newValue = event.target.value
        setAmountComission(newValue)
    }

    const handleChangePassword = (event) => {
        const newValue = event.target.value
        setPasswordComission(newValue)
    }


    const handleChangeAmountConclusion = (event) => {
        const newValue = event.target.value
        setAmountConclusion(newValue)
    }

    const handleChangePasswordConclusion = (event) => {
        const newValue = event.target.value
        setPasswordConclusion(newValue)
    }

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        handleAddBills();
    }, []);

    const handleAddBills = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT) + `/consultant`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setWallet(response.data);
            response.data.forEach((walletItem) => {
                if (walletItem.accountType === "COMMISSION") {
                    setWalletCommission(walletItem);
                } else if (walletItem.accountType === "AUXILIARY") {
                    setWalletAuxiliary(walletItem)
                }
            });
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
    }, [wallet]);

    const handleOpen = (item) => {
        switch (item) {
            case "bonus":
                setOpenMoreInfoBonus(true);
                break;
            case "cumulitive":
                setOpenMoreInfoCumulitive(true);
                break;
            case "bonusSecond":
                setOpenMoreInfoBonusSecond(true);
                break;
            case "project":
                setOpenMoreInfoProject(true);
                break;
        }
    };
    const handleClose = (item) => {
        switch (item) {
            case "bonus":
                setOpenMoreInfoBonus(false);
                break;
            case "cumulitive":
                setOpenMoreInfoCumulitive(false);
                break;
            case "bonusSecond":
                setOpenMoreInfoBonusSecond(false);
                break;
            case "project":
                setOpenMoreInfoProject(false);
                break;
        }
    };

    const styleIconButtonRep = {
        width: isSmallScreen ? "34px" : "34px",
        height: isSmallScreen ? "34px" : "34px",
        // color: "black",
        // marginRight: "5px",
    };

    const handleWithdrawalButton = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/transfer`
        const payload = {
            amount: amountComission,
            password: passwordComission,
            currency: currencyTransaction,
        }
        try {
            const response = await axios.post(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
        } catch (error) {
            console.error(error)
        }
    }

    const handleConclusionButton = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/pending`
        const payload = {
            amount: amountConclusion,
            password: passwordConclusion,
            currency: currencyTransaction,
        }
        try {
            const response = await axios.post(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
        } catch (error) {
            console.error(error)
        }
    }


    // Currency

    const StyledSelect = styled(Select)({
        width: '60px',
        borderRadius: "10px",
        // height: "50px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "10px",
        },
    });

    const handleChange = (event) => {
        setAge(event.target.value);
        if (event.target.value === "USD") {
            setCurrencyTransaction('USD')
        } else if (event.target.value === 'EUR') {
            setCurrencyTransaction('EUR')
        } else if (event.target.value === 'USDT') {
            setCurrencyTransaction('USDT')
        }
    };

    const StyledMenuItem = styled(MenuItem)({
        borderRadius: "10px",
    });


    return (
        <>
            <div
                className="wrapperBillsCard"
            >
                <div className="billsCardMain">
                    <div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            {wallet.map((item) => {
                                if (item.accountType === "COMMISSION") {
                                    return (
                                        <div>
                                            <div className="billMoney">
                                                <Euro style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '3px'}}>
                                                   {item.currencyAmount.eurAmount}
                                                </span>
                                            </div>
                                            <div className="billMoney">
                                                <Dollar style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '1px'}}>
                                                  {item.currencyAmount.usdAmount}
                                                </span>
                                            </div>
                                            <div className="billMoney">
                                                <USDT style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '3px'}}>
                                                  {item.currencyAmount.usdtAmount}
                                                </span>
                                            </div>
                                        </div>

                                    );
                                }
                            })}
                            <div
                                className="buttonAddWrapper"
                                style={{cursor: "pointer"}}
                            >
                                <RemoveCircleOutlineIcon
                                    style={styleIconButtonRep}
                                    className="activeElement"
                                    onClick={handleModalOpen}
                                />
                            </div>

                        </div>

                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <Typography
                                component="p"
                                variant="h5"
                                style={{
                                    fontFamily: "HelveticaNeueCyr",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    color: "gray",
                                }}
                            >
                                {t("BillsCardCumulativeBalance")}
                            </Typography>

                            <div style={{display: "flex", justifyContent: "flex-end"}}>
                                <Link
                                    style={{
                                        color: "white",
                                        width: "138px",
                                        height: "35px",
                                        background: "#3C50E0",
                                        borderRadius: "30px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        textDecoration: "none",
                                        textAlign: "center",
                                        cursor: 'pointer',
                                    }}
                                    color="text.secondary"
                                    //  color={'#4f4ff1'}
                                    onClick={() => handleOpen("bonusSecond")}
                                >
                                    {t("MainPageBillsMoreInfo")}
                                </Link>
                            </div>
                        </div>

                    </div>

                </div>
                <div className="billsCardMain">
                    <div>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            {wallet.map((item) => {
                                if (item.accountType === "AUXILIARY") {
                                    return (
                                        <div>
                                            <div className="billMoney">
                                                <Euro style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '3px'}}>
                                                   {item.currencyAmount.eurAmount}
                                                </span>
                                            </div>
                                            <div className="billMoney">
                                                <Dollar style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '1px'}}>
                                                  {item.currencyAmount.usdAmount}
                                                </span>
                                            </div>
                                            <div className="billMoney">
                                                <USDT style={{width: '25px', height: '25px'}}/>
                                                <span style={{marginLeft: '3px'}}>
                                                  {item.currencyAmount.usdtAmount}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                }
                            })}
                            <div
                                className="buttonAddWrapper"
                                style={{cursor: "pointer"}}
                            >
                                <RemoveCircleOutlineIcon
                                    style={styleIconButtonRep}
                                    className="activeElement"
                                    onClick={handleModalOpenSecond}
                                />
                            </div>

                        </div>

                        <Typography
                            component="p"
                            variant="h5"
                            style={{
                                fontFamily: "HelveticaNeueCyr",
                                fontSize: "14px",
                                fontWeight: "400",
                                lineHeight: "22px",
                                textAlign: "left",
                                color: "gray",
                            }}
                        >
                            {t("BillsCardAuxiliaryBalance")}
                        </Typography>
                    </div>

                    <div>
                        <div className="descriptionWrapper">
                            <div className="descriptionText">
                                {t("MainPageBillsMainBalanceUSDAwaitingWithdrawal")}
                            </div>

                            {/* <div className="descriptionText">{t('MainPageBillsMainBalanceUSDFrozen')}</div> */}
                        </div>
                    </div>
                </div>

            </div>
            {openMoreInfoBonusSecond && (
                <Dialog
                    open={openMoreInfoBonusSecond}
                    onClose={() => handleClose("bonusSecond")}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle style={{margin: "0 auto"}} id="alert-dialog-title">
                        {t("BillsModalWindowCumulativeBonusBalanceTitle")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <div
                                className="attentionWrapperYellow"
                                style={{
                                    marginBottom: "50px",
                                    marginTop: "50px",
                                    marginLeft: "15px",
                                }}
                            >
                                <div className="wrapperIconAttention">
                                    <WarningIcon
                                        style={{
                                            color: "#FFA500",
                                            marginLeft: "5px",
                                            marginRight: "5px",
                                        }}
                                    />
                                </div>
                                <div className="attentionText">
                  <span>
                    <p>
                      {t("BillsModalWindowCumulativeBonusBalanceTextFirst")}
                    </p>
             
                  </span>
                                </div>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            style={{letterSpacing: "1px"}}
                            onClick={() => handleClose("bonusSecond")}
                            autoFocus
                        >
                            Закрыть
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <div>
                <Modal
                    open={open}
                    onClose={handleModalClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t("ComissionModalWindowTitle")}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <div style={{
                                height: '120px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <FormControl style={{}}>
                                        <StyledSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={handleChange}
                                            style={{
                                                borderRadius: "10px",
                                                // height: "50px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <StyledMenuItem value={"EUR"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                                            </StyledMenuItem>
                                            <StyledMenuItem value={"USD"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                                            </StyledMenuItem>
                                            <StyledMenuItem value={"USDT"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                                            </StyledMenuItem>
                                        </StyledSelect>
                                    </FormControl>
                                    <TextField
                                        value={amountComission}
                                        label={t("BillsTableSecondTransactionAmount")}
                                        onChange={(e) => handleChangeAmount(e)}
                                        style={{width: '80%'}}
                                    >
                                    </TextField>
                                </div>

                                <TextField
                                    value={passwordComission}
                                    label={t("ProjectPageEnterPassword")}
                                    onChange={(e) => handleChangePassword(e)}
                                    type="password"
                                >

                                </TextField>
                            </div>

                        </Typography>
                        <Button
                            onClick={() => handleWithdrawalButton()}>{t("CommissionModalWindowWithdrawalButton")}</Button>
                    </Box>
                </Modal>
            </div>
            <div>
                <Modal
                    open={openSecond}
                    onClose={handleModalCloseSecond}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            {t("ComissionModalWindowTitleSecond")}
                        </Typography>
                        <Typography id="modal-modal-description" sx={{mt: 2}}>
                            <div style={{
                                height: '120px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between'
                            }}>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <FormControl style={{}}>
                                        <StyledSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            onChange={handleChange}
                                            style={{
                                                borderRadius: "10px",
                                                // height: "50px",
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <StyledMenuItem value={"USD"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyUsd")}
                                            </StyledMenuItem>
                                            <StyledMenuItem value={"EUR"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyEuro")}
                                            </StyledMenuItem>
                                            <StyledMenuItem value={"USDT"}>
                                                {t("CommissionModalWindowWithdrawalCurrencyUsdt")}
                                            </StyledMenuItem>
                                        </StyledSelect>
                                    </FormControl>
                                    <TextField
                                        value={amountConclusion}
                                        label={t("BillsTableSecondTransactionAmount")}
                                        onChange={(e) => handleChangeAmountConclusion(e)}
                                        style={{width: '80%'}}
                                    >
                                    </TextField>
                                </div>
                                <TextField
                                    value={passwordConclusion}
                                    label={t("ProjectPageEnterPassword")}
                                    onChange={(e) => handleChangePasswordConclusion(e)}
                                    type="password"
                                >

                                </TextField>
                            </div>

                        </Typography>
                        <Button
                            onClick={() => handleConclusionButton()}>{t("CommissionModalWindowWithdrawalButtonSecond")}</Button>
                    </Box>
                </Modal>
            </div>
        </>
    );
}

export default BillsPartner;
