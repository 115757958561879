import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TablePagination, Typography } from "@mui/material";
import "../../Style/Bills/BillsTable.scss";
import { useTranslation } from "react-i18next";
import urlFromTemplate from "../../configs/url";
import { ENDPOINTS } from "../../configs/endpoints";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";

function BillsTable() {
  const [contentTable, setContentTable] = useState([]);
  const [pagination, setPagination] = useState();
  // const [pageNumber, setPageNumber]
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState(0);
  const pageSize = 5;

  const getBillsTable = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.ACCOUNT_LOG);
    try {
      await axios
        .get(apiUrl, {
          withCredentials: true,
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Credentials": "true",
          },
          params: {
            size: 5,
            page: page,
          },
        })
        .then((response) => {
          setContentTable(response.data.content);
          setPagination(response.data.totalElements);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    getBillsTable();
  }, [page]);

  //Форматирование даты

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}.${month}.${year} ${hours}:${minutes}`;
  };

  const StyledTableHead = styled(TableHead)({
    display: "flex",
    justifyContent: "space-around",
    background: "#F7F9FC",
    "& th:first-of-type": {},
    "& th:last-of-type": {},
  });

  return (
    <div className="wrapperIncomeTableMain">
      <TableContainer style={{ boxShadow: "none" }} component={Paper}>
        <StyledTableHead>
          <Typography
            style={{
              margin: "10px",
              marginLeft: "20px",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              border: "none",
              fontFamily: "Helvetica Neue",
              fontSize: "14px",
              fontWeight: "400",
              lineHeight: "22px",
              textAlign: "left",
              color: "#64748B",
            }}
            component="p"
            variant="h6"
          >
            {t("BillsTable")}
            <div
              className="historyOperation"
              style={{
                marginRight: "20px",
                display: "flex",
                alignItems: "flex-end",
                border: "none",
                fontFamily: "Helvetica Neue",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "22px",
                textAlign: "left",
                color: "#64748B",
              }}
            >
              <a href="./list-operation">{t("BillsTableButton")}</a>
            </div>
          </Typography>
        </StyledTableHead>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableBody>
            {contentTable.map((item) => (
              <TableRow
                key={item.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }}
                  component="th"
                  scope="row"
                >
                  {formatDate(item.dateTime)}
                </TableCell>
                <TableCell  style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                  }} align="left">
                  {item.type}
                </TableCell>
                <TableCell style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#10B981",

                  }} align="left">
                  {item.currency}
                </TableCell>
                <TableCell style={{
                    paddingLeft: "16px",
                    padding: "25px",
                    fontFamily: "Helvetica Neue",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    textAlign: "left",
                    color: "#10B981",

                  }} align="left">
                  {item.amount}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={pagination}
          rowsPerPage={pageSize}
          page={page}
          onPageChange={handleChangePage}
        />
      </TableContainer>
    </div>
  );
}

export default BillsTable;
