import React, {useEffect, useState} from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import "../../../Style/Profile/Verification.scss";
import TextField from "@mui/material/TextField";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Box from "@mui/material/Box";
import {MuiFileInput} from "mui-file-input";
import PageWrapper from "../../../Routes/PageWrapper";
import {useTranslation} from "react-i18next";
import {ENDPOINTS} from "../../../configs/endpoints";
import axios from "axios";
import VerificationAddPhotos from "./VerificationAddPhotos";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {ReactComponent as CheckTrue} from "../../../images/Verification/checkTrue.svg";
import {ReactComponent as CheckFalse} from "../../../images/Verification/checkFalse.svg";
import {Typography} from "@mui/material";
import urlFromTemplate from "../../../configs/url";

function Verification() {
    const [fileFirst, setFileFirst] = React.useState(null);
    const [fileSecond, setFileSecond] = React.useState(null);
    const {t, i18n} = useTranslation();
    const [selectedFile, setSelectedFile] = useState(null);
    const [value, setValue] = React.useState(0);
    const [userVerify, setUserVerify] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleChangeForm = (newFile) => {
        setFileFirst(newFile);
    };
    const handleChangeFormSecond = (newFile) => {
        setFileSecond(newFile);
    };

    useEffect(() => {
        getVerification()
    }, [])

    function CustomTabPanel(props) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box sx={{p: 3}}>{children}</Box>}
            </div>
        );
    }

    CustomTabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const getVerification = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/verified`;
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            });
            setUserVerify(response.data.verified);
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <PageWrapper>
            <div className="verificationWrapper">
                <Accordion defaultExpanded={true}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
                            {userVerify
                                ?
                                <CheckTrue style={{width: "26px", height: "28px"}}/>
                                :
                                <CheckFalse style={{width: "26px", height: "28px"}}/>
                            }

                            <Typography sx={{marginLeft: "8px"}}>
                                {t("ProfileVerificationPersonality")}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <Box sx={{width: "100%"}}>
                        <Box sx={{borderBottom: 1, borderColor: "divider"}}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                aria-label="basic tabs example"
                            >
                                <Tab
                                    label={t("ProfileVerificationFastVerify")}
                                    {...a11yProps(0)}
                                />
                                <Tab
                                    label={t("ProfileVerificationSlowVerify")}
                                    {...a11yProps(1)}
                                />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <AccordionDetails>
                                <div
                                    className="attentionWrapper"
                                    style={{
                                        marginBottom: "50px",
                                        marginLeft: "15px",
                                        borderLeft: " 2px solid blue",
                                    }}
                                >
                                    <div className="wrapperIconAttention">
                                        <PriorityHighIcon style={{color: "blue"}}/>
                                    </div>
                                    <div className="attentionText">
                                        <span>
                                          <p>{t("ProfileVerificationPersonalityAttantion")}</p>
                                            {t("ProfileVerificationPersonalityAttantionFirst")}
                                            <br/>{" "}
                                            {t("ProfileVerificationPersonalityAttantionSecond")}
                                            <br/> {t("ProfileVerificationPersonalityAttantionThird")}
                                            <br/>
                                            {t("ProfileVerificationPersonalityAttantionFourth")}
                                        </span>
                                    </div>
                                </div>
                                <div className="wrapperFileInput">
                                    <VerificationAddPhotos/>
                                </div>
                            </AccordionDetails>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <AccordionDetails>
                                <div
                                    className="attentionWrapper"
                                    style={{
                                        marginBottom: "50px",
                                        marginLeft: "15px",
                                        borderLeft: " 2px solid blue",
                                    }}
                                >
                                    <div className="wrapperIconAttention">
                                        <PriorityHighIcon style={{color: "blue"}}/>
                                    </div>
                                    <div className="attentionText">
                                        <span>
                                          <p>{t("ProfileVerificationPersonalityAttantion")}</p>
                                            {t("ProfileVerificationPersonalityAttantionFirst")}
                                            <br/>{" "}
                                            {t("ProfileVerificationPersonalityAttantionSecond")}
                                            <br/> {t("ProfileVerificationPersonalityAttantionThird")}
                                            <br/>
                                            {t("ProfileVerificationPersonalityAttantionFourth")}
                                        </span>
                                    </div>
                                </div>
                                <div className="wrapperFileInput">
                                    <VerificationAddPhotos/>
                                </div>
                            </AccordionDetails>
                        </CustomTabPanel>
                    </Box>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        {/*userVerify
                        ?
                        <CheckTrue style={{ width: "26px", height: "28px" }} />
                        :*/}
                        <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
                            <CheckFalse style={{width: "26px", height: "28px"}}/>

                            <Typography sx={{marginLeft: "8px"}}>
                                {t("ProfileVerificationEmailTitle")}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <TextField
                            sx={{marginTop: "10px", width: "100%"}}
                            id="outlined-basic"
                            label={t("ProfileVerificationEmailText")}
                            variant="outlined"
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{display: "flex", alignItems: "center"}}
                    >
                        <Box sx={{display: "flex", alignItems: "center", width: "100%"}}>
                               {/*userVerify
                              ?
                              <CheckTrue style={{ width: "26px", height: "28px" }} />
                               :*/}
                            {
                                <CheckFalse style={{width: "26px", height: "28px"}}/>
                            }

                            <Typography sx={{marginLeft: "8px"}}>
                                {t("ProfileVerificationPlace")}
                            </Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div
                            className="attentionWrapper"
                            style={{
                                marginBottom: "50px",
                                marginLeft: "15px",
                                borderLeft: " 2px solid blue",
                            }}
                        >
                            <div className="wrapperIconAttention">
                                <PriorityHighIcon style={{color: "blue"}}/>
                            </div>
                            <div className="attentionText">
                                <span>
                                  <p>- {t("ProfileVerificationPlaceAttantion")}</p>
                                </span>
                            </div>
                        </div>
                        <div
                            className="attentionWrapper"
                            style={{
                                marginBottom: "50px",
                                marginLeft: "15px",
                                borderLeft: " 2px solid blue",
                            }}
                        >
                            <div className="wrapperIconAttention">
                                <PriorityHighIcon style={{color: "blue"}}/>
                            </div>
                            <div className="attentionText">
                                <span>
                                  <p>{t("ProfileVerificationPersonalityAttantion")}</p>
                                    {t("ProfileVerificationPersonalityAttantionFirst")}
                                    <br/> {t("ProfileVerificationPersonalityAttantionSecond")}
                                    <br/> {t("ProfileVerificationPersonalityAttantionThird")}
                                    <br/>
                                    {t("ProfileVerificationPersonalityAttantionFourth")}
                                </span>
                            </div>
                        </div>

                        <Box
                            sx={{
                                marginLeft: "10px",
                                height: "120px",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                            value="1"
                        >
                            <input
                                accept="image/*"
                                style={{display: "none"}}
                                id="upload-button-file"
                                type="file"
                                onChange={(e) => handleChangeForm(e.target.files[0])}
                            />
                            <label htmlFor="upload-button-file">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component="span"
                                    style={{background: "#3C50E0"}}
                                >
                                    {t("ProfileVerificationButton")}
                                </Button>
                            </label>
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </div>
        </PageWrapper>
    );
}

export default Verification;
