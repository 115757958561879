import React, {useEffect, useState} from 'react';
import PartnersNavigation from "./partenrsNavigation";
import PartnersCabinetContainer from "./partnersCabinetContainer";
import Cabinet from "./Cabinet/Cabinet";
import {useMediaQuery, useTheme} from "@mui/material";
import {Box} from "@mui/material";
import PageWrapper from "../../Routes/PageWrapper";
import { useTranslation } from 'react-i18next';
import ModalWindowCabinetConsultant from '../../Modal/ModalWindowCabinetConsultant/ModalWindowCabinetConsultant';

function IndexPartnerOffice() {

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))
    const { t, i18n } = useTranslation();


    const style = {
        width: '100%',
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row'
    }

    useEffect(() => {
      const language = localStorage.getItem('site_settings')
     
          let myObject = JSON.parse(language);
          i18n.changeLanguage(myObject['language'])


},[])

  return (
    <PageWrapper>
    <Box style={style}>
    <PartnersNavigation isSmallScreen={isSmallScreen}/>
    <PartnersCabinetContainer isSmallScreen={isSmallScreen} theme={theme}/>
    {/* <Cabinet isSmallScreen={isSmallScreen}/> */}
    {/* <ModalWindowCabinetConsultant/> */}
    </Box>
    </PageWrapper>
  )
}

export default IndexPartnerOffice;
