import React, { useEffect, useState } from "react";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { useTranslation } from 'react-i18next';

function StepOne () {
  const { t, i18n } = useTranslation();



    const styleActive = {
        border:"1px solid green" ,
        minWidth:'250px',
        minHeight:'160px'
      };

    return (
        <div className="stepOneWrapper">
            <div className="stepOneText">
               {t('BillsMoneyTransactionChooseRecipient')}
            </div>
            <div
            className="networkFirst"
            style={styleActive}
          >
            <div className="iconNetwork">
                <AccountBalanceIcon style={{width:'50px', height:'50px'}}/>
            </div>
            <div style={{padding:'10px', textAlign:'center'}} className="textNetwork"> {t('BillsMoneyTransactionMyBills')} </div>
          </div>
        </div>
    )
}


export default StepOne