import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "../Style/landing/LandingHeader.scss";
import { Link, json } from "react-router-dom";
import "../Style/Modal/SiteSettingsModal.scss";

function LandingHeader() {

  const [language, setLanguage] = useState("");

  const style = {
    background: "none",
    boxShadow: "none",
  };

  const colorButton = {
    color: "white",
  };

  const changeLanguage = (language) => {
    setLanguage(language);
  };

  useEffect(() => {
    const languageColor = localStorage.getItem('site_settings')
    let myObject = JSON.parse(languageColor);
    if (myObject['language'] === 'Русский'){
      setLanguage(true)
    }
    else{
      setLanguage(false)
    }
  },[])

  const changeLanguageRu = (color) => {
    setLanguage(color);
    const language = localStorage.getItem("site_settings");
    if (language) {
      let myObject = JSON.parse(language);

      myObject["language"] = "Русский";

      localStorage.setItem("site_settings", JSON.stringify(myObject));
    } else {
    }
  };
  const changeLanguageEn = (color) => {
    setLanguage(color);
    const language = localStorage.getItem("site_settings");
    if (language) {
      let myObject = JSON.parse(language);

      myObject["language"] = "English";
      localStorage.setItem("site_settings", JSON.stringify(myObject));
    } else {
    }
  };

  return (
    <div className="landingHeaderWrapper">
      <div className="landingHeader">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" style={style}>
            <Toolbar>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                DIAMOND
              </Typography>
              <Link to="registration">
                <Button style={colorButton}>Регистрация / Вход</Button>
              </Link>
              <div className="languageWrapper">
                <div className="language">
                  <a
                    style={{ color: language ? "gold" : "white" }}
                    onClick={() => changeLanguageRu(true)}
                    className="languageTextRu"
                  >
                    RU
                  </a>
                </div>
                <div className="language">
                  <a
                    style={{ color: language ? "white" : "gold" }}
                    onClick={() => changeLanguageEn(false)}
                    className="languageTextEn"
                  >
                    EN
                  </a>
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    </div>
  );
}

export default LandingHeader;
