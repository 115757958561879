// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainPageBody {
  max-width: 87.1440897325%;
  margin: 0 auto;
}

.activeElement {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Style/Body.scss"],"names":[],"mappings":"AAAA;EAGI,yBAAA;EAEA,cAAA;AAFJ;;AAMA;EACI,eAAA;AAHJ","sourcesContent":[".mainPageBody{\n    // background-color: #DCDCDC;\n    // background-color: '#eef1fa'\n    max-width: 1010px / 1159px * 100%;\n    // width: 90%;\n    margin: 0 auto;\n    // height: 10000px;\n}\n\n.activeElement{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
