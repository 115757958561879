import {Route, Routes} from "react-router-dom";
import React from 'react';
import BasicData from "./ProfileSections/BasicData";
import IndexBody from "./ProfileBody";



function RouterProfile() {
    return (
        <>
            <Routes>
                {/* <Route path="/" element={<IndexBody/>}> */}
                <Route path="/profile" element={<BasicData/>}/>
                {/* <Route path="profile/1" element={<BasicData/>}/> */}
                {/* <Route path="/profile" element={<BasicData/>}/> */}
                {/* </Route> */}
            </Routes>
        </>
    );
}

export default RouterProfile;