import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';


const PageWrapper = ({ children }) => {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5 }}
      >
        {children}
      </motion.div>
    );
  };

  export default PageWrapper