import { Route, Routes } from "react-router-dom";
import React from "react";
import Registration from "../Components/indexRegistartion.jsx/Registration";
import IndexLanding from "../indexLanding";
import MainPage from "../Components/MainPage/MainPage";
import Policy from "../landing/PrivacyPolicy";
import IndexProfile from "../Components/Profile/indexProfile";
import ProjectPage from "../Components/ProjectPage/ProjectPage";
import BasicData from "../Components/Profile/ProfileSections/BasicData";
import Contacts from "../Components/Profile/ProfileSections/Contacts";
import Password from "../Components/Profile/ProfileSections/Password";
import Location from "../Components/Profile/ProfileSections/Location";
import Notification from "../Components/Profile/ProfileSections/Notification";
import Verification from "../Components/Profile/ProfileSections/Verification";
import Telegram from "../Components/Profile/ProfileSections/Telegram";
import IndexBills from "../Components/Bills/indexBills";
import Terms from "../landing/TermsOfUse";
import Replenishment from "../Components/Bills/Pages/Replenishment";
import ReplenishmentSuccess from "../Components/Bills/Pages/ReplenishmentSuccess";
import Conclusion from "../Components/Bills/Pages/Conclusion";
import Body from "../Components/MainPage/Body/Body";
import IndexListOperation from "../Components/ListOperation/indexListOperation";
import IndexPartnerOffice from "../Components/partnerOffice/indexPartnerOffice";
import PartnersOffice from "../Components/partnerOffice/partnersOffice";
import InviteStructure from "../Components/partnerOffice/Structure/InviteStructure";
import GraphicalStructure from "../Components/partnerOffice/Structure/GraphicalStructure";
import MoneyTransactionWrapper from "../Components/Bills/Pages/Transaction/MoneyTransactionWrapper";
import MoneyTransaction from "../Components/Bills/Pages/Transaction/MoneyTransaction";
import MoneyTransactionSecond from "../Components/Bills/Pages/Transaction/MoneyTransaction";
import MoneyTransactionThird from "../Components/Bills/Pages/Transaction/MoneyTransaction";
import TermsEn from "../landing/TermsOfUseEn";
import PolicyEN from "../landing/PrivacyPolicyEn";
import Partnership from "../Components/partnerOffice/Partnership/Partnership";
import Events from "../Components/partnerOffice/Events/Events";
import ConsultantAgreement from "../Components/Agreements/Consultant";
import ProjectAgreement from "../Components/Agreements/Project";
import WithdrawAgreement from "../Components/Agreements/Withdraw";
import InvoiceAgreement from "../Components/Agreements/Invoice";
import VerificationPage from "../Components/VerificationPage/VerificationPage";
import WalletPage from "../Components/Wallet/WalletPage";

function Router() {
  return (
    <>
      <Routes>
        <Route path="/" element={<IndexLanding />} />
        <Route path="/registration" element={<Registration />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/terms-EN" element={<TermsEn />} />
        <Route path="/replenishment" element={<Replenishment />} />
        <Route
          path="/replenishment-success"
          element={<ReplenishmentSuccess />}
        />
        <Route path="/conclusion" element={<Conclusion />} />
        <Route path="/moneyTransaction" element={<MoneyTransactionWrapper />}>
          <Route path="/moneyTransaction" element={<MoneyTransaction />} />
          <Route
            path="moneyTransactionSecond"
            element={<MoneyTransactionSecond />}
          />
          <Route
            path="moneyTransactionThird"
            element={<MoneyTransactionThird />}
          />
        </Route>
        <Route path="/consultant" element={<IndexPartnerOffice />}>
          <Route path="/consultant" element={<PartnersOffice />} />
          <Route path="invite-structure" element={<InviteStructure />} />
          <Route path="graphical-structure" element={<GraphicalStructure />} />
          <Route path="referral" element={<Partnership />} />
          <Route path="events" element={<Events />} />
        </Route>
        <Route path="/privacy-policy" element={<Policy />} />
        <Route path="/privacy-policy-EN" element={<PolicyEN />} />
        <Route path="/mainpage" element={<MainPage />}>
          <Route path="/mainpage" element={<Body />} />
          <Route path="project" element={<ProjectPage />} />
          <Route path="bills" element={<IndexBills />} />
          <Route path="verification" element={<VerificationPage/>} />
          <Route path="list-operation" element={<IndexListOperation />} />
          <Route path="consultant-agreement" element={<ConsultantAgreement />} />
          <Route path="project-agreement" element={<ProjectAgreement />} />
          <Route path="withdraw-agreement" element={<WithdrawAgreement />} />
          <Route path="invoice" element={<InvoiceAgreement/>} />
          <Route path="wallet" element={<WalletPage/>} />
          <Route path="profile" element={<IndexProfile />}>
            <Route path="profile" element={<BasicData />} />
            {/* <Route path="basicdata" element={<BasicData/>}/> */}
            <Route path="contacts" element={<Contacts />} />
            <Route path="password" element={<Password />} />
            <Route path="location" element={<Location />} />
            <Route path="notification" element={<Notification />} />
            <Route path="verification" element={<Verification />} />
            <Route path="telegram" element={<Telegram />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default Router;
