import React, { useEffect, useState } from "react";
import '../../../../Style/MoneyTransaction/MoneyTransaction.scss'
import TextField from "@mui/material/TextField";
import { useTranslation } from 'react-i18next';

function StepThree () {
  const { t, i18n } = useTranslation();

  
 
    return (
        <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TextField
          autoComplete="off"
          sx={{ marginTop: "10px", width: "50%" }}
          id="outlined-basic"
          label={t('BillsMoneyTransactionThirdStep')}
          variant="outlined"
          InputLabelProps={{ style: {  fontSize: "14px" } }}
        />
      

        <div
          style={{
            width: "50%",
            // height: "70px",
            background: "#f3f3f3",
            marginTop: "8px",
            borderRadius: "10px",
          }}
        >
          <p style={{ marginLeft: "20px", fontSize: "14px" }}> {t('BillsWithdrawalTotalCount')} </p>
          <div style={{marginBottom:'15px' ,marginLeft: "20px", fontSize: "15px" }}>€ 0.00</div>
        </div>
        <div style={{ width: "50%" }}>
          <p style={{ marginLeft: "20px", fontSize: "12px" }}>
            {t('BillsWithdrawalCommission')}
          </p>
        </div>

        <div style={{ width: "50%" }}>
          <p> {t('BillsMoneyTransactionThirdStepFinPasswordDesc')} </p>
          <TextField
            autoComplete="off"
            sx={{ marginTop: "10px", width: "100%" }}
            id="outlined-basic"
            label={t('BillsMoneyTransactionThirdStepFinPassword')}
            variant="outlined"
            InputProps={{ style: { margin: "0px" } }}
            InputLabelProps={{ style: {  fontSize: "14px" } }}
          />
        </div>
      </div>
    )
}

export default StepThree