// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/landingPage.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.landingImageWrapper {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
  height: 100vh;
}

.landingOpacityWrapper {
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/Style/landing/IndexLanding.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;EACA,4BAAA;EACA,0BAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,oCAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;AACJ","sourcesContent":[".landingImageWrapper{\n    background-image: url(../../images/landingPage.webp);\n    background-repeat: no-repeat;\n    background-size: 100% 100%;\n    width: 100%;\n    height: 100%;\n    height: 100vh;\n}\n\n.landingOpacityWrapper{\n    background-color: rgba(0, 0, 0, 0.7);\n    width: 100%;\n    height: 100%;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
