// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contactsWrapper {
  width: 50%;
  min-width: 400px;
}

@media screen and (max-width: 1200px) {
  .contactsWrapper {
    width: 50%;
    max-width: 250px;
  }
}
@media screen and (max-width: 1130px) {
  .contactsWrapper {
    width: 50%;
    max-width: 200px;
  }
}
@media screen and (max-width: 950px) {
  .contactsWrapper {
    width: 50%;
    min-width: 200px;
  }
}
@media screen and (max-width: 600px) {
  .contactsWrapper {
    width: 70%;
    max-width: 400px;
    min-width: 200px;
  }
}
@media screen and (max-width: 420px) {
  .contactsWrapper {
    width: 64%;
    max-width: 350px;
    min-width: 200px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/Contacts.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;AACJ;;AAEA;EACI;IACI,UAAA;IACA,gBAAA;EACN;AACF;AAEA;EACI;IACI,UAAA;IACA,gBAAA;EAAN;AACF;AAGA;EACI;IACI,UAAA;IACA,gBAAA;EADN;AACF;AAIA;EACI;IACI,UAAA;IACA,gBAAA;IACA,gBAAA;EAFN;AACF;AAMA;EACI;IACI,UAAA;IACA,gBAAA;IACA,gBAAA;EAJN;AACF","sourcesContent":[".contactsWrapper{\n    width: 50%;\n    min-width: 400px;\n}\n\n@media screen and (max-width: 1200px) {\n    .contactsWrapper{\n        width: 50%;\n        max-width: 250px;\n    }\n}\n\n@media screen and (max-width: 1130px) {\n    .contactsWrapper{\n        width: 50%;\n        max-width: 200px;\n    }\n}\n\n@media screen and (max-width: 950px) {\n    .contactsWrapper{\n        width: 50%;\n        min-width: 200px;\n    }\n}\n\n@media screen and (max-width: 600px) {\n    .contactsWrapper{\n        width: 70%;\n        max-width: 400px;\n        min-width: 200px;\n    }\n}\n\n\n@media screen and (max-width: 420px) {\n    .contactsWrapper{\n        width: 64%;\n        max-width: 350px;\n        min-width: 200px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
