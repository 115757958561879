// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.verificationWrapper {
  padding: 20px;
}

@media screen and (max-width: 500px) {
  .wrapperFileInput {
    display: flex;
    flex-direction: column;
  }
}`, "",{"version":3,"sources":["webpack://./src/Style/Profile/Verification.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;;AAEA;EAGI;IACI,aAAA;IACA,sBAAA;EADN;AACF","sourcesContent":[".verificationWrapper{\n    padding: 20px;\n}\n\n@media screen and (max-width: 500px) {\n    .verificationWrapper{\n    }\n    .wrapperFileInput{\n        display: flex;\n        flex-direction: column;\n    }\n}\n\n@media screen and (max-width: 380px) {\n    .verificationWrapper{\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
