import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../Style/NameCabinet.scss";
import { TextField, styled } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import urlFromTemplate from "../../../configs/url";
import { ENDPOINTS } from "../../../configs/endpoints";
import { Typography } from "@mui/material";
import { ReactComponent as CheckTrue } from "../../../images/ModalWindowCabinetConsultant/checkTrue.svg";
import { ReactComponent as CheckFalse } from "../../../images/ModalWindowCabinetConsultant/checkFalse.svg";
function Partnership() {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [userActivate, setUserActivate] = useState({});
  const [userActivatePut, setUserActivatePut] = useState({});


  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const handleOpen = (id) => {
    handelGetUser(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Получение всех пользователей
  const handleGetUsers = async () => {
    const apiUrl =
      urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/consultant`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleGetUsers();
  }, []);

  //Получение user по id
  const handelGetUser = async (id) => {
    const apiUrl =
      urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/consultant/${id}`;
    try {
      const response = await axios.get(apiUrl, {
        withCredentials: true,
        headers: {
          Accept: "application/json",
          "Access-Control-Allow-Credentials": "true",
        },
      });
      setUserActivate(response.data);
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(true);
    }
  };

  // Активация пользователя 

  const handleActiveUser = (name) => {
    setUserActivate((prevState) => ({
        ...prevState,
        [name]: true,
      }));
      setUserActivatePut(userActivate)
  }

  useEffect(() => {
    if(userActivatePut.salesConversationsByConsultant === undefined){
      return
    }else {
      handleActiveUserPut();
    }
  },[userActivatePut])

  const handleActiveUserPut = async () => {
    const apiUrl = urlFromTemplate(ENDPOINTS.CONSULTANT_REQUIREMENT) + `/consultant`

    try{
        const response = await axios.put(apiUrl, userActivate ,{
            withCredentials: true,
            headers: {
              Accept: "application/json",
              "Access-Control-Allow-Credentials": "true",
            },
        })
    } catch (error) {
        console.error(error)
    }
  }
  return (
    <>
      <div className="wrapperPartners" id="wrapperPartnership">
        <div className="nameWrapper">
          <span className="nameText">{t("ConsultantDashboard")}</span>
        </div>
        <div className="wrapperBonusPartner" >
          <div className="usersWrapper" style={{ width: "90%"}}>
            <TableContainer
              component={Paper}
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Table
                sx={{ minWidth: 650, width: "90%" }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="right">id</TableCell>
                    <TableCell align="right">login</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {user.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      onClick={() => handleOpen(item.id)}
                    >
                      <TableCell align="right">{item.id}</TableCell>
                      <TableCell align="right">{item.login}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 600 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Название</TableCell>
                <TableCell align="left">Статус</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userActivate ? (
                <>
                <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t(
                          "CabinetPartnerModalWindowThirdTabSalesConversations"
                        )}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.salesConversationsByConsultant ? (
          <CheckTrue style={{ width: "26px", height: "28px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "28px", cursor:'pointer' }} onClick={() => handleActiveUser('salesConversationsByConsultant')} />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t("CabinetPartnerModalWindowThirdTabFunctionality")}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.accountFunctionalityByConsultant ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer' }} onClick={() => handleActiveUser('accountFunctionalityByConsultant')}  />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t("CabinetPartnerModalWindowThirdTabAgreementClient")}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                  {userActivate.clientAgreementByConsultant ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer' }} onClick={() => handleActiveUser('clientAgreementByConsultant')} />
        )}
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell>
                    <Typography>
                      <h3>
                        {t(
                          "CabinetPartnerModalWindowThirdTabAgreementConsultant"
                        )}
                      </h3>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {userActivate.consultantAgreementByConsultant ? (
          <CheckTrue style={{ width: "26px", height: "26px" }} />
        ) : (
          <CheckFalse style={{ width: "26px", height: "26px", cursor:'pointer'}} onClick={() => handleActiveUser("consultantAgreementByConsultant")}  />
        )}
                  </TableCell>
                </TableRow>
                </>
              ) : null}
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </>
  );
}

export default Partnership;
