import React, {useEffect, useState} from "react";
import urlFromTemplate from "../../configs/url";
import {ENDPOINTS} from "../../configs/endpoints";
import axios from "axios";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useTranslation} from "react-i18next";
import {ReactComponent as Withdraw} from '../../images/Verification/checkTrue.svg'
import {ReactComponent as Decline} from '../../images/Agreement/decline.svg'
import {ReactComponent as Pending} from '../../images/Verification/pending.svg'
import {ReactComponent as Agreement} from '../../images/incomeTable/Agreement.svg'
import ModalWindowAddWallet from "../../Modal/ModalWindowAddWallet";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";


function WalletPage() {

    const [wallets, setWallets] = useState([])
    const [walletHistory, setWalletHistory] = useState([]);
    const [isAddWalletModalOpened, setIsAddWalletModalOpened] = useState(false)
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [chosenWallet, setChosenWallet] = useState();
    const [deleteReason, setDeleteReason] = useState('')
    const [updateReason, setUpdateReason] = useState('')
    const [updatedWalletNumber, setUpdatedWalletNumber] = useState('');

    const [openReason, setOpenReason] = useState(false);
    const [currentReason, setCurrentReason] = useState('');

    const {t, i18n} = useTranslation();

    useEffect(() => {
        retrieveWallets()
        retrieveWalletHistory();
    }, [])

    useEffect(() => {
        if (chosenWallet) {
            setUpdatedWalletNumber(chosenWallet.number);
        }
    }, [chosenWallet]);

    const changeUpdatedWalletNumber = (e) => {
        const changedWalletNumber = e.target.value;
        setUpdatedWalletNumber(changedWalletNumber)
    }

    const retrieveWallets = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setWallets(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const retrieveWalletHistory = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET_LOG)
        try {
            const response = await axios.get(apiUrl, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            setWalletHistory(response.data)
        } catch (error) {
            console.error(error)
        }
    }

    const switchModalVisibility = () => {
        if (isAddWalletModalOpened) {
            retrieveWallets()
            retrieveWalletHistory()
        }
        setIsAddWalletModalOpened(!isAddWalletModalOpened)
    }

    //Форматирование даты
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");

        return `${day}.${month}.${year} ${hours}:${minutes}`;
    };

    const requestUpdateWallet = async () => {
        const payload = {
            number: updatedWalletNumber,
            currency: chosenWallet.currency,
            reason: updateReason,
        }
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET)

        try {
            await axios.put(apiUrl, payload, {
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            await retrieveWallets();
            await retrieveWalletHistory()
            handleCloseUpdate();
        } catch (error) {
            console.error(error)
        }
    }

    const requestDeleteWallet = async () => {
        const payload = {
            currency: chosenWallet.currency,
            reason: deleteReason,
        }
        const apiUrl = urlFromTemplate(ENDPOINTS.WALLET)

        try {
            await axios.delete(apiUrl, {
                data: payload,
                withCredentials: true,
                headers: {
                    Accept: "application/json",
                    "Access-Control-Allow-Credentials": "true",
                },
            })
            await retrieveWallets();
            await retrieveWalletHistory()
            handleCloseDelete();
        } catch (error) {
            console.error(error)
        }
    }

    const getStatusMessage = (status) => {
        switch (status) {
            case 'PENDING':
                return <Pending style={{width: "25px", height: '25px'}}/>;
            case 'CONFIRMED':
                return <Withdraw style={{width: "25px", height: '25px'}}/>;
            case 'DECLINED':
                return <Decline style={{width: "25px", height: '25px'}}/>;
            default:
                return 'Unknown status.';
        }
    }

    const handleOpenReason = (reason) => {
        setCurrentReason(reason);
        setOpenReason(true);
    }

    const handleCloseReason = () => {
        setCurrentReason('');
        setOpenReason(false);
    }

    const handleCloseUpdate = () => {
        setOpenUpdate(false);
        setUpdatedWalletNumber('');
        setUpdateReason('');
        setChosenWallet();
    }

    const handleChangeUpdate = (event) => {
        const newValue = event.target.value
        setUpdateReason(newValue)
    }

    const handleClickOpenUpdate = (wallet) => {
        setOpenUpdate(true)
        setChosenWallet(wallet)
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
        setDeleteReason('');
        setChosenWallet();
    }

    const handleChangeDelete = (event) => {
        const newValue = event.target.value
        setDeleteReason(newValue)
    }

    const handleClickOpenDelete = (wallet) => {
        setOpenDelete(true)
        setChosenWallet(wallet)
    }

    return (
        <div className="mainPageBody important-margin-top">
            <div style={{display: 'flex', justifyContent: 'space-between', width: '95%', alignItems: 'center'}}>
                <span style={{fontSize: "30px"}}>{t("Wallets")}</span>
                <button className="yellowButton" onClick={switchModalVisibility}>
                    {t("CreateWallet")}
                </button>
            </div>
            <div className="wrapperIncomeTableTransaction">
                <TableContainer component={Paper} style={{boxShadow: "none"}}>
                    <Table
                        aria-label="simple table">
                        <TableHead
                            style={{background: "#F7F9FC", border: "1px solid white"}}
                        >
                            <TableRow style={{border: "none"}}>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("WalletNumber")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementCurrency")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                    align="left"
                                >
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {wallets.length > 0 && wallets.map((row) => (
                                <TableRow
                                    key={row.is}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.number}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        align="left"
                                    >
                                        {row.currency}
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            onClick={() => handleClickOpenUpdate(row)}
                                            variant="contained"
                                            color="info"
                                        >
                                            {" "}
                                            {t("ChangeWallet")}{" "}
                                        </Button>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Button
                                            onClick={() => handleClickOpenDelete(row)}
                                            variant="contained"
                                            color="error"
                                        >
                                            {" "}
                                            {t("DeleteWallet")}{" "}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <ModalWindowAddWallet isOpen={isAddWalletModalOpened} onClose={switchModalVisibility}/>
            {chosenWallet && <>
                <Dialog open={openUpdate} onClose={handleCloseUpdate} maxWidth="lg">
                    <DialogTitle>
                        {t('UpdateWalletTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{marginBottom: '10px'}}>{t('AgreementCurrency')}: {chosenWallet.currency} </div>
                        <div style={{marginBottom: '5px'}}>{t('WalletNumber')}:</div>
                        <TextField
                            value={updatedWalletNumber}
                            onChange={(e) => changeUpdatedWalletNumber(e)}
                            style={{width: '500px', marginBottom: '10px'}}>
                        </TextField>
                        <div>{t('updateReason')}</div>
                        <textarea
                            value={updateReason}
                            onChange={(e) => handleChangeUpdate(e)}
                            style={{width: '500px', height: '100px', marginTop: '2px'}}>
                        </textarea>
                    </DialogContent>
                    <Button onClick={() => requestUpdateWallet()}>
                        {t('requestUpdate')}
                    </Button>
                </Dialog>
                <Dialog open={openDelete} onClose={handleCloseDelete} maxWidth="lg">
                    <DialogTitle>
                        {t('DeleteWalletTitle')}
                    </DialogTitle>
                    <DialogContent>
                        <div style={{marginBottom: '5px'}}>{t('WalletNumber')}: {chosenWallet.number}</div>
                        <div style={{marginBottom: '15px'}}>{t('AgreementCurrency')}: {chosenWallet.currency} </div>
                        <div>{t('deleteReason')}</div>
                        <textarea
                            value={deleteReason}
                            onChange={(e) => handleChangeDelete(e)}
                            style={{width: '500px', height: '100px'}}>
                        </textarea>
                    </DialogContent>
                    <Button onClick={() => requestDeleteWallet()}>
                        {t('requestDelete')}
                    </Button>
                </Dialog>
            </>
            }
            <span style={{fontSize: "30px"}}>{t("WalletHistory")}</span>
            <div className="wrapperIncomeTableTransaction">
                <TableContainer component={Paper} style={{boxShadow: "none"}}>
                    <Table
                        aria-label="simple table">
                        <TableHead
                            style={{background: "#F7F9FC", border: "1px solid white"}}
                        >
                            <TableRow style={{border: "none"}}>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("AgreementTime")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("Type")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("OldWalletNumber")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("NewWalletNumber")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("Currency")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("Reason")}</b>
                                </TableCell>
                                <TableCell
                                    style={{
                                        border: "none",
                                        fontFamily: "Helvetica Neue",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "22px",
                                        textAlign: "left",
                                        color: "#64748B",
                                    }}
                                >
                                    <b>{t("status")}</b>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {walletHistory.length > 0 && walletHistory.map((row) => (
                                <TableRow
                                    key={row.is}
                                    sx={{"&:last-child td, &:last-child th": {border: 0}}}
                                >
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {formatDate(row.dateTime)}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.type}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: row.oldNumber ? "left" : "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.oldNumber ? row.oldNumber: '—'}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: row.newNumber ? "left" : "center",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.newNumber ? row.newNumber : '—'}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            color: "#10B981",
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {row.currency}
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        <Agreement
                                            style={{
                                                width: '25px',
                                                height: '25px'
                                            }}
                                            onClick={() => handleOpenReason(row.requestReason)}
                                        />
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            paddingLeft: "16px",
                                            padding: "25px",
                                            fontFamily: "Helvetica Neue",
                                            fontSize: "16px",
                                            fontWeight: "400",
                                            lineHeight: "24px",
                                            textAlign: "left",
                                        }}
                                        component="th"
                                        scope="row"
                                    >
                                        {getStatusMessage(row.status)}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Dialog open={openReason} onClose={handleCloseReason} maxWidth="lg">
                <DialogTitle>
                    {t('Reason')}:
                </DialogTitle>
                <DialogContent>
                <textarea
                    value={currentReason}
                    disabled
                    style={{width: '500px', height: '100px'}}>

                </textarea>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default WalletPage