// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapperEvents {
  max-width: 87.1440897325%;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  min-height: 636px;
}`, "",{"version":3,"sources":["webpack://./src/Style/Events/Events.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,WAAA;EACA,cAAA;EACA,sBAAA;EACA,iBAAA;AACJ","sourcesContent":[".wrapperEvents{\n    max-width: 1010px / 1159px * 100%;\n    width: 100%;\n    margin: 0 auto;\n    box-sizing: border-box;\n    min-height: 636px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
