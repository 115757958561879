import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useTranslation } from "react-i18next";
import urlFromTemplate from '../configs/url';
import { ENDPOINTS } from '../configs/endpoints';
import axios from 'axios';
import '../Style/PhotoEditor/PhotoEditor.scss'




function PhotoDeleteModal ({open, handleClose, handleRerender})  {
    const { t, i18n } = useTranslation();

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius:'20px',
      };

      const handleDelete = async () => {
        const apiUrl = urlFromTemplate(ENDPOINTS.USER) + `/avatar`
        try{
          await axios.delete(apiUrl, {
            withCredentials: true,
            headers:{
              "Accept": "application/json",
              "Access-Control-Allow-Credentials": "true",
            }
          }).then((response) => {
            handleRerender()
            handleClose()
          })
        } catch (error) {
          console.error(error);
        }

      }

return(


    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box className='photoEditor' sx={style}>
      <h2>
        {t('MyCabinetModalWindowDeleteTitle')}
      </h2>
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          {t('MyCabinetModalWindowDeleteNo')}
        </Button>
        <Button variant="contained" color="primary" onClick={handleDelete} >
          {t('MyCabinetModalWindowDeleteYes')}
        </Button>
      </Box>
    </Box>
  </Modal>
)
}

export default PhotoDeleteModal










