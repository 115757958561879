// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registrationWrapper {
  height: 100vh;
}`, "",{"version":3,"sources":["webpack://./src/Style/registration/Registration.scss"],"names":[],"mappings":"AAAA;EAMI,aAAA;AAJJ","sourcesContent":[".registrationWrapper{\n    // background-image: url(../../images/registration.jpg);\n    // background-repeat: no-repeat;\n    // background-size: 100% 100%;\n    // width: 100%;\n    // height: 100%;\n    height: 100vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
